export type ButtonTypes =
  | "primary"
  | "outline"
  | "outlineWhite"
  | "red"
  | "orange";
export type ButtonSizes = "sm" | "md" | "lg";

export const getButtonStyle = ({
  type,
  size,
}: {
  type: ButtonTypes;
  size: ButtonSizes;
}): { buttonClass: string; textClass: string } => {
  const buttonClassMap = {
    primary:
      "flex items-center border-2 justify-center font-semibold text-base rounded-md bg-primary-color-100 hover:bg-primary-color-80 hover:border-solid hover:border-primary-color-100 w-full",
    red: "flex items-center justify-center font-semibold text-base rounded-md bg-contrast-color-1-100 w-full hover:bg-contrast-color-1-80",
    orange:
      "flex items-center justify-center font-semibold text-base rounded-md bg-contrast-color-2-100 w-full hover:bg-opacity-80 hover:border-solid hover:border-opacity-75",
    outline:
      "flex items-center justify-center font-semibold text-base rounded-md text-base bg-transparent border-primary-color-100 border-2 hover:bg-primary-color-100 hover:bg-opacity-20 hover:border-solid hover:border-primary-color-100 w-full",
    outlineWhite:
      "flex items-center justify-center font-semibold text-base rounded-md bg-transparent border border-solid border-neutral-color-1 w-full",
  };
  const buttonStyleMap = {
    primary: {
      sm: {
        buttonClass: buttonClassMap.primary,
        textClass: "flex px-4 font-opensans py-1 text-white",
      },
      md: {
        buttonClass: buttonClassMap.primary,
        textClass: "flex px-4 font-opensans py-2 text-white",
      },
      lg: {
        buttonClass: buttonClassMap.primary,
        textClass:
          "flex px-4 font-opensans py-4 text-white justify-center w-full",
      },
    },
    red: {
      sm: {
        buttonClass: buttonClassMap.red,
        textClass: "flex px-4 font-opensans py-1 text-primary-color-100",
      },
      md: {
        buttonClass: buttonClassMap.red,
        textClass: "flex px-4 font-opensans py-2 text-primary-color-100",
      },
      lg: {
        buttonClass: buttonClassMap.red,
        textClass:
          "flex px-4 font-opensans py-4 text-primary-color-100 justify-center w-full",
      },
    },
    orange: {
      sm: {
        buttonClass: buttonClassMap.orange,
        textClass: "flex px-4 font-opensans py-1 text-primary-color-100",
      },
      md: {
        buttonClass: buttonClassMap.orange,
        textClass: "flex px-4 font-opensans py-2 text-primary-color-100",
      },
      lg: {
        buttonClass: buttonClassMap.orange,
        textClass:
          "flex px-4 font-opensans py-4 text-primary-color-100 justify-center w-full",
      },
    },
    outline: {
      sm: {
        buttonClass: buttonClassMap.outline,
        textClass: `flex px-4 font-opensans py-1 text-primary-color-100`,
      },
      md: {
        buttonClass: buttonClassMap.outline,
        textClass: `flex px-4 font-opensans py-2 text-primary-color-100`,
      },
      lg: {
        buttonClass: buttonClassMap.outline,
        textClass:
          "flex px-4 font-opensans py-4 text-primary-color-100 justify-center w-full",
      },
    },
    outlineWhite: {
      sm: {
        buttonClass: buttonClassMap.outlineWhite,
        textClass: `flex px-4 font-opensans py-1 text-neutral-color-1`,
      },
      md: {
        buttonClass: buttonClassMap.outlineWhite,
        textClass: `flex px-4 font-opensans py-2 text-neutral-color-1`,
      },
      lg: {
        buttonClass: buttonClassMap.outlineWhite,
        textClass:
          "flex px-4 font-opensans py-4 text-neutral-color-1 justify-center w-full",
      },
    },
  };
  return buttonStyleMap[type][size] || { buttonClass: "", textClass: "" };
};
