/* eslint-disable no-underscore-dangle */
import React, { useContext, useMemo } from "react";

// packages
import { useLocation } from "react-router-dom";

// ts
import { Location } from "../../types";

// store
import { Context } from "../../store";
import { useAuth } from "../../hooks";
import Button from "../../components/Button";

const Order = (): JSX.Element => {
  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { email, first_name = "" },
      },
    },
  } = useContext(Context);
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { orderDetail, guestUserEmail, guestUserName } = (
    location as unknown as Location
  )?.state;

  const message = useMemo(() => {
    let messageObj = <div />;
    if (orderDetail?.status !== "success") {
      messageObj = (
        <div className="flex flex-col gap-4">
          <div className="flex">
            <h3>Failed to process your order, please try again!</h3>
          </div>
        </div>
      );
    } else {
      messageObj = (
        <div className="flex flex-col items-center gap-4 w-auto py-8 bg-neutral-color-1 px-0 border border-primary-color-100 border-opacity-10 sm:px-6 md:shadow-xl">
          <div className="flex">
            <h2 className="w-full break-words text-primary-color-100">
              {`Thank you for your order ${first_name || guestUserName}`}
            </h2>
          </div>
          {orderDetail?.id && (
            <div className="w-full inline-flex flex-wrap justify-center">
              <p className="flex pr-2 text-xl">Your order number is</p>
              <p className="break-all font-bold text-xl">{`${orderDetail.id}`}</p>
            </div>
          )}
          {(email || guestUserEmail) && (
            <div className="flex w-full flex-col flex-wrap">
              <p>We&apos;ve sent a receipt with your order details to</p>
              <p className="break-all text-neutral-color-3">{`${
                email || guestUserEmail
              }`}</p>
            </div>
          )}
          {isAuthenticated && (
            <a
              style={{ textDecoration: "none" }}
              href={`${window.location.origin}/account/orders-reviews`}
            >
              <Button
                text="View My Orders"
                size="sm"
                type="primary"
                onClick={() => null}
              />
            </a>
          )}
        </div>
      );
    }
    const messageObjWithExtraDetails = (
      <div className="flex flex-col justify-center items-center my-3 w-full">
        {messageObj}
      </div>
    );
    return messageObjWithExtraDetails;
  }, [
    orderDetail,
    first_name,
    email,
    guestUserEmail,
    guestUserName,
    isAuthenticated,
  ]);

  return (
    <div className="flex flex-col items-center justify-center w-full m-auto mb-4 px-2 py-4 max-w-xl md:px-4 md:py-6 md:max-w-3xl lg:max-w-6xl xl:max-w-screen-2xl">
      <div className="flex w-full">{message}</div>
    </div>
  );
};
export default Order;
