import { IDropdownComponentStyles } from "./types";

export type DropdownTypes = "primary" | "outline" | "outlineWhite";
export type DropdownSizes = "xs" | "sm" | "lg" | "fullWidth" | "custom";
export type DropdownLabelSizes = "sm" | "lg";
export type AddressFormStyle = false | true;

export interface IDropdownOption {
  label: string;
  value: string | number;
}

export const getButtonStyle = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  size,
  useAddressFormStyle,
}: {
  type: DropdownTypes;
  size: DropdownSizes;
  useAddressFormStyle: AddressFormStyle;
}): {
  buttonClass: string;
  textClass: string;
  dropdownComponentStyles: IDropdownComponentStyles;
} => {
  const widthStyle = {
    lg: "400px",
    sm: "195px",
    xs: "150px",
    fullWidth: "100%",
    custom: "",
  };
  const textSize = {
    lg: "inherit",
    sm: "15px",
    xs: "15px",
    fullWidth: "inherit",
    custom: "inherit",
  };
  const dropdownComponentStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    container: (provided: any) => ({
      ...provided,
      // eslint-disable-next-line no-nested-ternary
      width: widthStyle[size],
      minWidth: "80px",
    }),
    valueContainer: (provided: Record<string, unknown>) => ({
      ...provided,
      padding: useAddressFormStyle ? "" : "inherit",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    }),
    input: (provided: Record<string, unknown>) => ({
      ...provided,
      color: useAddressFormStyle ? "rgba(63, 65, 91, 1)" : "inherit",
      background: useAddressFormStyle ? "#f8fafc" : "inherit",
      width: useAddressFormStyle ? "100%" : "inherit",
      lineHeight: useAddressFormStyle ? "1.5rem" : "inherit",
      padding: useAddressFormStyle ? "0.75rem" : "inherit",
      outline: useAddressFormStyle ? "2px solid transparent" : "inherit",
      outlineOffset: useAddressFormStyle ? "2px" : "inherit",
      fontWeight: useAddressFormStyle ? "400" : "inherit",
      fontSize: useAddressFormStyle ? "1rem" : "inherit",
      fontFamily: useAddressFormStyle ? "OpenSans, sans-serif" : "inherit",
      textAlign: useAddressFormStyle ? "left" : "inherit",
    }),
    menu: (provided: Record<string, unknown>) => ({
      ...provided,
      textAlign: useAddressFormStyle ? "left" : "inherit",
      fontWeight: useAddressFormStyle ? "400" : "inherit",
      fontSize: useAddressFormStyle ? "1rem" : "inherit",
      fontFamily: useAddressFormStyle ? "OpenSans, sans-serif" : "inherit",
      zIndex: 99999,
      overflowY: "auto",
      paddingBottom: "5px",
    }),
    control: (provided: Record<string, unknown>) => ({
      ...provided,
      textAlign: useAddressFormStyle ? "left" : "inherit",
      fontWeight: useAddressFormStyle ? "400" : "inherit",
      fontSize: useAddressFormStyle ? "1rem" : textSize[size],
      fontFamily: useAddressFormStyle ? "OpenSans, sans-serif" : "inherit",
    }),
    singleValue: (provided: Record<string, unknown>) => ({
      ...provided,
      padding: useAddressFormStyle ? "0.75rem" : "inherit",
    }),
    menuPortal: (provided: Record<string, unknown>) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided: Record<string, unknown>) => ({
      ...provided,
      margin: 0, // Adjust margin
      padding: 0, // Adjust padding
      maxHeight: "150px",
    }),
  };
  return { buttonClass: "", textClass: "", dropdownComponentStyles };
};
