// ts
import { TypeOfState } from "../../components/TextInput/TextInput.utils";
import { Variant } from "../Product/types";

export const MAIN_PLACEHOLDER_IMAGE_KEY = "main_placeholder";
export const OTHER_PLACEHOLDER_IMAGE_KEY = "other_placeholder";
export const MAIN_IMAGE_KEY = "main_image";
export const OTHER_IMAGE_KEY = "other_image";
export const PLACE_HOLDER_IMAGE_KEYS = [
  MAIN_PLACEHOLDER_IMAGE_KEY,
  OTHER_PLACEHOLDER_IMAGE_KEY,
];
export const MAIN_IMAGE_PLACEHOLDER_TEXT =
  "Click here to add front(main) photo";
export const OTHER_IMAGE_PLACEHOLDER_TEXT = "Click here to add other photo(s)";
export const SIZE = 150;
export const PRODUCT_WEIGHT_UNITS = {
  oz: "ounces",
  lbs: "pounds",
  gms: "grams",
} as { [key: string]: string };
export const PRODUCT_DIMENSIONS_UNIT = {
  in: "inches",
  cm: "centimeters",
} as { [key: string]: string };
export const SHIPPING_COST_TYPES = {
  "Free shipping": "free",
  "Flat rate shipping": "flatrate",
  "Dynamic shipping": "dynamic",
} as { [key: string]: string };
export const DEFAULT_ADD_PRODUCT_DETAILS = {
  id: { value: "", isRequired: true, elementState: "normal" as TypeOfState },
  name: { value: "", isRequired: true, elementState: "normal" as TypeOfState },
  shop_id: {
    value: "",
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  category: {
    label: "",
    value: "",
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  description: {
    value: "",
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  tags: {
    value: [] as string[],
    isRequired: false,
    elementState: "normal" as TypeOfState,
  },
  variants: {
    value: {} as { [key: string]: Variant },
    isRequired: false,
    elementState: "normal" as TypeOfState,
  },
  has_variant: {
    value: false,
    isRequired: false,
    elementState: "normal" as TypeOfState,
  },
  originZipCode: {
    value: "",
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  estimated_processing_time: {
    value: 0,
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  sku: { value: "", isRequired: false, elementState: "normal" as TypeOfState },
  weight_value: {
    value: 0,
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  weight_units: {
    value: "pounds",
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  dimensions_length: {
    value: 0,
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  dimensions_width: {
    value: 0,
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  dimensions_height: {
    value: 0,
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  dimensions_units: {
    value: "inches",
    isRequired: true,
    elementState: "normal" as TypeOfState,
  },
  published: {
    value: false,
    isRequired: false,
    elementState: "normal" as TypeOfState,
  },
};

export const CATEGORIES = [
  {
    label: "Artist Trading Cards",
    value: "artist_trading_cards",
  },
  {
    label: "Skin Care",
    value: "skin_care",
  },
  {
    label: "Sponges & Body Brushes",
    value: "sponges_and_body_brushes",
  },
  {
    label: "Spa & Relaxation",
    value: "spa_and_relaxation",
  },
  {
    label: "Pacifiers & Clips",
    value: "pacifiers_and_clips",
  },
  {
    label: "Bath Accessories",
    value: "bath_accessories",
  },
  {
    label: "Makeup & Cosmetics",
    value: "makeup_and_cosmetics",
  },
  {
    label: "Bug Repellent",
    value: "bug_repellant",
  },
  {
    label: "Deodorant",
    value: "deodorant",
  },
  {
    label: "Bath Bombs",
    value: "bath_bombs",
  },
  {
    label: "Cold & Heat Packs",
    value: "cold_and_heat_packs",
  },
  {
    label: "Dolls & Miniatures",
    value: "dolls_and_miniatures",
  },
  {
    label: "Sunglasses & Eyewear",
    value: "sunglasses_and_eyewear",
  },
  {
    label: "Belts & Suspenders",
    value: "belts_and_suspenders",
  },
  {
    label: "Luggage & Travel",
    value: "luggage_travel",
  },
  {
    label: "Messenger Bags",
    value: "messenger_bags",
  },
  {
    label: "Hair Styling",
    value: "hair_styling",
  },
  {
    label: "Salves & Balms",
    value: "salves_and_balms",
  },
  {
    label: "Glass Art",
    value: "glass_art",
  },
  {
    label: "Hair Dye & Color",
    value: "hair_dye_and_color",
  },
  {
    label: "Exfoliation & Peels",
    value: "exfoliation_and_peels",
  },
  {
    label: "Diaper Bags",
    value: "diaper_bags",
  },
  {
    label: "Market Bags",
    value: "market_bags",
  },
  {
    label: "Drawing & Illustration",
    value: "drawing_and_illustration",
  },
  {
    label: "Massage",
    value: "massage",
  },
  {
    label: "Makeup Tools & Brushes",
    value: "tools_and_brushes",
  },
  {
    label: "Bleaching & Fade Creams",
    value: "bleaching_and_fade_creams",
  },
  {
    label: "Face Masks & Coverings",
    value: "face_masks_and_coverings",
  },
  {
    label: "Tinctures",
    value: "tinctures",
  },
  {
    label: "painting",
    value: "painting",
  },
  {
    label: "Scarves & Wraps",
    value: "scarves_and_wraps",
  },
  {
    label: "Moisturizers",
    value: "moisturizers",
  },
  {
    label: "Face",
    value: "makeup_and_cosmetics_face",
  },
  {
    label: "Tattooing & Henna",
    value: "tattooing_and_henna",
  },
  {
    label: "Eye Treatments",
    value: "eye_treatments",
  },
  {
    label: "Prints",
    value: "prints",
  },
  {
    label: "Diapers",
    value: "diapers",
  },
  {
    label: "Fanny Packs",
    value: "fanny_packs",
  },
  {
    label: "Supplements",
    value: "supplements",
  },
  {
    label: "Umbrellas & Rain Accessories",
    value: "umbrellas_and_rain_accessories",
  },
  {
    label: "Aromatherapy",
    value: "aromatherapy",
  },
  {
    label: "Photography",
    value: "photography",
  },
  {
    label: "Costume Accessories",
    value: "costume_accessories",
  },
  {
    label: "Sets",
    value: "sets",
  },
  {
    label: "Shower Caps",
    value: "shower_caps",
  },
  {
    label: "Wallets & Money clips",
    value: "wallets_and_money_clips",
  },
  {
    label: "Body Washes & Liquid Soaps",
    value: "body_washes_and_liquid_soaps",
  },
  {
    label: "Eyes",
    value: "makeup_and_cosmetics_eyes",
  },
  {
    label: "Canes & Walking",
    value: "canes_and_walking",
  },
  {
    label: "Bouquets & Corsages",
    value: "bouquets_and_corsages",
  },
  {
    label: "Bags & Purses",
    value: "bags_and_purses",
  },
  {
    label: "Tattooing",
    value: "tattooing",
  },
  {
    label: "Art & Collectibles",
    value: "Art_and_Collectibles",
  },
  {
    label: "Pouches & coin purses",
    value: "pouches_and_coin_purses",
  },
  {
    label: "Bath Oils",
    value: "bath_oils",
  },
  {
    label: "Suit & Tie Accessories",
    value: "suit_and_tie_accessories",
  },
  {
    label: "Totes",
    value: "totes",
  },
  {
    label: "Backpacks",
    value: "backpacks",
  },
  {
    label: "Bar Soaps",
    value: "bar_soaps",
  },
  {
    label: "Gloves & Mittens",
    value: "gloves_and_mittens",
  },
  {
    label: "Accessories",
    value: "accessories",
  },
  {
    label: "Baby & Child Care",
    value: "baby_child_care",
  },
  {
    label: "Bibs & Burping",
    value: "bibs_and_burping",
  },
  {
    label: "Facial Toner",
    value: "facial_toner",
  },
  {
    label: "Patches & Pins",
    value: "patches_and_pins",
  },
  {
    label: "Hats & Caps",
    value: "hats_and_caps",
  },
  {
    label: "Fine Art Ceramics",
    value: "fine_art_ceramics",
  },
  {
    label: "Loofahs",
    value: "loofahs",
  },
  {
    label: "Food & insulated Bags",
    value: "food_and_insulated_bags",
  },
  {
    label: "Sculpture",
    value: "sculpture",
  },
  {
    label: "Sachets & Soaks",
    value: "sachets_and_soaks",
  },
  {
    label: "Accessory Cases",
    value: "accessory_cases",
  },
  {
    label: "Face Mask Accessories",
    value: "face_mask_accessories",
  },
  {
    label: "Bath Salts & Scrubs",
    value: "bath_salts_and_scrubs",
  },
  {
    label: "Shaving & Grooming",
    value: "shaving_and_grooming",
  },
  {
    label: "Teething",
    value: "teething",
  },
  {
    label: "Wigs",
    value: "wigs",
  },
  {
    label: "Hair Care",
    value: "hair_care",
  },
  {
    label: "Bubble Bath",
    value: "bubble_bath",
  },
  {
    label: "Facial Care",
    value: "facial_care",
  },
  {
    label: "Mixed Media & Collage",
    value: "mixed_media_and_collage",
  },
  {
    label: "Sports Bags",
    value: "sports_bags",
  },
  {
    label: "Fragrances",
    value: "fragrances",
  },
  {
    label: "Feeding",
    value: "feeding",
  },
  {
    label: "Oral Care",
    value: "oral_care",
  },
  {
    label: "Changing Pads",
    value: "changing_pads",
  },
  {
    label: "Facial Scrubs & Washes",
    value: "facial_scrubs_and_washes",
  },
  {
    label: "Fiber Arts",
    value: "fiber_arts",
  },
  {
    label: "Hair Accessories",
    value: "hair_accessories",
  },
  {
    label: "Soaps",
    value: "soaps",
  },
  {
    label: "Nursing",
    value: "nursing",
  },
  {
    label: "Spa Kits & Gifts",
    value: "spa_kits_and_gifts",
  },
  {
    label: "Henna",
    value: "henna",
  },
  {
    label: "Collectibles",
    value: "collectibles",
  },
  {
    label: "Conditioners & Treatments",
    value: "conditioners_and_treatments",
  },
  {
    label: "Bath & Beauty",
    value: "bath_and_beauty",
  },
  {
    label: "Clothing & Shoe Bags",
    value: "clothing_and_shoe_bags",
  },
  {
    label: "Hair Extensions",
    value: "hair_extensions",
  },
  {
    label: "Handbags",
    value: "handbags",
  },
  {
    label: "Shampoos",
    value: "shampoos",
  },
  {
    label: "Lips",
    value: "makeup_and_cosmetics_lips",
  },
  {
    label: "Poufs",
    value: "poufs",
  },
  {
    label: "Keychains & Lanyards",
    value: "keychains_and_lanyards",
  },
  {
    label: "Skin Treatment Masks",
    value: "skin_treatment_masks",
  },
  {
    label: "Latkans",
    value: "latkans",
  },
  {
    label: "Massage Oils",
    value: "massage_oils",
  },
  {
    label: "Tattoo Care",
    value: "tattoo_care",
  },
  {
    label: "Washcloths",
    value: "washcloths",
  },
  {
    label: "Essential Oils",
    value: "essential_oils",
  },
  {
    label: "Baby Accessories",
    value: "baby_accessories",
  },
  {
    label: "Hand Fans",
    value: "hand_fans",
  },
  {
    label: "Personal Care",
    value: "personal_care",
  },
  {
    label: "Massage Tools",
    value: "massage_tools",
  },
  {
    label: "Cosmetic & Toiletry Storage",
    value: "cosmetic_and_toiletry_storage",
  },
];

export const STANDARD_SIZES = [
  "XSmall",
  "Small",
  "Medium",
  "Large",
  "XLarge",
  "XXLarge",
];
export const STANDARD_COLORS = [
  "White",
  "Black",
  "Red",
  "Blue",
  "Green",
  "Yellow",
  "Orange",
  "Grey",
];
