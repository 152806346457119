import React, { useState, useContext, useMemo, useEffect } from "react";

// packages
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

// components
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";

// actions
import { isShopNameAvailable } from "../actions";

// icons
import { closeRed, tickGreen } from "../../../assets";

// context
import SellOnLonimaContext from "../SellOnLonima.context";
import { Shop } from "../../../store/types";
import { SHOP_CREATION_ERROR } from "../constants";
import { getItem, setItem } from "../../../utils";

export const ShopName = ({ shops }: { shops: Array<Shop> }): JSX.Element => {
  const [availabilityState, setAvailabilityState] = useState({
    type: "",
    value: "",
  });
  const { name, setName, setIsNameAvailable } = useContext(SellOnLonimaContext);
  const localShopName = getItem("temp_shop_name") as string;
  const [shopName, setShopName] = useState(localShopName);

  const history = useHistory();

  useEffect(() => {
    if (shops?.length > 0) {
      history.push(`/shop/${shops[0].shop_id}`);
    }
  }, [shops, history]);

  useEffect(() => {
    if (name) {
      setShopName(name);
    }
  }, [name]);

  const updateIsAvailable = (
    nameStr: string,
    isAvailable: boolean,
    saveForNextStep = false
  ) => {
    if (!isAvailable) {
      setAvailabilityState({
        type: "warning",
        value: `Unfortunately, ${nameStr} is already taken.`,
      });
    } else {
      setAvailabilityState({
        type: "success",
        value: `Yay, ${nameStr} is available!`,
      });
      if (saveForNextStep) {
        if (!!shopName && isAvailable) {
          history.push("/sell-on-lonima/location");
        }
      }
    }
    setIsNameAvailable(isAvailable);
  };

  const updateIsNameUnavailable = (error = SHOP_CREATION_ERROR) => {
    setIsNameAvailable(false);
    setAvailabilityState({
      type: "error",
      value: error,
    });
  };

  const { mutate: isShopNameAvailableMutation } = useMutation(
    isShopNameAvailable,
    {
      onSuccess: ({ data }) => {
        updateIsAvailable(name, data?.value?.available);
      },
      onError: (err: Error) => {
        updateIsNameUnavailable(
          typeof err?.message === "string" && err?.message
            ? err?.message
            : SHOP_CREATION_ERROR
        );
      },
    }
  );

  const { mutate: isShopNameAvailableCreateMutation } = useMutation(
    isShopNameAvailable,
    {
      onSuccess: ({ data }) => {
        updateIsAvailable(shopName, data?.value?.available, true);
      },
      onError: (err: Error) => {
        updateIsNameUnavailable(
          typeof err?.message === "string" && err?.message
            ? err?.message
            : SHOP_CREATION_ERROR
        );
      },
    }
  );

  const isMinNameLength = useMemo(
    () => shopName?.trim()?.length >= 4,
    [shopName]
  );

  const checkNameAvailability = (isCreate = false) => {
    if (!isMinNameLength) {
      setIsNameAvailable(false);
      setAvailabilityState({
        type: "error",
        value: "Shop names must have a minimum of four characters.",
      });
    } else if (isCreate) {
      isShopNameAvailableCreateMutation(shopName);
    } else {
      isShopNameAvailableMutation(shopName);
    }
  };

  const updateName = (val: string) => {
    setName(val);
    setShopName(val);
    setItem("temp_shop_name", val);
    setAvailabilityState({
      type: "",
      value: "",
    });
  };

  const { type, value } = availabilityState;

  const goToNextStep = () => {
    if (isMinNameLength) {
      checkNameAvailability(true);
    }
  };

  const getIcon = () => {
    if (value) {
      switch (type) {
        case "error":
          return (
            <div className="flex justify-center text-contrast-color-1-100 font-opensans font-normal text-sm">
              {value}
            </div>
          );
        case "warning":
        case "success":
          return (
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                src={type === "warning" ? closeRed : tickGreen}
                alt={type === "warning" ? "closeRed" : "tickGreen"}
                style={{ width: 17.5, height: 17.5 }}
              />
              <div className="flex justify-center text-primary-color-100 font-opensans font-normal text-sm">
                {value}
              </div>
            </div>
          );
        default:
          return <div />;
      }
    }
    return <div />;
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <h1 className="flex font-playfair font-semibold text-3xl">
        Name your shop
      </h1>
      <h2 className="flex font-playfair font-light text-2xl">
        Choose something unique that reflects your style.
      </h2>
      <p className="flex font-opensans font-normal text-sm w-8/12">
        Your shop name will appear in your shop and next to each of your
        listings throughout Lonima.
      </p>
      <div className="flex flex-col gap-6 items-center md:items-stretch md:justify-center w-8/12">
        <div className="flex justify-center items-center md:flex-row flex-col w-full md:gap-0 gap-5">
          <div className="flex flex-2 w-full">
            <TextInput
              inputId="shop-name"
              label=""
              textInputType="text"
              value={shopName}
              containerStyleClass="w-full"
              typeOfState={type === "error" ? "error" : "normal"}
              size="sm"
              placeholder="Start typing your shop name ..."
              infoMessage=""
              overrideInfoMessageStyleClass=""
              onChange={updateName}
            />
          </div>
          <Button
            containerClassName="flex flex-1 w-full sm:w-6/12 md:w-full h-12 mt-0.5 ml-5"
            buttonClassName="rounded-none"
            text="Check Availability"
            size="md"
            type="red"
            onClick={checkNameAvailability}
            textClassName="text-primary-color-100"
          />
        </div>
        <div className="flex flex-col">{getIcon()}</div>
      </div>
      <div className="flex">
        <Button
          containerClassName="flex w-full"
          text="Continue"
          size="sm"
          type={isMinNameLength ? "primary" : "outline"}
          disabled={!isMinNameLength}
          onClick={goToNextStep}
        />
      </div>
    </div>
  );
};
export default ShopName;
