/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useEffect, useContext } from "react";
import { useMutation, useQuery } from "react-query";

// packages
import { v4 as uuid } from "uuid";
import { pick, omit } from "lodash";

// components
import Button from "../../../../../components/Button";
import AddressFormModal from "./AddressFormModal";
import ExistingAddress from "./ExistingAddress";

// ts
import { AddressResponse } from "../../../../../store/types";
import { AddressPayload } from "../../../../../types";
import { GET_ADDRESSES, GET_USER } from "../../../../../store/actions.types";

// actions
import {
  addAddress,
  updateAddress,
  deleteAddress,
  fetchAddresses,
  setDefaultAddress,
} from "./actions";
import { fetchUserProfile } from "../../../../../store/actions";

// store
import { Context } from "../../../../../store";

// constants
import {
  ADDRESS_PAYLOAD_KEYS,
  ADDRESS_HELPER_KEYS,
  defaultAddressValues,
} from "./constants";

interface AddressMap {
  [id: string]: AddressPayload;
}

interface AddressesDataResponse {
  data: { value: { addresses: AddressPayload[] } };
}

export const Addresses: FC = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [uniqID, setUniqID] = useState(uuid());
  const [addresses, setAddresses] = useState<AddressMap>({});
  const [editToAddressID, setEditToAddressID] = useState("");

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { default_address_id },
      },
    },
  } = useContext(Context);

  const { data: newAddressesData, refetch: refetchNewAdresses } = useQuery(
    GET_ADDRESSES,
    fetchAddresses,
    {
      enabled: false,
    }
  );

  const { refetch: refetchUserProfile } = useQuery(GET_USER, fetchUserProfile, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  // fetching all addresses on mount
  useEffect(() => {
    refetchNewAdresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * to sync API data with local state
   */
  const syncAddressesLocal = (latestAddresses: AddressesDataResponse) => {
    if (latestAddresses) {
      const latestAddressesValues =
        latestAddresses?.data?.value?.addresses || [];
      if (latestAddressesValues.length > 0) {
        const allAddressesFormatted = latestAddressesValues.reduce(
          (acc, curr: AddressPayload) => {
            // eslint-disable-next-line no-underscore-dangle
            const id = curr._id;
            if (id) {
              acc[id] = {
                ...curr,
                isDefaultShippingAddress: id === default_address_id,
              };
            }
            return acc;
          },
          {} as AddressMap
        );
        setAddresses(allAddressesFormatted);
      } else {
        setAddresses({});
      }
    }
  };

  useEffect(() => {
    syncAddressesLocal(newAddressesData as AddressesDataResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAddressesData, default_address_id]);

  const { mutate: setDefaultAddressMutation } = useMutation(setDefaultAddress, {
    onSuccess: () => {
      refetchUserProfile();
      refetchNewAdresses();
    },
    onError: () => {
      refetchNewAdresses();
    },
  });

  const setDefaultAddressID = ({
    id,
    isDefaultShippingAddress,
  }: {
    id: string;
    isDefaultShippingAddress: boolean;
  }) => {
    if (isDefaultShippingAddress) {
      setDefaultAddressMutation(id);
    }
  };

  const { mutate: addAddressMutation } = useMutation(addAddress, {
    onSuccess: (response) => {
      if (response?.data?.value) {
        const { id, isDefaultShippingAddress } = response.data.value;
        setDefaultAddressID({ id, isDefaultShippingAddress });
        refetchNewAdresses();
      }
    },
    onError: () => {
      refetchNewAdresses();
    },
  });

  const { mutate: updateAddressMutation } = useMutation(updateAddress, {
    onSuccess: (response) => {
      if (response?.data?.value) {
        const { id, isDefaultShippingAddress } = response.data.value;
        setDefaultAddressID({ id, isDefaultShippingAddress });
        refetchNewAdresses();
      }
    },
    onError: () => {
      refetchNewAdresses();
    },
  });

  const { mutate: deleteAddressMutation } = useMutation(deleteAddress, {
    onSuccess: () => {
      refetchNewAdresses();
    },
    onError: () => {
      refetchNewAdresses();
    },
  });

  const triggerModalOpen = (id: string) => {
    setEditToAddressID(id);
    setIsShowModal(true);
    setUniqID(uuid());
  };

  const getDefaultAddressID = (addressesObj: AddressMap) => {
    return (
      // eslint-disable-next-line no-underscore-dangle
      Object.values(addressesObj).find(
        (addressObj) => addressObj.isDefaultShippingAddress
      )?._id || ""
    );
  };

  /**
   * while adding a placeholder address we create a dummy uniq id
   * to holdd the local unsaved data
   */
  const addPlaceHolderAddress = (id = uuid()) => {
    setAddresses((prevAddresses) => {
      const copyObj = { ...prevAddresses };
      // check if there are any existing default shipping addresses,
      // else assign by default
      const defaultShippingAddressId = getDefaultAddressID(copyObj);
      copyObj[id] = {
        ...defaultAddressValues,
        isDefaultShippingAddress: !defaultShippingAddressId,
        _id: id,
        isShow: false,
        isPlaceHolderAddress: true,
      };
      return copyObj;
    });
    triggerModalOpen(id);
  };

  const resetEditAddressID = (id: string) => {
    if (id === editToAddressID) {
      setEditToAddressID("");
    }
  };

  const onRemoveAddress = (id: string) => {
    deleteAddressMutation(id);
    resetEditAddressID(id);
  };

  const upsertAddress = (address: AddressResponse) => {
    const { _id: id } = address;
    const payload = omit(
      pick(address, ADDRESS_PAYLOAD_KEYS),
      ADDRESS_HELPER_KEYS
    ) as AddressPayload;
    if (addresses[id]?.isPlaceHolderAddress) {
      addAddressMutation(payload);
    } else {
      updateAddressMutation(payload);
      resetEditAddressID(id);
    }
  };

  const saveAddress = (updatedAddress: AddressResponse) => {
    upsertAddress(updatedAddress);
  };

  const getOpenAddresses = () => {
    return Object.keys(addresses).reduce((acc, curr) => {
      const currentAddress = addresses[curr];
      if (currentAddress.isShow) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[curr] = currentAddress;
      }
      return acc;
    }, {} as AddressResponse);
  };

  const addressIDs = Object.keys(getOpenAddresses());

  return (
    <div className="flex-col w-full">
      <div className="flex font-poppins not-italic font-light text-2xl leading-9 text-primary-color-100">
        Shipping Addresses
      </div>
      <div className="flex flex-col mt-7 gap-12">
        {addressIDs.length > 0 &&
          addressIDs.map((addressID) => {
            return (
              <ExistingAddress
                key={addressID}
                existingAddress={addresses[addressID]}
                onEdit={() => triggerModalOpen(addressID)}
                onRemove={() => onRemoveAddress(addressID)}
              />
            );
          })}
      </div>
      <div className="flex gap-16 py-9 justify-start">
        <Button
          containerClassName="px-1 py-4"
          text="Add New Address"
          size="sm"
          type="primary"
          onClick={addPlaceHolderAddress}
        />
      </div>
      {isShowModal && (
        <AddressFormModal
          key={uniqID}
          uniqueAddressId={editToAddressID}
          isDefaultAddressReadOnly={
            addressIDs.length === 0 || editToAddressID === default_address_id
          }
          title={
            addresses[editToAddressID] &&
            !addresses[editToAddressID].isPlaceHolderAddress
              ? "Edit an address"
              : "Add new address"
          }
          onSave={saveAddress}
          previousAddress={
            (editToAddressID
              ? addresses[editToAddressID]
              : {}) as AddressResponse
          }
        />
      )}
    </div>
  );
};

export default Addresses;
