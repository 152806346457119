export const getGoogleMapsPlacePredictions = async (
  text: string
): Promise<unknown> =>
  // eslint-disable-next-line consistent-return
  new Promise((resolve, reject) => {
    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { input: text, componentRestrictions: { country: "us" } },
        resolve
      );
    } catch (e) {
      return reject(e);
    }
  });

export const getPlaceDetails = async (
  ref: HTMLDivElement,
  placeId: string
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    if (!ref) {
      reject(new Error("text input ref is not defined"));
    } else {
      const placesService = new window.google.maps.places.PlacesService(ref);
      placesService.getDetails(
        {
          placeId,
        },
        (place) => {
          resolve(place);
        }
      );
    }
  });
};

export default getGoogleMapsPlacePredictions;
