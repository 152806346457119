/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { InputProps } from "./types";
import { imageUnavailablePlaceHolder } from "../../assets";
import Dropdown from "../Dropdown";
import { fetchShopDetails } from "../../store/actions";
import { nonVariantTypeKeys } from "../Product/Product.utils";
import Button from "../Button";
import { VariantKeys } from "../../screens/Product/types";

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CartItem = ({
  productDetails,
  productThumbnail = imageUnavailablePlaceHolder,
  updateQuantity,
  removeCartItem,
  disableQuantityOption = false,
  hideRemoveOption = false,
  excludeUnderline = false,
  trackAvailable = false,
  note,
  onTrackClick,
}: InputProps): JSX.Element => {
  const {
    product,
    quantity,
    _id: lineItemId,
    estimated_processing_time,
    variant,
  } = productDetails;
  const [prodQuantity, setProdQuantity] = useState({
    label: quantity.toString(),
    value: quantity,
  });

  const { name, price, product_id, shop_id, variants } = product;

  const { data: shopDetailsResp } = useQuery(
    [{ id: shop_id }],
    fetchShopDetails,
    {
      enabled: !!shop_id,
    }
  );

  const { shopName } = useMemo(() => {
    if (!shopDetailsResp?.data?.value) {
      return { shopName: "" };
    }
    const { name: shopNameFromApi } = shopDetailsResp?.data?.value;
    return { shopName: shopNameFromApi };
  }, [shopDetailsResp?.data?.value]);

  const { originalPrice, discountPrice, ...restVariant } = useMemo(() => {
    let returnValue: { [key: string]: string | number } = {
      originalPrice: price,
      discountPrice: -1,
    };
    if (variants && variants.length > 0) {
      const variantTypeKeys = Object.keys(variants[0]).filter(
        (key) => !nonVariantTypeKeys.includes(key)
      );
      variants?.forEach((_variant) => {
        returnValue = {
          originalPrice: _variant.original_price,
          discountPrice: _variant.price,
        };
        if (variantTypeKeys.length > 0) {
          variantTypeKeys.forEach((variantTypeKey) => {
            returnValue[variantTypeKey] =
              _variant[variantTypeKey as "variantType"];
          });
        }
      });
      return returnValue;
    }
    return returnValue;
  }, [variants, price]);

  const estimatedProcessingTime = new Date();
  estimatedProcessingTime.setDate(
    estimatedProcessingTime.getDate() + estimated_processing_time
  );

  return (
    <div
      className={`flex flex-col w-full ${
        !excludeUnderline
          ? "border-b border-primary-color-100 border-opacity-25"
          : ""
      } px-2 py-2`}
      key={`${lineItemId}-${product_id}`}
    >
      {trackAvailable && (
        <div className="flex justify-end pb-4">
          <Button
            containerClassName="flex justify-center self-center rounded-md"
            text="Track your order"
            size="sm"
            type="primary"
            onClick={onTrackClick}
          />
        </div>
      )}
      <div className="flex w-full">
        <div className="flex items-start w-32 h-24 md:flex-2 md:w-40 h-30">
          <img
            src={product.main_image || product.image_url || productThumbnail}
            alt="cart-product"
            className="object-cover h-24 mr-2 w-36"
          />
          <div className="flex-col items-start justify-start hidden w-8/12 pl-3 md:flex">
            <Link className="font-bold" to={`/shop?id=${shop_id}`}>
              {shopName}
            </Link>
            <Link to={`/product?id=${product_id}`} target="_blank">
              <p className="text-left">{name}</p>
            </Link>
            {Object.values(restVariant || {}).length > 0 ? (
              <div className="flex gap-2">
                {Object.keys(restVariant).map((key) => {
                  return (
                    <p key={key}>
                      {key}: {restVariant[key]}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <div className="flex justify-between w-full md:flex-1">
            <div className="flex-col hidden md:flex">
              {(discountPrice as number) > -1 ? (
                <div className="flex gap-2">
                  <p className="font-bold">
                    ${(discountPrice as number).toFixed(2)}
                  </p>
                  <p className="font-bold line-through">
                    ${(originalPrice as number).toFixed(2)}
                  </p>
                </div>
              ) : (
                <div className="flex gap-2">
                  <p className="font-bold">
                    ${(originalPrice as number).toFixed(2)}
                  </p>
                </div>
              )}
              {(discountPrice as number) > -1 ? (
                <p className="text-sm line-through">
                  ${(originalPrice as number).toFixed(2)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-row items-start justify-between w-full md:flex-col">
              <div className="flex flex-col w-1/2 ml-4 text-left md:w-full md:hidden sm:text-2xl">
                {(discountPrice as number) > -1 ? (
                  <div>
                    <p className="font-bold leading-9">{discountPrice}</p>{" "}
                    <p className="text-base leading-8 line-through">
                      ${(originalPrice as number).toFixed(2)}
                    </p>
                  </div>
                ) : (
                  <p className="font-bold">
                    ${(originalPrice as number).toFixed(2)}
                  </p>
                )}
              </div>
              <div className="flex flex-col items-end w-1/2 text-sm md:w-full">
                <Dropdown
                  type="primary"
                  size="custom"
                  preSelectedOptions={[prodQuantity]}
                  disableDropdown={disableQuantityOption}
                  options={[
                    { label: "0", value: 0 },
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                    { label: "4", value: 4 },
                    { label: "5", value: 5 },
                    { label: "6", value: 6 },
                    { label: "7", value: 7 },
                    { label: "8", value: 8 },
                    { label: "9", value: 9 },
                    { label: "10", value: 10 },
                  ]}
                  onChange={(val) => {
                    if (val?.value || val?.value === 0) {
                      setProdQuantity({
                        label: val.label,
                        value: val.value as number,
                      });
                      updateQuantity(lineItemId, val.value as number);
                    }
                  }}
                />
                {!disableQuantityOption && !hideRemoveOption && (
                  <div
                    role="button"
                    tabIndex={0}
                    className="text-right underline cursor-pointer md:text-center"
                    onClick={() =>
                      removeCartItem({
                        productId: lineItemId,
                        productName: product?.name,
                      })
                    }
                    onKeyPress={() =>
                      removeCartItem({
                        productId: lineItemId,
                        productName: product?.name,
                      })
                    }
                  >
                    Remove
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-3/4 ml-4 md:hidden">
            <p className="text-left">{product.name}</p>
          </div>
        </div>
      </div>
      {variant && Object.keys(variant).length && (
        <div className="flex flex-col pt-2">
          {Object.keys(variant).map((key) => {
            return (
              <span className="flex">
                <p>
                  {key}: {variant[key as VariantKeys]}
                </p>
              </span>
            );
          })}
        </div>
      )}
      {estimatedProcessingTime && !disableQuantityOption && (
        <div className="flex flex-col mt-8 text-left sm:flex-row sm:items-center">
          <span className="flex text-sm font-bold">
            Estimated Delivery Date: &nbsp;
          </span>
          <div className="flex items-center">
            <span className="flex text-sm font-bold">
              {Months[estimatedProcessingTime.getMonth()]}{" "}
              {estimatedProcessingTime.getDate()},{" "}
              {estimatedProcessingTime.getFullYear()}
            </span>{" "}
            &nbsp;-&nbsp;
            <span className="flex text-sm font-bold">
              {Months[estimatedProcessingTime.getMonth()]}{" "}
              {estimatedProcessingTime.getDate() + 2},{" "}
              {estimatedProcessingTime.getFullYear()}
            </span>
          </div>
        </div>
      )}
      {note && <p className="text-left">Notes: {note}</p>}
    </div>
  );
};

export default CartItem;
