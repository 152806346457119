/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  FC,
  ChangeEvent,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";

// packages
import DOMPurify from "dompurify";
import { v4 as uuid } from "uuid";
import AsyncSelect from "react-select/async";
import { QueryClient } from "react-query";
import { useWindowSize } from "react-use";
import { useTable } from "react-table";

// components
import { capitalize, uniq } from "lodash";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import Modal from "../../../components/Modal";
import styles from "./ItemDetails.module.css";
import Button from "../../../components/Button";
import Variants from "./Variants";
import "../AddOrEditProduct.css";

// utils
import { isNumeric } from "../../../utils";
import { getPreSelectedOption, getOptions } from "./ItemDetails.utils";

// context
import AddOrEditProductContext from "../AddOrEditProduct.context";

import { searchCategories } from "../actions";
import {
  MANDATORY_VARIANT_KEYS,
  getInfoMessage,
  getTypeofState,
} from "./Variants/Variants";
import CheckBox from "../../../components/CheckBox";
import { Variant } from "../../Product/types";

const BASE_COLUMNS = [
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Original Price ($)",
    accessor: "original_price",
  },
  {
    Header: "Discount %",
    accessor: "discount_percentage",
  },
  {
    Header: "Selling Price ($)",
    accessor: "price",
  },
];

// eslint-disable-next-line react/prop-types
export const ItemDetails: FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });
  const [showEditVariantsModal, setShowEditVariantsModal] = useState(false);
  const [uniqID, setUniqID] = useState(uuid());
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const {
    productDetails: {
      id,
      name,
      category,
      description,
      originZipCode,
      estimated_processing_time,
      weight_value,
      weight_units,
      dimensions_length,
      dimensions_width,
      dimensions_height,
      dimensions_units,
      variants,
      has_variant,
    },
    updateProductDetails,
    setVariantSelections,
  } = useContext(AddOrEditProductContext);
  const [variantChangeWarning, setVariantChangeWarning] = useState({
    isShow: false,
    message: "",
    isSelected: false,
  });
  const defaultCategoriesInitialValue = [{ label: "", value: "" }];
  const [defaultCategoryOptions, setDefaultCategoryOptions] = useState(
    defaultCategoriesInitialValue
  );
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < 480, [width]);

  const labelStyle =
    "flex text-primary-color-100 mt-0.5 font-opensans font-normal leading-6 text-xs";
  const headingStyle = "flex mt-6 mb-2";
  const subHeadingStyle = "flex flex-col gap-1 md:gap-2";

  const PricingSwitchModal = () => {
    return (
      <Modal
        isForceClose={!variantChangeWarning.isShow}
        overrideModalStyle="w-5/12 max-w-7xl h-1/5"
      >
        <div className="flex flex-col justify-center p-8">
          <div className="flex">
            <div className="flex flex-col gap-10 w-6/12 md:w-3/12 flex-grow">
              {variantChangeWarning.message}
              <div className="flex gap-5">
                <Button
                  containerClassName="flex w-full justify-center rounded-md"
                  text="I'm sure"
                  size="sm"
                  type="outline"
                  onClick={() => {
                    if (variantChangeWarning.isSelected) {
                      updateProductDetails({
                        key: "variants",
                        value: {},
                      });
                    }
                    setVariantChangeWarning({
                      message: "",
                      isShow: false,
                      isSelected: variantChangeWarning.isSelected,
                    });
                  }}
                />
                <Button
                  containerClassName="flex w-full justify-center rounded-md"
                  text="Take me back"
                  size="sm"
                  type="outline"
                  onClick={() => {
                    setVariantChangeWarning({
                      message: "",
                      isShow: false,
                      isSelected: false,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const loadOptions = async (searchText: string) => {
    const data = await queryClient.fetchQuery(
      [{ searchText }],
      searchCategories
    );
    const searchData = data?.data?.value;
    const options =
      searchData?.length > 0
        ? searchData.reduce(
            (
              allOptions: { label: string; value: string }[],
              option: {
                hierarchy: string[];
                hierarchy_details: {
                  name: string;
                  value: string;
                  id: string;
                }[];
                name: string;
                value: string;
              }
            ) => {
              const { hierarchy = [], hierarchy_details = [], value } = option;
              let label = "";
              hierarchy.forEach((hierarchyItem) => {
                const friendlyName = hierarchy_details.find(
                  (hierarchy_detail) => hierarchy_detail.value === hierarchyItem
                )?.name as string;
                if (friendlyName) {
                  label = label ? `${label} → ${friendlyName}` : friendlyName;
                }
              });
              allOptions.push({ label, value });
              return allOptions;
            },
            []
          )
        : [];
    return options;
  };

  let debounceCategorySearch: NodeJS.Timeout;
  const loadOptionsWithDebounce = async (searchText: string): Promise<[]> => {
    clearTimeout(debounceCategorySearch);
    return new Promise((resolve) => {
      debounceCategorySearch = setTimeout(async () => {
        try {
          const data = await loadOptions(searchText);
          resolve(data);
        } catch (e) {
          resolve([]);
        }
      }, 300);
    });
  };

  const variantColumns = Object.keys(
    Object.values(variants.value || [])[0] || {}
  ).filter((val) => !MANDATORY_VARIANT_KEYS.includes(val) && val !== "_id");

  const columns = useMemo(
    () => [
      {
        Header: "Variants",
        columns: [
          ...variantColumns.map((accessor) => ({
            Header: capitalize(accessor),
            accessor,
          })),
          ...BASE_COLUMNS,
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(variantColumns)]
  );

  const variantsData = useMemo(
    () => Object.values(variants.value || []),
    [variants.value]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data:
        variantsData.length > 0
          ? variantsData.map((variantsRecord) => {
              return {
                ...variantsRecord,
              };
            })
          : [
              {
                original_price: "-",
                quantity: "-",
                discount_percentage: "-",
                price: "-",
              },
            ],
    });

  const fetchCategoryDefaultOptions = async () => {
    const data = await loadOptions(category?.value?.trim() || "");
    setDefaultCategoryOptions(data);
  };
  useEffect(() => {
    fetchCategoryDefaultOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const firstVariant = variantsData?.[0];
  const firstVariantOgPrice = parseFloat(firstVariant?.original_price || "0");
  const firstVariantPrice = parseFloat(firstVariant?.price || "0");
  const firstVariantQuantity = parseFloat(firstVariant?.quantity || "0");
  const firstVariantDiscount = parseFloat(
    firstVariant?.discount_percentage || "0"
  );

  useEffect(() => {
    const variantKeys = uniq(
      variantsData.reduce((acc, variant) => {
        const recordKeys = Object.keys(variant).filter(
          (key) => !MANDATORY_VARIANT_KEYS.includes(key) && key !== "_id"
        );
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, ...recordKeys];
        return acc;
      }, [] as string[])
    );
    const initialVariantSelections = variantKeys.reduce((acc, key) => {
      variantsData.forEach((variant) => {
        const value = variant[key as "variant_key"];
        if (!acc[key]) {
          acc[key] = [];
        }
        if (!acc[key].includes(value as string)) {
          acc[key].push(value as string);
        }
      });
      return acc;
    }, {} as { [key: string]: string[] });
    setVariantSelections(initialVariantSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsData]);

  useEffect(() => {
    const errorStateMap = {
      name: !!name.value.trim(),
      category: !!category.value.trim(),
      description: !!description.value.trim(),
      originZipCode:
        Number.isInteger(originZipCode.value) &&
        originZipCode.value.toString().length === 5,
      estimated_processing_time:
        isNumeric(estimated_processing_time.value, false) &&
        estimated_processing_time.value > 0 &&
        estimated_processing_time.value <= 5,

      weight_value: isNumeric(weight_value.value),
      dimensions_length: isNumeric(dimensions_length.value),
      dimensions_width: isNumeric(dimensions_width.value),
      dimensions_height: isNumeric(dimensions_height.value),
      variants: !has_variant?.value
        ? !Number.isNaN(firstVariantDiscount) &&
          firstVariantDiscount >= 0 &&
          !Number.isNaN(firstVariantOgPrice) &&
          firstVariantOgPrice > 0 &&
          firstVariantQuantity > 0
        : true,
    } as { [key: string]: boolean };
    Object.keys(errorStateMap).forEach((key) => {
      updateProductDetails({
        key,
        value: errorStateMap[key] ? "normal" : "error",
        valueKey: "elementState",
      });
    });
    setTitleCharCount(name.value.length);
    setDescriptionCharCount(description.value.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name.value,
    category.value,
    description.value,
    originZipCode.value,
    estimated_processing_time.value,
    weight_value.value,
    dimensions_length.value,
    dimensions_width.value,
    dimensions_height.value,
    has_variant,
    variantsData,
  ]);

  const getCategoryValue = () => {
    if (!category) {
      return { label: "", value: "" };
    }
    const label =
      category.label ||
      defaultCategoryOptions?.find((item) => item.value === category.value)
        ?.label;
    return { label, value: category.value };
  };

  const getDimensionsDropDown = () => {
    return (
      <div className="flex w-6/12">
        <Dropdown
          placeholderInput=""
          title="Units"
          type="primary"
          size="xs"
          textSize="sm"
          preSelectedOptions={[
            getPreSelectedOption({
              type: "dimensions",
              value: dimensions_units.value as string,
            }) as {
              label: string;
              value: string;
            },
          ]}
          options={getOptions("dimensions")}
          onChange={(val) => {
            if (val?.value) {
              updateProductDetails({
                key: "dimensions_units",
                value: (val?.value as string)?.trim(),
                isEdited: true,
              });
            }
          }}
        />
      </div>
    );
  };

  const showInfoErrorMsg = !has_variant?.value;

  return (
    <div className="flex flex-col gap-6 text-left">
      <div
        className=" flex flex-col rounded-md
    border border-solid border-primary-color-100 border-opacity-10 shadow gap-4 p-4"
      >
        <div className={subHeadingStyle}>
          <h3 className={headingStyle}>Item Details</h3>
          <div className="flex font-normal font-opensans text-sm md:text-lg">
            Tell customers all about your item. Include keywords that customers
            would use to search for this item.
          </div>
        </div>
        {variantChangeWarning.isShow && <PricingSwitchModal key={uniqID} />}
        <div className="flex flex-col md:gap-6 gap-3">
          <div className="flex max-w-xl flex-col">
            <label aria-required htmlFor="name" className={labelStyle}>
              Item Title
              <span className="text-red-500"> *</span>
            </label>
            <div
              className={`flex border border-primary-color-100 border-opacity-25 text-primary-color-100 bg-neutral-color-2 rounded-md mt-0.5 p-3 font-opensans font-normal leading-6 ${
                isMobile ? "text-sm w-full" : "text-base"
              }`}
            >
              <textarea
                rows={2}
                value={name.value}
                maxLength={140}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  const value = DOMPurify.sanitize(e.target.value);
                  updateProductDetails({
                    key: "name",
                    value,
                    isEdited: true,
                  });
                  setTitleCharCount(value.length);
                }}
                className="w-full bg-neutral-color-2 h-full"
              />
            </div>
            <div className="flex justify-between">
              {!name.value.trim() ? (
                <p className="flex text-sm text-left text-red-500 pt-0">
                  Title field cannot be empty.
                </p>
              ) : (
                <p />
              )}
              <p className="text-right text-sm">{titleCharCount}/140</p>
            </div>
          </div>
          <div className="flex max-w-xl flex-col">
            <label aria-required htmlFor="category" className={labelStyle}>
              Category
              <span className="text-red-500"> *</span>
            </label>
            <div className="flex w-full">
              <AsyncSelect
                cacheOptions
                value={id ? getCategoryValue() : null}
                defaultOptions={defaultCategoryOptions}
                defaultValue={getCategoryValue()}
                onChange={(e) => {
                  updateProductDetails({
                    key: "category",
                    value: e?.value,
                    isEdited: true,
                  });
                  updateProductDetails({
                    key: "category",
                    value: e?.label,
                    valueKey: "label",
                  });
                }}
                isLoading={!category}
                loadOptions={loadOptionsWithDebounce}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    fontSize: isMobile ? "12px" : "",
                  }),
                  container: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
          <div className="flex max-w-xl flex-col">
            <label aria-required htmlFor="description" className={labelStyle}>
              Description
              <span className="text-red-500"> *</span>
            </label>
            <div
              className={`flex border border-primary-color-100 border-opacity-25 text-primary-color-100 bg-neutral-color-2 rounded-md mt-0.5 p-3 font-opensans font-normal leading-6 ${
                isMobile ? "text-sm w-full" : "text-base"
              }`}
            >
              <textarea
                rows={7}
                value={description.value}
                maxLength={1000}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  const value = DOMPurify.sanitize(e.target.value);
                  updateProductDetails({
                    key: "description",
                    value,
                    isEdited: true,
                  });
                  setDescriptionCharCount(value.length);
                }}
                className="w-full bg-neutral-color-2 h-full"
              />
            </div>
            <div className="flex justify-between">
              {!description.value.trim() ? (
                <div className="flex text-sm text-left text-red-500 pt-0">
                  Description field cannot be empty.
                </div>
              ) : (
                <p />
              )}
              <p className="text-right text-sm">{descriptionCharCount}/1000</p>
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full sm:w-1/4">
            <div className="flex flex-col">
              <TextInput
                hideInput={has_variant?.value}
                disabled={has_variant?.value}
                isRequired
                inputId="quantity"
                label="Quantity"
                textInputType="text"
                value={firstVariant?.quantity || "0"}
                containerStyleClass="w-full"
                overrideInputContainerClass="w-inherit"
                typeOfState={
                  showInfoErrorMsg
                    ? getTypeofState("quantity", firstVariant?.quantity || "0")
                    : "normal"
                }
                size={isMobile ? "xs" : "sm"}
                placeholder="0"
                infoMessage={
                  showInfoErrorMsg
                    ? getInfoMessage("quantity", firstVariant?.quantity)
                    : ""
                }
                onChange={(value: string) => {
                  updateProductDetails({
                    key: "variants",
                    value: {
                      single_variant: {
                        ...firstVariant,
                        quantity: value,
                      },
                    },
                  });
                }}
              />
              {has_variant?.value && (
                <p className="text-sm">Enter quantity in variants</p>
              )}
            </div>
            <div className="flex flex-col">
              <TextInput
                hideInput={has_variant?.value}
                disabled={has_variant?.value}
                isRequired
                inputId="original_price"
                label="Price ($)"
                textInputType="text"
                value={`${firstVariantOgPrice || ""}`}
                containerStyleClass="w-full"
                overrideInputContainerClass="w-inherit"
                decimalPrecision={2}
                typeOfState={
                  showInfoErrorMsg
                    ? getTypeofState(
                        "original_price",
                        firstVariant?.original_price || "0.00"
                      )
                    : "normal"
                }
                size={isMobile ? "xs" : "sm"}
                placeholder="0"
                infoMessage={
                  showInfoErrorMsg
                    ? getInfoMessage(
                        "original_price",
                        firstVariant?.original_price as string
                      )
                    : ""
                }
                onChange={(value: string) => {
                  const sellingPrice =
                    value && !Number.isNaN(firstVariantDiscount)
                      ? (
                          parseFloat(value) -
                          (parseFloat(value) * firstVariantDiscount) / 100
                        ).toFixed(2)
                      : "0.00";
                  updateProductDetails({
                    key: "variants",
                    value: {
                      single_variant: {
                        ...firstVariant,
                        original_price: value,
                        price: sellingPrice,
                      },
                    },
                  });
                }}
                onBlur={() => {
                  const value = firstVariantOgPrice.toFixed(2) || "0.00";
                  const sellingPrice =
                    value && !Number.isNaN(firstVariantDiscount)
                      ? (
                          parseFloat(value) -
                          (parseFloat(value) * firstVariantDiscount) / 100
                        ).toFixed(2)
                      : "0.00";
                  updateProductDetails({
                    key: "variants",
                    value: {
                      single_variant: {
                        ...firstVariant,
                        original_price:
                          firstVariantOgPrice.toFixed(2) || "0.00",
                        price: sellingPrice,
                      },
                    },
                  });
                }}
              />
              {has_variant?.value && (
                <p className="text-sm">Enter price in variants</p>
              )}
            </div>
            <div className="flex flex-col">
              <TextInput
                inputId="discount_percentage"
                label="Discount (%)"
                textInputType="text"
                // hide if no variants are added
                hideInput={
                  has_variant?.value && Object.keys(variants.value).length === 0
                }
                value={`${firstVariantDiscount || ""}`}
                containerStyleClass="w-full"
                overrideInputContainerClass="w-inherit"
                decimalPrecision={2}
                typeOfState={
                  showInfoErrorMsg
                    ? getTypeofState(
                        "discount_percentage",
                        firstVariant?.discount_percentage || "0.00"
                      )
                    : "normal"
                }
                size={isMobile ? "xs" : "sm"}
                placeholder="0"
                infoMessage={
                  showInfoErrorMsg
                    ? getInfoMessage(
                        "discount_percentage",
                        firstVariant?.discount_percentage as string
                      )
                    : ""
                }
                onChange={(value: string) => {
                  const discountValue = Number.isNaN(parseFloat(value))
                    ? "0"
                    : value;
                  const getSellingPrice = (currentVariant: Variant) => {
                    const currentVariantOgPrice = parseFloat(
                      currentVariant?.original_price || "0"
                    );
                    return currentVariant?.original_price
                      ? (
                          currentVariantOgPrice -
                          (currentVariantOgPrice * parseFloat(discountValue)) /
                            100
                        ).toFixed(2)
                      : "0.00";
                  };
                  if (has_variant?.value) {
                    updateProductDetails({
                      key: "variants",
                      value: Object.keys(variants.value).reduce(
                        (roundedValueVariants, variantKey) => {
                          const variantObj = {
                            ...variants.value[variantKey],
                          };
                          // eslint-disable-next-line no-param-reassign
                          roundedValueVariants[variantKey] = {
                            ...variantObj,
                            discount_percentage: value,
                            price: getSellingPrice(variantObj),
                          };
                          return roundedValueVariants;
                        },
                        {} as { [key: string]: Variant }
                      ),
                      isEdited: true,
                    });
                  } else {
                    updateProductDetails({
                      key: "variants",
                      value: {
                        single_variant: {
                          ...firstVariant,
                          price: getSellingPrice(firstVariant),
                          discount_percentage: value || "0.00",
                        },
                      },
                      isEdited: true,
                    });
                  }
                }}
                onBlur={(value: string) => {
                  if (has_variant?.value) {
                    updateProductDetails({
                      key: "variants",
                      value: Object.keys(variants.value).reduce(
                        (roundedValueVariants, variantKey) => {
                          const variantObj = {
                            ...variants.value[variantKey],
                          };
                          // eslint-disable-next-line no-param-reassign
                          roundedValueVariants[variantKey] = {
                            ...variantObj,
                            discount_percentage: value,
                          };
                          return roundedValueVariants;
                        },
                        {} as { [key: string]: Variant }
                      ),
                    });
                  } else {
                    updateProductDetails({
                      key: "variants",
                      value: {
                        single_variant: {
                          ...firstVariant,
                          discount_percentage: value || "0.00",
                        },
                      },
                    });
                  }
                }}
              />
            </div>
            <div className="flex">
              {!has_variant?.value && (
                <TextInput
                  hideInput={has_variant?.value}
                  inputId="price"
                  disabled
                  label="Selling Price ($)"
                  textInputType="text"
                  value={`${
                    firstVariant?.price ? firstVariantPrice.toFixed(2) : "0.00"
                  }`}
                  containerStyleClass="w-full"
                  overrideInputContainerClass="w-inherit"
                  typeOfState="normal"
                  infoMessage=" "
                  size="sm"
                  placeholder="0.00"
                  overrideInfoMessageStyleClass=""
                  onChange={() => {}}
                />
              )}
            </div>
          </div>
        </div>
        {has_variant?.value && variantsData?.[0]?._id !== "unique_variant" && (
          <div className={styles.table}>
            <table
              {...getTableProps()}
              style={{
                overflowX: "auto",
                width: "100%",
              }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className="flex w-auto">
          <CheckBox
            text="This product has variants"
            isEnabled
            isSelected={has_variant?.value || false}
            onChange={(values) => {
              updateProductDetails({
                key: "has_variant",
                value: values.isSelected,
              });
              updateProductDetails({
                key: "variants",
                value: {},
              });
            }}
          />
        </div>
        {has_variant?.value && (
          <div className={subHeadingStyle}>
            <div
              className={`flex flex-col ${
                width > 450 ? `${width < 940 ? "w-5/12" : "w-3/12"} ` : "w-full"
              }`}
            >
              <Button
                containerClassName="flex w-full justify-center rounded-md h-10"
                text="Add / Edit variants"
                textClassName={`${isMobile ? "text-xs" : "text-base"}`}
                size="sm"
                type="outline"
                onClick={() => {
                  setShowEditVariantsModal(true);
                  setUniqID(uuid());
                }}
              />
            </div>
          </div>
        )}
        {showEditVariantsModal && <Variants key={uniqID} />}
      </div>
      <div
        className={`${subHeadingStyle} rounded-md
    border border-solid border-primary-color-100 border-opacity-10 shadow p-4`}
      >
        <h3 className={headingStyle}>Delivery</h3>
        <div className="flex">
          <TextInput
            isRequired={originZipCode.isRequired}
            inputId="originZipCode"
            label="Origin postal code"
            textInputType="number"
            value={originZipCode.value}
            containerStyleClass="w-full"
            overrideInputContainerClass={isMobile ? "w-6/12" : "w-3/12"}
            typeOfState={originZipCode.elementState}
            size={isMobile ? "xs" : "sm"}
            placeholder=""
            infoMessage={
              Number.isInteger(originZipCode.value) &&
              originZipCode.value.toString().length === 5
                ? ""
                : "Zipcode should be 5 characters long (numeric only)"
            }
            // overrideInfoMessageStyleClass={`text-${isMobile ? "xs" : "sm"}`}
            onChange={(value: string) => {
              updateProductDetails({
                key: "originZipCode",
                value: parseInt(value, 10),
                isEdited: true,
              });
            }}
          />
        </div>
        {/* <div className="flex font-xs md:font-sm font-opensans text-left mb-4 md:mb-9">
          Shoppers will see prices based on their location and the weight and
          dimensions of the listing.
        </div> */}
        <div className="flex w-full">
          <TextInput
            isRequired={estimated_processing_time.isRequired}
            inputId="estimated_processing_time"
            label="Estimated Processing Time (in days). Make sure to include delivery times as well."
            textInputType="text"
            value={estimated_processing_time.value?.toString() || ""}
            containerStyleClass="w-full"
            overrideInputContainerClass={isMobile ? "w-6/12" : "w-3/12"}
            typeOfState={estimated_processing_time.elementState}
            size={isMobile ? "xs" : "sm"}
            placeholder=""
            infoMessage={
              isNumeric(estimated_processing_time.value, false) &&
              estimated_processing_time.value > 0 &&
              estimated_processing_time.value <= 5
                ? ""
                : "Estimated Processing should be a number greater than 0 and less than or equal to 5 days"
            }
            overrideInfoMessageStyleClass=""
            onChange={(value: string) => {
              updateProductDetails({
                key: "estimated_processing_time",
                value,
                isEdited: true,
              });
            }}
          />
        </div>
        <div className="flex justify-start gap-2 w-full">
          <TextInput
            isRequired
            inputId="weight"
            label="Item Weight"
            textInputType="text"
            value={weight_value.value.toString()}
            containerStyleClass={isMobile ? "w-6/12" : "w-3/12"}
            overrideInputContainerClass="w-full"
            typeOfState={weight_value.elementState}
            size={isMobile ? "xs" : "sm"}
            placeholder="0.00"
            infoMessage={
              isNumeric(weight_value.value)
                ? ""
                : "Weight should be a valid decimal"
            }
            overrideInfoMessageStyleClass=""
            onChange={(value: string) => {
              updateProductDetails({
                key: "weight_value",
                value,
                isEdited: true,
              });
            }}
          />
          <div className="flex w-6/12 mt-5">
            <Dropdown
              isRequired
              placeholderInput=""
              title=""
              type="primary"
              size="xs"
              preSelectedOptions={[
                getPreSelectedOption({
                  type: "weight",
                  value: weight_units.value as string,
                }) as {
                  label: string;
                  value: string;
                },
              ]}
              options={getOptions("weight")}
              onChange={(val) => {
                if (val?.value) {
                  updateProductDetails({
                    key: "weight_units",
                    value: (val?.value as string)?.trim(),
                    isEdited: true,
                  });
                }
              }}
            />
          </div>
        </div>
        <div
          className={`flex gap-10 font-bold font-opensans text-${
            isMobile ? "sm" : "base"
          } mt-4`}
        >
          Item size (when packed)
        </div>
        <div className={`${isMobile ? "flex-col" : "flex"} w-full gap-4`}>
          {isMobile ? getDimensionsDropDown() : null}
          <TextInput
            isRequired
            inputId="length"
            label="Length"
            textInputType="text"
            value={dimensions_length.value.toString()}
            containerStyleClass={isMobile ? "my-4 w-6/12" : "w-2/12"}
            typeOfState={dimensions_length.elementState}
            size="sm"
            placeholder="0.00"
            infoMessage={
              isNumeric(dimensions_length.value)
                ? ""
                : "Length should be a valid decimal"
            }
            overrideInfoMessageStyleClass=""
            onChange={(value: string) => {
              updateProductDetails({
                key: "dimensions_length",
                value,
                isEdited: true,
              });
            }}
          />
          <TextInput
            isRequired
            inputId="width"
            label="Width/Depth"
            textInputType="text"
            value={dimensions_width.value.toString()}
            containerStyleClass={isMobile ? "my-4 w-6/12" : "w-2/12"}
            typeOfState={dimensions_width.elementState}
            size="sm"
            placeholder="0.00"
            infoMessage={
              isNumeric(dimensions_width.value)
                ? ""
                : "Width should be a valid decimal"
            }
            overrideInfoMessageStyleClass=""
            onChange={(value: string) => {
              updateProductDetails({
                key: "dimensions_width",
                value,
                isEdited: true,
              });
            }}
          />
          <TextInput
            isRequired
            inputId="height"
            label="Height"
            textInputType="text"
            value={dimensions_height.value.toString()}
            containerStyleClass={isMobile ? "my-4 w-6/12" : "w-2/12"}
            typeOfState={dimensions_height.elementState}
            size="sm"
            placeholder="0.00"
            infoMessage={
              isNumeric(dimensions_height.value)
                ? ""
                : "Height should be a valid decimal"
            }
            overrideInfoMessageStyleClass=""
            onChange={(value: string) => {
              updateProductDetails({
                key: "dimensions_height",
                value,
                isEdited: true,
              });
            }}
          />
          {!isMobile ? getDimensionsDropDown() : null}
        </div>
      </div>
    </div>
  );
};

ItemDetails.defaultProps = {
  urlProductId: "",
};

export default ItemDetails;
