import { createContext } from "react";

// ts
import { Dispatch } from "../../types";
import { AddImage, AddProductDetails } from "./AddOrEditProduct.types";

// constants
import { DEFAULT_ADD_PRODUCT_DETAILS } from "./AddOrEditProduct.constants";

const AddOrEditProductContext = createContext({
  productDetails: DEFAULT_ADD_PRODUCT_DETAILS as AddProductDetails,
  images: [] as AddImage[],
  setImages: (() => {}) as Dispatch<AddImage[]>,
  updateProductDetails: (() => {}) as ({
    key,
    value,
    valueKey,
    isEdited,
  }: {
    key: string;
    value?: string | number | Record<string, unknown> | boolean;
    valueKey?: string;
    isEdited?: boolean;
  }) => void,
  variantSelections: {} as { [key: string]: string[] },
  setVariantSelections: (() => null) as Dispatch<
    React.SetStateAction<{
      [key: string]: string[];
    }>
  >,
});
export default AddOrEditProductContext;
