/* eslint-disable import/prefer-default-export */
import {
  PRODUCT_WEIGHT_UNITS,
  PRODUCT_DIMENSIONS_UNIT,
  SHIPPING_COST_TYPES,
} from "../AddOrEditProduct.constants";

const unitMap = {
  weight: PRODUCT_WEIGHT_UNITS,
  dimensions: PRODUCT_DIMENSIONS_UNIT,
  shipping: SHIPPING_COST_TYPES,
};
type OptionType = "weight" | "dimensions" | "shipping";

export const getOptions = (
  type: OptionType
): {
  label: string;
  value: string;
}[] => {
  const source = unitMap[type];
  return Object.keys(source).reduce((acc, key) => {
    acc.push({ label: key, value: source[key] });
    return acc;
  }, [] as { label: string; value: string }[]);
};

export const getPreSelectedOption = ({
  type,
  value,
}: {
  type: OptionType;
  value: string;
}): {
  label: string;
  value: string;
} => {
  const source = unitMap[type];
  return value
    ? {
        label: Object.keys(source).reduce((res, key) => {
          if (value === source[key]) {
            return key;
          }
          return res;
        }, ""),
        value,
      }
    : ({} as { label: string; value: string });
};
