import React, { useMemo } from "react";

// packages
import { useLocation } from "react-router-dom";

const Footer = (): JSX.Element => {
  const loc = useLocation();
  const isVendorPage = useMemo(
    () =>
      loc.pathname.includes("/sell-on-lonima/name") ||
      loc.pathname.includes("/sell-on-lonima/location"),
    [loc]
  );
  return (
    <>
      {!isVendorPage && (
        <div className="flex w-full p-4 bg-neutral-color-1">
          <div className="flex w-full items-center flex-wrap justify-center gap-6">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/document/d/e/2PACX-1vSQ_6KbF1J2-CZac3L2xURI4gzDeZxI1KWrs_qPYsGR1hkpRbLNPHTXw_WpA2kn4dNiZxBRn3wzYHn-/pub"
            >
              <p className="text-primary-color-100 hover:underline">
                Privacy Policy
              </p>
            </a>
            <div
              className="cursor-pointer"
              onKeyDown={() => null}
              role="button"
              tabIndex={0}
              onClick={() => {
                // attach the class to the first element with the class name
                const elements = document.getElementsByClassName("cky-modal");
                if (elements.length >= 1) {
                  elements[0].classList.add("cky-modal-open");
                }
              }}
            >
              <p className="text-primary-color-100 hover:underline">
                Cookie Preferences
              </p>
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/document/d/e/2PACX-1vTTBrc7aeUjuRFB6-ssK2O9v9DReieVD2FY_U_ttytH61g0IgHxrLvVPvn_UbIN3LX8IK7QRXuqEyVH/pub"
            >
              <p className="text-primary-color-100 hover:underline">
                Terms Of Use
              </p>
            </a>
            <div className="flex font-opensans text-primary-color-100">
              &#169; Lonima, 2024
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
