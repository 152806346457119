import React, { useState, useEffect } from "react";
import styles from "./CheckBox.module.css";

// ts
export type OnChangeFunction = ({
  isEnabled,
  isSelected,
}: {
  isEnabled: boolean;
  isSelected: boolean;
}) => void;
interface Props {
  text: string;
  isSelected?: boolean;
  isEnabled?: boolean;
  onChange: OnChangeFunction;
}

const CheckBox = ({
  text = "Item",
  isSelected = false,
  isEnabled = false,
  onChange = () => {},
}: Props): JSX.Element => {
  const [isCbSelected, setIsCbSelected] = useState(isSelected);

  useEffect(() => {
    setIsCbSelected(isSelected);
  }, [isSelected]);

  const onClick = () => {
    onChange({ isEnabled, isSelected: !isCbSelected });
  };

  return (
    <div
      tabIndex={0}
      role="checkbox"
      aria-checked={isCbSelected}
      onClick={() => isEnabled && onClick()}
      onKeyDown={() => {}} // TODO: add event here to support keyboard only users
      className={styles["checkbox-container"]}
    >
      <input type="checkbox" checked={isCbSelected} onChange={() => {}} />
      <span className={styles.checkbox} />
      <p className="text-xs text-left">{text}</p>
    </div>
  );
};

CheckBox.defaultProps = {
  isSelected: false,
  isEnabled: false,
};

export default CheckBox;
