/* eslint-disable no-underscore-dangle */
import React, { useEffect, useContext, useMemo, useState } from "react";

// packages
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

import { pick } from "lodash";

// ts
import {
  ICartResponse,
  ICartProductDetails,
} from "../../../components/CartItem/types";

// redux
import {
  fetchPaymentMethod,
  fetchSavedPaymentMethod,
  updateDuplicatePaymentMethod,
} from "../actions";
import { fetchCart } from "../../../containers/CartItem/actions";

// store
import { Context } from "../../../store";
import { GET_CART } from "../../../store/actions.types";

// components
import ExistingAddress from "../../Profile/Tabs/AccountSettings/Addresses/ExistingAddress";
import Cart from "../../Cart";
import CardInfo from "./CardInfo";
import CartSummary from "../../../components/CartSummary";

// utils
import { getItem, setItem } from "../../../utils";

// hooks
import { useQueryParams, useAuth } from "../../../hooks";
import arrowLeftIcon from "../../../assets/arrow-left.svg";
import { AddressPayload } from "../../../types";

const SHIPPING_BILLING_PAYMENT_TO_STYLE =
  "flex font-opensans not-italic text-lg leading-7 text-primary-color-100 font-bold pb-3";

const Review = (): JSX.Element => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const urlParams = useParams();
  const { id: checkoutSessionId }: { id?: string } = urlParams;
  const { isAuthenticated } = useAuth();
  const setupIntentID = queryParams.get("setup_intent") || "";

  const newShippingAddress = JSON.parse(getItem("newShippingAddress") || "{}");
  const [shippingAddress, setShippingAddress] =
    useState<AddressPayload>(newShippingAddress);
  const existingShippingAddressID = getItem("existingShippingAddressID") || "";

  const {
    state: {
      user: {
        profile: { address },
      },
    },
  } = useContext(Context);

  const { mutate: updateDuplicatePaymentMethodMutation } = useMutation(
    updateDuplicatePaymentMethod,
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  const {
    data: paymentMethodDetails,
    refetch: getPaymentMethod,
    isLoading,
  } = useQuery(
    [
      {
        setup_intent_id: setupIntentID,
      },
    ],
    fetchPaymentMethod,
    {
      enabled: false,
      onSuccess: (data) => {
        if (isAuthenticated) {
          updateDuplicatePaymentMethodMutation({
            paymentMethodId: data?.data?.value.id,
          });
        }
      },
      onError: (e) => {
        // eslint-disable-next-line no-console
        console.error("error on payment details", e);
      },
    }
  );

  const {
    data: savedCardPaymentMethodDetails,
    refetch: getSavedCardPaymentMethod,
  } = useQuery(
    [
      {
        checkoutSessionId,
      },
    ],
    fetchSavedPaymentMethod,
    {
      enabled: false,
    }
  );

  const { data: cartDetails } = useQuery(GET_CART, fetchCart, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const {
    card: {
      brand: cardBrand,
      exp_month: expiryMonth,
      exp_year: expiryYear,
      last4: lastFourDigits,
    } = {
      brand: "",
      exp_month: "",
      exp_year: "",
      last4: "",
    },
    billing_details: { name: billingName, address: billingAddress } = {
      name: "",
      address: {},
    },
    type = "card",
  } = useMemo(() => {
    return (
      (setupIntentID === "saved_card"
        ? savedCardPaymentMethodDetails?.data?.value?.paymentMethod || {}
        : paymentMethodDetails?.data?.value) || {}
    );
  }, [paymentMethodDetails, savedCardPaymentMethodDetails, setupIntentID]);

  const cartData = useMemo(
    () => (cartDetails?.data?.value || {}) as ICartResponse,
    [cartDetails]
  );
  const cartProductData = useMemo(
    () =>
      cartData?.data?.length > 0
        ? cartData?.data?.reduce((cartAllShopData, shopData) => {
            shopData?.items?.forEach((item) => {
              cartAllShopData.push(item);
            });
            return cartAllShopData;
          }, [] as ICartProductDetails[])
        : [],
    [cartData]
  );

  useEffect(() => {
    if (setupIntentID === "saved_card") {
      getSavedCardPaymentMethod();
    } else {
      getPaymentMethod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if shipping and billing addresses are already created and present in store
  useEffect(() => {
    const keys = [
      "address_ln1",
      "address_ln2",
      "city",
      "country",
      "county",
      "state",
      "zip_code",
      "isShow",
      "full_name",
      "email",
      "phone",
    ];
    const addressesDataValues = address || [];
    const existingSData = addressesDataValues?.find(
      (newAddressesDataValue) =>
        newAddressesDataValue?._id === existingShippingAddressID
    );
    if (existingSData) {
      setShippingAddress(pick(existingSData, keys) as AddressPayload);
    }
  }, [address, existingShippingAddressID]);

  /**
   * we are creating/storing addresses in DB only if user is registered
   * - does not apply for Guest user
   */
  const onReview = () => {
    if (billingName) {
      setItem("guest_billing_full_name", billingName);
    }
    history.push({
      pathname: `/checkout/${checkoutSessionId}/order`,
      state: isAuthenticated
        ? { shippingAddressID: existingShippingAddressID }
        : {},
    });
  };

  return (
    <div className="flex flex-col sm:px-4 sm:py-4 md:py-6 lg:max-w-7xl mx-auto mb-10 gap-5">
      <div
        className={`${SHIPPING_BILLING_PAYMENT_TO_STYLE} justify-center mt-3`}
      >
        Review your order details
      </div>
      <div className="flex flex-col">
        <div className="flex p-3 sm:p-4 items-start justify-start ml-4 flex-col sm:flex-row sm:justify-evenly gap-x-4 gap-y-6">
          <div className="flex flex-col flex-1 text-left">
            <div className={SHIPPING_BILLING_PAYMENT_TO_STYLE}>
              Shipping To:
            </div>
            <ExistingAddress
              existingAddress={shippingAddress}
              showActionIcons={false}
              isFullNameBold={false}
            />
          </div>
          <div className="flex flex-col flex-1 text-left">
            <div className={SHIPPING_BILLING_PAYMENT_TO_STYLE}>Billing To:</div>
            {billingAddress && (
              <ExistingAddress
                showEmailAndPhone={false}
                existingAddress={{
                  full_name: billingName,
                  address_ln1: billingAddress?.line1,
                  address_ln2: billingAddress?.line2
                    ? billingAddress?.line2
                    : "",
                  city: billingAddress?.city,
                  state: billingAddress?.state,
                  country: billingAddress?.country,
                  zip_code: billingAddress?.postal_code,
                }}
                showActionIcons={false}
                isFullNameBold={false}
              />
            )}
          </div>
          {type === "card" && (
            <div className="flex flex-col flex-1">
              <div className={SHIPPING_BILLING_PAYMENT_TO_STYLE}>
                Payment Method
              </div>
              <CardInfo
                lastFourDigits={lastFourDigits}
                expiryMonth={expiryMonth}
                expiryYear={expiryYear}
                cardBrand={cardBrand}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col p-2 sm:mx-4 sm:flew-row">
          <a
            href={`/checkout/${checkoutSessionId}`}
            className="flex text-xl font-bold my-3"
          >
            <img src={arrowLeftIcon} className="mr-2" alt="previous" />
            Edit Shipping Address
          </a>
          <div className="flex flex-col lg:flex-row justify-evenly">
            <div className="flex w-full flex-2">
              <Cart
                showCartText={false}
                hideIsGiftOption
                disableQuantityOption
                showCartSummary={false}
                reviewPageStyle="w-full mx-0 p-0 sm:p-1"
              />
            </div>
            <div className="flex w-full my-4 sm:m-0 flex-1">
              <CartSummary
                isActionButtonEnabled={!isLoading}
                cartProductData={cartProductData}
                cartData={cartData}
                showCartText={false}
                onAction={onReview}
                mViewShortcut={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
