import React, { FC, useContext, useEffect, useMemo, useState } from "react";

// packages
import { useHistory, useLocation } from "react-router-dom";

// context
import { useQuery } from "react-query";
import SellOnLonimaContext from "./SellOnLonima.context";

// components
import Progress from "./Progress";
import ShopName from "./ShopName";
import Location from "./Location";
import { Context } from "../../store";
import { GET_USER } from "../../store/actions.types";
import { fetchUserProfile } from "../../store/actions";

export const SellOnLonima: FC = () => {
  const [name, setName] = useState("");
  const [shopId, setShopId] = useState("");
  const [isNameAvailable, setIsNameAvailable] = useState(false);
  const [location, setLocation] = useState("");
  const history = useHistory();

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { shops, email_verified },
      },
    },
  } = useContext(Context);

  const { refetch: getUserDetails } = useQuery(GET_USER, fetchUserProfile, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pathname } = useLocation();

  const activeField = useMemo(() => {
    const tokens = pathname.split("/");
    return tokens[tokens.length - 1];
  }, [pathname]);

  if (activeField !== "name" && activeField !== "location") {
    history.push("/sell-on-lonima/name");
  }

  return (
    <div className="flex flex-col gap-10">
      <SellOnLonimaContext.Provider
        value={{
          name,
          setName,
          location,
          setLocation,
          isNameAvailable,
          setIsNameAvailable,
          shopId,
          setShopId,
          shops,
          email_verified,
        }}
      >
        <div className="flex flex-col justify-center items-center bg-neutral-color-1 py-12">
          <Progress activeField={activeField} />
        </div>
        {activeField === "name" && <ShopName shops={shops} />}
        {activeField === "location" && (
          <Location emailVerified={email_verified} />
        )}
      </SellOnLonimaContext.Provider>
    </div>
  );
};
export default SellOnLonima;
