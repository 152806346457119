/* eslint-disable import/prefer-default-export */
export const userProfileDefaultValues = {
  _id: "",
  email_verified: false,
  default_address_id: "",
  address: [],
  email: "",
  first_name: "",
  last_name: "",
  preferences: [],
  pronouns: [],
  shops: [],
  isLoading: false,
  avatar: "",
  created_at: "",
  about: "",
  dob: "",
  isAdmin: false,
};
