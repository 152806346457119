import React, { FC, useEffect, useMemo } from "react";

// components
import { useQuery } from "react-query";
import Header from "../../components/Header";
import { setCookie } from "../../utils/cookieStorage";
// import { getAddressFromLocation } from "../../utils";
import { GET_CATEGORY_HIERARCHY } from "../../store/actions.types";
import { getCategoryHierarchy } from "./actions";

const HeaderComp: FC = () => {
  // const [locState, setLocState] = useState("");
  // const [city, setCity] = useState("");
  // const [zipCode, setZipCode] = useState("");
  const handleSignIn = () => {
    setCookie("redirect_url_last_visited", window.location.pathname, 7);
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/login`;
  };

  // useEffect(() => {
  //   const options = {
  //     enableHighAccuracy: true,
  //     timeout: 5000,
  //     maximumAge: 0,
  //   };
  //   function success(pos: { coords: { latitude: number; longitude: number } }) {
  //     const crd = pos.coords;
  //     const currentUserLocInCookie = getCookie("user_location");
  //     if (!currentUserLocInCookie && window.google) {
  //       getAddressFromLocation(crd).then((address) => {
  //         setCity(address.city);
  //         setLocState(address.state);
  //         setZipCode(address.zipCode);
  //         setCookie("user_location", JSON.stringify(address), 14);
  //       });
  //     } else if (currentUserLocInCookie) {
  //       const parsedCookie = JSON.parse(currentUserLocInCookie);

  //       setCity(parsedCookie.city);
  //       setLocState(parsedCookie.state);
  //       setZipCode(parsedCookie.zipCode);
  //     }
  //   }

  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   function error(err: unknown) {
  //     setTimeout(() => {
  //       const coordinates = getCookie("coordinates");
  //       if (!coordinates) {
  //         return;
  //       }
  //       const coords: {
  //         latitude: string;
  //         longitude: string;
  //         postalcode: string;
  //       } = JSON.parse(coordinates);
  //       if (coords?.latitude && coords?.longitude) {
  //         const latitude = parseFloat(coords.latitude);
  //         const longitude = parseFloat(coords.longitude);
  //         getAddressFromLocation({ latitude, longitude }).then((address) => {
  //           setCity(address.city);
  //           setLocState(address.state);
  //           setZipCode(address.zipCode);
  //         });
  //       }
  //     }, 500);
  //   }

  //   navigator.geolocation.getCurrentPosition(success, error, options);
  // }, []);

  const { data, refetch } = useQuery(
    GET_CATEGORY_HIERARCHY,
    getCategoryHierarchy,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const categoryData = useMemo(() => {
    return data?.data?.value;
  }, [data]);

  return (
    <>
      <Header
        placeHolder="Find something amazing near you..."
        handleSignIn={handleSignIn}
        // location={{ city, state: locState, zipCode }}
        categories={categoryData || []}
      />
    </>
  );
};

export default HeaderComp;
