/* eslint-disable @typescript-eslint/no-unused-expressions */
import { FC, useState, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";

interface Props {
  id: string;
  children: ReactNode;
  type?: string;
}

const Portal: FC<Props> = ({ id = "", type = "div", children }) => {
  const [element] = useState(document.createElement(type));
  const portalRoot = document.getElementById(id);

  useEffect(() => {
    if (portalRoot) {
      portalRoot.appendChild(element);
    } else {
      element.setAttribute("id", id);
      document.body.appendChild(element);
    }

    return () => {
      if (portalRoot) {
        portalRoot.removeChild(element);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return ReactDOM.createPortal(children, element);
};

export default Portal;
