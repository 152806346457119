/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from "react";

// components
import Button from "../../../../../../components/Button";
import Modal from "../../../../../../components/Modal";
import AddressForm from "../../../../../../components/AddressForm";

// ts
import { AddressResponse } from "../../../../../../store/types";

interface Props {
  title: string;
  isDefaultAddressReadOnly: boolean;
  previousAddress?: AddressResponse;
  uniqueAddressId: string;
  onSave: (address: AddressResponse) => void;
  onClose?: () => void;
}

export const AddressFormModal = ({
  title,
  isDefaultAddressReadOnly,
  previousAddress,
  uniqueAddressId,
  onSave,
  onClose,
}: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [values, setValues] = useState<AddressResponse>({} as AddressResponse);
  const [isSaveEligible, setIsSaveEligible] = useState(false);

  // TODO: change values types
  const handleSave = () => {
    if (isSaveEligible) {
      onSave(values);
      setIsModalOpen(false);
      if (onClose) {
        onClose();
      }
    }
  };

  const updateIsSaveEligible = (val: boolean) => {
    setIsSaveEligible(val);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      isForceClose={!isModalOpen}
      overrideModalStyle="justify-evenly md:max-w-64 overflow-y-auto"
      overrideModalContainerStyle="items-end sm:items-center"
      onClose={closeModal}
    >
      <div className="flex flex-col justify-end px-4 mt-6 w-full">
        <div className="flex justify-center pb-6">
          <div className="flex font-poppins not-italic font-light text-2xl leading-9 text-primary-color-100">
            {title}
          </div>
        </div>
        <AddressForm
          previousAddress={previousAddress}
          isDefaultAddressReadOnly={isDefaultAddressReadOnly}
          isAddressModal
          updateValues={(vals: AddressResponse) => {
            setValues(vals);
          }}
          uniqueAddressId={uniqueAddressId}
          showExtraFormElements
          setIsSaveEligible={updateIsSaveEligible}
        />
        <div className="flex flex-col justify-center items-center md:flex-row md:gap-2 pt-4 pb-4">
          <div className="flex">
            <Button
              containerClassName="w-48 px-1 py-2"
              text="Cancel"
              size="sm"
              type="outline"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <div
            className={`flex ${
              isSaveEligible
                ? "pointer-events-auto cursor-pointer"
                : "pointer-events-none cursor-not-allowed"
            }`}
          >
            <Button
              disabled={!isSaveEligible}
              containerClassName="w-48 px-1 py-2"
              text="Save"
              size="sm"
              type="primary"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddressFormModal.defaultProps = {
  previousAddress: null,
  onClose: () => {},
};

export default AddressFormModal;
