import { useEffect, useState } from "react";
import { getItem } from "../utils";

const useAuth = (): { isAuthenticated: boolean } => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getItem("token"));

  const handleStorage = () => {
    setIsLoggedIn(!!getItem("token"));
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  return { isAuthenticated: isLoggedIn || !!getItem("token") };
};

export default useAuth;
