import React, { useEffect, useMemo, LegacyRef, useContext } from "react";

// packages
import { useMeasure } from "react-use";

// components
import { ImageEditor } from "../../Shop/Profile/EditProfile/ImageEditor";

// ts
import { AddImage } from "../AddOrEditProduct.types";

// context
import AddOrEditProductContext from "../AddOrEditProduct.context";

// constants
import {
  MAIN_PLACEHOLDER_IMAGE_KEY,
  OTHER_PLACEHOLDER_IMAGE_KEY,
  MAIN_IMAGE_KEY,
  MAIN_IMAGE_PLACEHOLDER_TEXT,
  OTHER_IMAGE_PLACEHOLDER_TEXT,
} from "../AddOrEditProduct.constants";

interface Props {
  onUpload: ({
    imageType,
    blobImg,
  }: {
    imageType: string;
    blobImg: Blob | MediaSource;
  }) => void;
}

export const Images = ({ onUpload }: Props): JSX.Element => {
  const [productsRef] = useMeasure();

  const { images, setImages } = useContext(AddOrEditProductContext);

  const otherImagesCount = images?.filter(
    ({ imageKey }) =>
      ![
        MAIN_PLACEHOLDER_IMAGE_KEY,
        OTHER_PLACEHOLDER_IMAGE_KEY,
        MAIN_IMAGE_KEY,
      ].includes(imageKey)
  )?.length;

  const isOtherImagesTresholdReached = useMemo(
    () => otherImagesCount >= 9,
    [otherImagesCount]
  );

  useEffect(() => {
    if (isOtherImagesTresholdReached) {
      setImages(((prevImages: AddImage[]) => {
        let temp = [...prevImages];
        temp = temp.filter(
          ({ imageKey }) => imageKey !== OTHER_PLACEHOLDER_IMAGE_KEY
        );
        return temp;
      }) as unknown as AddImage[]);
    } else {
      setImages(((prevImages: AddImage[]) => {
        const temp = [...prevImages];
        const matchIndex = temp.findIndex(
          ({ imageKey }) => imageKey === OTHER_PLACEHOLDER_IMAGE_KEY
        );
        if (matchIndex === -1) {
          temp.unshift({
            imageKey: OTHER_PLACEHOLDER_IMAGE_KEY,
            renderElement: () => (
              <ImageEditor
                text={OTHER_IMAGE_PLACEHOLDER_TEXT}
                type={OTHER_PLACEHOLDER_IMAGE_KEY}
                onUpload={({ blobImg, type }) =>
                  onUpload({ imageType: type, blobImg })
                }
              />
            ),
          });
        }
        return temp;
      }) as unknown as AddImage[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtherImagesTresholdReached]);

  useEffect(() => {
    setImages([
      {
        imageKey: MAIN_PLACEHOLDER_IMAGE_KEY,
        renderElement: () => (
          <div className="flex flex-col">
            <ImageEditor
              text={MAIN_IMAGE_PLACEHOLDER_TEXT}
              type={MAIN_PLACEHOLDER_IMAGE_KEY}
              onUpload={({ blobImg, type }) =>
                onUpload({ imageType: type, blobImg })
              }
            />
            <p className="text-left md:text-center">Main Image</p>
          </div>
        ),
      },
      {
        imageKey: OTHER_PLACEHOLDER_IMAGE_KEY,
        renderElement: () => (
          <ImageEditor
            text={OTHER_IMAGE_PLACEHOLDER_TEXT}
            type={OTHER_PLACEHOLDER_IMAGE_KEY}
            onUpload={({ blobImg, type }) =>
              onUpload({ imageType: type, blobImg })
            }
          />
        ),
      },
    ] as AddImage[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // main image should be first
  const sortedImages = images.sort((a, b) => {
    if ([MAIN_IMAGE_KEY, MAIN_PLACEHOLDER_IMAGE_KEY].includes(a.imageKey))
      return -1;
    if ([MAIN_IMAGE_KEY, MAIN_PLACEHOLDER_IMAGE_KEY].includes(b.imageKey))
      return 1;
    return 0;
  });

  return (
    <div className="flex flex-col gap-4 md:gap-12">
      <div className="flex flex-col gap-1 md:gap-2">
        <h3 className="flex mt-6 mb-2 font-bold">Photos</h3>
        <div className="flex text-sm font-normal text-left font-opensans md:text-lg">
          Add up to ten photos to help sell your item. Include different angles
          and environments to make your item come to life.
        </div>
        <p className="text-left">
          Main image will be your primary image shown everywhere on the site.
        </p>
        <div
          style={{
            display: "grid",
            gap: 10,
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
            gridTemplateRows: "repeat(auto-fill, minmax(150px, 1fr))",
          }}
          ref={productsRef as LegacyRef<HTMLDivElement> | undefined}
        >
          {sortedImages.map(({ imageKey, type, renderElement }) => (
            <div key={imageKey}>
              {renderElement()}
              {type === MAIN_IMAGE_KEY && (
                <p className="text-left md:text-center">Main Image</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Images;
