/* eslint-disable @typescript-eslint/naming-convention */
// packages
import { pick } from "lodash";

// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../../../../axios";

// ts
import { Result, AddressPayload } from "../../../../../types";

// constants
import { ADDRESS_PAYLOAD_KEYS } from "./constants";

export const addAddress = async ({
  _id,
  full_name,
  isDefaultShippingAddress,
  ...restAddress
}: AddressPayload): Promise<Result> => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/address`,
      {
        ...restAddress,
        full_name,
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { message, _id } = data;
    return await Promise.resolve({
      data: {
        value: {
          id: _id,
          message,
          isDefaultShippingAddress,
        },
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateAddress = async ({
  _id,
  full_name,
  isDefaultShippingAddress,
  ...restAddress
}: AddressPayload): Promise<Result> => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/user/address/id/${_id}`,
      {
        ...restAddress,
        full_name,
      }
    );
    const { message } = data;
    return await Promise.resolve({
      data: {
        value: {
          id: _id,
          isDefaultShippingAddress,
          message,
        },
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const deleteAddress = async (id: string): Promise<Result> => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/user/address/id/${id}`
    );
    return await Promise.resolve({
      data: {
        value: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          addresses: data.map((address: any) => {
            const updatedAddress = {
              ...address,
              isShow: true,
              isDefaultShippingAddress: false,
              isPlaceHolderAddress: false,
            };
            return pick(updatedAddress, ADDRESS_PAYLOAD_KEYS);
          }),
        },
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchAddresses = async (): Promise<Result> => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/user/address/list`
    );
    return await new Promise((resolve) =>
      resolve({
        data: {
          value: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            addresses: data.map((address: any) => {
              const updatedAddress = {
                ...address,
                isShow: true,
                isDefaultShippingAddress: false,
                isPlaceHolderAddress: false,
              };
              return pick(updatedAddress, ADDRESS_PAYLOAD_KEYS);
            }),
          },
        },
      })
    );
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const setDefaultAddress = async (addressID: string): Promise<Result> => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/user/address/id/${addressID}/default`
    );
    const { message } = data;
    return await new Promise((resolve) =>
      resolve({
        data: {
          value: {
            message,
          },
        },
      })
    );
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
