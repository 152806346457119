/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// components
import React from "react";
import { ClipLoader } from "react-spinners";
import { getButtonStyle, ButtonTypes, ButtonSizes } from "./Button.utils";

// ts
interface Props {
  text?: string;
  type?: ButtonTypes;
  size?: ButtonSizes;
  disabled?: boolean;
  containerClassName?: string;
  buttonClassName?: string;
  textClassName?: string;
  loading?: boolean;
  loadingColor?: string;
  onClick?: (e?: React.MouseEventHandler<HTMLButtonElement>) => void;
}

const Button = ({
  text,
  type = "primary",
  size = "sm",
  disabled = false,
  containerClassName,
  textClassName,
  buttonClassName,
  onClick = () => {},
  loading = false,
  loadingColor,
}: Props): JSX.Element => {
  const { buttonClass, textClass } = getButtonStyle({ type, size });
  return (
    <div
      onClick={() => (disabled || loading ? null : onClick())}
      className={containerClassName}
    >
      <button
        disabled={disabled || loading}
        type="button"
        className={`hover:animate-shine w-full px-2 h-inherit ${buttonClass} cursor-${
          disabled || loading ? "not-allowed" : "pointer"
        } ${buttonClassName} ${disabled ? "bg-disabled-500 text-white" : ""}`}
      >
        {loading && <ClipLoader color={loadingColor} loading size={30} />}
        <div
          className={`${textClassName} ${textClass} ${loading ? "flex-2" : ""}`}
        >
          {text}
        </div>
      </button>
    </div>
  );
};

Button.defaultProps = {
  containerClassName: "",
  buttonClassName: "",
  textClassName: "",
  text: "Enabled",
  type: "primary",
  size: "sm",
  disabled: false,
  loading: false,
  loadingColor: "#FFF",
  onClick: () => {},
};

export default Button;
