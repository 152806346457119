/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useState,
  useEffect,
  MutableRefObject,
  useCallback,
} from "react";

// packages
import { v4 as uuid } from "uuid";

// components
import { PatternFormat } from "react-number-format";
import CheckBox from "../CheckBox";
import Dropdown from "../Dropdown";
import TextInput from "../TextInput";

// utils
import { getTextInputStyle, TypeOfState } from "../TextInput/TextInput.utils";

// ts
import { Address, Element, KeyType } from "./types";
import { AddressResponse } from "../../store/types";

// constants
import {
  US_STATES_OPTIONS,
  DEFAULT_ADDRESS_OBJECT,
  MANDATORY_ADDRESS_KEYS,
  getPreSelectedUSState,
  DEFAULT_ELEMENT,
} from "./constants";
import { validateEmail } from "../../utils";
import { Address as PlaceAddress } from "../../screens/SellOnLonima/Location";
import {
  getGoogleMapsPlacePredictions,
  getPlaceDetails,
} from "../../screens/SellOnLonima/Location/location.utils";
import { validatePhone } from "../../utils/string";

interface Props {
  isDefaultAddressReadOnly?: boolean;
  isAddressModal?: boolean;
  setIsSaveEligible: (val: boolean) => void;
  previousAddress?: AddressResponse;
  updateValues: (address: AddressResponse) => void;
  showExtraFormElements?: boolean;
  uniqueAddressId: string;
}

interface LocationType {
  address_ln1: string;
  address_ln2: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
}

export const AddressForm = ({
  isDefaultAddressReadOnly,
  isAddressModal,
  setIsSaveEligible,
  previousAddress,
  updateValues,
  showExtraFormElements,
  uniqueAddressId,
}: Props): JSX.Element => {
  const [userEnteredLocation, setUserEnteredLocation] = useState("");
  const defaultAddressObject = showExtraFormElements
    ? {
        ...DEFAULT_ADDRESS_OBJECT,
        email: DEFAULT_ELEMENT,
        phone: DEFAULT_ELEMENT,
      }
    : DEFAULT_ADDRESS_OBJECT;
  const formatPreviousToCurrentAddress = useCallback(
    (tempPrevAddress = previousAddress as AddressResponse) => {
      const formattedPreviousAddress = Object.keys(tempPrevAddress).reduce(
        (acc, curr) => {
          acc[curr as KeyType] =
            (curr as KeyType) === ("_id" as KeyType)
              ? (tempPrevAddress[curr as KeyType] as unknown as Element)
              : {
                  value: tempPrevAddress[curr as KeyType],
                  elementState: "normal" as TypeOfState,
                };

          return acc;
        },
        {} as Address
      );

      return formattedPreviousAddress;
    },
    [previousAddress]
  );
  const defaultAddressValues = previousAddress?.address_ln1
    ? formatPreviousToCurrentAddress(previousAddress)
    : defaultAddressObject;

  const [addressForm, setAddressForm] = useState<Address>(defaultAddressValues);
  const [searchRef, setSearchRef] =
    useState<MutableRefObject<HTMLInputElement | null>>();
  const [addresses, setAddresses] = useState<PlaceAddress[]>([]);

  const [uniqID] = useState(uuid());

  const {
    full_name,
    isDefaultShippingAddress,
    email,
    phone,
    address_ln1,
    address_ln2,
    city,
    state,
    zip_code,
  } = addressForm;

  useEffect(() => {
    if (previousAddress?.address_ln1) {
      setUserEnteredLocation(
        `${previousAddress?.address_ln1}, ${previousAddress?.city}, ${previousAddress?.state}`
      );
    }
  }, [previousAddress]);

  const requiredAddressKeys = useCallback(
    () =>
      showExtraFormElements
        ? [...MANDATORY_ADDRESS_KEYS, "email", "phone"]
        : MANDATORY_ADDRESS_KEYS,
    [showExtraFormElements]
  );

  const updateLocation = async (val: string) => {
    setUserEnteredLocation(val);
    if (val.length > 4) {
      const results = (await getGoogleMapsPlacePredictions(
        userEnteredLocation
      )) as PlaceAddress[];
      setAddresses(
        results?.length
          ? results.map(({ description: addressDescription, place_id }) => ({
              description: addressDescription,
              place_id,
            }))
          : []
      );
    } else {
      setAddresses([]);
    }
  };

  const updateAddressForm = useCallback(
    (key: KeyType, value: string | boolean, overrideRequired?: boolean) => {
      setAddressForm((form) => {
        return {
          ...form,
          [key]: {
            value,
            elementState:
              !overrideRequired &&
              requiredAddressKeys().includes(key) &&
              !value?.toString()?.trim()
                ? "error"
                : "normal",
          },
        };
      });
    },
    [requiredAddressKeys]
  );

  const onSelectAddress = async (placeId: string) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { formatted_address, address_components } = (await getPlaceDetails(
      searchRef?.current as HTMLDivElement,
      placeId
    )) as {
      formatted_address: string;
      address_components: Array<{
        short_name: string;
        long_name: string;
        types: Array<string>;
      }>;
    };

    setUserEnteredLocation(formatted_address);
    const fullAddressObj = {
      street_number: "",
      route: "",
      postal_code: "",
      postal_code_suffix: "",
      locality: "",
      administrative_area_level_1: "",
      country: "",
    };
    address_components.forEach((addressComponent) => {
      const componentType = addressComponent.types[0];

      switch (componentType) {
        case "street_number": {
          fullAddressObj.street_number = addressComponent.short_name;
          break;
        }

        case "route": {
          fullAddressObj.route = addressComponent.short_name;
          break;
        }

        case "postal_code": {
          fullAddressObj.postal_code = addressComponent.long_name;
          break;
        }

        case "postal_code_suffix": {
          fullAddressObj.postal_code_suffix = addressComponent.long_name;
          break;
        }

        case "locality":
          fullAddressObj.locality = addressComponent.long_name;
          break;

        case "administrative_area_level_1": {
          fullAddressObj.administrative_area_level_1 =
            addressComponent.short_name;
          break;
        }

        case "country":
          fullAddressObj.country = addressComponent.long_name;
          break;
        default:
          break;
      }
    });

    const locationObj = {
      address_ln1: `${fullAddressObj.street_number} ${fullAddressObj.route}`,
      address_ln2: "",
      city: fullAddressObj.locality,
      state: fullAddressObj.administrative_area_level_1,
      country: fullAddressObj.country,
      zip_code: `${fullAddressObj.postal_code} - ${fullAddressObj.postal_code_suffix}`,
    };
    Object.keys(locationObj).forEach((locItem) => {
      updateAddressForm(
        locItem as KeyType,
        locationObj[locItem as keyof LocationType]
      );
    });

    setAddresses([]);
  };

  const setEmail = useCallback(() => {
    if (!validateEmail(email?.value as string)) {
      setAddressForm((form) => {
        return {
          ...form,
          email: {
            value: email?.value,
            elementState: "error",
          },
        };
      });
    } else {
      setAddressForm((form) => {
        return {
          ...form,
          email: {
            value: email?.value,
            elementState: "normal",
          },
        };
      });
    }
  }, [email?.value]);

  const setPhone = useCallback(() => {
    if (!validatePhone(phone?.value as string)) {
      setAddressForm((form) => {
        return {
          ...form,
          phone: {
            value: phone?.value,
            elementState: "error",
          },
        };
      });
    } else {
      setAddressForm((form) => {
        return {
          ...form,
          phone: {
            value: phone?.value,
            elementState: "normal",
          },
        };
      });
    }
  }, [phone?.value]);

  const getAddressValues = (currentAddressForm: Address) => {
    return Object.keys(currentAddressForm).reduce((acc, curr) => {
      const currentKey = curr as KeyType;
      acc[currentKey] =
        currentKey === ("_id" as KeyType)
          ? (currentAddressForm[currentKey] as unknown as string)
          : currentAddressForm[currentKey]?.value;
      return acc;
    }, {} as AddressResponse);
  };

  useEffect(() => {
    const currentForm = addressForm;
    const isSaveEligible = Object.keys(currentForm)?.reduce(
      (acc, currentFormKey) => {
        if (requiredAddressKeys().includes(currentFormKey)) {
          const currentFormKeyValue =
            currentForm[
              currentFormKey as
                | "full_name"
                | "address_ln1"
                | "zip_code"
                | "city"
                | "state"
                | "email"
                | "phone"
            ];
          if (
            typeof currentFormKeyValue === "object" &&
            (currentFormKeyValue as Element).elementState === "error"
          ) {
            return false;
          }
        }
        return acc;
      },
      true
    );
    setIsSaveEligible(isSaveEligible);
    updateValues({ ...getAddressValues(currentForm), _id: uniqueAddressId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressForm]);

  useEffect(() => {
    setEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email?.value, setEmail]);
  useEffect(() => setPhone(), [phone?.value, setPhone]);

  return (
    <div key={uniqID}>
      <div className="flex flex-col justify-end w-full gap-2 md:gap-3">
        {showExtraFormElements && (
          <div className="flex flex-col w-full">
            <div className="flex w-full">
              <TextInput
                isRequired
                inputId="email"
                label="Email Address"
                textInputType="text"
                value={email?.value as string}
                containerStyleClass="w-full"
                typeOfState={email?.elementState}
                size="sm"
                placeholder=""
                infoMessage={
                  email?.elementState === "error"
                    ? "Please enter a valid email"
                    : ""
                }
                overrideInfoMessageStyleClass=""
                onChange={(val: string) => updateAddressForm("email", val)}
              />
            </div>
            <div className="flex flex-col w-full pt-2">
              <div
                aria-required
                className={`flex ${
                  getTextInputStyle({
                    size: "sm",
                    typeOfState: phone?.elementState ?? "normal",
                  }).labelClass
                } w-inherit `}
              >
                Phone
                <span className="text-red-500"> *</span>
              </div>
              <PatternFormat
                value={phone?.value as string}
                type="tel"
                format="+1 (###) ###-####"
                allowEmptyFormatting
                mask="_"
                onValueChange={(value) =>
                  updateAddressForm("phone", value.formattedValue)
                }
                className="p-2 border border-primary-color-100 border-opacity-25 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-base"
                required
              />
            </div>
          </div>
        )}
        <div className="flex w-full">
          <TextInput
            isRequired
            inputId="full_name"
            label="Full Name"
            textInputType="text"
            value={full_name?.value as string}
            containerStyleClass="w-full"
            typeOfState={full_name?.elementState}
            size="sm"
            placeholder=""
            infoMessage=""
            overrideInfoMessageStyleClass=""
            onChange={(val: string) => updateAddressForm("full_name", val)}
          />
        </div>
        <div className="flex flex-col w-full">
          <TextInput
            isRequired
            setRef={(ref) => setSearchRef(ref)}
            inputId="location"
            label="Address"
            textInputType="text"
            value={userEnteredLocation}
            typeOfState={
              userEnteredLocation?.trim()?.length === 0 ? "error" : "normal"
            }
            containerStyleClass="w-full"
            size="sm"
            placeholder="Start typing your address..."
            infoMessage={
              userEnteredLocation?.trim()?.length === 0
                ? "Address cannot be empty"
                : ""
            }
            overrideInfoMessageStyleClass=""
            onChange={updateLocation}
          />
          {addresses.length > 0 && (
            <div className="flex flex-col w-full border border-opacity-25 h-7/12 border-primary-color-100">
              {addresses.map(
                ({ description: addressDescription, place_id }) => {
                  return (
                    <div
                      className="flex p-2 border-b cursor-pointer border-primary-color-100"
                      onClick={() => onSelectAddress(place_id)}
                      key={place_id}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                    >
                      {addressDescription}
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col w-full gap-3">
          <div className="flex w-full">
            <TextInput
              disabled
              readOnly
              inputId="address_ln1"
              label="Street Address"
              textInputType="text"
              value={address_ln1.value ? (address_ln1.value as string) : ""}
              containerStyleClass="w-full"
              size="sm"
              placeholder=""
              infoMessage=""
              overrideInfoMessageStyleClass=""
              onChange={() => {}}
            />
          </div>
          <div className="flex w-full">
            <TextInput
              inputId="address_ln2"
              label="Apt / Suite / Other"
              textInputType="text"
              value={address_ln2.value ? (address_ln2.value as string) : ""}
              containerStyleClass="w-full"
              size="sm"
              placeholder=""
              infoMessage=""
              overrideInfoMessageStyleClass=""
              onChange={(value) => {
                updateAddressForm("address_ln2", value);
              }}
            />
          </div>
          <div className="flex gap-4 md:flex-nowrap md:gap-8">
            <TextInput
              disabled
              inputId="zip_code"
              label="Zip / Postal code"
              textInputType="text"
              value={zip_code.value ? (zip_code.value as string) : ""}
              containerStyleClass="w-full"
              size="sm"
              placeholder=""
              infoMessage=""
              overrideInfoMessageStyleClass=""
              onChange={() => {}}
            />
            <TextInput
              disabled
              inputId="city"
              label="City"
              textInputType="text"
              value={city.value ? (city.value as string) : ""}
              containerStyleClass="w-full"
              size="sm"
              placeholder=""
              infoMessage=""
              overrideInfoMessageStyleClass=""
              onChange={() => {}}
            />
          </div>
          {state.value && (
            <div className="flex w-full">
              <Dropdown
                disableDropdown
                placeholderInput="Choose State ..."
                title="State"
                type="primary"
                size="fullWidth"
                textSize="sm"
                preSelectedOptions={[
                  getPreSelectedUSState(
                    state.value ? (state.value as string) : ""
                  ) as {
                    label: string;
                    value: string;
                  },
                ]}
                options={US_STATES_OPTIONS}
                useAddressFormStyle
              />
            </div>
          )}
        </div>
        {isAddressModal && (
          <div className="flex w-full ml-2">
            <CheckBox
              text="Make this my default shipping address"
              isSelected={
                isDefaultAddressReadOnly
                  ? true
                  : (isDefaultShippingAddress?.value as boolean)
              }
              isEnabled={!isDefaultAddressReadOnly}
              onChange={({ isSelected }: { isSelected: boolean }) =>
                updateAddressForm("isDefaultShippingAddress", isSelected)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

AddressForm.defaultProps = {
  previousAddress: {} as AddressResponse,
  isAddressModal: false,
  isDefaultAddressReadOnly: false,
  showExtraFormElements: false,
};

export default AddressForm;
