export type TextInputSizes = "xs" | "sm" | "lg";
export type TypeOfState = "normal" | "active" | "error" | "success";
export type TextInputTypes = "text" | "number" | "password" | "email";

interface IProps {
  size: TextInputSizes;
  typeOfState: TypeOfState;
}

export const getTextInputStyle = ({
  size,
  typeOfState,
}: IProps): {
  labelClass: string;
  inputClass: string;
  inputClassContainer: string;
  infoMessageStyleClass: string;
} => {
  const textInputClassContainerMap = {
    normal: {
      xs: "flex items-center border border-primary-color-100 border-opacity-25 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-sm",
      sm: "flex items-center border border-primary-color-100 border-opacity-25 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-base",
      lg: "flex items-center border border-primary-color-100 border-opacity-25 text-primary-color-100 rounded-md mt-0.5 h-66 font-opensans font-normal leading-6 text-base",
    },
    active: {
      xs: "flex items-center border border-neutral-color-3 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-sm",
      sm: "flex items-center border border-neutral-color-3 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-base",
      lg: "flex items-center border border-neutral-color-3 text-primary-color-100 rounded-md mt-0.5 h-66 font-opensans font-normal leading-6 text-base",
    },
    error: {
      xs: "flex items-center border border-neutral-color-4 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-sm",
      sm: "flex items-center border border-neutral-color-4 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-base",
      lg: "flex items-center border border-neutral-color-4 text-primary-color-100 rounded-md mt-0.5 h-66 font-opensans font-normal leading-6 text-base",
    },
    success: {
      xs: "flex items-center border border-neutral-color-5 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-sm",
      sm: "flex items-center border border-neutral-color-5 text-primary-color-100 rounded-md mt-0.5 font-opensans font-normal leading-6 text-base",
      lg: "flex items-center border border-neutral-color-5 text-primary-color-100 rounded-md mt-0.5 h-66 font-opensans font-normal leading-6 text-base",
    },
  };

  const textInputClassDefaultStyle = "outline-none rounded-md w-full p-3 pr-3";
  const textInputClassContainerSmallStyle =
    "outline-none rounded-md w-full p-2 pr-2";

  const textInputClassMap = {
    normal: {
      xs: textInputClassDefaultStyle,
      sm: textInputClassContainerSmallStyle,
      lg: textInputClassDefaultStyle,
    },
    active: {
      xs: textInputClassDefaultStyle,
      sm: textInputClassContainerSmallStyle,
      lg: textInputClassDefaultStyle,
    },
    error: {
      xs: textInputClassDefaultStyle,
      sm: textInputClassContainerSmallStyle,
      lg: textInputClassDefaultStyle,
    },
    success: {
      xs: textInputClassDefaultStyle,
      sm: textInputClassContainerSmallStyle,
      lg: textInputClassDefaultStyle,
    },
  };

  const labelClassMap = {
    default: `font-opensans font-normal ${
      ["xs", "sm"].includes(size) ? "text-xs" : "text-lg leading-7"
    }`,
  };

  const infoMessageStyleMap = {
    normal: "h-10",
    active: "h-10",
    error: `h-10 text-neutral-color-4 font-opensans mt-0.5 font-normal ${
      ["xs", "sm"].includes(size) ? "text-xs" : "text-sm  leading-5"
    }`,
    success: `h-10 text-neutral-color-5 font-opensans mt-0.5 font-normal ${
      ["xs", "sm"].includes(size) ? "text-xs" : "text-sm  leading-5"
    }`,
  };

  return {
    inputClassContainer: textInputClassContainerMap[typeOfState][size],
    inputClass: textInputClassMap[typeOfState][size],
    labelClass: labelClassMap.default,
    infoMessageStyleClass: infoMessageStyleMap[typeOfState],
  };
};
