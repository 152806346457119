import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { ErrorBoundary } from "react-error-boundary";
import { SplitFactory } from "@splitsoftware/splitio-react";
import Hotjar from "@hotjar/browser";
import Header from "./containers/Header";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import Store from "./store";
import ToastBox from "./components/ToastBox";
import Footer from "./containers/Footer";
import { ErrorFallBack } from "./components/ErrorFallBack";

const siteId = 5070094;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorFallBack}>
          <Store>
            <SplitFactory
              config={{
                core: {
                  authorizationKey: process.env
                    .REACT_APP_SPLIT_CORE_AUTHORIZATION_KEY as string,
                  key: process.env.REACT_APP_SPLIT_CORE_API_KEY as string,
                },
              }}
            >
              <div className="App">
                <div className="flex flex-col justify-between">
                  <Header />
                  <ToastBox />
                  <div className="page-body">
                    <Routes />
                  </div>
                  <Footer />
                </div>
              </div>
            </SplitFactory>
          </Store>
        </ErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
