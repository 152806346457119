/* eslint-disable import/prefer-default-export */

export function getCookie(cname: string): string | null {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  if (!decodedCookie) return "";

  for (let i = 0; i < ca.length; i += 1) {
    const c = ca[i];
    const cookieArr = c.split(name);
    if (cookieArr[1]) {
      return cookieArr[1];
    }
  }
  return "";
}

export function setCookie(cname: string, cvalue: string, exdays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = exdays ? `expires=${d.toUTCString()}` : "";
  document.cookie = `${cname}=${encodeURIComponent(cvalue)};${expires};path=/`;
}
