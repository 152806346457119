import React from "react";
import { useQuery } from "react-query";
import { getUserPaymentMethods } from "./actions";
import CreditCard from "./CreditCard";

export const CreditCards = (): JSX.Element => {
  const { data: paymentMethods } = useQuery(
    "FETCH_PAYMENT_METHODS",
    getUserPaymentMethods
  );
  const paymentData = paymentMethods?.data?.value?.paymentData;
  return (
    <div className="flex flex-col items-start gap-3">
      <p className="font-poppins font-light text-xl">Credit Cards</p>
      <p className="font-opensans mb-4 text-left">
        Credit cards can only be added during the checkout process.
      </p>
      {paymentData &&
        paymentData.map(
          (paymentMethod: {
            card: {
              brand: string;
              exp_month: string;
              exp_year: string;
              last4: string;
            };
            billing_details: { address: { postal_code: string } };
          }) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { brand, exp_month, exp_year, last4 } = paymentMethod?.card;
            const { address } = paymentMethod?.billing_details;
            return (
              <CreditCard
                brand={brand}
                last4Digits={last4}
                billingZipCode={address?.postal_code}
                expiryMonth={exp_month}
                expiryYear={exp_year}
                isDefault={false}
              />
            );
          }
        )}
      {(!paymentData || paymentData.length === 0) && (
        <p className="font-opensans mb-4">No saved cards</p>
      )}
    </div>
  );
};

export default CreditCards;
