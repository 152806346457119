import React, { useState } from "react";
import { capitalize } from "lodash";
import moment from "moment";
import { useWindowSize } from "react-use";
import { useMutation, RefetchOptions, QueryObserverResult } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import Button from "../../../../components/Button";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../../../components/TextInput";

// components
import {
  orderBagIcon,
  arrowUpIcon,
  arrowDownIcon,
  imageUnavailablePlaceHolder,
} from "../../../../assets";
import ManageOrder from "./ManageOrder";
import Shipments from "./Shipments";

// ts
import { ChildOrder, ParentOrder } from "../../Shop.types";

// actions
import { updateOrderStatus } from "../actions";
import Modal from "../../../../components/Modal";

export const getOptions = (
  options: string[]
): { label: string; value: string }[] =>
  options.map((orderStatus) => ({
    label: orderStatus.split("_").map(capitalize).join(" "),
    value: orderStatus,
  })) || [{ label: "", value: "" }];

const Order = ({
  order,
  reFetchOrdersByShop,
}: {
  order: ChildOrder;
  reFetchOrdersByShop: (
    options?: RefetchOptions | undefined
  ) => Promise<
    QueryObserverResult<AxiosError<unknown, unknown> | ParentOrder[], unknown>
  >;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [showUpdateOrderModal, setShowUpdateOrderModal] = useState(false);
  const [showManageOrder, setShowManageOrder] = useState(false);
  const [showShipments, setShowShipments] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const { width } = useWindowSize();
  const isSmallDevices = width < 768;

  const toggleIsOpen = () => {
    setIsOpen((s) => !s);
  };

  const setErrorMessage = () => {
    toast.error("Failed to update order status, Please try again", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const orderKeys = Object.keys(order);
  const orderUpdatedDate = order?.updated_at;

  const { mutate: updateOrderStatusMutation } = useMutation(updateOrderStatus, {
    onSuccess: () => {
      toast.success("Order status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      reFetchOrdersByShop();
      toggleIsOpen();
    },
    onError: () => {
      setErrorMessage();
    },
  });

  const detailStyle =
    "flex font-opensans text-primary-color-70 font-bold text-sm";
  const manageOrderStyle = `${detailStyle} hover:text-primary-color-100`;
  const orderPropertyStyle = "flex text-sm font-opensans";
  const disabled = ["CANCELLED", "SHIPPING_LABEL_CREATED"].includes(
    order.status
  );
  const isOrderCancelled = order.status === "CANCELLED";

  const productQuantity = order.product_details.reduce((sum, product) => {
    // eslint-disable-next-line no-param-reassign
    sum += product.quantity || 0;
    return sum;
  }, 0);

  const shipmentsQuantity = order?.shipments?.reduce((sum, shipment) => {
    const shipmentQuantity = shipment.variants.reduce(
      (variantQuantity, variant) => {
        // eslint-disable-next-line no-param-reassign
        variantQuantity += variant.quantity || 0;
        return variantQuantity;
      },
      0
    );
    // eslint-disable-next-line no-param-reassign
    sum += shipmentQuantity || 0;
    return sum;
  }, 0);

  const areAllProductsShipped = productQuantity === shipmentsQuantity;

  return (
    <div
      className="flex flex-col gap-3 p-5 text-base font-normal border border-solid rounded-md shadow-md border-primary-color-100 border-opacity-10 font-poppins bg-neutral-color-1"
      key={order._id}
    >
      {showUpdateOrderModal && (
        <Modal
          isForceClose={!showUpdateOrderModal}
          overrideModalStyle="justify-evenly md:max-w-2xl w-full"
          overrideModalContainerStyle="items-center"
          onClose={() => setShowUpdateOrderModal(false)}
        >
          <div className="flex flex-col items-center justify-center w-full h-48 gap-1">
            <h3 className="p-4 text-center">
              Are you sure you want to cancel this order?
            </h3>
            <TextInput
              isRequired
              containerStyleClass="w-5/12"
              inputId="cancelation_reason"
              label="Reason for cancelation"
              textInputType="text"
              value={cancellationReason}
              typeOfState={cancellationReason ? "normal" : "error"}
              size="sm"
              placeholder=""
              overrideInfoMessageStyleClass=""
              onChange={setCancellationReason}
            />
            <div className="flex pt-6 justify-evenly">
              <Button
                text="Confirm Cancelation"
                size="sm"
                type="primary"
                disabled={!cancellationReason}
                onClick={() => {
                  setShowUpdateOrderModal(false);
                  updateOrderStatusMutation({
                    id: order._id,
                    status: "CANCELLED",
                    note: cancellationReason,
                  });
                }}
              />
            </div>
          </div>
        </Modal>
      )}
      {showManageOrder && (
        <ManageOrder
          isOpen={showManageOrder}
          onClose={() => setShowManageOrder(false)}
          order={order}
          reFetchOrdersByShop={reFetchOrdersByShop}
        />
      )}
      {showShipments && (
        <Shipments
          isOpen={showShipments}
          onClose={() => setShowShipments(false)}
          order={order}
          reFetchOrdersByShop={reFetchOrdersByShop}
        />
      )}
      <div
        className="flex justify-between w-full"
        role="button"
        tabIndex={0}
        onClick={toggleIsOpen}
        onKeyDown={() => {
          return null;
        }}
      >
        <div className="flex gap-5 md:gap-10">
          <div className="flex" style={{ height: "fit-content" }}>
            <img height={25} width={25} src={orderBagIcon} alt="bag-icon" />
          </div>
          <div className="flex-col">
            <div className="flex items-center gap-2">
              <div className="flex text-sm font-bold font-opensans">
                {order._id}
              </div>
            </div>
            <div className={detailStyle}>
              Status: {capitalize(order.status || "")}
            </div>
            <div className={detailStyle}>
              {orderKeys.includes("total") ? (
                <div className={detailStyle}>
                  Total Before Tax: {`$${order.total}`}
                </div>
              ) : null}
            </div>
            <div className={detailStyle}>
              {orderKeys.includes("order_tax") ? (
                <div className={detailStyle}>
                  Tax: {`$${order.order_tax.toFixed(2)}`}
                </div>
              ) : null}
            </div>
            <div className={detailStyle}>
              {orderKeys.includes("order_total_after_tax") ? (
                <div className={detailStyle}>
                  Total: {`$${order.order_total_after_tax}`}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap">
          <div className="flex">
            {orderUpdatedDate ? (
              <div className={detailStyle}>
                {`${moment(orderUpdatedDate).format("LLL")}`}
              </div>
            ) : null}
          </div>
          {!isOrderCancelled && !areAllProductsShipped && (
            <div className={manageOrderStyle}>
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setShowManageOrder(true);
                  e.stopPropagation();
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
                className="underline cursor-pointer whitespace-nowrap"
              >
                Manage Order
              </div>
            </div>
          )}
          {!isOrderCancelled && (
            <div className={manageOrderStyle}>
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setShowShipments(true);
                  e.stopPropagation();
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
                className="underline cursor-pointer whitespace-nowrap"
              >
                Manage Shipments
              </div>
            </div>
          )}
        </div>
        <div className="flex items-start">
          {!isOrderCancelled && (
            <img
              src={isOpen ? arrowUpIcon : arrowDownIcon}
              alt="arrow-up-down"
            />
          )}
        </div>
      </div>
      {isOpen ? (
        <div className="flex flex-col gap-5 p-2">
          <div className="flex flex-col gap-5">
            {!isOrderCancelled && (
              <div className="flex flex-col items-start gap-2 mt-4">
                <Button
                  text="Cancel Order"
                  size="sm"
                  type="red"
                  disabled={disabled}
                  containerClassName={isSmallDevices ? "w-full" : "w-5/12"}
                  onClick={() => {
                    setShowUpdateOrderModal(true);
                  }}
                />
                {disabled && (
                  <span className="text-xs text-red-500">
                    *Note: Order cannot be canceled if products are already
                    shipped
                  </span>
                )}
              </div>
            )}
            <div
              className="flex flex-col gap-10"
              style={{ borderTop: "2px solid rgba(63, 65, 91, 0.25)" }}
            >
              {order?.product_details?.map((productDetail) => {
                const {
                  price = "-",
                  variant = {},
                  quantity = "",
                  details,
                  product_id: productId = "",
                } = productDetail;
                const { name = "", product_images: productImages = [] } =
                  details;
                return (
                  <div
                    className="flex flex-col justify-between gap-10 mt-5"
                    key={productId}
                  >
                    <div className="flex">
                      <img
                        src={productImages[0] || imageUnavailablePlaceHolder}
                        alt="order-product"
                        className="mr-2 w-36 h-36"
                        style={{ height: "fit-content" }}
                      />
                      <div className="flex flex-col items-start gap-2 pl-2 font-normal font-opensans">
                        <div
                          className="flex text-base text-left underline font-opensans text-primary-color-100"
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                        >
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${window.location.origin}/product?id=${productId}`}
                          >
                            {capitalize(name) || ""}
                          </a>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex flex-col text-base font-opensans md:text-xl">
                            {Object.keys(variant).map((variantKey) => {
                              return (
                                <div className={orderPropertyStyle}>
                                  {`${capitalize(variantKey)}: ${capitalize(
                                    (variant as { [key: string]: string })[
                                      variantKey as string
                                    ] || "-"
                                  )}`}
                                </div>
                              );
                            })}
                          </div>
                          <div className={orderPropertyStyle}>
                            {`Price: $${price}`}
                          </div>
                          <div className={orderPropertyStyle}>
                            Quantity: {quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Order;
