/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from "react";

// packages
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useWindowSize } from "react-use";

// components
import Pagination from "../../../../components/Pagination";
import TextInput from "../../../../components/TextInput";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Product from "./Product";
import styles from "./ShopProducts.module.css";

// ts
import { ListData } from "../../../../types";
import { Product as ProductType } from "../../../Product/types";

import { removeProduct } from "../../../Product/actions";

export const ShopProducts = ({
  onPageChange,
  shopId,
  isLoading,
  pageNumber,
  products = {
    data: [],
    resultsInfo: { pageNo: 1, pageSize: 1, totalCount: 1 },
  },
  refetchProductsByShop,
  isUserBelongToShop,
}: {
  onPageChange: (page: number) => unknown;
  products: ListData;
  shopId: string;
  isLoading: boolean;
  pageNumber: number;
  refetchProductsByShop: () => void;
  isUserBelongToShop: boolean;
}): JSX.Element => {
  const history = useHistory();
  const [productToDelete, setProductToDelete] = useState({ id: "", name: "" });
  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [error, setError] = useState("");
  const { width } = useWindowSize();

  const { data, resultsInfo } = products;
  const dataWithAddProductButton = [
    { name: "Add Product", _id: "add_products" },
    ...data,
  ];
  const getAddProductsComp = () => {
    const isMobile = width < 768;
    return (
      <div
        key="add-product"
        style={
          isMobile
            ? {
                position: "fixed",
                bottom: "2%",
                left: "0%",
                zIndex: 100,
              }
            : {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }
        }
      >
        <div
          className="flex items-center relative md:w-56 lg:w-60 md:p-3 md:h-76 lg:h-80 
          border-solid border-2 md:border-contrast-color-1-90 lg:border-contrast-color-1-90 
          rounded-xl justify-center gap-3 cursor-pointer"
          role="button"
          tabIndex={0}
          style={
            isMobile
              ? {
                  height: "50%",
                  padding: 10,
                  background: "rgba(135,206,250, 0.8)",
                  border: "none",
                }
              : { height: "100%", padding: 10 }
          }
          onKeyDown={() => {}}
          onClick={() => history.push(`/add-product?shop_id=${shopId}`)}
        >
          <div className={styles.plus} />
          <div className="text-sm md:text-base">Add Product</div>
        </div>
      </div>
    );
  };
  const { pageSize, totalCount } = resultsInfo;
  const content = (
    <div className="grid grid-cols-2 gap-x-1 gap-y-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 sm:gap-x-4 md:gap-x-5 lg:gap-x-10 lg:gap-y-8 xl:gap-x-12 xl:gap-y-10">
      {dataWithAddProductButton.map((product) => {
        if (product._id === "add_products") {
          if (isUserBelongToShop) {
            return getAddProductsComp();
          }
          return null;
        }
        return (
          <Product
            key={product._id}
            product={product as ProductType}
            onRemove={() => {
              setProductToDelete({ id: product._id, name: product.name });
              setIsModalOpen(true);
            }}
          />
        );
      })}
    </div>
  );

  useEffect(() => {
    if (!isModalOpen) {
      setDeleteConfirmationText("");
    }
  }, [isModalOpen]);

  const resetError = () => {
    setTimeout(() => {
      setError("");
    }, 5000);
  };

  const { mutate: deleteProduct, isLoading: isDeleteProductLoading } =
    useMutation(removeProduct, {
      onSuccess: () => {
        refetchProductsByShop();
        setIsModalOpen(false);
      },
      onError: () => {
        setError("Failed to delete product, please try again.");
        resetError();
      },
    });

  const isProductDeleteEnabled = deleteConfirmationText === "DELETE";

  return (
    <div className="flex flex-col w-full gap-y-6">
      {isModalOpen && productToDelete.id && (
        <Modal
          isForceClose={!isModalOpen}
          overrideModalStyle="justify-evenly md:max-w-2xl w-full"
          overrideModalContainerStyle="items-end sm:items-center"
          onClose={() => setIsModalOpen(false)}
        >
          <div className="flex flex-col mt-6 w-full">
            <div className="flex justify-evenly pb-6 pt-6">
              <div className="flex font-poppins not-italic font-light text-xl text-primary-color-100">
                Are you sure you want to delete this product?
              </div>
            </div>
            <div className="flex flex-col mt-10">
              <div
                className={`flex w-full px-6 ${
                  isProductDeleteEnabled ? "mb-10" : ""
                }`}
              >
                <TextInput
                  isRequired
                  inputId=""
                  label="Enter DELETE to remove this product from the shop listing"
                  textInputType="text"
                  value={deleteConfirmationText}
                  containerStyleClass="w-full"
                  typeOfState={isProductDeleteEnabled ? "normal" : "error"}
                  size="sm"
                  placeholder=""
                  infoMessage={
                    isProductDeleteEnabled
                      ? ""
                      : "This process cannot be undone"
                  }
                  overrideInfoMessageStyleClass=""
                  onChange={setDeleteConfirmationText}
                />
              </div>
              <div className="flex justify-evenly w-full mb-3">
                <Button
                  containerClassName="flex w-max border border-solid border-white rounded-md"
                  text="Cancel"
                  size="sm"
                  type="outline"
                  disabled={isDeleteProductLoading}
                  onClick={() => {
                    if (!isDeleteProductLoading) {
                      setIsModalOpen(false);
                    }
                  }}
                />
                <Button
                  containerClassName="flex w-max border border-solid border-white rounded-md"
                  text="Delete"
                  size="sm"
                  type="primary"
                  disabled={isDeleteProductLoading || !isProductDeleteEnabled}
                  onClick={() => {
                    if (!isDeleteProductLoading && isProductDeleteEnabled) {
                      deleteProduct({ productId: productToDelete.id });
                    }
                  }}
                />
              </div>
              <div className="flex flex-end w-full justify-center">
                {!!error && (
                  <div className="flex justify-start font-opensans font-normal text-sm text-neutral-color-4">
                    {error}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div
        className="flex flex-wrap m-auto gap-x-6 overflow-hidden items-center justify-center"
        style={{ width: "inherit", justifyContent: "flex-start" }}
      >
        <Pagination
          isContentLoading={isLoading}
          renderContent={content}
          pageNumber={pageNumber - 1}
          totalPages={Math.ceil(totalCount / pageSize)}
          handlePageClick={(selectedItem) => {
            onPageChange(selectedItem?.selected + 1);
          }}
        />
      </div>
    </div>
  );
};
