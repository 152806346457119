/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from "react";

// ts
import { AddressPayload } from "../../../../../../types";

// icons
import edit from "../../../../../../assets/edit.svg";
import remove from "../../../../../../assets/delete.svg";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import { emailOutlineIcon, phoneOutlineIcon } from "../../../../../../assets";

interface Props {
  existingAddress: AddressPayload;
  onEdit?: () => void;
  onRemove?: () => void;
  showActionIcons?: boolean;
  isFullNameBold?: boolean;
  showEmailAndPhone?: boolean;
}

export const ExistingAddress = ({
  existingAddress,
  onEdit = () => {},
  onRemove = () => {},
  showActionIcons = true,
  isFullNameBold = true,
  showEmailAndPhone = true,
}: Props): JSX.Element => {
  const {
    full_name = "",
    address_ln1 = "",
    address_ln2 = "",
    city = "",
    state = "",
    zip_code = "",
    isDefaultShippingAddress = false,
    country = "",
    email = "",
    phone = "",
  } = existingAddress;
  const baseTextStyle =
    "flex font-opensans not-italic text-lg leading-7 text-primary-color-100";

  const [showDeleteAddressModal, setShowDeleteAddressModal] = useState(false);
  return (
    <div className="flex flex-col">
      {showDeleteAddressModal && (
        <Modal
          isForceClose={!showDeleteAddressModal}
          overrideModalStyle="justify-evenly md:max-w-2xl w-100"
          overrideModalContainerStyle="items-end sm:items-center"
          onClose={() => setShowDeleteAddressModal(false)}
        >
          <div className="flex flex-col justify-center w-full h-48">
            <p className="px-4 text-xl text-center font-poppins">
              Are you sure you want to delete this address?
            </p>
            <div className="flex pt-6 justify-evenly">
              <Button
                text="Cancel"
                size="sm"
                type="outline"
                onClick={() => setShowDeleteAddressModal(false)}
              />
              <Button
                text="Delete"
                size="sm"
                type="primary"
                onClick={onRemove}
              />
            </div>
          </div>
        </Modal>
      )}
      <div
        className={`${baseTextStyle} ${isFullNameBold ? "font-bold" : ""} mb-2`}
      >
        {full_name}
      </div>
      {showEmailAndPhone && (
        <div className="flex flex-col gap-2">
          <div className={`${baseTextStyle} font-normal`}>
            <img
              width={25}
              height={5}
              src={emailOutlineIcon}
              alt="email-outline"
            />
            <p className="ml-2">{email}</p>
          </div>
          <div className={`${baseTextStyle} font-normal items-center`}>
            <img
              width={25}
              height={10}
              src={phoneOutlineIcon}
              alt="phone-outline"
            />
            <p className="ml-2">{phone}</p>
          </div>
        </div>
      )}

      <div className="flex flex-col mt-2 gap">
        <div className="flex not-italic font-normal leading-7 font-opensans text-primary-color-100 text-md">
          {`${address_ln1}, ${address_ln2}`}
        </div>
        <div className="flex not-italic leading-7 font-opensans text-primary-color-100 text-md">
          {`${city}, ${state} ${zip_code}`}
        </div>
        <div className="flex not-italic leading-7 font-opensans text-primary-color-100 text-md">
          {country}
        </div>
      </div>
      {showActionIcons && (
        <div className="flex w-full gap-40 border-b border-opacity-25 border-primary-color-100">
          <div className="flex">
            <div className="flex gap-12">
              <div
                className="flex items-center gap-4"
                tabIndex={0}
                role="button"
                onKeyDown={() => {}}
                onClick={onEdit}
              >
                <img src={edit} alt="Edit" className="cursor-pointer" />
                <div className={`${baseTextStyle} font-bold`}>Edit</div>
              </div>
              {!isDefaultShippingAddress && (
                <div
                  className="flex items-center gap-3"
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => {}}
                  onClick={() => setShowDeleteAddressModal(true)}
                >
                  <img src={remove} alt="Remove" className="cursor-pointer" />
                  <div className={`${baseTextStyle} font-bold`}>Remove</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex">
            {isDefaultShippingAddress && (
              <div className="flex font-normal leading-9 font-poppins text-md text-primary-color-100">
                Default
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ExistingAddress.defaultProps = {
  onEdit: () => {},
  onRemove: () => {},
  showActionIcons: true,
  isFullNameBold: true,
  showEmailAndPhone: true,
};

export default ExistingAddress;
