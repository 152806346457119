// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";
import { setItem } from "../../utils";

const LogoutPage = (): null => {
  useEffect(() => {
    setItem("token", "");
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/logout`;
  }, []);

  return null;
};

export default LogoutPage;
