/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";

const SvgBag = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  const { fill = "#3F415B" } = props;
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="5"
        y="0"
        width="10"
        height="9"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.21094 0.5H14.2729V8.8057H5.21094V0.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5229 8.80576C13.1089 8.80576 12.7729 8.46976 12.7729 8.05576V5.02376C12.7729 3.35676 11.4179 1.99976 9.75094 1.99976C8.93994 2.02776 8.17994 2.30776 7.60594 2.87576C7.03194 3.44476 6.71394 4.20176 6.71094 5.00876V8.05576C6.71094 8.46976 6.37494 8.80576 5.96094 8.80576C5.54694 8.80576 5.21094 8.46976 5.21094 8.05576V5.02376C5.21594 3.79476 5.69094 2.66076 6.54994 1.81076C7.40794 0.959756 8.55994 0.534756 9.75394 0.499756C12.2449 0.499756 14.2729 2.52976 14.2729 5.02376V8.05576C14.2729 8.46976 13.9369 8.80576 13.5229 8.80576Z"
          fill={fill}
        />
      </g>
      <mask
        id="mask1"
        maskUnits="userSpaceOnUse"
        x="0"
        y="4"
        width="20"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.97461H19.4998V20.4996H0V4.97461Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.008 6.47461C3.074 6.47461 1.5 8.04661 1.5 9.97961V15.4956C1.5 17.4276 3.074 18.9996 5.008 18.9996H14.492C16.426 18.9996 18 17.4276 18 15.4956V9.97961C18 8.04661 16.426 6.47461 14.492 6.47461H5.008ZM14.492 20.4996H5.008C2.247 20.4996 0 18.2546 0 15.4956V9.97961C0 7.21961 2.247 4.97461 5.008 4.97461H14.492C17.254 4.97461 19.5 7.21961 19.5 9.97961V15.4956C19.5 18.2546 17.254 20.4996 14.492 20.4996Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default SvgBag;
