/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React, { useMemo, useCallback } from "react";

// packages
import { ClipLoader } from "react-spinners";
import { useTable } from "react-table";
import moment from "moment";

// components
import styles from "./Payments.module.css";

interface Payment {
  amount: number;
  currency: string;
  source_types: { [key: string]: number };
}
interface PaymentsObj {
  balance: {
    object: string;
    available: Payment[];
    livemode: boolean;
    pending: Payment[];
  } | null;
  transactions: {
    object: string;
    data: {
      id: string;
      object: string;
      amount: number;
      available_on: number;
      created: number;
      currency: string;
      description: string;
      exchange_rate: string;
      fee: number;
      fee_details: string;
      net: number;
      reporting_category: string;
      source: string;
      status: string;
      type: string;
    }[];
    has_more: boolean;
    url: string;
  } | null;
}

export const Payments = ({
  payments,
  isLoading,
}: {
  payments: PaymentsObj;
  isLoading: boolean;
}): JSX.Element => {
  const getTotal = useCallback(
    (key: "available" | "pending") => {
      return payments?.balance?.[key]?.reduce((sum, transaction) => {
        // eslint-disable-next-line no-param-reassign
        sum += (((transaction?.amount || 0) / 100) * 100) / 100;
        return sum;
      }, 0);
    },
    [payments?.balance]
  );

  const transactions = payments?.transactions?.data;

  const availableBalance = useMemo(() => getTotal("available"), [getTotal]);
  const pendingBalance = useMemo(() => getTotal("pending"), [getTotal]);

  const valueFormatters = (key: string, value?: number | string) => {
    switch (key) {
      case "fee":
      case "net":
      case "amount":
        return `$${(((Number(value) || 0) / 100) * 100) / 100}`;
      case "available_on":
      case "created":
        return value
          ? moment.unix(Number(value)).format("MM-DD-YYYY HH:mm:ss")
          : "-";
      default:
        return value || "-";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Transactions",
        columns: [
          {
            Header: "Created on",
            accessor: "created",
          },
          {
            Header: "Available on",
            accessor: "available_on",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Category",
            accessor: "reporting_category",
          },
          {
            Header: "Fee",
            accessor: "fee",
          },
          {
            Header: "Net",
            accessor: "net",
          },
          {
            Header: "Amount",
            accessor: "amount",
          },
        ],
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: transactions || [],
    });

  if (isLoading) {
    return <ClipLoader color="#E07A5F" size={30} loading />;
  }

  const containerStyles =
    "flex items-center relative w-full md:p-3 border-solid border-1 rounded-md shadow-md justify-center gap-3 cursor-pointer";

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex w-full gap-6" style={{ height: 100, maxWidth: 400 }}>
        <div className={containerStyles}>{`Balance available: $${
          // eslint-disable-next-line prettier/prettier
          availableBalance || 0.00
        }`}</div>
        <div className={containerStyles}>{`Balance pending: $${
          // eslint-disable-next-line prettier/prettier
          pendingBalance || 0.00
        }`}</div>
      </div>
      <div className="flex">
        <div className={styles.table}>
          <table
            {...getTableProps()}
            style={{
              overflow: "scroll",
              width: "100%",
            }}
          >
            {rows?.length > 0 && (
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const { key } = column.getHeaderProps() || {};
                      return (
                        <th
                          {...column.getHeaderProps()}
                          style={{
                            background:
                              key !== "header_Transactions_0"
                                ? "#d3d3d3"
                                : "inherit",
                          }}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {(rows?.length > 0 &&
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {valueFormatters(cell.column.id, cell.value)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })) || (
                <div>
                  <td className="center-align">No transactions</td>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
