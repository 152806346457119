import { amexCard, discoverCard, masterCard, visaCard } from "../assets";

// eslint-disable-next-line import/prefer-default-export
export const Months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const brandImageMap = {
  visa: visaCard,
  mastercard: masterCard,
  amex: amexCard,
  discover: discoverCard,
};

export const orderStatusMap = {
  pending: "Pending",
  confirmed: "Confirmed",
  ready_to_ship: "Ready to Ship",
  shipped: "Shipped",
  canceled: "Canceled",
};

export const orderColorMap = {
  pending: "contrast-color-1-100",
  confirmed: "green-300",
  ready_to_ship: "neutral-color-5",
  shipped: "neutral-color-5",
  canceled: "red-400",
};
