import React, { FC, useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";

// components
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import Tab from "../../../../components/Tab";
import Addresses from "./Addresses";

// constants
import tabs from "./constants";
import GeneralAccountSettings from "./GeneralAccountSettings";

// store
import { Context } from "../../../../store";

import { fetchUserProfile } from "../../../../store/actions";
import { GET_USER } from "../../../../store/actions.types";
import CreditCards from "./CreditCards";
import { getItem } from "../../../../utils";

export const AccountSettings: FC = () => {
  const [currentTabValue, setCurrentTabValue] = useState(tabs[0].value);

  const {
    treatments: { customer_account_settings: customerAccountSettings },
  } = useSplitTreatments({
    names: ["customer_account_settings"],
    attributes: { userId: getItem("split_override_user_id") },
  });

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { created_at, email, email_verified },
      },
    },
  } = useContext(Context);

  const { refetch: getUserDetails } = useQuery(GET_USER, fetchUserProfile, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTabs = () => {
    switch (currentTabValue) {
      case "addresses":
        return customerAccountSettings.treatment === "on" && email_verified ? (
          <Addresses />
        ) : null;
      case "account":
        return <GeneralAccountSettings createdAt={created_at} email={email} />;
      // case "preferences":
      //   return <AccountPreferences />;
      case "payments":
        return email_verified && <CreditCards />;
      default:
        return (
          <div className="flex-col">
            <div className="flex mb-2 text-2xl font-light leading-9 font-poppins">
              General Account Settings
            </div>
            <div className="text-lg leading-7 font-opensans">Sell with us.</div>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col overflow-x-hidden md:flex-row">
      <div className="flex flex-col md:flex-grow">
        <div className="flex flex-row mb-5 overflow-x-auto border-b border-opacity-25 md:flex-col border-primary-color-100">
          {tabs.map((tab) => {
            const uniqKey = `${tab.display}-${tab.value}`;
            if (
              (tab.value === "addresses" &&
                customerAccountSettings.treatment === "off") ||
              (tab.value === "payments" &&
                customerAccountSettings.treatment === "off")
            ) {
              return null;
            }
            return (
              // TODO: border width should be dynamic?
              <div
                className="w-40 border-b border-opacity-25 border-primary-color-100"
                key={`${uniqKey}-container`}
              >
                <Tab
                  key={uniqKey}
                  option={tab}
                  isSelected={currentTabValue === tab.value}
                  onClick={setCurrentTabValue}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col items-start mt-2 md:ml-24">
        <div className="flex">{getTabs()}</div>
      </div>
    </div>
  );
};
export default AccountSettings;
