import React, { useState, useEffect, useContext, FC } from "react";
import { useLocation, useHistory } from "react-router-dom";

// packages
import { useMutation } from "react-query";
import { v4 as uuid } from "uuid";

// components
import { toast } from "react-toastify";
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import ResendEmailBanner from "../../containers/ResendEmailBanner";
import Tab from "../../components/Tab";
import BaseProfile from "./Tabs/BaseProfile";
// import Messages from "./Tabs/Messages";
import OrdersReviews from "./Tabs/OrdersReviews";
import SellOnLonima from "../SellOnLonima";
import AccountSettings from "./Tabs/AccountSettings";
// constants
import tabs, { defaultProfileConfig } from "./constants";

// store
import { Context } from "../../store";

// ts
import { UserDetails } from "./types";

// actions
import uploadProfileAvatar from "./actions";

import "react-toastify/dist/ReactToastify.css";
import { getItem } from "../../utils";
import { ErrorFallBack } from "../../components/ErrorFallBack";
import { UserProfile } from "../../store/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KV = { [key: string]: any };

const Profile: FC = () => {
  const [currentTabValue, setCurrentTabValue] = useState(tabs[0].value);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [uploadAvatarLoading, setUploadAvatarLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({} as KV);

  const {
    state: {
      user: { profile = {} as KV },
    },
  } = useContext(Context);

  const loc = useLocation();
  const history = useHistory();

  // to directly land on a specific tab
  useEffect(() => {
    const routeTokens = loc.pathname.trim().split("/");
    const path = Array.isArray(routeTokens)
      ? routeTokens[routeTokens.length - 1]
      : "";
    const tabValues = tabs.map(({ value }) => value);
    if (tabValues.includes(path)) {
      setCurrentTabValue(path);
    }
  }, [loc]);

  // mapping only the profile info needed
  useEffect(() => {
    const profileKeys = Object.keys(profile);
    setUserDetails(
      Object.keys(defaultProfileConfig).reduce((acc, defaultProfileKey) => {
        if (profileKeys.includes(defaultProfileKey)) {
          acc[defaultProfileKey] =
            profile[defaultProfileKey as keyof UserProfile];
        }
        return acc;
      }, {} as KV)
    );
  }, [profile]);

  const onSelect = (tabValue: string) => {
    const routeToPush = tabValue ? `/account/${tabValue}` : "/account";
    history.push(routeToPush);
  };

  const { mutate } = useMutation(uploadProfileAvatar, {
    onSuccess: (data) => {
      // to re-trigger image update in avatar
      setAvatarUrl(`${data?.data?.value}?id=${uuid()}`);
      setUploadAvatarLoading(false);
      toast.success("Successfully uploaded the avatar", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    onError: () => {
      setUploadAvatarLoading(false);
      toast.error("Failed to upload avatar, please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  useEffect(() => {
    if (userDetails?.avatar) {
      setAvatarUrl(userDetails.avatar);
    }
  }, [userDetails]);

  const {
    treatments: { customer_orders_reviews: customerOrdersReviews },
    isReady,
  } = useSplitTreatments({
    names: ["customer_account_settings", "customer_orders_reviews"],
    attributes: { userId: getItem("split_override_user_id") as string },
  });
  if (!isReady) return <div>Loading...</div>;

  const getTabs = () => {
    switch (currentTabValue) {
      // case "messages":
      //   return <Messages />;
      case "orders-reviews":
        return customerOrdersReviews.treatment === "on" &&
          profile.email_verified ? (
          <OrdersReviews />
        ) : (
          <ErrorFallBack
            resetErrorBoundary={() => {}}
            backgroundColor="white"
            show404Text
            showLogo={false}
          />
        );
      case "sell-on-lonima":
        return <SellOnLonima />;
      case "account-settings":
        return <AccountSettings />;
      default:
        return (
          <BaseProfile
            userDetails={userDetails as UserDetails}
            avatarUrl={avatarUrl}
            uploadAvatarLoading={uploadAvatarLoading}
            onProfilePictureUpload={(file) => {
              setUploadAvatarLoading(true);
              mutate(file);
            }}
          />
        );
    }
  };

  return (
    <div className="flex flex-col p-4">
      <ResendEmailBanner />
      <div className="flex justify-between">
        <div className="flex flex-col w-full overflow-x-hidden md:flex-grow">
          <div className="flex border-b overflow-x-auto border-primary-color-100 border-opacity-25 mb-5">
            {tabs.map((tab) => {
              if (
                tab.value === "orders-reviews" &&
                (customerOrdersReviews.treatment === "off" ||
                  !profile.email_verified)
              ) {
                return null;
              }
              return (
                <Tab
                  key={tab.value}
                  option={tab}
                  isSelected={currentTabValue === tab.value}
                  onClick={onSelect}
                />
              );
            })}
          </div>
          <div className="flex">{getTabs()}</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
