/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useQuery } from "react-query";

// packages
import { v4 as uuid } from "uuid";
import Carousel from "react-multi-carousel";
import simplur from "simplur";
import "react-multi-carousel/lib/styles.css";
import { useSplitTreatments } from "@splitsoftware/splitio-react";

// components
import { capitalize } from "lodash";
import StarIcon from "../StarIcon";
import Button from "../Button";
import Tab from "../Tab";
import AddToCartModal from "./AddToCartModal";
import Reviews from "./Reviews";

// icons
import {
  arrowDownIcon,
  arrowUpIcon,
  heartPngFilledIcon,
  heartPngIcon,
} from "../../assets";

// ts
import { ExtraDetail } from "./Product.types";

// constants
import {
  TOTAL_RATING_STARS,
  extraDetailsStyle,
  responsive,
  IMAGE_SIZE,
} from "./Product.constants";
import { MAIN_IMAGE_KEY } from "../../screens/AddOrEditProduct/AddOrEditProduct.constants";

// hooks
import { useAuth, useQueryParams } from "../../hooks";

// actions
import { fetchShopDetails } from "../../store/actions";

// utils
import {
  nonVariantTypeKeys,
  setPlaceHolderImageOnError,
} from "./Product.utils";
import { getItem, deleteItem } from "../../utils";
import Dropdown from "../Dropdown";

interface VariantObj {
  _id: string;
  original_price: string;
  price: string;
  quantity?: string;
}

export interface Props {
  main_product_image_url?: string;
  other_product_image_urls?: string[];
  title: string;
  price: number;
  rating?: number;
  isHotDeal?: boolean;
  isFreeShipping?: boolean;
  reviewsCount: number;
  itemDetails?: string;
  shippingAndReturns?: string;
  handleAddToCart?: ({ variantId }: { variantId?: string }) => void;
  handleAddToFavorites?: (isFavorite: boolean) => void;
  addToCartLoading?: boolean;
  faqs?: string;
  isFavoriteProduct?: boolean;
  readOnly?: boolean;
  variantSelections?: Array<{
    values: Array<string>;
    _id: string;
    property: string;
  }>;
  variants?: Array<{
    _id: string;
    variantType: string;
    price: number;
    original_price: number;
    quantity: number;
  }>;
  maxPrice?: number;
  minPrice?: number;
  shopId?: string;
  dimensions?: {
    length: number;
    width: number;
    height: number;
    units: string;
  };
  weight?: {
    units: string;
    value: number;
  };
  showAddToCartModal?: boolean;
}

const Product = ({
  title,
  maxPrice,
  minPrice,
  main_product_image_url,
  other_product_image_urls,
  readOnly = false,
  variantSelections,
  variants,
  rating = 0,
  reviewsCount,
  itemDetails = "Items details description goes here",
  shippingAndReturns = "No Return policy mentioned for this product",
  handleAddToCart = () => {},
  handleAddToFavorites = () => {},
  addToCartLoading = false,
  isFavoriteProduct = false,
  shopId,
  dimensions,
  weight,
  showAddToCartModal,
}: Props): JSX.Element => {
  const productReview = useRef<HTMLDivElement | null>(null);
  const queryParams = useQueryParams();
  const productId = queryParams.get("id");
  const productShopId = queryParams.get("shop_id") || shopId || "";
  const previewId = queryParams.get("preview_id") || "";
  const isPreviewMode = queryParams.get("preview")?.trim() === "true";
  const previewItemStorageId = `preview-${productShopId}-${previewId}`;
  useEffect(() => {
    return () => {
      deleteItem(previewItemStorageId);
    };
  }, [previewItemStorageId]);

  const { data: shopDetails } = useQuery(
    [{ id: productShopId }],
    fetchShopDetails,
    {
      enabled: !!productShopId,
    }
  );

  const {
    description,
    name: shopName,
    rating: shopRating,
    total_review_count: totalShopReviewCount,
  } = shopDetails?.data?.value || {};

  const productExtraDetailsMap = {
    itemDetails: { title: "Item Details", value: itemDetails, isOpen: true },
    shippingAndReturns: {
      title: "Returns",
      value: shippingAndReturns,
      isOpen: true,
    },
    // faqs: { title: `FAQ's`, value: faqs, isOpen: false },
  };

  const tabs = useMemo(() => {
    return [
      { display: "Reviews for this item", value: "reviews" },
      // { display: "About this item", value: "about" },
    ];
  }, []);

  const [currentImg, setCurrentImg] = useState("");
  const [uniqID, setUniqID] = useState(uuid());
  const [productName, setProductName] = useState(title);
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
  const [extraDetails, setExtraDetails] = useState<{
    [key: string]: ExtraDetail;
  }>(productExtraDetailsMap);
  const [currentTabValue, setCurrentTabValue] = useState(tabs[0].value);
  const [isFavProduct, setIsFavProduct] = useState(isFavoriteProduct);
  const { isAuthenticated } = useAuth();
  const [showFavorite, setShowFavorite] = useState(false);
  const toggleShowFavoriteIcon = () => {
    if (!isPreviewMode) {
      setShowFavorite(!showFavorite);
    }
  };
  const [variantValueSelection, setVariantValueSelection] = useState<{
    [variantType: string]: string;
  }>({});

  const { productPriceRange, previewImagesUrls } = useMemo(() => {
    if (isPreviewMode) {
      const previewItemRaw = getItem(previewItemStorageId) as string;
      const previewItemDetails = JSON.parse(previewItemRaw || "{}");

      const mainImage = previewItemDetails?.previewImages?.find(
        (img: { imageKey: string; url: string }) =>
          img.imageKey === MAIN_IMAGE_KEY
      ) as { imageKey: string; url: string };
      if (mainImage) {
        setCurrentImg(mainImage.url);
      }
      if (previewItemDetails?.name?.value) {
        setProductName(previewItemDetails?.name?.value);
      }
      let priceRange = { min: 0, max: 0 };
      if (Object.keys(previewItemDetails?.variants?.value || {}).length > 0) {
        const values = (
          Object.values(previewItemDetails?.variants?.value || {}) as {
            price: string;
            original_price: string;
          }[]
        ).reduce((prices, variant) => {
          const priceCompValue = +variant?.price || +variant?.original_price;
          prices.push(priceCompValue);
          return prices;
        }, [] as number[]);
        values.sort((a, b) => a - b);
        priceRange = { min: values[0], max: values[values.length - 1] };
      }
      return {
        productPriceRange: priceRange,
        previewImagesUrls:
          previewItemDetails?.previewImages?.map(
            (previewImage: { imageKey: string; url: string }) =>
              previewImage.url
          ) || [],
      };
    }
    return {
      productPriceRange: { min: minPrice, max: maxPrice },
      previewImagesUrls: [
        main_product_image_url,
        ...(other_product_image_urls || []),
      ],
    };
  }, [
    isPreviewMode,
    main_product_image_url,
    maxPrice,
    minPrice,
    other_product_image_urls,
    previewItemStorageId,
  ]);

  const { previewDimensions, previewWeight } = useMemo(() => {
    let previewDimensionsLocal = { height: 0, length: 0, width: 0, units: "" };
    let previewWeightLocal = { value: 0, units: "" };
    if (isPreviewMode) {
      const previewItemRaw = getItem(previewItemStorageId) as string;
      const previewItemDetails = JSON.parse(previewItemRaw || "{}");
      previewDimensionsLocal = {
        length: previewItemDetails?.dimensions_length?.value,
        width: previewItemDetails?.dimensions_width?.value,
        height: previewItemDetails?.dimensions_height?.value,
        units: previewItemDetails?.dimensions_units?.value,
      };
      previewWeightLocal = {
        value: previewItemDetails?.weight_value?.value,
        units: previewItemDetails?.weight_units?.value,
      };
    }
    return {
      previewDimensions: previewDimensionsLocal,
      previewWeight: previewWeightLocal,
    };
  }, [isPreviewMode, previewItemStorageId]);

  useEffect(() => {
    if (showAddToCartModal) {
      setShowAddedToCartModal(true);
      setUniqID(uuid());
    }
  }, [showAddToCartModal]);

  useEffect(() => {
    if (isPreviewMode) {
      const previewItemRaw = getItem(previewItemStorageId) as string;
      setExtraDetails({
        itemDetails: {
          title: "Item Details",
          value:
            JSON.parse(previewItemRaw || "{}")?.description?.value ||
            itemDetails,
          isOpen: true,
        },
        shippingAndReturns: {
          title: "Returns",
          value: shippingAndReturns,
          isOpen: true,
        },
        // faqs: { title: `FAQ's`, value: faqs, isOpen: false },
      });
    }
    if (itemDetails) {
      setExtraDetails((prev) => {
        const temp = { ...prev };
        temp.itemDetails.value = itemDetails;
        return temp;
      });
    }
  }, [previewItemStorageId, isPreviewMode, itemDetails, shippingAndReturns]);

  useEffect(() => {
    if (main_product_image_url) {
      setCurrentImg(main_product_image_url);
    }
  }, [main_product_image_url]);

  useEffect(() => {
    if (title) {
      setProductName(title);
    }
  }, [title]);

  const updateCurrentImg = (img: string) => {
    if (img) {
      setCurrentImg(img);
    }
  };

  useEffect(() => {
    setIsFavProduct(isFavoriteProduct);
  }, [isFavoriteProduct]);

  const previewVariants = useMemo(() => {
    const previewItemRaw = getItem(previewItemStorageId) as string;
    return isPreviewMode
      ? JSON.parse(previewItemRaw || "{}")?.variants?.value || {}
      : {};
  }, [isPreviewMode, previewItemStorageId]);

  const typeOfVariants = !isPreviewMode
    ? variantSelections?.map((variantType) => variantType.property)
    : Object.keys(previewVariants)?.reduce(
        (allVariants: string[], variantKey) => {
          const variantValue = previewVariants[variantKey] || {};
          const variantProperties = Object.keys(variantValue).filter(
            (key) => !nonVariantTypeKeys.includes(key)
          );
          variantProperties.forEach((variantProperty) => {
            if (!allVariants.includes(variantProperty)) {
              allVariants.push(variantProperty);
            }
          });
          return allVariants;
        },
        []
      );

  // might have to move this to utils so other places can use this logic
  const { originalPrice, discountPrice, variantId, quantity } = useMemo(() => {
    if (!typeOfVariants || typeOfVariants.length === 0) {
      return {
        originalPrice: variants?.[0]?.original_price || "",
        discountPrice: variants?.[0]?.price || "",
        quantity: variants?.[0]?.quantity || 0,
        variantId: variants?.[0]?._id || "",
      };
    }
    if (Object.keys(variantValueSelection).length === 0) {
      return {
        originalPrice:
          productPriceRange.max === productPriceRange.min
            ? productPriceRange.min
            : `${productPriceRange.min} - $${productPriceRange.max}`,
        discountPrice: null,
        variantId: variants?.[0]._id,
      };
    }
    const obj = isPreviewMode
      ? Object.keys(previewVariants)?.reduce((matchedVariants, variantKey) => {
          const variant = previewVariants[variantKey];
          if (
            typeOfVariants?.every(
              (variantType) =>
                variant?.[variantType] === variantValueSelection?.[variantType]
            )
          ) {
            matchedVariants.push({ ...variant, _id: uuid() });
          }
          return matchedVariants;
        }, [] as VariantObj[])
      : variants?.filter((variant) =>
          typeOfVariants?.every(
            (variantType) =>
              variant?.[variantType as "variantType"] ===
              variantValueSelection?.[variantType]
          )
        );

    if (obj && obj.length > 0) {
      return {
        originalPrice: obj[0].original_price,
        discountPrice: obj[0].price,
        variantId: obj[0]._id,
        quantity: obj[0].quantity,
      };
    }
    return {
      originalPrice: "",
      discountPrice: "",
    };
  }, [
    typeOfVariants,
    variantValueSelection,
    variants,
    productPriceRange.max,
    productPriceRange.min,
    isPreviewMode,
    previewVariants,
  ]);

  const variantTypesWithValues = useMemo(() => {
    if (typeOfVariants?.length === 0) {
      return {};
    }
    const variantSelMap: { [key: string]: string[] } = (
      typeOfVariants as string[]
    ).reduce((acc, curr) => {
      return { ...acc, [curr]: [] };
    }, {});
    if (!isPreviewMode) {
      typeOfVariants?.forEach((variantType) => {
        variants?.forEach((variant) => {
          if (
            !variantSelMap[variantType]?.includes(
              variant?.[variantType as "variantType"]
            )
          ) {
            variantSelMap[variantType].push(
              variant[variantType as "variantType"]
            );
          }
        });
      });
    } else {
      Object.keys(previewVariants).forEach((previewVariant) => {
        const previewVariantValue = previewVariants[previewVariant];
        Object.keys(previewVariantValue).forEach((property) => {
          const propertyValue = previewVariantValue[property];
          if (!variantSelMap[property as keyof typeof variantSelMap]) {
            variantSelMap[property as keyof typeof variantSelMap] = [];
          }
          if (
            !variantSelMap[property as keyof typeof variantSelMap].includes(
              propertyValue
            )
          ) {
            variantSelMap[property as keyof typeof variantSelMap].push(
              propertyValue
            );
          }
        });
      });
    }
    return variantSelMap;
  }, [isPreviewMode, previewVariants, typeOfVariants, variants]);

  const getTabs = () => {
    const containerStyle = "flex flex-col";
    const headingStyle =
      "flex font-opensans font-bold text-sm text-primary-color-100";
    const contentStyle =
      "flex font-opensans font-normal text-sm text-primary-color-100";
    switch (currentTabValue) {
      case "reviews":
        return (
          <div className={containerStyle}>
            <div className={contentStyle}>
              <Reviews productId={productId!} />
            </div>
          </div>
        );
      case "about":
        return (
          <div className={containerStyle}>
            <div className={headingStyle}>About</div>
            <div className="flex flex-col text-sm font-normal font-opensans text-primary-color-100">
              <p>{description || ""}</p>
              <div className="flex gap-1">
                {!!totalShopReviewCount && (
                  <div className="flex text-sm font-light cursor-pointer font-opensans text-primary-color-100 hover:underline">
                    {`${simplur`${totalShopReviewCount} review[|s]`}`}
                  </div>
                )}
                {!!shopRating && (
                  <div className="flex items-center justify-center">
                    {Array.from({ length: TOTAL_RATING_STARS }, (v, i) => (
                      <StarIcon
                        starId={i}
                        key={`star_${i}`}
                        marked={shopRating > i}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  const onSelect = (value: string) => {
    setCurrentTabValue(value);
  };

  const setAccordion = (key: string, isOpenValue: boolean) => {
    setExtraDetails((prev) => {
      const temp = { ...prev };
      temp[key].isOpen = isOpenValue;
      return temp;
    });
  };

  const getImages = () => {
    const images = !isPreviewMode
      ? [main_product_image_url, ...(other_product_image_urls || [])]
      : previewImagesUrls;
    return images.map((imgUrl: string) => {
      const isCurrentImage = imgUrl === currentImg;
      return (
        <div
          className={`flex flex-1 w-max ${
            isCurrentImage
              ? "border border-solid border-contrast-color-1-100"
              : ""
          }`}
          style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }}
          onClick={() => updateCurrentImg(imgUrl)}
          key={imgUrl}
        >
          <img
            src={imgUrl}
            alt=""
            className="object-cover cursor-pointer"
            style={{
              opacity: `${isCurrentImage ? 1 : 0.5}`,
            }}
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
            onError={setPlaceHolderImageOnError}
          />
        </div>
      );
    });
  };

  const isUseCarousel = useMemo(
    () =>
      [main_product_image_url, ...(other_product_image_urls || [])]?.length >
        4 || previewImagesUrls?.length > 0,
    [
      main_product_image_url,
      other_product_image_urls,
      previewImagesUrls?.length,
    ]
  );

  const addToCart = () => {
    if (!isPreviewMode) {
      handleAddToCart({ variantId });
    }
  };

  const handleScrollProductReviewToView = () => {
    if (productReview?.current) {
      window.scrollTo({
        top: productReview.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const favoriteIcon = isFavProduct ? heartPngFilledIcon : heartPngIcon;
  const favoriteDivClassName = isFavProduct
    ? "bg-white"
    : "bg-neutral-color-1 hover:bg-white";
  const shopNameStyle =
    "flex font-opensans font-normal text-2xl text-primary-color-100 underline";
  const originalPriceStyle =
    discountPrice && discountPrice !== originalPrice
      ? "font-poppins font-extralight text-3xl text-contrast-color-1-100 line-through"
      : "font-poppins font-semibold text-3xl text-primary-color-100";

  const variantOptions = Object.keys(variantTypesWithValues).reduce(
    (acc, curr) => {
      acc[curr] = variantTypesWithValues[curr].map((value) => ({
        label: value,
        value,
      }));
      return acc;
    },
    {} as { [key: string]: { label: string; value: string }[] }
  );

  const {
    treatments: { view_cart: viewCart },
  } = useSplitTreatments({
    names: ["view_cart"],
    attributes: { userId: getItem("split_override_user_id") as string },
  });

  return (
    <div>
      <div>
        {showAddedToCartModal && (
          <AddToCartModal
            key={uniqID}
            imageUrl={currentImg}
            title={productName}
            price={originalPrice as number}
            readOnly={readOnly}
            discountedPrice={discountPrice as number}
            onClose={() => {
              setShowAddedToCartModal(false);
            }}
          />
        )}
        <div className="flex flex-col m-2 md:flex-row gap-11 md:m-6">
          <div className="flex flex-col w-full md:w-3/5">
            <div className="flex flex-col">
              <div
                onMouseEnter={toggleShowFavoriteIcon}
                onMouseLeave={toggleShowFavoriteIcon}
                className="relative flex w-full overflow-hidden h-96"
              >
                {isAuthenticated && (isFavProduct || showFavorite) && (
                  <div
                    className={`flex cursor-pointer items-center justify-center rounded-full absolute overflow-scroll p-2 w-9 h-9 ${favoriteDivClassName}`}
                    style={{ top: "0.8rem", right: "0.8rem" }}
                  >
                    <img
                      src={favoriteIcon}
                      width={20}
                      height={20}
                      alt="favorite-product"
                      style={{
                        position: "relative",
                      }}
                      onClick={() => {
                        handleAddToFavorites(!isFavProduct);
                        setIsFavProduct(!isFavProduct);
                      }}
                      onKeyPress={() => {
                        handleAddToFavorites(!isFavProduct);
                        setIsFavProduct(!isFavProduct);
                      }}
                    />
                  </div>
                )}
                <img
                  className="block object-cover w-auto h-auto max-w-full max-h-full m-auto"
                  src={currentImg}
                  alt="cover"
                  onError={setPlaceHolderImageOnError}
                />
              </div>
              <div
                style={{
                  display: `${isUseCarousel ? "" : "flex"}`,
                  justifyContent: `${isUseCarousel ? "" : "space-between"}`,
                  marginTop: 12,
                  width: "100%",
                }}
              >
                {isUseCarousel ? (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <Carousel responsive={responsive}>{getImages()}</Carousel>
                ) : (
                  <div className="flex justify-between gap-1">
                    {getImages()}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-2/5">
            <div className="flex text-left">
              <h1 className="text-3xl font-normal font-poppins text-primary-color-100">
                {productName
                  ? `${productName.substring(0, 150)}${
                      productName.length > 150 ? "..." : ""
                    }`
                  : ""}
              </h1>
            </div>
            <div className="flex gap-3 mt-4">
              {discountPrice &&
                typeof discountPrice === "number" &&
                discountPrice >= 0 &&
                discountPrice !== originalPrice && (
                  <h2 className="text-3xl font-semibold font-poppins text-primary-color-100">
                    {`$${discountPrice}`}
                  </h2>
                )}
              {originalPrice ? (
                <h4 className={originalPriceStyle}>{`$${originalPrice}`}</h4>
              ) : (
                <h4>Not Available</h4>
              )}
            </div>
            <div className="flex flex-wrap gap-4">
              {!isPreviewMode && (
                <div className="flex gap-1 mt-2">
                  <div className="flex items-center justify-center">
                    {rating > 0
                      ? Array.from({ length: TOTAL_RATING_STARS }, (v, i) => (
                          <StarIcon
                            starId={i}
                            key={`star_${i}`}
                            marked={rating > i}
                          />
                        ))
                      : "0 Reviews"}
                  </div>
                  {!!reviewsCount && (
                    <div
                      className="flex text-lg font-light cursor-pointer font-opensans text-primary-color-100 hover:underline"
                      onClick={handleScrollProductReviewToView}
                    >
                      {`(${simplur`${reviewsCount} review[|s]`})`}
                    </div>
                  )}
                </div>
              )}
            </div>
            {!!shopName &&
              (!isPreviewMode ? (
                <div className="flex items-center mt-4">
                  <p className={`${shopNameStyle} no-underline`}>
                    Shop: {"   "}
                  </p>
                  <a
                    href={`/shop/${productShopId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={shopNameStyle}
                  >
                    {`${shopName}`}
                  </a>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  Shop: <div className={shopNameStyle}>{`${shopName}`}</div>
                </div>
              ))}
            <div className="flex gap-4">
              {typeOfVariants?.map((variantType) => {
                return (
                  <div className="flex w-full mt-4 md:w-1/2">
                    <Dropdown
                      isRequired
                      placeholderInput={`Choose ${variantType}`}
                      title={capitalize(variantType)}
                      type="primary"
                      size="fullWidth"
                      options={
                        variantOptions[variantType] || [
                          { label: "", value: "" },
                        ]
                      }
                      onChange={(val) => {
                        if (val?.value) {
                          setVariantValueSelection(
                            (prevVariantValueSelection) => {
                              return {
                                ...prevVariantValueSelection,
                                [variantType]: val.value as string,
                              };
                            }
                          );
                        }
                      }}
                    />
                  </div>
                );
              })}
            </div>
            {quantity === 0 && (
              <p className="py-2 text-left text-neutral-color-4">
                Out of stock
              </p>
            )}
            {viewCart.treatment === "on" && (
              <div className="flex flex-col w-full gap-3 mt-8">
                <Button
                  containerClassName="flex rounded-md"
                  loading={addToCartLoading}
                  text="Add To Cart"
                  size="lg"
                  type="primary"
                  disabled={
                    isPreviewMode ||
                    typeof originalPrice !== "number" ||
                    quantity === 0
                  }
                  onClick={addToCart}
                />
              </div>
            )}
            <div className="flex flex-col items-start gap-3 mt-8">
              {Object.keys(extraDetails).map((extraDetailKey) => {
                const currentProductDetail =
                  extraDetails[
                    extraDetailKey as
                      | "shippingAndReturns"
                      | "itemDetails"
                      | "faqs"
                  ];
                return (
                  <div
                    className="flex flex-col w-full"
                    key={JSON.stringify(currentProductDetail || uuid())}
                  >
                    <div
                      className={extraDetailsStyle}
                      onClick={() =>
                        setAccordion(
                          extraDetailKey,
                          !currentProductDetail?.isOpen
                        )
                      }
                    >
                      <div>{currentProductDetail?.title || ""}</div>
                      <div>
                        <img
                          src={
                            currentProductDetail?.isOpen
                              ? arrowUpIcon
                              : arrowDownIcon
                          }
                          alt="arrow-up-down"
                        />
                      </div>
                    </div>
                    {currentProductDetail.value &&
                      currentProductDetail?.isOpen && (
                        <div className="flex flex-col w-full text-left">
                          {currentProductDetail.value}
                          {extraDetailKey === "itemDetails" && (
                            <div className="flex flex-col pt-4">
                              <div className="flex flex-col pb-4">
                                <span className="text-base font-semibold font-popins">
                                  Dimensions
                                </span>
                                <p>
                                  H:
                                  {!isPreviewMode
                                    ? dimensions?.height
                                    : previewDimensions?.height}{" "}
                                  (
                                  {!isPreviewMode
                                    ? dimensions?.units
                                    : previewDimensions?.units}
                                  ) W:
                                  {!isPreviewMode
                                    ? dimensions?.width
                                    : previewDimensions.width}
                                  (
                                  {!isPreviewMode
                                    ? dimensions?.units
                                    : previewDimensions?.units}
                                  ) L:
                                  {!isPreviewMode
                                    ? dimensions?.length
                                    : previewDimensions?.length}{" "}
                                  (
                                  {!isPreviewMode
                                    ? dimensions?.units
                                    : previewDimensions?.units}
                                  )
                                </p>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-base font-semibold font-opensans">
                                  Weight
                                </span>
                                <p>
                                  {!isPreviewMode
                                    ? weight?.value
                                    : previewWeight?.value}
                                  (
                                  {!isPreviewMode
                                    ? weight?.units
                                    : previewWeight?.units}
                                  )
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        ref={productReview}
        className="flex flex-col w-full p-1 overflow-x-hidden md:w-1/2 md:flex-grow"
      >
        <div className="flex mb-5 overflow-x-auto border-b border-opacity-25 border-primary-color-100">
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              option={tab}
              isSelected={currentTabValue === tab.value}
              onClick={onSelect}
            />
          ))}
        </div>
        <div className="flex">{getTabs()}</div>
      </div>
    </div>
  );
};

Product.defaultProps = {
  main_product_image_url: "",
  other_product_image_urls: [],
  rating: 0,
  isHotDeal: false,
  isFreeShipping: true,
  addToCartLoading: false,
  isFavoriteProduct: false,
  readOnly: false,
  itemDetails: "Item details description goes here",
  shippingAndReturns: "No Return policy mentioned for this product",
  variantSelections: [],
  minPrice: 0,
  maxPrice: 0,
  variants: [],
  faqs: "",
  showAddToCartModal: false,
  handleAddToCart: () => {},
  handleAddToFavorites: () => {},
};

export default Product;
