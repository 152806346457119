import { AxiosError } from "axios";
import { Result } from "../../types";

// utils
import axios, { processErrorCatch } from "../../axios";
import { convertBlobToFile, getUseQueryParams } from "../../utils";

// ts
import { ProductPayload } from "./types";
import { AddImage } from "../AddOrEditProduct/AddOrEditProduct.types";
import { MAIN_IMAGE_KEY } from "../AddOrEditProduct/AddOrEditProduct.constants";

const getFormData = ({
  productData,
  images,
  deletedImages = [],
}: {
  productData: ProductPayload;
  images: AddImage[];
  deletedImages?: string[];
}) => {
  const formData = new FormData();
  const mainImage = images.find(({ imageKey }) => imageKey === MAIN_IMAGE_KEY);
  if (images.length) {
    images.forEach(({ imageKey, blobImg }) => {
      if (blobImg) {
        formData.append(
          "images",
          convertBlobToFile(
            blobImg as Blob,
            (blobImg as Blob & { name: string })?.name || imageKey
          )
        );
      }
    });
  }
  if (mainImage) {
    formData.append(
      "main_image",
      (mainImage.blobImg as Blob & { name: string })?.name || mainImage.imageKey
    );
  }
  if (deletedImages.length) {
    formData.append("delete_images", JSON.stringify(deletedImages));
  }
  Object.keys(productData).forEach((productDataKey) => {
    const value = productData[productDataKey as "name"];
    formData.append(
      productDataKey,
      typeof value === "object" ? JSON.stringify(value) : value
    );
  });
  return formData;
};

export const fetchProductDetails = async (payload: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: any;
}): Promise<Result> => {
  try {
    const keyValues = getUseQueryParams(payload);
    const resp = await axios.get(`/product/id/${keyValues?.id}`);
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const createProduct = async ({
  productData,
  images,
}: {
  productData: ProductPayload;
  images: AddImage[];
}): Promise<Result> => {
  try {
    const formData = getFormData({ productData, images });
    const resp = await axios.post("/product", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateProduct = async ({
  id,
  payload,
  images,
  deletedImages,
}: {
  id: string;
  payload: ProductPayload;
  images: AddImage[];
  deletedImages: string[];
}): Promise<Result> => {
  try {
    const formData = getFormData({
      productData: payload,
      images,
      deletedImages,
    });
    const resp = await axios.put(`/product/id/${id}`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const addProductToCart = async (payload: {
  product_id: string;
  quantity: number;
  variant_id?: string;
}): Promise<Result> => {
  try {
    const resp = await axios.post(`/cart`, payload);
    return await Promise.resolve({
      data: { value: resp.data, headers: resp.headers },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const removeProduct = async ({
  productId,
}: {
  productId: string;
}): Promise<Result> => {
  try {
    const resp = await axios.delete(`/product/id/${productId}`);
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
