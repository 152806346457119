import React from "react";

// icons
import { closeRed, tickGreen } from "../../../assets";

export const getDataProcessingInfo = ({
  type,
  value,
}: {
  type: string;
  value: string;
}): JSX.Element => {
  if (value) {
    switch (type) {
      case "error":
        return (
          <div className="flex justify-center text-contrast-color-1-100 font-opensans font-normal text-sm">
            {value}
          </div>
        );
      case "warning":
      case "success":
        return (
          <div className="flex items-center justify-center gap-2">
            <img
              src={type === "warning" ? closeRed : tickGreen}
              alt={type === "warning" ? "closeRed" : "tickGreen"}
              style={{ width: 17.5, height: 17.5 }}
            />
            <div className="flex justify-center text-primary-color-100 font-opensans font-normal text-sm">
              {value}
            </div>
          </div>
        );
      default:
        return <div />;
    }
  }
  return <div />;
};

export default getDataProcessingInfo;
