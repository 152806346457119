import React, { FC, useState, useEffect, useMemo } from "react";

// packages
import { useQuery } from "react-query";

// store
import { fetchAllOrders } from "../../actions";

// constants
import { getOrderReviewTab } from "../../constants";

// components
import Orders from "./Orders";
import Tab from "../../../../components/Tab";
import Pagination from "../../../../components/Pagination";

// ts
import { ParentOrder } from "./OrdersReviews.types";

const pageSize = 10;

export const OrdersReviews: FC = () => {
  const [allOrders, setAllOrders] = useState<ParentOrder[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentTabValue, setCurrentTabValue] = useState(
    getOrderReviewTab.value
  );
  const [pageNumber, setPageNumber] = useState(1);

  const { refetch, isFetching } = useQuery(
    [{ pageNumber, pageSize }],
    fetchAllOrders,
    {
      onSuccess: ({ data }) => {
        const {
          data: ordersData,
          resultsInfo: { totalCount: totalCountValue },
        } = (data?.value || {}) as {
          data: ParentOrder[];
          resultsInfo: { pageNo: number; pageSize: number; totalCount: number };
        };
        setAllOrders(ordersData);
        setTotalCount(totalCountValue);
      },
      enabled: false,
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const parentOrders = allOrders
    ?.sort((a, b) => b.created_at.localeCompare(a.created_at))
    .reduce((allParentOrders, currentOrder) => {
      const { id } = currentOrder;
      // eslint-disable-next-line no-param-reassign
      allParentOrders[id] = currentOrder;
      return allParentOrders;
    }, {} as { [parentOrderId: string]: ParentOrder });

  const onSelect = (tabValue: string) => {
    setCurrentTabValue(tabValue);
  };

  const tab = useMemo(() => {
    const tabObj = { ...getOrderReviewTab };
    tabObj.display = getOrderReviewTab.getDisplay(totalCount);
    return tabObj;
  }, [totalCount]);

  const content = <Orders parentOrders={parentOrders} />;

  return (
    <div className="flex flex-col flex-1 items-start">
      {/* <h3 className="flex leading-9 mb-2">Orders and Reviews</h3> */}
      {totalCount === 0 ? (
        <div className="font-opensans text-lg leading-7">
          Your orders and reviews will appear here.
        </div>
      ) : (
        <div className="flex flex-col w-full overflow-x-hidden md:flex-grow">
          <div className="flex border-b overflow-x-auto border-primary-color-100 border-opacity-25 mb-5">
            <Tab
              key={tab.value}
              option={tab}
              isSelected={currentTabValue === tab.value}
              onClick={onSelect}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center mt-2">
              <Pagination
                isContentLoading={isFetching}
                renderContent={content}
                pageNumber={pageNumber - 1}
                totalPages={Math.ceil(totalCount / pageSize)}
                handlePageClick={(selectedItem) => {
                  setPageNumber(selectedItem?.selected + 1);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default OrdersReviews;
