import React from "react";
import { brandImageMap } from "../../../../../../utils/constants";
import { IBrandImage } from "../../../../types";

interface IProps {
  brand: string;
  last4Digits: string;
  billingZipCode: string;
  expiryMonth: string;
  expiryYear: string;
  isDefault?: boolean;
}

export const CreditCard = ({
  brand,
  last4Digits,
  expiryMonth,
  expiryYear,
  isDefault,
  billingZipCode,
}: IProps): JSX.Element => {
  const brandImage = brandImageMap[brand as keyof IBrandImage];
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 font-bold items-center">
        <img src={brandImage} alt="credit-card" />
        {brand} card ending in {last4Digits}
      </div>
      <div className="flex gap-4 w-full">
        <p className="font-bold">Billing Zipcode:</p>
        <p>{billingZipCode}</p>
      </div>
      <div className="flex gap-4">
        <p className="font-bold">Expires:</p>
        <p>
          {expiryMonth}/{expiryYear}
        </p>
      </div>
      <div className="flex gap-40 border-b border-primary-color-100 w-full border-opacity-25 pb-3 mb-3">
        {/* <div className="flex">
          <div className="flex gap-12">
            <div
              className="flex gap-4 items-center"
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
              //   onClick={onEdit}
            >
              <img src={edit} alt="Edit" className="cursor-pointer" />
              <div className={`${baseTextStyle} font-bold`}>Edit</div>
            </div>
            <div
              className="flex items-center gap-3"
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
              //   onClick={onRemove}
            >
              <img src={remove} alt="Remove" className="cursor-pointer" />
              <div className={`${baseTextStyle} font-bold`}>Remove</div>
            </div>
          </div>
        </div> */}
        <div className="flex">
          {isDefault && (
            <div className="flex font-poppins font-normal text-md leading-9 text-primary-color-100">
              Default
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreditCard;

CreditCard.defaultProps = {
  isDefault: false,
};
