/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// components
import React, { useEffect, useState } from "react";
import Select, { ValueType } from "react-select";
import {
  getButtonStyle,
  DropdownTypes,
  DropdownSizes,
  DropdownLabelSizes,
  IDropdownOption,
} from "./Dropdown.utils";

// ts
interface Props {
  title?: string;
  type?: DropdownTypes;
  size?: DropdownSizes;
  textSize?: DropdownLabelSizes;
  placeholderInput?: string;
  isRequired?: boolean;
  options: Array<IDropdownOption>;
  preSelectedOptions?: Array<IDropdownOption>;
  disableDropdown?: boolean;
  onChange?: (val: IDropdownOption | null) => void;
  useAddressFormStyle?: boolean;
  isClearable?: boolean;
}

const Dropdown = ({
  title,
  type = "primary",
  size = "sm",
  textSize = "lg",
  isRequired = false,
  placeholderInput,
  options,
  preSelectedOptions,
  disableDropdown,
  useAddressFormStyle = false,
  isClearable = false,
  onChange = () => {},
}: Props): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState(preSelectedOptions);

  useEffect(() => {
    if (preSelectedOptions) {
      setSelectedOptions(preSelectedOptions);
    }
  }, [preSelectedOptions]);

  function handleContextMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
  }

  const { dropdownComponentStyles } = getButtonStyle({
    type,
    size,
    useAddressFormStyle,
  });
  return (
    <div
      className={`flex flex-col items-start text-primary-color-100 text-${textSize} ${
        size === "fullWidth" ? "w-full" : ""
      } 
      `}
    >
      {title && (
        <span className="flex font-opensans font-normal">
          <div
            style={
              textSize === "sm"
                ? { fontSize: "0.75rem" }
                : { fontSize: "1.125rem" }
            }
          >
            {title}
          </div>
          {isRequired && <span className="text-red-500"> *</span>}
        </span>
      )}
      <Select
        menuPortalTarget={document.body}
        onContextMenu={handleContextMenu}
        placeholder={placeholderInput}
        styles={dropdownComponentStyles}
        options={options}
        value={selectedOptions}
        isDisabled={disableDropdown}
        defaultValue={preSelectedOptions?.[0] || { label: "", value: "" }}
        isClearable={isClearable}
        onChange={(value: ValueType<IDropdownOption, false>) => {
          setSelectedOptions([value as IDropdownOption]);
          onChange(value);
        }}
      />
    </div>
  );
};

Dropdown.defaultProps = {
  placeholderInput: "",
  title: "",
  type: "primary",
  size: "sm",
  textSize: "lg",
  isRequired: false,
  onChange: () => {},
  preSelectedOptions: [{ label: "", value: "" }],
  disableDropdown: false,
  useAddressFormStyle: false,
  isClearable: false,
};

export default Dropdown;
