import React, { ReactElement } from "react";

const SvgEdit = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  const { fill = "#3F415B", onClick = () => {} } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.751 19.4397H11.498C11.084 19.4397 10.748 19.1037 10.748 18.6897C10.748 18.2757 11.084 17.9397 11.498 17.9397H18.751C19.165 17.9397 19.501 18.2757 19.501 18.6897C19.501 19.1037 19.165 19.4397 18.751 19.4397Z"
        fill={fill}
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000488281 0.500122H17.1811V19.4397H0.000488281V0.500122Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1108 2.51676L1.69576 14.2918C1.52476 14.5058 1.46176 14.7818 1.52476 15.0468L2.20576 17.9318L5.24476 17.8938C5.53376 17.8908 5.80076 17.7618 5.97776 17.5418C9.19476 13.5168 15.3278 5.84276 15.5018 5.61776C15.6658 5.35176 15.7298 4.97576 15.6438 4.61376C15.5558 4.24276 15.3248 3.92776 14.9918 3.72676C14.9208 3.67776 13.2358 2.36976 13.1838 2.32876C12.5498 1.82076 11.6248 1.90876 11.1108 2.51676ZM1.61376 19.4398C1.26676 19.4398 0.964763 19.2018 0.883763 18.8628L0.0647626 15.3918C-0.104237 14.6728 0.0637626 13.9308 0.524763 13.3548L9.94476 1.57276C9.94876 1.56876 9.95176 1.56376 9.95576 1.55976C10.9888 0.324765 12.8568 0.142765 14.1168 1.15376C14.1668 1.19276 15.8398 2.49276 15.8398 2.49276C16.4478 2.85476 16.9228 3.50176 17.1028 4.26776C17.2818 5.02576 17.1518 5.80777 16.7348 6.46876C16.7038 6.51776 16.6768 6.55976 7.14876 18.4798C6.68976 19.0518 6.00176 19.3848 5.26276 19.3938L1.62376 19.4398H1.61376Z"
          fill={fill}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2234 9.18474C14.0634 9.18474 13.9034 9.13375 13.7664 9.02975L8.31442 4.84175C7.98642 4.58975 7.92442 4.11975 8.17642 3.78975C8.42942 3.46175 8.89942 3.40075 9.22842 3.65275L14.6814 7.83975C15.0094 8.09174 15.0714 8.56275 14.8184 8.89175C14.6714 9.08375 14.4484 9.18474 14.2234 9.18474Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgEdit;
