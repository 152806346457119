/* eslint-disable react/require-default-props */
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button";
import { logoIcon } from "../../assets";

export const ErrorFallBack = ({
  resetErrorBoundary,
  showLogo = true,
  backgroundColor = "bg-primary-color-10",
  show404Text = false,
}: {
  resetErrorBoundary: () => void;
  showLogo?: boolean;
  backgroundColor?: string;
  show404Text?: boolean;
}): JSX.Element => {
  const history = useHistory();
  const textLine1 = show404Text ? "404" : "Oops!";
  const textLine2 = show404Text
    ? "This is not the page you are looking for"
    : "Something went wrong";
  const buttonStyle =
    "flex justify-center self-center rounded-md whitespace-nowrap";
  const textStyle = "flex justify-center";
  return (
    <div
      style={{ width: "100vw", height: "100vh", margin: 0, padding: 0 }}
      className={backgroundColor}
    >
      <div
        className="flex-col gap-1 justify-center font-poppins font-normal text-sm"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {showLogo && (
          <div className="flex justify-center">
            <a href="/">
              <img
                src={logoIcon}
                alt="lonima-logo"
                className="w-24 sm:w-28 md:w-44"
              />
            </a>
          </div>
        )}
        <div className={`${textStyle} text-6xl font-semibold py-4`}>
          {textLine1}
        </div>
        <div className={`${textStyle} text-3xl`}>{textLine2}</div>
        <div className="flex justify-center font-poppins font-normal text-sm mt-4 gap-2">
          <Button
            containerClassName={buttonStyle}
            text="Back to Home"
            size="sm"
            type="primary"
            textClassName="text-xs"
            onClick={() => {
              history.push("/");
              resetErrorBoundary();
            }}
          />
          {!show404Text && (
            <Button
              containerClassName={buttonStyle}
              text="Reload Page"
              size="sm"
              type="primary"
              textClassName="text-xs"
              onClick={() => resetErrorBoundary()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorFallBack;
