import React from "react";

// icons
import { bagIcon } from "../../assets";

const ordersText = "Your orders and reviews";

const getTabDisplay = (text: string, count: number) => (
  <div className="flex">
    <div className="flex">{text}</div>
    {count > 0 && <div className="flex ml-2">{`(${count})`}</div>}
  </div>
);

const tabs = [
  { display: "Profile", value: "profile-settings" },
  // { display: "Messages", value: "messages" },
  { display: "Orders & Reviews", value: "orders-reviews" },
  { display: "Account Settings", value: "account-settings" },
];

export const getOrderReviewTab = {
  display: ordersText,
  value: "orders",
  icon: bagIcon,
  getDisplay: (count: number): JSX.Element => getTabDisplay(ordersText, count),
} as {
  display: string | JSX.Element;
  value: string;
  icon: string;
  getDisplay: (count: number) => JSX.Element;
};

export const defaultProfileConfig = {
  first_name: "",
  last_name: "",
  dob: "",
  pronouns: [],
  about: "",
  my_favorite_items: false,
  my_favorite_shops: false,
  my_shop: false,
  avatar: "",
  location: "",
  email_verified: false,
};

export const DEFAULT_PRONOUNS = [
  "He / His",
  "She / Her",
  "They / Them",
  "Rather not say",
];

export const ENABLED_PROFILE_KEYS = [
  "first_name",
  "last_name",
  "about",
  "pronouns",
  "dob",
];

export default tabs;
