// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../axios";

// ts
import { Result, AddressPayload } from "../../types";

export const isShopNameAvailable = async (name: string): Promise<Result> => {
  try {
    const { data } = await axios.get(`/shop/available/${name}`);
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const createShop = async ({
  name,
  address,
}: {
  name: string;
  address: AddressPayload;
}): Promise<Result> => {
  try {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { address_ln1, city, state, zip_code } = address;
    const { data } = await axios.post(`/shop`, {
      name,
      // TODO: need to make description optional
      description: `Welcome to ${name}`,
      address: [
        {
          address_ln1,
          city,
          state,
          zip_code,
        },
      ],
    });
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const addShopAddress = async ({
  address,
  shopId,
}: {
  address: AddressPayload;
  shopId: string;
}): Promise<Result> => {
  try {
    const { data } = await axios.post(`/shop/id/${shopId}/address`, {
      ...address,
    });
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export default isShopNameAvailable;
