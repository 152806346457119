import React from "react";
import { Route, Switch, RouteProps, Redirect } from "react-router-dom";
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import Home from "./containers/Home";
import { useAuth } from "./hooks";
import ActivateUser from "./screens/ActivateUser";
import CategoriesPage from "./screens/Categories";
import Product from "./screens/Product";
import RedirectUser from "./screens/Login/RedirectUser";
import Profile from "./screens/Profile";
import Checkout from "./screens/Checkout";
import Order from "./screens/Checkout/Order";
import Payment from "./screens/Checkout/Payment";
import Review from "./screens/Checkout/Review";
import ProcessedOrder from "./screens/Order";
import PublicProfile from "./screens/PublicProfile";
import Shop from "./screens/Shop";
import AddOrEditProduct from "./screens/AddOrEditProduct";
import SellOnLonima from "./screens/SellOnLonima";
import MyShop from "./screens/MyShop";
import ShopSuccess from "./screens/SellOnLonima/ShopSuccess";
import LogoutPage from "./screens/Logout/Logout";
import { getItem } from "./utils";
import AccountSettings from "./screens/Profile/Tabs/AccountSettings";
import { ErrorFallBack } from "./components/ErrorFallBack";
import { setCookie } from "./utils/cookieStorage";
import Cart from "./screens/Cart";

const PrivateRoute = ({ component, path }: RouteProps) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    setCookie("redirect_url_last_visited", window.location.pathname, 7);
  }
  return isAuthenticated ? (
    <Route component={component} path={path} />
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  );
};

const Router = (): JSX.Element => {
  const {
    treatments: {
      view_cart: viewCart,
      // customer_account_settings: customerAccountSettings,
    },
  } = useSplitTreatments({
    names: [
      "view_cart",
      "customer_account_settings",
      "customer_orders_reviews",
    ],
    attributes: { userId: getItem("split_override_user_id") as string },
  });
  // if (!isReady) return <div>Loading...</div>;

  return (
    <Switch>
      <Route exact path="/log-user-in" component={RedirectUser} />
      <Route
        path="/login"
        component={() => {
          window.location.href = `${process.env.REACT_APP_API_BASE_URL}/login`;
          return null;
        }}
      />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/confirm-email" component={ActivateUser} />
      <PrivateRoute path="/account" component={Profile} />
      <PrivateRoute
        path="/account/account-settings"
        component={AccountSettings}
      />

      {/* : (
        <Route
          path="/account/account-settings"
          component={() => (
            <ErrorFallBack
              resetErrorBoundary={() => {}}
              backgroundColor="white"
              show404Text
              showLogo={false}
            />
          )}
        />
      )} */}
      <PrivateRoute path="/add-product" component={AddOrEditProduct} />
      <PrivateRoute path="/edit-product" component={AddOrEditProduct} />
      <PrivateRoute path="/profile" component={PublicProfile} />
      <Route path="/search" component={CategoriesPage} />
      <Route path="/product" component={Product} />
      {viewCart.treatment === "on" ? (
        <Route path="/checkout/:id/payment" component={Payment} />
      ) : (
        <Route
          path="/checkout/:id/payment"
          component={() => (
            <ErrorFallBack
              resetErrorBoundary={() => {}}
              backgroundColor="white"
              show404Text
              showLogo={false}
            />
          )}
        />
      )}
      {viewCart.treatment === "on" ? (
        <Route path="/checkout/:id/review" component={Review} />
      ) : (
        <Route
          path="/checkout/:id/review"
          component={() => (
            <ErrorFallBack
              resetErrorBoundary={() => {}}
              backgroundColor="white"
              show404Text
              showLogo={false}
            />
          )}
        />
      )}
      {viewCart.treatment === "on" ? (
        <Route path="/checkout/:id/order" component={Order} />
      ) : (
        <Route
          path="/checkout/:id/order"
          component={() => (
            <ErrorFallBack
              resetErrorBoundary={() => {}}
              backgroundColor="white"
              show404Text
              showLogo={false}
            />
          )}
        />
      )}
      {viewCart.treatment === "on" ? (
        <Route path="/order" component={ProcessedOrder} />
      ) : (
        <Route
          path="/order"
          component={() => (
            <ErrorFallBack
              resetErrorBoundary={() => {}}
              backgroundColor="white"
              show404Text
              showLogo={false}
            />
          )}
        />
      )}
      {viewCart.treatment === "on" ? (
        <Route path="/checkout/:id" component={Checkout} />
      ) : (
        <Route
          path="/checkout/:id"
          component={() => (
            <ErrorFallBack
              resetErrorBoundary={() => {}}
              backgroundColor="white"
              show404Text
              showLogo={false}
            />
          )}
        />
      )}
      {viewCart.treatment === "on" ? (
        <Route path="/cart" component={Cart} />
      ) : (
        <Route
          path="/cart"
          component={() => (
            <ErrorFallBack
              resetErrorBoundary={() => {}}
              backgroundColor="white"
              show404Text
              showLogo={false}
            />
          )}
        />
      )}
      <PrivateRoute path="/sell-on-lonima/:type" component={SellOnLonima} />
      <PrivateRoute path="/sell-on-lonima/myshop/:id" component={MyShop} />
      <PrivateRoute path="/shop/success" component={ShopSuccess} />
      <Route path="/shop/:id" component={Shop} />
      <Route exact path="/" component={Home} />
    </Switch>
  );
};

export default Router;
