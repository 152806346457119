/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, ChangeEvent } from "react";

// packages
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { v4 as uuid } from "uuid";
import { useClickAway } from "react-use";

// components
import heic2any from "heic2any";
import { ClipLoader } from "react-spinners";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import * as Icons from "../../../../components/Icons";
import { MAIN_PLACEHOLDER_IMAGE_KEY } from "../../../AddOrEditProduct/AddOrEditProduct.constants";

const DEFAULT_CROP_RATIO = {
  unit: "%",
  width: 50,
  height: 50,
  // aspect: 1 / 1,
} as Crop;

export const ImageEditor = ({
  onUpload,
  type = "",
  text = "",
  isCircularCrop = false,
}: {
  onUpload: ({
    blobImg,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    type,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    text,
  }: {
    blobImg: Blob | MediaSource;
    type: string;
    text: string;
  }) => void;
  type?: string;
  text?: string;
  isCircularCrop?: boolean;
}): JSX.Element => {
  const [upImg, setUpImg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [inputId, setInputId] = useState(uuid());
  const [crop, setCrop] = useState<Crop>(DEFAULT_CROP_RATIO);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [completedCrop, setCompletedCrop] = useState<Crop>(null!);

  const ref = useRef(null);
  const fileRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imgRef = useRef<any>(null);

  const resetImage = () => {
    setUpImg("");
    imgRef.current.value = "";
    setInputId(uuid());
    setCrop(DEFAULT_CROP_RATIO);
  };

  useClickAway(ref, () => {
    resetImage();
  });

  const openFileBrowser = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e.target.files?.length > 0) {
      setLoading(true);
      const reader = new FileReader();
      let selectedImage = e.target.files[0];
      if (selectedImage.type === "image/heic") {
        const blobURL = URL.createObjectURL(selectedImage);

        // convert "fetch" the new blob url
        const blobRes = await fetch(blobURL);

        // convert response to blob
        const blob = await blobRes.blob();

        // convert to PNG - response is blob
        const conversionResult = await heic2any({ blob });
        selectedImage = conversionResult;
      }
      reader.addEventListener("load", async () => {
        setUpImg(reader?.result);
      });
      setInputId(uuid());
      reader.readAsDataURL(selectedImage);
    }
  };

  const onLoad = (img: HTMLImageElement) => {
    setLoading(false);
    imgRef.current = img;
  };

  const handleUpload = () => {
    if (!completedCrop || !imgRef.current) {
      return;
    }
    const croppedImage = imgRef.current;
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    const croppedImg = completedCrop as {
      x: number;
      y: number;
      width: number;
      height: number;
    };

    const scaleX = croppedImage.naturalWidth / croppedImage.width;
    const scaleY = croppedImage.naturalHeight / croppedImage.height;
    if (canvas && croppedImg) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const pixelRatio = window.devicePixelRatio;

        let targetWidth = croppedImg.width * pixelRatio * scaleX;
        let targetHeight = croppedImg.height * pixelRatio * scaleY;

        const maxDimension = 4096;

        if (targetWidth > maxDimension || targetHeight > maxDimension) {
          const ratio = Math.min(
            maxDimension / targetWidth,
            maxDimension / targetHeight
          );
          targetWidth *= ratio;
          targetHeight *= ratio;
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
          croppedImage,
          croppedImg?.x * scaleX,
          croppedImg?.y * scaleY,
          croppedImg?.width * scaleX,
          croppedImg?.height * scaleY,
          0,
          0,
          targetWidth / pixelRatio,
          targetHeight / pixelRatio
        );
        canvas.toBlob((blob) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const blobBuffer = blob as any;
          if (!blobBuffer) {
            return;
          }
          const name = uuid();
          blobBuffer.name = name;
          blobBuffer.originalname = name;
          // setBlobImg(blobBuffer);
          onUpload({ blobImg: blobBuffer, type, text });
          resetImage();
        }, "image/jpeg");
      }
    }
  };

  if (loading && !upImg) {
    return <ClipLoader color="#E07A5F" size={30} loading />;
  }

  return (
    <div>
      <input
        key={inputId}
        type="file"
        ref={fileRef}
        id="upload-button"
        accept=".png, .jpg, .jpeg, .heic, .heif, .webp"
        className="hidden"
        onChange={handleChange}
      />
      {upImg && (
        <Modal
          overrideModalStyle="w-full sm:w-1/2 justify-center"
          overrideModalContainerStyle="items-end sm:items-center"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
          }}
          hideCloseIcon
        >
          <div className="flex flex-col h-auto" ref={ref}>
            <div className="flex self-center p-4">
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                circularCrop={isCircularCrop}
                crop={crop}
                style={{
                  width: "100%",
                }}
                imageStyle={{
                  height: "400px",
                  objectFit: "contain",
                }}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
            </div>
            <div className="flex pb-4 justify-evenly">
              <div
                onKeyDown={() => {}}
                onClick={() => {}}
                role="button"
                tabIndex={0}
                aria-label="Crop"
              >
                <Button
                  text="Crop"
                  size="sm"
                  type="outline"
                  onClick={handleUpload}
                />
              </div>
              <div
                onKeyDown={() => {}}
                onClick={() => {}}
                role="button"
                tabIndex={0}
                aria-label="Cancel"
              >
                <Button
                  text="Cancel"
                  size="sm"
                  type="outline"
                  onClick={resetImage}
                />
              </div>
            </div>
            <p className="flex justify-center p-2 pb-4">
              Make sure to hit save on this page after adding the image
            </p>
          </div>
        </Modal>
      )}
      {!upImg && (
        <div
          className={`${
            text
              ? "flex flex-col justify-center items-center cursor-pointer border-2 border-disabled-400 border-dashed"
              : ""
          } cursor-pointer`}
          role="button"
          style={text ? { width: 150, height: 150 } : {}}
          onKeyDown={() => {}}
          tabIndex={0}
          onClick={openFileBrowser}
        >
          {type === MAIN_PLACEHOLDER_IMAGE_KEY ? (
            <div>
              {text}
              <p className="text-red-500">(required)*</p>
            </div>
          ) : (
            text || <Icons.Edit />
          )}
        </div>
      )}
    </div>
  );
};

ImageEditor.defaultProps = {
  type: "",
  text: "",
  isCircularCrop: false,
};

export default ImageEditor;
