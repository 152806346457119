import React, { useState, useEffect } from "react";

// utils
import { baseArrowStyle, getArrowPositionStyle } from "./PopOver.utils";

// ts
import { Position } from "./PopOver.types";

export default function PopOver({
  position = "" as Position,
  children,
  overrideStyleClassName,
  showArrow = true,
}: {
  children?: JSX.Element;
  position?: Position;
  overrideStyleClassName?: string;
  showArrow?: boolean;
}): JSX.Element {
  const [container] = useState(document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  const containerStyle = `flex rounded-md 
    border border-solid border-primary-color-100 border-opacity-10 
    p-4 
    bg-white sm:mt-5 
    filter drop-shadow ${overrideStyleClassName}`;
  const arrowStyle = showArrow ? baseArrowStyle : {};
  const arrowPositionStyle = showArrow ? getArrowPositionStyle(position) : {};
  return (
    <div className={containerStyle}>
      <div
        style={
          {
            ...arrowStyle,
            ...arrowPositionStyle,
          } as React.CSSProperties
        }
        className="hidden md:block"
      />
      {children}
    </div>
  );
}

PopOver.defaultProps = {
  children: <div />,
  position: "left",
  overrideStyleClassName: "",
  showArrow: true,
};
