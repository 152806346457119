/* eslint-disable @typescript-eslint/naming-convention */
// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../axios";
import { getUseQueryParams } from "../../utils";

// ts
import { Result } from "../../types";

export const searchCategories = async (payload: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: any;
}): Promise<Result> => {
  try {
    const keyValues = getUseQueryParams(payload);
    const resp = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/category/search/${keyValues?.searchText}`
    );
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export default searchCategories;
