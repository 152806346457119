/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect, useMemo } from "react";

// components
import * as Icons from "../../../../components/Icons";

// constants
import { MAIN_IMAGE_KEY } from "../../AddOrEditProduct.constants";

export const Image = ({
  blobImg,
  imageKey,
  onRemove,
  imageUrl,
}: {
  blobImg?: Blob | MediaSource;
  imageKey: string;
  imageUrl?: string;
  onRemove: ({ imgKey, imgUrl }: { imgKey: string; imgUrl: string }) => void;
}): JSX.Element => {
  const [image, setImage] = useState<string>(
    (blobImg ? URL.createObjectURL(blobImg) : imageUrl) as string
  );
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    setImage((blobImg ? URL.createObjectURL(blobImg) : imageUrl) as string);
  }, [blobImg, imageUrl]);

  const getContent = () => (
    <div
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      className="p-2 relative cursor-pointer"
    >
      {isMouseOver && (
        <div
          style={{
            position: "absolute",
            inset: "45%",
            zIndex: 20,
          }}
        >
          <Icons.Delete
            onClick={() =>
              onRemove({ imgUrl: imageUrl || "", imgKey: imageKey })
            }
            fill="red"
            width="15px"
            height="15x"
          />
        </div>
      )}
      <img
        src={image}
        alt={imageKey}
        style={{
          opacity: `${isMouseOver ? 0.2 : 1}`,
          objectFit: "contain",
          maxHeight: 150,
        }}
      />
    </div>
  );

  const isImageKey = useMemo(() => imageKey === MAIN_IMAGE_KEY, [imageKey]);

  const borderStyle = !isMouseOver
    ? `border border-${
        isImageKey ? "neutral-color-5" : "primary-color-100"
      } border-opacity-${isImageKey ? "100" : "20"} `
    : "";

  return (
    <div
      className={`flex flex-col justify-center items-center rounded-md ${borderStyle} cursor-pointer`}
      style={{ width: 150, height: 150 }}
    >
      {getContent()}
    </div>
  );
};

Image.defaultProps = {
  blobImg: null,
  imageUrl: "",
};

export default Image;
