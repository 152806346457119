import React, { ReactElement } from "react";

const SvgArrowDown = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  const { fill = "#3F415B", width = 16, height = 17 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.97994 5.73214C3.15745 5.55463 3.43523 5.53849 3.63097 5.68373L3.68705 5.73214L8.00016 10.045L12.3133 5.73214C12.4908 5.55463 12.7686 5.53849 12.9643 5.68373L13.0204 5.73214C13.1979 5.90965 13.214 6.18743 13.0688 6.38317L13.0204 6.43925L8.35372 11.1059C8.17621 11.2834 7.89843 11.2996 7.70269 11.1543L7.64661 11.1059L2.97994 6.43925C2.78468 6.24398 2.78468 5.9274 2.97994 5.73214Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgArrowDown;
