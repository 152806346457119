import React from "react";
import CartItem from "../../components/CartItem";
import {
  ICartProductDetails,
  ICartResponse,
} from "../../components/CartItem/types";

const CartItemContainer = ({
  cartProductData,
  handleUpdateQuantity,
  showCartText,
  disableQuantityOption = false,
  hideIsGiftOption,
  cartData,
}: {
  cartProductData: Array<ICartProductDetails>;
  showCartText: boolean;
  handleUpdateQuantity: (
    prodId: string,
    quant: number,
    productName?: string
  ) => void;
  disableQuantityOption?: boolean;
  hideIsGiftOption?: boolean;
  cartData: ICartResponse;
}): JSX.Element => {
  if (cartProductData.length < 1) return <div />;
  const hasOnlyOneProduct = cartProductData.length === 1;
  return (
    <div className="flex flex-col items-start w-full gap-5">
      {cartData?.data?.map((shopData) => {
        const shopDataKeys = Object.keys(shopData);
        const products = shopData.items;
        const shopName = shopData.shop_name || "";
        const shopId = shopData.shop_id || "";
        return (
          <div
            className="flex flex-col items-start w-full p-5 border rounded"
            style={{
              border: "1px solid lightGray",
              boxShadow:
                "0 0 12px #11111112, 0 4px 4px #1111111a, 0 6px 8px #11111112",
            }}
            key={shopId}
          >
            <div className="flex flex-col items-start w-full">
              {shopDataKeys.includes("shop_name") ? (
                <div className="flex text-lg font-bold font-opensans text-primary-color-100">
                  <a
                    href={`/shop/${shopId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="outline-none"
                  >
                    <div>{`${shopName}`}</div>
                  </a>
                </div>
              ) : null}
              {showCartText && (
                <div className="flex w-full pt-4 font-bold">
                  <span className="flex flex-2">Item</span>
                  <span className="flex-1 hidden md:flex">
                    <span className="flex justify-end w-full md:justify-between">
                      <p className="hidden md:block">Price</p>
                      <p>Quantity</p>
                    </span>
                  </span>
                </div>
              )}
            </div>
            <span className="w-full pb-2 border-b border-opacity-25 border-primary-color-100" />
            {products.map((product) => (
              <CartItem
                key={`cartitem-${product._id}`}
                removeCartItem={({ productId, productName }) => {
                  handleUpdateQuantity(productId, 0, productName);
                }}
                productDetails={product}
                updateQuantity={(prodId, quant) =>
                  handleUpdateQuantity(prodId, quant, "")
                }
                handleIsGift={(prodId, isGift) => {
                  // eslint-disable-next-line no-console
                  console.log("update quantity", prodId, isGift);
                }}
                disableQuantityOption={disableQuantityOption}
                hideIsGiftOption={hideIsGiftOption}
                hideRemoveOption={hasOnlyOneProduct && !showCartText}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

CartItemContainer.defaultProps = {
  disableQuantityOption: false,
  hideIsGiftOption: false,
};

export default CartItemContainer;
