/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useContext } from "react";

// packages
import { v4 as uuid } from "uuid";
import { useWindowSize } from "react-use";
import { intersection, isEqual, startCase, capitalize } from "lodash";
import Creatable from "react-select/creatable";
import { OptionsType } from "react-select";

// components
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import TextInput from "../../../../components/TextInput";

// context
import AddOrEditProductContext from "../../AddOrEditProduct.context";

// constants
import {
  STANDARD_SIZES,
  STANDARD_COLORS,
} from "../../AddOrEditProduct.constants";

// ts
import { Variant } from "../../../Product/types";
import CheckBox from "../../../../components/CheckBox";
import { TypeOfState } from "../../../../components/TextInput/TextInput.utils";

const DEFAULT_VARIANT_SELECTIONS = {
  size: STANDARD_SIZES,
  color: STANDARD_COLORS,
} as { [key: string]: string[] };

export const MANDATORY_VARIANT_KEYS = [
  "quantity",
  "original_price",
  "price",
  "discount_percentage",
];

export function isPositiveNumber(str: string): boolean {
  return /^[0-9]+(?:\.[0-9]+)?$/.test(str) && parseFloat(str) > 0;
}

export const getTypeofState = (
  type: "quantity" | "original_price" | "discount_percentage",
  value: string
): TypeOfState => {
  const hasError =
    typeof value !== "number" ? !isPositiveNumber(value) : value <= 0;
  switch (type) {
    case "discount_percentage": {
      if (hasError && parseFloat(value) < 0) {
        return "error";
      }
      return "normal";
    }
    case "original_price": {
      const hasOriginalPriceError =
        typeof value !== "number"
          ? !(isPositiveNumber(value) && parseFloat(value) >= 1)
          : value < 1;
      if (hasOriginalPriceError) {
        return "error";
      }
      return "normal";
    }
    default: {
      if (hasError) {
        return "error";
      }
      return "normal";
    }
  }
};

export const getInfoMessage = (
  type: "price" | "quantity" | "original_price" | "discount_percentage",
  value: string,
  showSpaceByDefault = false
): string => {
  const hasError =
    typeof value !== "number" ? !isPositiveNumber(value) : value <= 0;
  switch (type) {
    case "discount_percentage": {
      if (hasError && parseFloat(value) < 0 && value !== undefined) {
        return "Discount percentage should be a valid number.";
      }
      return showSpaceByDefault ? " " : "";
    }
    case "original_price": {
      const hasOriginalPriceError =
        typeof value !== "number"
          ? !(isPositiveNumber(value) && parseFloat(value) >= 1)
          : value < 1;
      if (hasOriginalPriceError) {
        return "Price should be a number and greater than or equal to 1$.";
      }
      return showSpaceByDefault ? " " : "";
    }
    default: {
      if (hasError) {
        return `${startCase(type)} should be a number and greater than 0.`;
      }
      return showSpaceByDefault ? " " : "";
    }
  }
};

export const Variants = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const {
    productDetails: { variants },
    updateProductDetails,
    variantSelections,
    setVariantSelections,
  } = useContext(AddOrEditProductContext);
  const [localVariants, setLocalVariants] = useState(variants);
  const [deleteVariants, setDeleteVariants] = useState<Array<string>>([]);
  const { width } = useWindowSize();
  const isSmallDevices = width < 768;
  const variantSelectionKeys = Object.keys(variantSelections);

  useEffect(() => {
    setLocalVariants((prevLocalVariants) => {
      const value = { ...prevLocalVariants.value };
      Object.keys(value).forEach((key) => {
        const variant = value[key];
        const updatedVariant = { ...variant };
        const updatedVariantKeys = Object.keys(updatedVariant);
        updatedVariantKeys.forEach((variantKey) => {
          const variantSelectionValues = variantSelections[variantKey] || [];
          const currentVariantValue =
            updatedVariant[variantKey as "variant_key"];
          // remove from variant if any variant option is removed
          if (
            !MANDATORY_VARIANT_KEYS.includes(variantKey) &&
            typeof currentVariantValue === "string" &&
            currentVariantValue !== "" &&
            variantKey !== "_id" &&
            !variantSelectionValues.includes(currentVariantValue)
          ) {
            updatedVariant[variantKey as "variant_key"] = "";
          }
          if (
            !variantSelectionKeys.includes(variantKey) &&
            !MANDATORY_VARIANT_KEYS.includes(variantKey)
          ) {
            delete updatedVariant[variantKey as "variant_key"];
          }
        });
        variantSelectionKeys.forEach((variantKey) => {
          if (!updatedVariantKeys.includes(variantKey)) {
            updatedVariant[variantKey as "variant_key"] = "";
          }
        });
        value[key] = updatedVariant;
      });
      return { ...prevLocalVariants, value };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variantSelections)]);

  const addVariant = () => {
    const updatedVariants = { ...localVariants.value };
    const deafultObj = {
      quantity: "0",
      original_price: "0.00",
      price: "0.00",
      discount_percentage: "0.00",
    } as Variant;
    variantSelectionKeys.forEach((variantKey) => {
      deafultObj[variantKey as "variant_key"] = "";
    });
    updatedVariants[uuid()] = deafultObj;
    setLocalVariants({
      value: updatedVariants,
      isRequired: true,
      elementState: "normal",
    });
  };

  const removeVariants = () => {
    const updatedVariants = { ...localVariants.value };
    deleteVariants.forEach((delVariant) => delete updatedVariants[delVariant]);

    setLocalVariants({
      value: updatedVariants,
      isRequired: true,
      elementState: "normal",
    });
    setDeleteVariants([]);
  };

  const uniqValues = Object.keys(localVariants.value || {}).reduce(
    (uniqValuesMap, key) => {
      const values = localVariants.value[key];
      const result = Object.keys(values).reduce((acc, value) => {
        if (
          !MANDATORY_VARIANT_KEYS.includes(value) &&
          !!values[value as "variant_key"]
        ) {
          acc.push(values[value as "variant_key"] as string);
        }
        return acc;
      }, [] as string[]);
      const uniqValueKey = result.join("");
      if (uniqValueKey) {
        if (!uniqValuesMap[uniqValueKey]) {
          // eslint-disable-next-line no-param-reassign
          uniqValuesMap[uniqValueKey] = [key];
        } else {
          // eslint-disable-next-line no-param-reassign
          uniqValuesMap[uniqValueKey] = [...uniqValuesMap[uniqValueKey], key];
        }
      }
      return uniqValuesMap;
    },
    {} as { [key: string]: string[] }
  );
  const duplicates = Object.values(uniqValues).reduce((dups, values) => {
    if (values.length > 1) {
      // eslint-disable-next-line no-param-reassign
      dups = [...(dups || []), ...values.slice(1)];
    }
    return dups;
  }, [] as string[]);

  const variantKeyValues = Object.keys(localVariants.value || {}).reduce(
    (variantKeys, key, index) => {
      const variantKV = localVariants.value[key];
      const keys = Object.keys(variantKV);
      const values: string[] = [];
      keys.forEach((variantKey) => {
        if (variantKV[variantKey as "variant_key"]) {
          values.push(variantKey as string);
        }
      });
      // eslint-disable-next-line no-param-reassign
      variantKeys[index?.toString()] = values;
      return variantKeys;
    },
    {} as { [key: string]: string[] }
  );

  const hasIrregularVariants = () => {
    const variantKeys = Object.keys(variantKeyValues);

    if (variantKeys?.length > 1) {
      return Object.keys(variantKeyValues).reduce(
        (hasBadVariants, variantKeyValue) => {
          if (
            !isEqual(
              variantKeyValues[variantKeyValue],
              intersection(...Object.values(variantKeyValues))
            ) ||
            variantKeyValues[variantKeyValue]?.length === 0
          ) {
            return true;
          }
          return hasBadVariants;
        },
        false
      );
    }
    return false;
  };

  const variantsHasError = localVariants.elementState === "error";

  useEffect(() => {
    let hasError = false;
    Object.keys(localVariants.value).forEach((key) => {
      const { quantity, original_price } = localVariants.value[key];
      const floatQuantity = parseFloat(quantity);
      const floatOriginalPrice =
        original_price !== undefined && parseFloat(original_price);

      const errorStateMap = {
        quantity: floatQuantity > 0,
        original_price:
          !!floatOriginalPrice &&
          floatOriginalPrice >= 1 &&
          !Number.isNaN(floatOriginalPrice),
      } as { [key: string]: boolean };

      const valueStates = Object.values(errorStateMap);

      if (valueStates.includes(false)) {
        hasError = true;
      }
    });
    hasError = !hasError ? duplicates.length > 0 : true;

    setLocalVariants((prevVariants) => {
      return {
        value: prevVariants.value,
        isRequired: true,
        elementState: hasError ? "error" : "normal",
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicates.length, localVariants.value]);

  const isSaveDisabled = variantsHasError || hasIrregularVariants();

  return (
    <Modal
      isForceClose={!isModalOpen}
      overrideModalStyle="h-5/6 w-full max-w-7xl"
      overrideModalContainerStyle="items-center"
    >
      <div className="px-12">
        <div className="flex flex-col">
          <div className="pb-4">
            <h4 className="flex mt-6 mb-2">
              Variants (Use variants if your item is in different colors, sizes,
              materials, etc.)
            </h4>
            <p className="flex font-normal font-opensans text-left text-sm">
              You can select or type to create up to 2 variants (including 1
              custom variant).
            </p>
          </div>
          <Creatable
            isMulti
            isClearable
            placeholder="Select or type to create a variant"
            options={Object.keys(DEFAULT_VARIANT_SELECTIONS).map((value) => ({
              label: capitalize(value),
              value,
            }))}
            value={variantSelectionKeys.map((value) => ({
              label: capitalize(value),
              value,
            }))}
            onChange={(
              value: OptionsType<{ label: string; value: string }>
            ) => {
              if (variantSelectionKeys.length < 2 || value.length < 2) {
                setVariantSelections(
                  value.reduce((acc, currVal) => {
                    acc[currVal.value] = variantSelections[currVal.value] || [];
                    return acc;
                  }, {} as { [key: string]: string[] })
                );
              }
            }}
            onCreateOption={(inputValue) => {
              const newCustomVariant = variantSelectionKeys.filter(
                (variantSelection) =>
                  !["size", "color"].includes(variantSelection)
              );
              if (!newCustomVariant.length && variantSelectionKeys.length < 2) {
                setVariantSelections(
                  (prevSelections: { [key: string]: string[] }) => {
                    return {
                      ...prevSelections,
                      [inputValue]: [],
                    };
                  }
                );
              }
            }}
          />
          <span className="text-sm mt-2">
            {variantSelectionKeys.length < 2
              ? "Type to create a new variant"
              : "Delete a variant to select/add a new one."}
          </span>
        </div>
        {variantSelectionKeys.length > 0 && (
          <div className="flex flex-col">
            <div className="pb-2">
              <h4 className="flex mt-6 mb-2">Variant Options</h4>
              <p className="flex font-opensans text-left text-sm">
                Variant will be available for selection in the product page in
                the following options.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              {variantSelectionKeys.map((variantSelectionKey) => {
                const options =
                  DEFAULT_VARIANT_SELECTIONS[variantSelectionKey]?.map(
                    (value) => ({ label: value, value })
                  ) || variantSelections[variantSelectionKey];
                return (
                  <div
                    className="border rounded-sm p-4 flex flex-col"
                    key={variantSelectionKey}
                  >
                    <div className="pb-4">
                      <span className="font-bold">
                        {capitalize(variantSelectionKey)}
                      </span>
                    </div>
                    <Creatable
                      isMulti
                      isClearable
                      placeholder={`Select or type to create a ${variantSelectionKey}`}
                      options={options}
                      value={variantSelections[variantSelectionKey].map(
                        (value) => ({ label: value, value })
                      )}
                      onChange={(
                        value: OptionsType<{ label: string; value: string }>
                      ) => {
                        setVariantSelections(
                          (prevSelections: { [key: string]: string[] }) => {
                            return {
                              ...prevSelections,
                              [variantSelectionKey]: value.map(
                                (valer) => valer.value
                              ),
                            };
                          }
                        );
                      }}
                      onCreateOption={(inputValue) => {
                        setVariantSelections(
                          (prevSelections: { [key: string]: string[] }) => {
                            return {
                              ...prevSelections,
                              [variantSelectionKey]: [
                                ...(variantSelections[variantSelectionKey] ||
                                  []),
                                inputValue,
                              ],
                            };
                          }
                        );
                      }}
                    />
                    <span className="text-sm mt-4">
                      {`${
                        options.length > 0 ? "Select/Type" : "Type"
                      } to create a new variant option`}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {variantSelectionKeys.length > 0 && (
          <div className="flex flex-col w-full pt-6 justify-between">
            <div
              className={`${
                isSmallDevices ? "flex-col gap-5" : "flex"
              } justify-between pb-6`}
            >
              <h4 className="flex">Edit Variants</h4>
              <Button
                containerClassName={`flex ${
                  isSmallDevices ? "h-5 mb-10 w-6/12" : "h-10"
                } justify-center rounded-md`}
                disabled={deleteVariants.length === 0}
                buttonClassName="h-10 w-full"
                text="Delete Variants"
                size="sm"
                type="red"
                onClick={removeVariants}
              />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col gap-10">
                <div
                  className="flex flex-col w-full gap-10 border border-solid 
      border-primary-color-100 border-opacity-10 p-5 overflow-x-auto"
                >
                  {Object.keys(localVariants.value).map((variant) => {
                    const values = localVariants.value[variant];
                    // filter out the keys that are not part of the variant
                    const variantKeys = Object.keys(values).filter(
                      (value) =>
                        ![
                          "quantity",
                          "original_price",
                          "price",
                          "discount_percentage",
                        ].includes(value)
                    );
                    const isDuplicate = duplicates.includes(variant);
                    // eslint-disable-next-line no-nested-ternary
                    const helperTextForColor = isDuplicate
                      ? "This value is already selected"
                      : "";
                    const helperTextComp = () => {
                      const helperText = helperTextForColor;
                      if (!helperText) {
                        return null;
                      }
                      return (
                        <span
                          className={`${
                            isSmallDevices ? "text-xs" : "text-sm"
                          } text-red-500`}
                        >
                          {helperTextForColor}
                        </span>
                      );
                    };
                    const quantityInfo = getInfoMessage(
                      "quantity",
                      values.quantity,
                      true
                    );
                    return (
                      <div className="flex w-max" key={variant}>
                        <div className="flex mb-5">
                          <CheckBox
                            text=""
                            isSelected={deleteVariants.includes(variant)}
                            isEnabled
                            onChange={({
                              isSelected,
                            }: {
                              isSelected: boolean;
                            }) => {
                              let deletedVariants = [];
                              if (isSelected) {
                                deletedVariants = [...deleteVariants, variant];
                              } else {
                                deletedVariants = deleteVariants.filter(
                                  (delVariant) => delVariant !== variant
                                );
                              }
                              setDeleteVariants(deletedVariants);
                            }}
                          />
                        </div>
                        <div className="flex w-full border-b gap-4 items-start">
                          <div className="flex flex-shrink-0 flex-nowrap gap-5">
                            {variantKeys.map((variantKey) => {
                              const preSelectedValue =
                                values[variantKey as "variant_key"] || "";
                              return (
                                <div
                                  className="flex flex-col gap-1"
                                  key={variantKey}
                                >
                                  <label
                                    aria-required
                                    className="flex font-opensans font-normal text-xs"
                                    htmlFor={variantKey}
                                  >
                                    {capitalize(variantKey)}
                                  </label>
                                  <Dropdown
                                    type="primary"
                                    isClearable
                                    size={isSmallDevices ? "xs" : "sm"}
                                    preSelectedOptions={[
                                      {
                                        label: preSelectedValue,
                                        value: preSelectedValue,
                                      },
                                    ]}
                                    options={
                                      variantSelections[variantKey]?.map(
                                        (item) => ({
                                          label: item,
                                          value: item,
                                        })
                                      ) || []
                                    }
                                    onChange={(selValue) => {
                                      const updatedVariants = {
                                        ...localVariants.value,
                                      };
                                      updatedVariants[variant] = {
                                        ...updatedVariants[variant],
                                        [variantKey]: selValue?.value as string,
                                      };
                                      setLocalVariants({
                                        value: updatedVariants,
                                        isRequired: true,
                                        elementState: "normal",
                                      });
                                    }}
                                  />
                                  {helperTextComp()}
                                </div>
                              );
                            })}
                          </div>
                          <div className="flex flex-shrink-0 flex-nowrap gap-5 items-end md:items-center">
                            <TextInput
                              isRequired
                              inputId="quantity"
                              label={
                                isSmallDevices
                                  ? "Quantity"
                                  : "Quantity Available"
                              }
                              textInputType="text"
                              value={localVariants.value[variant].quantity}
                              containerStyleClass="w-full"
                              overrideInputContainerClass="w-inherit"
                              typeOfState={getTypeofState(
                                "quantity",
                                values.quantity || "0"
                              )}
                              size="sm"
                              placeholder="0"
                              infoMessage={quantityInfo}
                              overrideInfoMessageStyleClass=""
                              onChange={(value) => {
                                const updatedVariants = {
                                  ...localVariants.value,
                                };
                                updatedVariants[variant] = {
                                  ...updatedVariants[variant],
                                  quantity: value as unknown as string,
                                };
                                setLocalVariants({
                                  value: updatedVariants,
                                  isRequired: true,
                                  elementState: "normal",
                                });
                              }}
                            />
                            <TextInput
                              isRequired
                              inputId="original_price"
                              label="Price ($)"
                              textInputType="text"
                              value={`${
                                values.original_price
                                  ? parseFloat(values.original_price)
                                  : ""
                              }`}
                              containerStyleClass="w-full"
                              overrideInputContainerClass="w-inherit"
                              typeOfState={getTypeofState(
                                "original_price",
                                values.original_price || "0.00"
                              )}
                              size="sm"
                              placeholder="0.00"
                              infoMessage={getInfoMessage(
                                "original_price",
                                values.original_price as string,
                                true
                              )}
                              overrideInfoMessageStyleClass=""
                              decimalPrecision={2}
                              onBlur={(value) => {
                                const updatedVariants = {
                                  ...localVariants.value,
                                };
                                const sellingPrice =
                                  value &&
                                  !Number.isNaN(
                                    parseFloat(values.discount_percentage)
                                  )
                                    ? parseFloat(value) -
                                      (parseFloat(value) *
                                        parseFloat(
                                          values.discount_percentage
                                        )) /
                                        100
                                    : "0.00";
                                updatedVariants[variant] = {
                                  ...updatedVariants[variant],
                                  original_price: value,
                                  price: sellingPrice.toString(),
                                };
                                setLocalVariants({
                                  value: updatedVariants,
                                  isRequired: true,
                                  elementState: "normal",
                                });
                              }}
                              onChange={(value) => {
                                const updatedVariants = {
                                  ...localVariants.value,
                                };

                                updatedVariants[variant] = {
                                  ...updatedVariants[variant],
                                  original_price: value,
                                };
                                setLocalVariants({
                                  value: updatedVariants,
                                  isRequired: true,
                                  elementState: "normal",
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4">
              <span
                className="cursor-pointer underline text-left"
                onClick={addVariant}
                onKeyDown={addVariant}
                role="button"
                tabIndex={0}
              >
                +Add New variant
              </span>
            </div>
            <Button
              containerClassName="h-5 mb-10 sm:h-20 self-center rounded-md w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
              buttonClassName="h-10 w-full"
              text="Confirm"
              size="sm"
              type={isSaveDisabled ? "outline" : "red"}
              disabled={isSaveDisabled}
              onClick={() => {
                if (!isSaveDisabled) {
                  const value = Object.keys(localVariants.value).reduce(
                    (roundedValueVariants, variantKey) => {
                      const variantObj = {
                        ...localVariants.value[variantKey],
                      };

                      // eslint-disable-next-line no-param-reassign
                      roundedValueVariants[variantKey] = variantObj;
                      return roundedValueVariants;
                    },
                    {} as { [key: string]: Variant }
                  );
                  updateProductDetails({
                    key: "variants",
                    value,
                  });
                  setIsModalOpen(false);
                }
              }}
            />
            <div className="flex">
              {hasIrregularVariants() && (
                <span className="h-10 text-neutral-color-4 font-opensans font-normal text-sm leading-5">
                  Variant Selection should be uniform.
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Variants;
