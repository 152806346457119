/* eslint-disable no-nested-ternary */
import axios, { AxiosError, AxiosResponse } from "axios";

// utils
import { getItem, setItem } from "./utils";
import { getCookie, setCookie } from "./utils/cookieStorage";

export const processErrorCatch = (error: AxiosError): Promise<Error> => {
  const rejectionReason = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.error.message
      : error.message
    : (error as Error)?.toString() || error;
  const cause = axios.isAxiosError(error)
    ? error.response
      ? error.response.data.error.name
      : error.message
    : error;
  throw new Error(rejectionReason, { cause });
};

export const lonimaAPI = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const setUserCoordinates = (response: AxiosResponse) => {
  const coordinateHeaders = [
    "cf-iplatitude",
    "cf-iplongitude",
    "cf-ippostalcode",
  ];
  const coordinates: Record<string, unknown> = {};
  coordinateHeaders.forEach((prop) => {
    const key = prop.substring(5);
    if (response?.headers[prop]) {
      coordinates[key] = response?.headers[prop];
    }
  });
  if (Object.keys(coordinates).length > 0) {
    setCookie("coordinates", JSON.stringify(coordinates), 14);
  }
};

lonimaAPI.interceptors.request.use(
  (config) => {
    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
      },
    };
    if (getItem("token")) {
      newConfig.headers.Authorization = `Bearer ${getItem("token")}`;
    } else if (getCookie("session-id")) {
      newConfig.headers.sessionid = getCookie("session-id");
    }
    return newConfig;
  },
  (error) => {
    throw error;
  }
);

lonimaAPI.interceptors.response.use(
  (response) => {
    // Triggered only when the http status code is 2xx
    // a new Authorization token is sent for any API call
    // when the current token is about to expire in less than 5 hours
    if (response?.headers?.authorization) {
      setItem("token", `${response.headers.authorization}`);
    }
    setUserCoordinates(response);
    return response;
  },
  (error) => {
    if ([401].includes(error?.response?.status)) {
      setItem("token", "");
      window.location.href = `${window.location.origin}/logout`;
    }
    throw error;
  }
);

export default lonimaAPI;
