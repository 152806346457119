/* eslint-disable @typescript-eslint/naming-convention */
// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../axios";
import { getUseQueryParams } from "../../utils";

// ts
import { Result } from "../../types";
import { UserDetails, Review } from "./types";

const uploadProfileAvatar = async (avatar: File): Promise<Result> => {
  try {
    const formData = new FormData();
    formData.append("avatar", avatar);
    const {
      data: { imagePath },
    } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/uploadprofilepic`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    return await Promise.resolve({ data: { value: imagePath } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateUserDetails = async (
  userDetails: UserDetails
): Promise<Result> => {
  try {
    const { data } = await axios.put("/user", userDetails);
    const {
      first_name,
      last_name,
      email,
      address,
      shops,
      avatar,
      email_verified,
      dob = "",
      pronouns,
      about,
      preferences,
      isAdmin,
    } = data;
    return await Promise.resolve({
      data: {
        value: {
          first_name,
          last_name,
          email,
          address,
          shops,
          email_verified,
          avatar,
          dob,
          pronouns,
          about,
          preferences,
          isAdmin,
        },
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchAllOrders = async (payload: {
  queryKey: { pageNumber: number; pageSize: number }[];
}): Promise<Result> => {
  try {
    const { pageNumber, pageSize } = getUseQueryParams(payload);
    const { data } = await axios.get(
      `/order/list?pageNo=${pageNumber}&pageSize=${pageSize}`
    );
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const createProductReview = async (review: Review): Promise<Result> => {
  try {
    const { data } = await axios.post("/product/reviews", review);
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const getOrderReviews = async (payload: {
  queryKey: { id: string }[];
}): Promise<Result> => {
  try {
    const { id } = getUseQueryParams(payload);
    const { data } = await axios.get(`/order/id/${id}/reviews`);
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export default uploadProfileAvatar;
