/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";

// components
import TextInput from "../TextInput";

import { searchIcon } from "../../assets";

// ts
import { TypeOfState } from "../TextInput/TextInput.utils";

export type OnSearchChangeFunction = (input: string) => void;
export interface Props {
  placeHolder?: string;
  searchedInput?: string;
  focusUniqID?: string;
  typeOfState?: TypeOfState;
  isSetFocus?: boolean;
  onSearch: OnSearchChangeFunction;
  onFocus?: () => void;
  onClickSearchBox?: () => void;
  onChange?: OnSearchChangeFunction;
}

const HeaderSearch = ({
  placeHolder = "",
  searchedInput = "",
  focusUniqID = "",
  isSetFocus = false,
  typeOfState = "normal",
  onSearch = () => {},
  onFocus = () => {},
  onChange = () => {},
  onClickSearchBox = () => {},
}: Props): JSX.Element => {
  const [input, setInput] = useState("");

  useEffect(() => {
    if (searchedInput) {
      setInput(searchedInput);
    }
  }, [searchedInput]);

  const onClick = () => {
    onSearch(input);
  };

  const search = (
    <div
      className={`cursor-pointer pr-3 ${
        input ? "pointer-events-auto" : "pointer-events-none"
      }`}
      onClick={onClick}
    >
      <img
        src={searchIcon}
        alt="Search"
        className={input ? "filter-active-icon" : "filter-inactive-icon"}
      />
    </div>
  );

  return (
    <TextInput
      isSetFocus={isSetFocus}
      typeOfState={typeOfState}
      onFocus={onFocus}
      inputId="search"
      value={input}
      isRequired={false}
      onChange={(value: string) => {
        setInput(value);
        onChange(value);
      }}
      onClick={onClickSearchBox}
      focusUniqID={focusUniqID}
      containerStyleClass="w-full justify-center"
      placeholder={placeHolder || ""}
      renderExtraComp={() => search}
    />
  );
};

export default HeaderSearch;
