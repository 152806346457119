/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
// ts
import { AxiosError } from "axios";
import { Result } from "../types";

// utils
import axios, { processErrorCatch } from "../axios";
import { setItem, getUseQueryParams } from "../utils";
import { CategoriesPageTypes } from "../screens/Categories/types";

export const fetchUserProfile = async (): Promise<Result> => {
  try {
    const { data } = await axios.get("/user");
    const {
      _id,
      first_name,
      last_name,
      email,
      address,
      shops,
      email_verified = false,
      default_address = "",
      avatar,
      dob = "",
      pronouns,
      about,
      preferences = [],
      isAdmin,
      created_at,
      payment = {},
    } = data;
    return await Promise.resolve({
      data: {
        value: {
          _id,
          first_name,
          last_name,
          email,
          address,
          shops,
          email_verified,
          default_address_id: default_address,
          avatar,
          dob,
          pronouns,
          payment,
          about,
          preferences,
          isAdmin,
          created_at,
        },
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const logout = async (): Promise<Result> => {
  setItem("token", "");
  window.location.href = `${window.location.origin}/logout`;
  return Promise.resolve({ message: "Successfully logged out" });
};

export const fetchSearchTypeAheadResults = async (
  search: string
): Promise<Result> => {
  try {
    const { data } = await axios.post(`/product/typeahead`, { search });
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchProducts = async ({
  text_search,
  filters,
  limit,
  page,
}: CategoriesPageTypes): Promise<Result> => {
  try {
    const { data } = await axios.post(
      `/product/search?pageNo=${page}&pageSize=${limit}`,
      {
        filters: { text_search, ...filters },
      }
    );
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchCart = async (): Promise<Result> => {
  try {
    const { data } = await axios.get(`/cart`);
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchShopDetails = async (payload: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: any;
}): Promise<Result> => {
  try {
    const keyValues = getUseQueryParams(payload);
    const resp = await axios.get(`/shop/id/${keyValues?.id}`);
    return await new Promise((resolve) =>
      resolve({ data: { value: resp.data } })
    );
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
