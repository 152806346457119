/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { closeLightOutlineIcon } from "../../assets";

export default function Modal({
  overrideModalStyle,
  overrideModalContainerStyle,
  children,
  alwaysOpen,
  isForceClose,
  onClick,
  onClose = () => {},
  hideCloseIcon = false,
}: {
  children: JSX.Element;
  alwaysOpen?: boolean;
  isForceClose?: boolean;
  overrideModalStyle?: string;
  overrideModalContainerStyle?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClose?: () => void;
  hideCloseIcon?: boolean;
}): JSX.Element {
  const [baseStyle, setBaseStyle] = useState(`fixed inset-0 h-auto block`);

  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isForceClose && node) {
      node.current?.remove();
      setBaseStyle("");
      onClose();
    }
    const handleHotKeys = (e: MouseEvent) => {
      if (!alwaysOpen && !node?.current?.contains(e.target as HTMLElement)) {
        node.current?.remove();
        setBaseStyle("");
        onClose();
      }
    };
    document.addEventListener("mousedown", handleHotKeys);
    return () => {
      document.removeEventListener("mousedown", handleHotKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, isForceClose]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={onClick}>
      <div
        className={`${baseStyle} block w-full opacity-30 bg-primary-color-100 z-1001`}
      />
      <div
        className={`${baseStyle} w-full flex justify-center z-1001 ${overrideModalContainerStyle}`}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          ref={node}
          style={{ maxHeight: "calc(100vh - 4rem)" }}
          className={`relative flex flex-col bg-white rounded-md overflow-y-auto ${overrideModalStyle}`}
        >
          {!hideCloseIcon && (
            <div className="flex justify-end">
              <button
                className="mt-5 mr-5"
                onClick={() => {
                  node.current?.remove();
                  setBaseStyle("");
                  onClose();
                }}
                type="button"
              >
                <img src={closeLightOutlineIcon} alt="close modal" />
              </button>
            </div>
          )}
          {children}
        </motion.div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  alwaysOpen: false,
  isForceClose: false,
  overrideModalStyle: "",
  overrideModalContainerStyle: "",
  onClick: () => {},
  onClose: () => {},
  hideCloseIcon: false,
};
