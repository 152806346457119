/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from "react";

// packages
import { useQuery, useMutation } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

// actions
import { placeOrder } from "../actions";
import { fetchCart } from "../../../store/actions";
import { GET_CART } from "../../../store/actions.types";

// hook
import { useAuth } from "../../../hooks";

// utils
import { getItem, deleteItem } from "../../../utils";
import { AddressPayload } from "../../../types";

const ORDER_PROCESSING = "Hang tight...we are placing your order";

const Order = (): JSX.Element => {
  const newShippingAddress = JSON.parse(
    getItem("newShippingAddress") || "{}"
  ) as AddressPayload;
  const [isLoading, setIsLoading] = useState(true);
  const [guestUserEmail, setGuestUserEmail] = useState(
    newShippingAddress?.email
  );
  const [guestUserName, setGuestUserName] = useState(
    newShippingAddress?.full_name
  );

  const urlParams = useParams();
  const { id: checkoutSessionId } = urlParams as { id: string };

  const { refetch: refetchCart } = useQuery(GET_CART, fetchCart, {
    enabled: false,
  });

  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { shippingAddressID } = (location as unknown as Location)?.state;

  const toggleLoading = () => {
    setIsLoading((s) => !s);
  };

  // clean up in local storage
  useEffect(() => {
    if (newShippingAddress) {
      setGuestUserEmail(newShippingAddress.email);
      setGuestUserName(newShippingAddress.full_name);
    }
    return () => {
      deleteItem("guest_billing_full_name");
      deleteItem("newBillingAddress");
      deleteItem("newShippingAddress");
      deleteItem("existingShippingAddressID");
      deleteItem("existingBillingAddressID");
      deleteItem("paymentIntentURL");
    };
  }, [newShippingAddress]);

  const { mutate } = useMutation(placeOrder, {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onSuccess: ({ data }) => {
      toggleLoading();
      const id = data?.value?.id || "";
      setTimeout(() => {
        refetchCart();
        history.push({
          pathname: "/order",
          state: {
            orderDetail: {
              id,
              status: "success",
              message: "Processed your order successfully",
            },
            guestUserEmail,
          },
        });
      }, 500);
    },
    onError: () => {
      toggleLoading();
      history.push({
        pathname: "/order",
        state: {
          orderDetail: {
            status: "failure",
            message: "Failed to process your order",
          },
          guestUserEmail,
        },
      });
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        mutate({
          checkout_session_id: checkoutSessionId,
          ship_to: shippingAddressID,
        });
      }, 2000);
    } else {
      // for guest users
      setTimeout(() => {
        mutate({
          checkout_session_id: checkoutSessionId,
          email: guestUserEmail,
          fullName: guestUserName,
          ship_to: newShippingAddress,
        });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = useMemo(() => {
    if (isLoading) {
      return ORDER_PROCESSING;
    }
    return "";
  }, [isLoading]);

  return (
    <div className="flex flex-col p-6 w-full h-full bg-white items-center justify-center">
      <h2 className="font-poppins font-semibold text-3xl text-primary-color-100">
        {status}
      </h2>
      <div>
        <ClipLoader color="#000000" loading={isLoading} size={100} />
      </div>
    </div>
  );
};
export default Order;
