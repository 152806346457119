import React, { useMemo, useState, useEffect, useContext } from "react";

// packages
import { useQuery, useMutation } from "react-query";

// packages
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// components
import Product from "../../components/Product";
import { Props as ProductProps } from "../../components/Product/Product";

// utils
import { useAuth, useQueryParams } from "../../hooks";

// actions
import { addProductToCart, fetchProductDetails } from "./actions";
import { fetchProductReviews } from "../../components/Product/Reviews/Reviews.actions";
import { FETCH_ALL_FAVORITES, GET_CART } from "../../store/actions.types";
import { fetchCart, fetchShopDetails } from "../../store/actions";
import {
  addProductToDefaultFavorites,
  getAllUserFavorites,
  removeProductFromFavorites,
} from "../../containers/Favorites/actions";
import { setCookie } from "../../utils/cookieStorage";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../../store";

const ProductContainer = (): JSX.Element => {
  const queryParams = useQueryParams();
  const [productDetails, setProductDetails] = useState<{
    _id?: string;
    image_url?: string;
    product_images?: string[];
    shop_id?: string;
    name?: string;
    description?: string;
    price?: number;
    isHotDeal?: boolean;
    isFreeShipping?: boolean;
    product_rating?: {
      product_rating?: number;
      no_of_reviews: number;
      rating?: number;
    };
    variant_selections?: Array<{
      values: Array<string>;
      _id: string;
      property: string;
    }>;
    variants?: Array<{
      _id: string;
      price: number;
      original_price: number;
      quantity: number;
      variantType: string;
    }>;
    max_price?: number;
    min_price?: number;
    dimensions?: {
      length: number;
      width: number;
      height: number;
      units: string;
    };
    weight?: {
      units: string;
      value: number;
    };
  }>({});
  const [shopDetails, setShopDetails] = useState({});
  const [reviewsCount, setReviewsCount] = useState(0);
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
  const { isAuthenticated } = useAuth();

  const history = useHistory();

  const id = useMemo(() => queryParams.get("id") || "", [queryParams]);

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { email_verified },
      },
    },
  } = useContext(Context);

  useEffect(() => {
    if (!id) {
      history.push("/");
    }
  }, [history, id]);

  useQuery(
    [{ productId: id, pageNumber: 1, pageSize: 20 }],
    fetchProductReviews,
    {
      enabled: !!id,
      onSuccess: ({ data }) => {
        setReviewsCount(
          data?.value?.productReviews?.resultInfos?.totalCount || 0
        );
      },
      onError: () => {
        // eslint-disable-next-line no-console
        console.log("failed to fetch product reviews");
      },
    }
  );

  const { data: productDetailsResp } = useQuery([{ id }], fetchProductDetails, {
    enabled: Object.keys(productDetails)?.length === 0,
  });
  const { data: shopDetailsResp } = useQuery(
    [{ id: productDetails?.shop_id }],
    fetchShopDetails,
    {
      enabled: !!productDetails?._id && Object.keys(shopDetails)?.length === 0,
    }
  );

  useEffect(() => {
    setProductDetails(productDetailsResp?.data?.value || {});
  }, [productDetailsResp]);

  useEffect(() => {
    setShopDetails(shopDetailsResp?.data?.value || {});
  }, [shopDetailsResp]);

  const { refetch: refetchCart } = useQuery(GET_CART, fetchCart, {
    enabled: false,
  });

  const { mutate: addToCartMutation, isLoading: addToCartLoading } =
    useMutation(addProductToCart, {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSuccess: ({ data }) => {
        refetchCart();
        if (!isAuthenticated) {
          const sessionId = data?.headers.sessionid;
          setCookie("session-id", sessionId, 30);
        }
        setShowAddedToCartModal(true);
      },
      onError: (e) => {
        toast.error(e || "Failed to add to cart", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    });

  const { refetch: refetchAllFavorites, data: allFavorites } = useQuery(
    FETCH_ALL_FAVORITES,
    getAllUserFavorites,
    {
      enabled: isAuthenticated && !!email_verified,
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: addToDefaultFavorites, isLoading: addToFavoritesLoading } =
    useMutation(addProductToDefaultFavorites, {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSuccess: () => {
        // show success message for favorites
        refetchAllFavorites();
      },
      onError: () => {},
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: removeFromFavorites, isLoading: removeFromFavoritesLoading } =
    useMutation(removeProductFromFavorites, {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSuccess: () => {
        // show success message for favorites
        refetchAllFavorites();
      },
      onError: () => {},
    });

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    main_product_image_url,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    other_product_image_urls,
    title,
    price,
    rating,
    isHotDeal,
    isFreeShipping,
    variantSelections,
    variants,
    maxPrice,
    minPrice,
    shopId,
    itemDetails,
    dimensions,
    weight,
  } = useMemo(() => {
    const productMainImageUrl = productDetails?.image_url || "";
    const result = {} as ProductProps;
    result.main_product_image_url = productMainImageUrl;
    result.other_product_image_urls =
      (productDetails?.product_images as string[])?.length > 0
        ? (productDetails.product_images as string[]).filter(
            (imgUrl) => imgUrl !== productMainImageUrl
          )
        : [];
    result.title = productDetails?.name || "";
    result.price = productDetails?.price || 0;
    result.isHotDeal = productDetails?.isHotDeal || false;
    result.isFreeShipping = productDetails?.isFreeShipping || true;
    result.rating =
      productDetails?.product_rating?.rating ||
      productDetails?.product_rating?.product_rating;
    result.variantSelections = productDetails?.variant_selections;
    result.variants = productDetails?.variants;
    result.maxPrice = productDetails?.max_price;
    result.minPrice = productDetails?.min_price;
    result.shopId = productDetails?.shop_id;
    result.itemDetails = productDetails?.description;
    result.dimensions = productDetails?.dimensions;
    result.weight = productDetails?.weight;
    return result;
  }, [productDetails]) as ProductProps;

  const handleAddToCart = ({ variantId }: { variantId?: string }) => {
    addToCartMutation({
      product_id: id,
      quantity: 1,
      variant_id: variantId,
    });
  };

  const handleAddToFavorites = (isFavorite: boolean) => {
    if (isFavorite) {
      addToDefaultFavorites({
        item_id: id,
        type: "products",
      });
    } else {
      removeFromFavorites({
        item_id: id,
        type: "products",
      });
    }
  };

  const allFavoritesArray = allFavorites?.data?.value;

  const isFavoriteProduct = allFavoritesArray
    ? allFavoritesArray[0]?.items?.filter(
        // eslint-disable-next-line no-underscore-dangle
        (favorite: string) => favorite === id
      ).length > 0
    : false;

  return (
    <Product
      itemDetails={itemDetails}
      addToCartLoading={addToCartLoading}
      handleAddToCart={handleAddToCart}
      showAddToCartModal={showAddedToCartModal}
      main_product_image_url={main_product_image_url}
      other_product_image_urls={other_product_image_urls}
      title={title}
      price={price}
      maxPrice={maxPrice}
      minPrice={minPrice}
      dimensions={dimensions}
      weight={weight}
      rating={rating}
      shopId={shopId}
      reviewsCount={reviewsCount}
      isFreeShipping={isFreeShipping}
      isHotDeal={isHotDeal}
      variantSelections={variantSelections}
      variants={variants}
      handleAddToFavorites={handleAddToFavorites}
      isFavoriteProduct={isFavoriteProduct}
    />
  );
};
export default ProductContainer;
