/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React, {
  useState,
  useEffect,
  useContext,
  MutableRefObject,
  useMemo,
} from "react";

// packages
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

// components
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";

// actions
import { createShop } from "../actions";

// icons
import { arrowLeftIcon, closeRed, tickGreen } from "../../../assets";

// context
import SellOnLonimaContext from "../SellOnLonima.context";

// ts
import { AddressPayload } from "../../../types";

// utils
import {
  getGoogleMapsPlacePredictions,
  getPlaceDetails,
} from "./location.utils";
import { SHOP_CREATION_ERROR } from "../constants";
import { getItem } from "../../../utils";

export interface Address {
  description: string;
  place_id: string;
}

export const Location = ({
  emailVerified,
}: {
  emailVerified: boolean;
}): JSX.Element => {
  const [availabilityState, setAvailabilityState] = useState({
    type: "",
    value: "",
  });

  const [addresses, setAddresses] = useState<Address[]>([]);
  const [searchRef, setSearchRef] =
    useState<MutableRefObject<HTMLInputElement | null>>();
  const { location, setLocation, name, isNameAvailable } =
    useContext(SellOnLonimaContext);

  const shopName = getItem("temp_shop_name") || "";

  const history = useHistory();

  useEffect(() => {
    if (!(name || isNameAvailable || shopName)) {
      history.push("/sell-on-lonima/name");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, isNameAvailable]);

  const verifyShopLocation = (address) => {
    const addressTokens = address.split(",").reverse();
    if (addressTokens.length === 0) {
      return ["country"];
    }
    if (addressTokens.length === 1) {
      return ["state", "zipCode", "street"];
    }
    if (addressTokens.length === 2) {
      const [state, zip] = addressTokens[1]?.trim().split(" ");
      if (!state && !zip) {
        return ["state", "zipCode", "street"];
      }
      if (!zip) {
        return ["zipCode", "street"];
      }
    }
    if (addressTokens.length === 3) {
      const [state, zip] = addressTokens[1]?.trim().split(" ");
      if (!state && !zip) {
        return ["state", "zipCode", "street"];
      }
      if (!zip) {
        return ["zipCode", "street"];
      }
      return ["street"];
    }
    return [];
  };

  const onSelectAddress = async (placeId: string) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { formatted_address } = (await getPlaceDetails(
      searchRef?.current as HTMLDivElement,
      placeId
    )) as { formatted_address: string };
    const missingAddressFields = verifyShopLocation(formatted_address);
    if (missingAddressFields.length > 0) {
      if (missingAddressFields.includes("country")) {
        setAvailabilityState({
          type: "error",
          value: "Please enter a valid shop address",
        });
      } else {
        setAvailabilityState({
          type: "error",
          value: `Please enter a valid shop address: missing ${missingAddressFields.join(
            ","
          )}`,
        });
      }
    }
    setLocation(formatted_address);
    setAddresses([]);
  };

  const { mutate: createShopMutation } = useMutation(createShop, {
    onSuccess: ({ data }) => {
      // history.push(`/shop/${data?.value?._id}`);
      history.push(`/shop/success?id=${data?.value?._id}`);
    },
    onError: (err: Error) => {
      setAvailabilityState({
        type: "error",
        value:
          typeof err?.message === "string" && err?.message
            ? err?.message
            : SHOP_CREATION_ERROR,
      });
    },
  });

  const isEnabled = useMemo(
    () => verifyShopLocation(location).length === 0 && emailVerified,
    [location, emailVerified]
  );

  const setShopLocation = () => {
    if (isEnabled) {
      const address = {
        address_ln1: "",
        address_ln2: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
      } as AddressPayload;
      const addressTokens = location.split(",").reverse();
      address.country = addressTokens[0]?.trim();
      const [state, zipCode] = addressTokens[1].split(" ").filter((t) => t);
      address.state = state?.trim();
      address.zip_code = zipCode?.trim();
      address.city = addressTokens[2]?.trim();
      if (addressTokens.length === 5) {
        address.address_ln2 = addressTokens[3]?.trim();
        address.address_ln1 = addressTokens[4]?.trim();
      } else {
        address.address_ln1 = addressTokens[3]?.trim();
      }
      createShopMutation({ address, name: shopName as string });
    }
  };

  const updateLocation = async (val: string) => {
    setLocation(val);
    if (val.length > 4) {
      const results = (await getGoogleMapsPlacePredictions(
        location
      )) as Address[];
      setAddresses(
        results.length
          ? results.map(({ description, place_id }) => ({
              description,
              place_id,
            }))
          : []
      );
    } else {
      setAddresses([]);
    }
    setAvailabilityState({
      type: "",
      value: "",
    });
  };

  const { type, value } = availabilityState;

  const getIcon = () => {
    if (value) {
      switch (type) {
        case "error":
          return (
            <div className="flex justify-center text-sm font-normal text-contrast-color-1-100 font-opensans">
              {value}
            </div>
          );
        case "warning":
        case "success":
          return (
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                src={type === "warning" ? closeRed : tickGreen}
                alt={type === "warning" ? "closeRed" : "tickGreen"}
                style={{ width: 17.5, height: 17.5 }}
              />
              <div className="flex justify-center text-sm font-normal text-primary-color-100 font-opensans">
                {value}
              </div>
            </div>
          );
        default:
          return <div />;
      }
    }
    return <div />;
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <a
        href={`${window.location.origin}/sell-on-lonima/name`}
        className="flex w-full pl-4 mt-3 mb-6 font-bold text-md"
      >
        <img src={arrowLeftIcon} className="mr-2" alt="previous" />
        Edit Shop Name ({shopName})
      </a>
      <h1 className="flex text-3xl font-semibold font-playfair">
        Set your Address
      </h1>
      {/* <div className="flex w-8/12 text-sm font-normal font-opensans">
        Your shop name will appear in your shop and next to each of your
        listings throughout Lonima. After you open your shop, you can change
        your name once.
      </div> */}
      <div className="flex flex-col items-center w-full md:justify-center">
        <div className="flex flex-col items-start justify-center w-full gap-5 p-4 md:w-6/12 md:gap-0 md:flex-row ">
          <div className="flex flex-col w-full">
            <TextInput
              setRef={setSearchRef}
              inputId="location"
              label=""
              textInputType="text"
              value={location}
              containerStyleClass="w-full"
              typeOfState={type === "error" ? "error" : "normal"}
              size="sm"
              placeholder="Add your address..."
              infoMessage=""
              overrideInfoMessageStyleClass=""
              onChange={updateLocation}
            />
            {addresses.length > 0 && (
              <div className="flex flex-col w-full border border-opacity-25 h-7/12 border-primary-color-100">
                {addresses.map(({ description, place_id }) => {
                  return (
                    <div
                      className="flex p-2 border-b cursor-pointer border-primary-color-100"
                      onClick={() => onSelectAddress(place_id)}
                      key={place_id}
                    >
                      {description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col h-10">{getIcon()}</div>
      </div>
      <div className="flex">
        <Button
          containerClassName="flex w-full"
          text="Complete"
          size="sm"
          type={isEnabled ? "primary" : "outline"}
          disabled={!isEnabled}
          onClick={setShopLocation}
        />
      </div>
    </div>
  );
};
export default Location;
