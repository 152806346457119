import React, { useContext, useEffect } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import { Context } from "../../store";
import "animate.css/animate.min.css";

export default function ToastBox(): JSX.Element {
  const { state, dispatch } = useContext(Context);

  const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut",
  });

  useEffect(() => {
    if (state.global_message.message) {
      toast.dark(state.global_message.message, {
        transition: bounce,
      });
    }
    return () => {
      dispatch({ type: "SET_GLOBAL_MESSAGE", payload: "" });
    };
  }, [state.global_message.message]); //eslint-disable-line

  return (
    <ToastContainer autoClose={3000} hideProgressBar position="bottom-center" />
  );
}
