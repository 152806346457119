/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

// ts
export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: { display: any; value: any; icon?: any };
  isSelected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (value: any) => void;
}

const Tab = ({
  option: { display, value, icon },
  onClick,
  isSelected,
}: Props): JSX.Element => {
  return (
    <div
      className={`flex bg-transparent ${
        isSelected ? "border-b-4" : "border-b-4 border-transparent"
      } cursor-pointer`}
      onClick={() => onClick(value)}
    >
      <div
        className={`flex font-poppins leading-6 p-3 text-base capitalize items-center ${
          isSelected ? "font-bold" : "text-primary-color-100"
        } mx-1 my-0`}
      >
        {icon && (
          <img
            className="mr-2"
            height={25}
            width={25}
            src={icon}
            alt="tab-icon"
          />
        )}
        {display}
      </div>
    </div>
  );
};

Tab.defaultProps = {
  option: { display: "", value: "" },
  isSelected: false,
  onClick: () => {},
};

export default Tab;
