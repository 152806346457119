export const TOTAL_RATING_STARS = 5;

export const highlights = {
  hotDeal: {
    color: "FALL_BACK_IMAGE#000",
    backgroundColor:
      "linear-gradient(90deg, rgba(251, 188, 5, 0.5) 0%, rgba(224, 122, 95, 0.5) 100%)",
  },
  freeShipping: {
    color: "#000",
    backgroundColor:
      "linear-gradient(90deg, rgba(52, 168, 83, 0.5) 0%, rgba(161, 224, 122, 0.5) 100%)",
  },
};

export const extraDetailsStyle = `flex justify-between font-poppins font-semibold text-lg 
border-b-2 border-primary-color-100 border-opacity-25 mb-5 w-full cursor-pointer`;

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const IMAGE_CONTAINER_WIDTH = "777px";
export const IMAGE_CONTAINER_HEIGHT = "453px";
export const IMAGE_THUMBNAIL_WIDTH = "175px";
export const IMAGE_THUMBNAIL_HEIGHT = "102px";
export const IMAGE_SIZE = 100; // px
