/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { ClipLoader } from "react-spinners";
import { InputProps } from "./types";
import { arrowRightIcon, arrowLeftIcon } from "../../assets";

const Pagination = ({
  isContentLoading,
  totalPages,
  handlePageClick,
  pageNumber,
  renderContent = <div />,
}: InputProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 750);
    return () => {
      clearTimeout(timer);
    };
  }, [pageNumber]);

  const handleClick = ({ selected }: { selected: number }) => {
    handlePageClick({ selected });
  };

  const btnStyle =
    "w-8 bg-neutral-color-1 cursor-pointer hover:bg-primary-color-30 rounded-md flex items-center justify-center";
  const linkStyle = "no-underline hover:no-underline";
  const pageLinkStyle = `${linkStyle} p-1`;
  const arrowStyle = "object-contain self-center w-8 h-8 p-2";
  const isMobile = window.innerWidth < 768;
  const getPageRange = () => {
    if (isMobile) {
      if (totalPages > 1) {
        return 1;
      }
      return totalPages;
    }
    if (totalPages > 5) {
      return 6;
    }
    return totalPages;
  };

  if (isContentLoading || isLoading) {
    return <ClipLoader color="#E07A5F" size={30} loading />;
  }
  return (
    <div className="w-full sm:w-auto">
      <div>{renderContent}</div>
      {totalPages > 0 ? (
        <div className="flex justify-center mt-10">
          <ReactPaginate
            containerClassName="flex gap-2 md:gap-4"
            activeClassName="bg-primary-color-50"
            pageClassName={btnStyle}
            pageLinkClassName={pageLinkStyle}
            breakLabel="..."
            breakClassName={btnStyle}
            breakLinkClassName={linkStyle}
            nextLabel={
              <img src={arrowRightIcon} className={arrowStyle} alt="next" />
            }
            nextClassName={btnStyle}
            nextLinkClassName={linkStyle}
            previousLabel={
              <img src={arrowLeftIcon} className={arrowStyle} alt="previous" />
            }
            previousClassName={btnStyle}
            previousLinkClassName={linkStyle}
            onPageChange={handleClick}
            pageRangeDisplayed={getPageRange()}
            marginPagesDisplayed={1}
            pageCount={totalPages}
            forcePage={pageNumber}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Pagination;
