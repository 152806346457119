import React, { useEffect, useContext, useState, useRef, useMemo } from "react";

// packages
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import debounce from "lodash/debounce";
import { v4 as uuid } from "uuid";

// components
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import Search from "../Search";
import CheckoutProgress from "./CheckoutProgress";
import SearchResults from "./SearchResults";
import User from "./User";
import Categories from "./Categories";

// store
import { Context } from "../../store";
import {
  fetchUserProfile,
  fetchSearchTypeAheadResults,
  fetchCart,
} from "../../store/actions";
import { SET_USER, GET_USER, GET_CART } from "../../store/actions.types";

// hooks
import { useAuth } from "../../hooks";
import { getItem, setItem } from "../../utils";

// assets
import { cartIcon, hamburgerIcon, logoIcon, lockIcon } from "../../assets";

// ts
import { SearchValue } from "../../store/types";
import { TypeOfState } from "../TextInput/TextInput.utils";
// import Location from "../Location";
// import { setCookie } from "../../utils/cookieStorage";
import { ICategories } from "./Categories/types";
import { ICartResponse } from "../CartItem/types";
import Button from "../Button";
import ResendEmailBanner from "../../containers/ResendEmailBanner";

const MIN_SEARCH_CHARACTERS = 3;
const DEBOUNCE_IN_MS = 500; // ms

export interface Props {
  placeHolder?: string;
  // location: {
  //   city: string;
  //   state: string;
  //   zipCode: string;
  // };
  categories: ICategories[];
  handleSignIn: () => void;
  onFocus?: () => void;
}

const tokenValidationMessages = ["jwt expired", "invalid token"];

const Header = ({
  placeHolder,
  // location,
  categories,
  handleSignIn,
}: Props): JSX.Element => {
  const searchRef = useRef<HTMLDivElement>(null);
  const [hideCategories, setHideCategories] = useState(true);
  const [focusUniqID, setFocusUniqID] = useState(uuid());
  const [isSearchFocus, setIsSearchFocus] = useState(false);
  const [searchValue, setSearchValue] = useState<SearchValue>({
    value: "",
    value_type: "TypeAhead",
  });
  const [searchSelectedValue, setSearchSelectedValue] = useState("");
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [showDefaultSearches, setShowDefaultSearches] = useState(false);
  // const [showLocationModal, setShowLocationModal] = useState(false);
  const [showSearchResultsModal, setShowSearchResultsModal] = useState(false);
  // const [geoLocation, setGeoLocation] = useState({
  //   city: location.city,
  //   state: location.state,
  //   zipCode: location.zipCode,
  // });
  const [searchResultsWidth, setSearchResultsWidth] = useState(-1);
  const [searchTextBoxState, setSearchTextBoxState] =
    useState<TypeOfState>("normal");

  const [cartDataLength, setCartDataLength] = useState(0);

  const { isAuthenticated } = useAuth();

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { email, first_name, avatar, shops = [] },
      },
    },
    dispatch,
  } = useContext(Context);
  const history = useHistory();

  const loc = useLocation();

  const shopId = shops.length ? shops[shops.length - 1].shop_id : "";

  const isCheckoutPage = useMemo(
    () => loc.pathname.includes("/checkout"),
    [loc]
  );

  const { data: cartData, refetch: refetchCart } = useQuery(
    GET_CART,
    fetchCart,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    refetchCart();
  }, [refetchCart, loc.pathname]);

  const isVendorPage = useMemo(
    () =>
      loc.pathname.includes("/sell-on-lonima/name") ||
      loc.pathname.includes("/sell-on-lonima/location"),
    [loc]
  );

  const hideHeader = useMemo(
    () => isCheckoutPage || isVendorPage,
    [isCheckoutPage, isVendorPage]
  );

  const { data, isLoading } = useQuery(GET_USER, fetchUserProfile, {
    enabled: !email && isAuthenticated,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      refetchCart();
    },
  });

  useEffect(() => {
    const cartResponse = cartData?.data?.value as ICartResponse;

    setCartDataLength(cartResponse?.items_count || 0);
  }, [cartData?.data?.value]);

  // fetching typeahead results
  const {
    mutate: fetchSearchTypeAheadResultsMutation,
    isLoading: isTypeAheadResultsLoading,
  } = useMutation(fetchSearchTypeAheadResults, {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onSuccess: ({ data }) => {
      const typeAheadSearchResults = data?.value as {
        searchResultCount: number;
      };
      setSearchResultsCount(
        typeAheadSearchResults?.searchResultCount || (0 as number)
      );
      setIsSearchFocus(true);
      setFocusUniqID(uuid());
    },
    onError: () => {
      setSearchValue({ value: "", value_type: "TypeAhead" });
      setSearchResultsCount(0 as number);
    },
  });

  const IsNoResultsFound = useMemo(() => {
    return (
      searchValue?.value?.trim()?.length > MIN_SEARCH_CHARACTERS &&
      searchResultsCount === 0
    );
  }, [searchValue, searchResultsCount]);

  // calculating the width of the search results container
  const computeSearchBoxWidth = () => {
    if (searchRef?.current) {
      setSearchResultsWidth(searchRef.current.offsetWidth - 18);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", computeSearchBoxWidth);
    refetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (!searchRef?.current?.contains(e.target as HTMLElement)) {
        setShowSearchResultsModal(false);
        setSearchTextBoxState("normal");
      }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
      if (searchRef?.current?.contains(e.target as HTMLElement)) {
        // on press Enter key
        if (e?.key === "Enter") {
          if (
            searchValue?.value &&
            searchValue?.value_type === "TypeAhead" &&
            !IsNoResultsFound
          ) {
            setFocusUniqID(uuid());
            setSearchSelectedValue(searchValue?.value);
            history.push(
              `/search?product_search=${encodeURIComponent(
                searchValue.value
              )}&product_count=${searchResultsCount}`
            );
            setShowSearchResultsModal(false);
          }
        } else {
          setSearchTextBoxState("normal");
          setShowSearchResultsModal(true);
        }
      } else {
        setSearchTextBoxState("normal");
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRef, searchValue]);

  // triggering this only if search is of type TypeAhead
  useEffect(() => {
    const value = searchValue?.value?.trim();
    if (
      value &&
      value.length > MIN_SEARCH_CHARACTERS &&
      searchValue?.value_type === "TypeAhead"
    ) {
      fetchSearchTypeAheadResultsMutation(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (!isSearchFocus) {
      setSearchResultsWidth(0);
    } else {
      computeSearchBoxWidth();
    }
  }, [isSearchFocus]);

  useEffect(() => {
    if (!isSearchFocus) {
      setSearchResultsWidth(0);
    } else {
      computeSearchBoxWidth();
    }
  }, [isSearchFocus]);

  // useEffect(() => {
  //   setGeoLocation({
  //     city: location.city,
  //     state: location.state,
  //     zipCode: location.zipCode,
  //   });
  // }, [location]);

  useEffect(() => {
    if (data && tokenValidationMessages.includes(data.error || "")) {
      setItem("token", "");
    }
    if (!isLoading) {
      if (isAuthenticated && data?.data?.value) {
        dispatch({
          type: SET_USER,
          payload: { profile: { ...data.data.value, isLoading } },
        });
        if (["/register", "login"].includes(loc.pathname)) {
          history.push("/");
        }
      }
    } else {
      dispatch({ type: SET_USER, payload: { profile: { isLoading } } });
    }
  }, [dispatch, history, data, isAuthenticated, isLoading, loc]);

  // adding this for a forced header rerender when token changes
  useEffect(() => {}, [loc, isAuthenticated]);

  const handleCategoriesModal = () => {
    setHideCategories(!hideCategories);
  };

  const onSelect = (searchResultString: string) => {
    // we are fetching complete product details for only TypeAhead search
    if (!IsNoResultsFound) {
      setSearchSelectedValue(searchValue?.value);
      history.push(
        `/search?product_search=${encodeURIComponent(
          searchValue.value
        )}&product_count=${searchResultsCount}`
      );
      setShowSearchResultsModal(false);
    } else {
      setSearchSelectedValue(searchResultString);
      setSearchResultsCount(0);
      setShowSearchResultsModal(false);
    }
  };

  const onFocus = () => {
    setShowSearchResultsModal(true);
    // when no value is enetered, show the default searches
    if (!searchValue?.value) {
      setShowDefaultSearches(true);
    }
  };

  const debounceOnChange = debounce((value: string) => {
    const refinedValue = value?.trim();
    if (!refinedValue) {
      setSearchResultsCount(0);
    }
    setSearchValue({
      value: refinedValue,
      value_type: searchSelectedValue?.length === 0 ? "TypeAhead" : "Search",
    });
  }, DEBOUNCE_IN_MS);

  const onChange = (toSearchValue: string) => {
    setSearchTextBoxState(toSearchValue?.length === 0 ? "normal" : "active");
    setSearchSelectedValue("");
    setShowDefaultSearches(toSearchValue?.trim()?.length === 0);
    debounceOnChange(toSearchValue);
  };

  const onSearch = (onSearchValue: string) => {
    setSearchValue({
      value: onSearchValue,
      value_type: "Search",
    });
  };

  const onClickSearchBox = () => {
    setSearchTextBoxState("active");
    computeSearchBoxWidth();
  };

  // const handleApplyLocation = (newGeoLocation: {
  //   city: string;
  //   state: string;
  //   zipCode: string;
  // }) => {
  //   if (newGeoLocation.city) {
  //     setGeoLocation(newGeoLocation);
  //   } else if (newGeoLocation.zipCode) {
  //     if (window.google) {
  //       const geocoder = new google.maps.Geocoder();
  //       geocoder.geocode(
  //         { address: newGeoLocation.zipCode },
  //         (results, status) => {
  //           if (status === "OK" && results) {
  //             const addressComp = results[0].address_components;
  //             const address = {
  //               city: "",
  //               state: "",
  //               zipCode: newGeoLocation.zipCode,
  //             };
  //             addressComp.forEach(
  //               (comp: {
  //                 types: Array<string>;
  //                 long_name: string;
  //                 short_name: string;
  //               }) => {
  //                 if (comp.types.includes("locality")) {
  //                   address.city = comp.long_name;
  //                 }
  //                 if (comp.types.includes("administrative_area_level_1")) {
  //                   address.state = comp.short_name;
  //                 }
  //                 if (comp.types.includes("postal_code")) {
  //                   address.zipCode = comp.short_name;
  //                 }
  //               }
  //             );

  //             setGeoLocation(address);
  //             setCookie("user_location", JSON.stringify(address), 14);
  //           } else {
  //             // eslint-disable-next-line no-console
  //             console.log(
  //               `Geocode was not successful for the following reason: ${status}`
  //             );
  //           }
  //         }
  //       );
  //     }
  //   }
  //   setShowLocationModal(false);
  // };

  // const { city, state, zipCode } = geoLocation;

  const {
    treatments: { view_cart: viewCart },
    // isReady,
  } = useSplitTreatments({
    names: ["view_cart"],
    attributes: { userId: getItem("split_override_user_id") as string },
  });
  // if (!isReady) return <div>Loading...</div>;

  const isHomePage = loc.pathname.length === 1;

  return (
    <div
      className={`flex flex-col px-2 h-30 ${
        isVendorPage
          ? "justify-center bg-neutral-color-1"
          : `justify-start bg-neutral-color-1 ${
              !isHomePage
                ? "lg:border-b lg:border-primary-color-100 lg:border-opacity-25"
                : ""
            } lg:justify-between md:px-5 md:pt-2`
      }`}
    >
      {isVendorPage && <ResendEmailBanner />}
      <div
        className={`flex flex-wrap items-center ${
          isVendorPage ? "justify-center" : "justify-between"
        } ${hideHeader ? "space-around md:mb-3" : ""}`}
      >
        <div
          className={`flex py-3 px-2 ${
            hideHeader
              ? `w-full  ${
                  isVendorPage ? "justify-center" : "justify-between"
                } md:w-1/3`
              : ""
          }`}
        >
          <div
            className={`flex order-1 ${
              hideHeader ? "flex-3 lg:w-1/3" : "md:flex-1 md:w-full lg:w-1/6"
            } items-center ${
              isVendorPage ? "justify-center" : "justify-between"
            } md:order-1`}
          >
            <div className="cursor-pointer">
              <a href="/">
                <img
                  src={logoIcon}
                  alt="lonima-logo"
                  className="w-24 sm:w-28 md:w-32"
                />
              </a>
            </div>
          </div>
          {isCheckoutPage && (
            <div className="inline-flex items-center order-2 font-light leading-3 font-opensans md:text-base lg:text-xl md:mx-1 lg:mx-4">
              <div>Secure Checkout</div>
              <img src={lockIcon} className="ml-1" alt="lockIcon" />
            </div>
          )}
        </div>
        {viewCart.treatment === "on" && !hideHeader && (
          <div className="flex items-center order-3 w-full sm:w-5/5 md:w-full lg:flex-2 lg:w-4/6 md:order-3 lg:order-2">
            <div id="categories-header" className="flex w-10 pr-2 lg:hidden">
              <button type="button" onClick={handleCategoriesModal}>
                <img src={hamburgerIcon} alt="categories-menu" />
              </button>
            </div>

            {viewCart.treatment === "on" && (
              <div
                className="relative flex flex-col w-full flex-2 lg:px-2 flex-grow-2"
                ref={searchRef}
              >
                <div className="flex w-full">
                  <Search
                    typeOfState={searchTextBoxState}
                    isSetFocus={isSearchFocus}
                    focusUniqID={focusUniqID}
                    placeHolder={placeHolder}
                    searchedInput={searchSelectedValue}
                    onSearch={onSearch}
                    onFocus={onFocus}
                    onChange={onChange}
                    onClickSearchBox={onClickSearchBox}
                  />
                </div>
                {showSearchResultsModal && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 0,
                      width: `${
                        searchResultsWidth > 0
                          ? `${searchResultsWidth}px`
                          : "40%"
                      }`,
                      filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))",
                      zIndex: 35,
                    }}
                  >
                    <SearchResults
                      isLoading={isTypeAheadResultsLoading}
                      searchValue={searchValue?.value}
                      IsNoResultsFound={IsNoResultsFound}
                      showDefaultSearches={showDefaultSearches}
                      onSelect={onSelect}
                      searchResultsCount={searchResultsCount}
                    />
                  </div>
                )}
              </div>
            )}
            {/* {city && (
              <div
                className="flex items-center w-8 pl-2 sm:w-1/5 lg:w-1/3"
                role="button"
                onKeyPress={() => setShowLocationModal(true)}
                onClick={() => setShowLocationModal(true)}
                tabIndex={0}
              >
                <div className="cursor-pointer sm:w-13 sm:px-2 md:pl-2">
                  <img src={locationIcon} alt="Location" />
                </div>
                <div className="hidden pl-2 font-light text-left capitalize sm:block font-poppins sm:text-sm lg:text-base text-primary-color-100">
                  {city}, {state}
                </div>
              </div>
            )} */}
          </div>
        )}
        {isCheckoutPage && (
          <div className="flex justify-center order-3 w-full gap-3 text-sm font-semibold flex-nowrap md:no-flex-wrap md:w-2/3 font-opensans">
            <CheckoutProgress />
          </div>
        )}
        {!hideHeader && (
          <div className="flex items-center justify-end order-2 w-6/12 m-5 md:pl-1 md:flex-1 md:w-full md:order-2 lg:order-3 lg:m-2 lg:w-1/6">
            {isHomePage && !shopId && (
              <Button
                containerClassName="hidden md:flex rounded-md mr-8"
                text="Sell on Lonima"
                size="sm"
                type="orange"
                onClick={() => history.push("/sell-on-lonima/name")}
              />
            )}
            <User
              isAuthenticated={isAuthenticated}
              handleSignIn={handleSignIn}
              title={first_name}
              avatar={avatar}
              shopId={shopId}
              handleFavoritesClick={() => history.push("/profile")}
            />
            {viewCart.treatment === "on" && (
              <div
                role="button"
                tabIndex={0}
                className="relative flex p-2 ml-4 rounded-full cursor-pointer md:p-3 hover:bg-disabled-300"
                onKeyPress={() => {
                  history.push(`/cart`);
                }}
                onClick={() => {
                  history.push(`/cart`);
                }}
              >
                <img src={cartIcon} alt="cart" />
                {cartDataLength ? (
                  <span className="absolute -top-2 -right-2">
                    <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-contrast-color-1-100">
                      {cartDataLength}
                    </div>
                  </span>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
      {viewCart.treatment === "on" && !hideHeader && categories?.length > 0 && (
        <Categories
          hiddenFlag={hideCategories}
          onCloseCategories={handleCategoriesModal}
          categories={categories}
        />
      )}
      {/* {showLocationModal && (
        <Location
          currentUserLocation={{ city, state, zipCode }}
          handleApplyLocation={handleApplyLocation}
        />
      )} */}
    </div>
  );
};

Header.defaultProps = {
  placeHolder: "",
  location: { city: "", state: "", zipCode: "" },
  categories: [],
  isAuthenticated: false,
  onSearch: () => {},
  handleSignIn: () => {},
};

export default Header;
