import React, { useContext, useEffect } from "react";
import { amexCard, discoverCard, masterCard, visaCard } from "../../assets";
import Button from "../Button";
import { ICartProductDetails, ICartResponse } from "../CartItem/types";
import { Context } from "../../store";
import { useAuth } from "../../hooks";

const CartSummary = ({
  cartProductData,
  showCartText,
  isActionButtonEnabled,
  onAction,
  mViewShortcut = false,
  cartData,
  showLoading = false,
  onTotalChange,
}: {
  showCartText: boolean;
  isActionButtonEnabled: boolean;
  mViewShortcut: boolean;
  onAction: ({ pushToCheckout }: { pushToCheckout?: boolean }) => void;
  cartProductData: Array<ICartProductDetails>;
  cartData: ICartResponse;
  showLoading?: boolean;
  onTotalChange?: (total: string) => void;
}): JSX.Element => {
  const onActionButtonClick = () => {
    if (isActionButtonEnabled) {
      if (showCartText) {
        onAction({ pushToCheckout: true });
      }
      onAction({});
    }
  };

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { email_verified },
      },
    },
  } = useContext(Context);

  const { isAuthenticated } = useAuth();

  const cartDataKeys = Object.keys(cartData);
  const originalStrVal = `${
    cartDataKeys.includes("original_price")
      ? `$${(cartData.original_price || 0).toFixed(2)}`
      : "-"
  }`;
  const discountStrVal = `${
    cartDataKeys.includes("original_price") &&
    cartDataKeys.includes("discounted_price") &&
    cartData.original_price !== cartData.discounted_price
      ? `-$${(cartData.original_price - cartData.discounted_price).toFixed(2)}`
      : "-"
  }`;

  const tax = cartData.sales_tax || 0;

  // eslint-disable-next-line no-nested-ternary
  const subTotal = cartDataKeys.includes("discounted_price")
    ? cartData.discounted_price || 0
    : cartDataKeys.includes("original_price")
    ? cartData.original_price || 0
    : 0;

  const subTotalStrVal = `$${subTotal.toFixed(2)}`;
  const finalTotal = showCartText ? subTotal : subTotal + tax;

  useEffect(() => {
    if (onTotalChange) {
      onTotalChange(subTotalStrVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.discounted_price, cartData.original_price, onTotalChange]);

  if (mViewShortcut) {
    return (
      <div className="md:hidden flex flex-col items-start w-full">
        {showCartText && (
          <h4>You have {cartProductData.length} item(s) in your cart</h4>
        )}
        <div className="my-3 p-4 w-full flex flex-col justify-between gap-3 ">
          <div className="flex justify-between">
            <p className="font-bold">{`${
              showCartText ? "Total" : "Order total"
            } (${cartProductData.length} item${
              cartProductData.length > 1 ? "s" : ""
            })`}</p>
            <p>{subTotalStrVal}</p>
          </div>
          <Button
            disabled={
              !isActionButtonEnabled || (isAuthenticated && !email_verified)
            }
            containerClassName={`flex w-full sm:w-1/2 justify-center self-center rounded-md ${
              isActionButtonEnabled ? "" : "opacity-20"
            }`}
            text={showCartText ? "Proceed to checkout" : "Place your order"}
            size="lg"
            type="primary"
            onClick={onActionButtonClick}
            loading={showLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="w-full flex flex-col justify-start rounded-md
    md:border md:border-solid md:border-primary-color-100 md:border-opacity-10 p-4 md:shadow-xl"
      style={{ height: "fit-content", maxHeight: "90vh" }}
    >
      <p className="text-lg hidden sm:block">
        {showCartText ? "Your Cart" : "Order Summary"}
      </p>
      <div className="flex flex-col gap-3 border-b border-primary-color-100 border-opacity-25 py-4">
        <div className="flex justify-between">
          <p className="font-bold">Items(s) total</p>
          <p>{originalStrVal}</p>
        </div>
        <div className="flex justify-between">
          <p>Discount</p>
          <p>{discountStrVal}</p>
        </div>
      </div>
      <div className="flex flex-col gap-3 border-b border-primary-color-100 border-opacity-25 py-4">
        <div className="flex justify-between">
          <p className="font-bold">Subtotal</p>
          <p>{subTotalStrVal}</p>
        </div>
        {!showCartText && (
          <div className="flex justify-between">
            <p>Taxes</p>
            <p>${tax.toFixed(2)}</p>
          </div>
        )}
      </div>
      <div className="py-4 border-b border-primary-color-100 border-opacity-25">
        <div className="flex justify-between">
          <p className="font-bold">{`${
            showCartText ? "Total" : "Order total"
          } (${cartProductData.length} item${
            cartProductData.length > 1 ? "s" : ""
          })`}</p>
          <p>${finalTotal.toFixed(2)}</p>
        </div>
      </div>
      {showCartText && (
        <div className="flex justify-between items-center py-4 gap-4">
          <p className="font-sm">Pay by CC</p>
          <div className="flex flex-wrap">
            <img
              src={visaCard}
              width={48}
              height={36}
              alt="visa-credit-card"
              className="mr-1 my-1"
            />
            <img
              src={amexCard}
              width={48}
              height={36}
              alt="amex-credit-card"
              className="mr-1 my-1"
            />
            <img
              src={masterCard}
              width={48}
              height={36}
              alt="master-credit-card"
              className="mr-1 my-1"
            />
            <img
              src={discoverCard}
              width={48}
              height={36}
              alt="discover-credit-card"
              className="my-1"
            />
          </div>
        </div>
      )}
      <Button
        containerClassName={`flex w-full mt-4 justify-center sm:w-1/2 lg:w-full self-center rounded-md ${
          isActionButtonEnabled ? "" : "opacity-20"
        }`}
        text={showCartText ? "Proceed to checkout" : "Place your order"}
        size="lg"
        type="primary"
        onClick={onActionButtonClick}
        loading={showLoading}
        disabled={
          !isActionButtonEnabled || (isAuthenticated && !email_verified)
        }
      />
      <div className="flex mt-2 text-sm w-full justify-center flex-wrap gap-2">
        {showCartText ? (
          "Additional taxes may apply"
        ) : (
          <>
            {"By clicking Place your order, you agree to Lonima’s "}
            <a
              className="text-neutral-color-3"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/document/d/e/2PACX-1vSSG9xq5enxNlzAiOPTisER97fFf7_QnK5dkzm2E0a8o9_hwL0tRkN34NUZ3K2mXNzc01kAllREMMry/pub"
            >
              Terms Of Use
            </a>
            {" and "}
            <a
              className="text-neutral-color-3"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/document/d/e/2PACX-1vSP6EHMrAag4IU-aAMj_TWuGJ5vql9BwkfYaZamFlReWiSr0VtFFOLTYP6hR2ZXPIs7DQvqib1ohiRN/pub"
            >
              Privacy Policy
            </a>
          </>
        )}
      </div>
    </div>
  );
};

CartSummary.defaultProps = {
  showLoading: false,
  onTotalChange: () => {},
};

export default CartSummary;
