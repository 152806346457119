import React from "react";

// packages
import { useHistory } from "react-router-dom";

// components
import Modal from "../../Modal";
import Button from "../../Button";

// utils
import { setPlaceHolderImageOnError } from "../Product.utils";

export interface Props {
  imageUrl: string;
  title: string;
  price: number;
  readOnly?: boolean;
  discountedPrice: number;
  onClose: () => void;
}

const BUTTON_CONTAINER_STYLE = "flex w-1/2 rounded-md";

const AddToCartModal = ({
  imageUrl,
  title,
  price,
  onClose,
  discountedPrice,
  readOnly = false,
}: Props): JSX.Element => {
  const history = useHistory();

  const priceStyle = discountedPrice
    ? "font-poppins font-extralight text-3xl text-contrast-color-1-100 line-through"
    : "font-poppins font-semibold text-3xl text-primary-color-100";

  return (
    <Modal
      overrideModalStyle="md:max-w-2xl h-auto"
      overrideModalContainerStyle="items-end sm:items-center"
    >
      <div className="flex flex-col w-full justify-evenly cursor-default h-auto">
        <div className="flex w-full justify-center items-baseline">
          <h4 className="font-poppins font-light not-italic text-2xl mt-4 ml-2 text-primary-color-100">
            Added to cart
          </h4>
        </div>
        <div className="flex flex-col sm:flex-row gap-5 p-4 overflow-auto">
          <div className="flex rounded-full bg-white">
            <div className="flex flex-1 md:w-56 lg:w-60 object-cover border border-solid">
              <img
                src={imageUrl}
                alt="cart-product"
                style={{
                  color: "white",
                }}
                className="w-full"
                onError={setPlaceHolderImageOnError}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex">
              <h1 className="font-poppins font-normal text-xl text-primary-color-100 text-left">
                {title ? `${title.substring(0, 50)}...` : ""}
              </h1>
            </div>
            <div className="flex mt-5 gap-3">
              {discountedPrice && (
                <h2 className="font-poppins font-semibold text-3xl text-primary-color-100">
                  {`$${discountedPrice}`}
                </h2>
              )}
              {price && <h2 className={priceStyle}>{`$${price}`}</h2>}
            </div>
          </div>
        </div>
        <div
          className="w-full border-t border-opacity-25 bg-white "
          style={{ border: "1px solid #DADEE3" }}
        >
          <div className="flex justify-center gap-3 m-5">
            <Button
              containerClassName={BUTTON_CONTAINER_STYLE}
              text="Go To Cart"
              size="sm"
              type="outline"
              onClick={() => {
                if (readOnly) {
                  onClose();
                } else {
                  history.push("/cart");
                }
              }}
            />
            <Button
              containerClassName={BUTTON_CONTAINER_STYLE}
              text="Continue Shopping"
              size="sm"
              type="primary"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddToCartModal;
