/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import React, { useContext, useMemo, useState, useEffect } from "react";

// packages
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import QRCode from "react-qr-code";

// components
import StarIcon from "../../../components/StarIcon";
import Button from "../../../components/Button";
import EditProfile from "./EditProfile";
import Modal from "../../../components/Modal";

// constants
import { TOTAL_RATING_STARS } from "../../../components/Product/Product.constants";

// context
import ShopContext from "../Shop.context";
import { Context } from "../../../store";

// actions
import { createPaymentAccount } from "./actions";

// utils
import { setPlaceHolderImageOnError } from "../../../components/Product/Product.utils";
import { getDataProcessingInfo } from "./utils";

// hooks
import { useQueryParams, useAuth } from "../../../hooks";

// icons
import { qrCodeIcon } from "../../../assets";

export const Profile = ({
  onSave,
  productsSize,
}: {
  onSave: () => void;
  productsSize: number;
}): JSX.Element => {
  const [, setShowSettings] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [todos, setTodos] = useState<Array<{
    id: number;
    text: string;
    description: string;
    link: string;
    completed: boolean | (() => boolean);
  }> | null>(null);
  const [processingInfo, setProcessingInfo] = useState({
    type: "",
    value: "",
  });
  const { isAuthenticated } = useAuth();

  const {
    state: {
      user: {
        profile: { shops, payment },
      },
    },
  } = useContext(Context);

  const {
    description = "",
    address,
    name = "",
    default_address,
    cover_image,
    logo_image,
    rating,
    total_review_count,
    imageRerenderId,
    about_us,
    policies,
    return_and_exchanges,
  } = useContext(ShopContext);

  const history = useHistory();
  const currentUrl = window.location.href;

  const { id } = useParams() as { id: string };
  const queryParams = useQueryParams();
  const mode = queryParams.get("mode");

  const {
    title,
    shortDescription,
    ratings,
    location,
    numOfReviews,
    shopAvatar = "",
    shopCoverImage = "",
    aboutUs,
    returnAndExchanges,
    policies: shopPolicies,
  } = useMemo(() => {
    const defaultAddress = default_address
      ? address?.find(({ _id }) => ({ _id }))
      : address?.[0];
    // TODO: add shop ratings and number of reviews
    return {
      title: name,
      shortDescription: description,
      ratings: rating,
      location: `${defaultAddress?.city || ""}${
        defaultAddress?.state ? "," : ""
      } ${defaultAddress?.state || ""}`,
      numOfReviews: total_review_count,
      shopAvatar: logo_image,
      shopCoverImage: cover_image,
      aboutUs: about_us,
      policies,
      returnAndExchanges: return_and_exchanges,
    };
  }, [
    description,
    name,
    default_address,
    address,
    total_review_count,
    rating,
    cover_image,
    logo_image,
    about_us,
    policies,
    return_and_exchanges,
  ]);

  const isUserBelongToShop = useMemo(
    () => id === shops?.[0]?.shop_id,
    [shops, id]
  );

  const { mutate: connectStripeAccount } = useMutation(createPaymentAccount, {
    onSuccess: ({ data }) => {
      const stripeUrl = data?.value?.accountLink;
      window.open(stripeUrl, "_self");
      setShowSettings(false);
      setStripeLoading(false);
    },
    onError: (err: Error) => {
      setShowSettings(false);
      setStripeLoading(false);
      setProcessingInfo({
        type: "error",
        value: `${err.message}`,
      });
      setTimeout(() => {
        setProcessingInfo({
          type: "",
          value: "",
        });
      }, 5000);
    },
  });

  useEffect(() => {
    if (mode === "refresh") {
      connectStripeAccount();
    } else if (mode === "return") {
      setProcessingInfo({
        type: "",
        value: "",
      });
      history.push(`/shop/${id}`);
    }
  }, [connectStripeAccount, history, id, mode]);

  const { type, value } = processingInfo;

  const handleTodoClick = (todo: { id: number }) => {
    if (todo.id === 1) {
      setStripeLoading(true);
      connectStripeAccount();
    } else if (todo.id === 3) {
      setIsEditMode(true);
    }
  };

  useEffect(() => {
    if (isAuthenticated && isUserBelongToShop) {
      const shopTodoCompletedCheck = () => {
        const parsedAboutUs = JSON.parse(aboutUs);
        const parsedReturnAndExchanges = JSON.parse(returnAndExchanges);
        const parsedShopPolicies = JSON.parse(shopPolicies);
        const _aboutUs = aboutUs && Object.keys(parsedAboutUs).length > 0;
        const _returnAndExchanges =
          returnAndExchanges &&
          Object.keys(parsedReturnAndExchanges).length > 0;
        const _shopPolicies =
          shopPolicies && Object.keys(parsedShopPolicies).length > 0;
        if (!_aboutUs || !_returnAndExchanges || !_shopPolicies) return false;

        if (
          parsedAboutUs?.blocks?.find((data) => data.text) &&
          parsedReturnAndExchanges?.blocks?.find((data) => data.text) &&
          parsedShopPolicies?.blocks?.find((data) => data.text)
        ) {
          return true;
        }

        return false;
      };
      setTodos([
        {
          id: 1,
          text: "Connect with Stripe ",
          description:
            "Connect your bank account with Stripe to receive your earnings.",
          link: "",
          completed: payment?.account_enabled ?? false,
        },
        {
          id: 2,
          text: "Add a product",
          description:
            "Take the first step towards expanding your market presence by adding a new product on Lonima today.",
          link: `/add-product?shop_id=${id}`,
          completed: productsSize > 0,
        },
        {
          id: 3,
          text: "Edit shop details",
          description:
            "Enhance your shop's profile by updating your shop's About us, Returns & Exchanges, Shop Policies.",
          link: "",
          completed: () => shopTodoCompletedCheck(),
        },
      ]);
    }
  }, [
    isAuthenticated,
    isUserBelongToShop,
    productsSize,
    payment?.account_enabled,
    id,
    aboutUs,
    returnAndExchanges,
    shopPolicies,
  ]);

  const incompleteTodos = todos?.filter((todo) =>
    typeof todo.completed === "function" ? !todo.completed() : !todo.completed
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full mt-4 md-lg:flex-row-reverse lg:mt-0">
        <div className="items-center w-full md:justify-end md:w-full">
          <img
            className="object-cover w-full h-56 md:h-72"
            onError={setPlaceHolderImageOnError}
            alt="Shop-Cover"
            key={`cover-${imageRerenderId}`}
            src={`${shopCoverImage}?v=${imageRerenderId}`}
          />
        </div>
        <div className="flex flex-col items-center justify-center m-2 md:flex-row md:w-full md:justify-around">
          {/* <div className="flex gap-2">
           
          </div> */}
          <div className="flex flex-col items-center justify-center gap-8 text-left md-lg:flex-row md:justify-evenly">
            <img
              className="rounded-full cursor-pointer w-36 md-lg:w-44"
              onError={setPlaceHolderImageOnError}
              alt="Avatar"
              key={`profile-${imageRerenderId}`}
              src={`${shopAvatar}?v=${imageRerenderId}`}
            />
            <div className="flex flex-col items-center justify-center gap-2">
              <h2 className="text-primary-color-100">{title}</h2>
              <h4 className="text-primary-color-100">{shortDescription}</h4>
              <div className="flex justify-center gap-2">
                {Array.from({ length: TOTAL_RATING_STARS }, (v, i) => (
                  <StarIcon starId={i} key={`star_${i}`} marked={ratings > i} />
                ))}
                {numOfReviews > 0 ? `(${numOfReviews})` : ""}
              </div>
              <div className="flex text-sm font-opensans">{location}</div>
              <div className="flex flex-row justify-center w-full gap-2">
                {isUserBelongToShop && !!id && isAuthenticated && (
                  <div className="flex gap-4">
                    <Button
                      containerClassName="flex rounded-md"
                      text="Edit Shop"
                      size="sm"
                      type="primary"
                      onClick={() => setIsEditMode(true)}
                    />
                  </div>
                )}
                <img
                  className="cursor-pointer"
                  src={qrCodeIcon}
                  width={40}
                  height={40}
                  alt="shop-qr-code"
                  onClick={() => setShowQrCodeModal(true)}
                />
              </div>
              {showQrCodeModal && (
                <Modal
                  overrideModalStyle="w-auto"
                  overrideModalContainerStyle="items-center"
                  onClose={() => setShowQrCodeModal(false)}
                  hideCloseIcon
                >
                  <QRCode size={256} value={currentUrl} viewBox="0 0 256 256" />
                </Modal>
              )}
              {isEditMode && (
                <EditProfile
                  onClose={() => setIsEditMode(false)}
                  onSave={onSave}
                />
              )}
              <div className="flex flex-col">
                {getDataProcessingInfo({ type, value })}
              </div>
            </div>
          </div>
          {/* {!isMobile && options?.length > 0 && (
          <div className="flex mb-60">
            {SettingsPopUp}
            {showSettings && (
              <Settings
                toggleShowOptions={toggleShowOptions}
                options={options as Option[]}
              />
            )}
          </div>
        )} */}
        </div>
      </div>
      {incompleteTodos && incompleteTodos.length > 0 && (
        <div className="w-full p-4 rounded-lg">
          <h2 className="mb-4 text-xl font-semibold text-left text-primary">
            Action Items
          </h2>
          <div>
            {incompleteTodos.map((todo) => (
              <div
                key={todo.id}
                className="flex items-center justify-between p-4 mb-3 transition-shadow bg-white rounded-lg shadow hover:shadow-md"
              >
                <div className="flex flex-col items-start">
                  <span className="font-medium text-primary">{todo.text}</span>
                  <p className="mb-2 text-sm text-left text-gray-600">
                    {todo.description}
                  </p>
                </div>
                <Button
                  type="red"
                  text={
                    (
                      typeof todo.completed === "function"
                        ? todo.completed()
                        : todo.completed
                    )
                      ? "Completed"
                      : "Complete"
                  }
                  size="sm"
                  loading={todo.id === 1 ? stripeLoading : undefined}
                  className="px-3 py-1 text-white rounded bg-contrast-color-1-100 hover:bg-contrast-color-2"
                  onClick={() => {
                    if (todo.link) {
                      history.push(todo.link);
                    } else {
                      handleTodoClick(todo);
                    }
                  }}
                >
                  {todo.completed ? "Completed" : "Complete"}
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
