import { React, useEffect } from "react";

export default function HubSpotForm({
  region,
  portalId,
  formId,
  target,
  overrideStyles,
  overrideClasses,
}: {
  region: string;
  portalId: string;
  formId: string;
  target: string;
  overrideStyles?: React.CSSProperties;
  overrideClasses: string;
}): JSX.Element {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: `#${target}`,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={overrideStyles} className={overrideClasses}>
      <div id={target} />
    </div>
  );
}

HubSpotForm.defaultProps = {
  overrideStyles: {},
};
