import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";

// packages
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Stripe,
  StripeElements,
  StripePaymentElement,
  StripePaymentElementChangeEvent,
  loadStripe,
} from "@stripe/stripe-js";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { pick } from "lodash";
import { v4 as uuid } from "uuid";
import { Slide, toast } from "react-toastify";
import Cart from "../../Cart";

// ts
import { AddressPayload, Location } from "../../../types";

// hooks
import { useAuth } from "../../../hooks";
import { arrowLeftIcon } from "../../../assets";
import CartSummary from "../../../components/CartSummary";
import {
  ICartProductDetails,
  ICartResponse,
} from "../../../components/CartItem/types";
import CardSelection from "../CardSelection";
import ExistingAddress from "../../Profile/Tabs/AccountSettings/Addresses/ExistingAddress";
import { Context } from "../../../store";
import {
  createPaymentAndOrder,
  getTax,
  handle3DSStripePayment,
} from "../actions";
import AddressForm from "../../../components/AddressForm";
import CheckBox from "../../../components/CheckBox";
import { AddressResponse } from "../../../store/types";
import "react-toastify/dist/ReactToastify.css";

// Need to do this because we need to access stripe and elements
// outside of the Elements component to send it down to createOrder
// so we can create a new payment method if user is adding a new card
// or if user is a guest user
let globalStripe: Stripe | null = null;
let globalElements: StripeElements | null = null;

const StripeForm = ({
  onReady,
  onPaymentElementChange,
  isLoading,
  cardError,
  setIsSaveEligible,
  onBillingAddressChange,
}: {
  onReady: (e: StripePaymentElement) => void;
  onPaymentElementChange: (event: StripePaymentElementChangeEvent) => void;
  isLoading: boolean;
  cardError: string;
  setIsSaveEligible: (val: boolean) => void;
  onBillingAddressChange: (val: AddressResponse) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isBillingShippingSameAddress, setIsBillingShippingSameAddress] =
    useState(true);
  const [billingAddress, setBillingAddress] = useState<AddressResponse>(
    {} as AddressResponse
  );

  globalElements = elements;
  globalStripe = stripe;
  useEffect(() => {
    if (isBillingShippingSameAddress) {
      setIsSaveEligible(true);
    }
  }, [isBillingShippingSameAddress, setIsSaveEligible]);
  return (
    <div className="flex flex-col sm:pl-4 max-w-2xl">
      <form>
        <PaymentElement
          onReady={onReady}
          onChange={onPaymentElementChange}
          options={{
            fields: {
              billingDetails: {
                address: {
                  country: "never",
                  postalCode: "never",
                },
              },
            },
          }}
        />
        <div className="flex mt-5 pb-5">
          <CheckBox
            text="My billing address is same as my shipping address"
            isSelected={isBillingShippingSameAddress}
            isEnabled
            onChange={() =>
              setIsBillingShippingSameAddress((prevValue) => !prevValue)
            }
          />
        </div>
        {!isBillingShippingSameAddress && (
          <AddressForm
            previousAddress={billingAddress}
            uniqueAddressId={uuid()}
            isDefaultAddressReadOnly={false}
            updateValues={(vals) => {
              setBillingAddress(vals);
              onBillingAddressChange(vals);
            }}
            setIsSaveEligible={(val) => setIsSaveEligible(val)}
          />
        )}
      </form>
      {/* {!isLoading && isAuthenticated && (
        <div className="flex mt-5">
          <CheckBox
            text="Set as Default"
            isSelected={isDefaultCard}
            isEnabled
            onChange={toggleDefaultCard}
          />
        </div>
      )} */}
      {!isLoading && cardError && (
        <div className="card-error" role="alert">
          {cardError}
        </div>
      )}
    </div>
  );
};

const InjectedCheckoutForm = ({
  isLoading,
  onReady,
  onPaymentElementChange,
  cardError,
  amount,
  setIsSaveEligible,
  onBillingAddressChange,
}: {
  isLoading: boolean;
  onReady: () => void;
  onPaymentElementChange: (event: StripePaymentElementChangeEvent) => void;
  cardError: string;
  amount: number;
  setIsSaveEligible: (val: boolean) => void;
  onBillingAddressChange: (val: AddressResponse) => void;
}): JSX.Element => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
  );
  const options = {
    mode: "payment" as "setup" | "payment" | "subscription" | undefined,
    currency: "usd",
    amount: amount || 100,
    appearance: {
      theme: "stripe" as "stripe" | "night" | "flat" | undefined,
    },
    paymentMethodCreation: "manual" as "manual" | undefined,
  };
  // Need to add another StripeForm component because
  // we are not allowed to access stripe elements and
  // stripe object outside of the Elements component
  return (
    <>
      <div className="flex justify-center w-full pt-5 p-4">
        <h4 className="w-full flex">Choose a payment type:</h4>
      </div>
      <Elements stripe={stripePromise} options={options}>
        <StripeForm
          onPaymentElementChange={onPaymentElementChange}
          cardError={cardError}
          onReady={onReady}
          isLoading={isLoading}
          setIsSaveEligible={setIsSaveEligible}
          onBillingAddressChange={onBillingAddressChange}
        />
      </Elements>
    </>
  );
};

const CheckoutForm = (): JSX.Element => {
  const {
    state: {
      user: {
        profile: { address },
      },
    },
  } = useContext(Context);
  const history = useHistory();

  const [error, setError] = useState("");
  const [orderProcessing, setOrderProcessing] = useState(false);
  const [isSaveEligible, setIsSaveEligible] = useState(false);
  const [isPaymentDetailsEntered, setIsPaymentDetailsEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentType, setPaymentType] = useState("new_card");
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");
  const [cartTotal, setCartTotal] = useState("0.00");
  const [cartDetails, setCartDetails] = useState({});
  const [shippingAddress, setShippingAddress] = useState({} as AddressPayload);
  const [billingAddress, setBillingAddress] = useState({} as AddressPayload);
  const location = useLocation();
  const urlParams = useParams();
  const { id: checkoutSessionId }: { id: string } = urlParams as { id: "" };
  const { isAuthenticated } = useAuth();
  const existingShippingAddressID = (location as unknown as Location)?.state
    ?.shippingAddressID;
  const newShippingAddress = (location as unknown as Location)?.state
    ?.newShippingAddress;

  const setAddresses = async () => {
    setShippingAddress(newShippingAddress);
  };

  useEffect(() => {
    const keys = [
      "address_ln1",
      "address_ln2",
      "city",
      "country",
      "county",
      "state",
      "zip_code",
      "isShow",
      "full_name",
      "email",
      "phone",
    ];
    const addressesDataValues = address || [];
    const existingSData = addressesDataValues?.find(
      (newAddressesDataValue) =>
        newAddressesDataValue?._id === existingShippingAddressID
    );
    if (existingSData) {
      setShippingAddress(pick(existingSData, keys) as AddressPayload);
    }
  }, [address, existingShippingAddressID]);

  useEffect(() => {
    if (!isAuthenticated) {
      setAddresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReady = () => {
    setIsLoading(false);
  };

  const { mutate: getTaxMutation } = useMutation(getTax, {
    onSuccess: (data) => {
      setCartDetails(data);
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.error("Failed to calculate tax");
    },
  });

  useEffect(() => {
    if (shippingAddress && shippingAddress.address_ln1) {
      getTaxMutation({
        checkoutId: checkoutSessionId,
        shippingAddress,
      });
    }
  }, [shippingAddress, checkoutSessionId, getTaxMutation]);

  const { mutate: handle3DSPayment } = useMutation(handle3DSStripePayment, {
    onSuccess: (data) => {
      setOrderProcessing(false);
      history.push({
        pathname: "/order",
        state: {
          orderDetail: {
            id: data?.value?.orderId,
            status: "success",
            message: "Processed your order successfully",
          },
          guestUserEmail: newShippingAddress ? newShippingAddress.email : "",
          guestUserName: newShippingAddress ? newShippingAddress.full_name : "",
        },
      });
    },
    onError: () => {
      setOrderProcessing(false);
      toast.error(
        `Payment authentication failed on the card. Please try again or use a different card`,
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Slide,
        }
      );
    },
  });

  const { mutate: createOrder } = useMutation(createPaymentAndOrder, {
    onSuccess: ({ data }) => {
      if (!data?.value) {
        setOrderProcessing(false);
        toast.error(
          `Failed to place the order. Please try again or contact customer support`,
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Slide,
          }
        );
        return;
      }
      if (data?.value?.status === "requires_action") {
        handle3DSPayment({
          stripe: globalStripe,
          clientSecret: data?.value?.clientSecret,
          orderId: data?.value?.orderData.id,
        });
        return;
      }
      setOrderProcessing(false);
      history.push({
        pathname: "/order",
        state: {
          orderDetail: {
            id: data?.value?.orderData.id,
            status: "success",
            message: "Processed your order successfully",
          },
          guestUserEmail: newShippingAddress ? newShippingAddress.email : "",
          guestUserName: newShippingAddress ? newShippingAddress.full_name : "",
        },
      });
    },
    onError: (e) => {
      setOrderProcessing(false);
      let errorMessage = "";
      if (e.cause === "InsufficientInventoryError") {
        errorMessage = e.message;
      } else {
        errorMessage = `${e} Please try a different card`;
      }
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide,
      });
    },
  });

  const cartData = useMemo(
    () => (cartDetails || {}) as ICartResponse,
    [cartDetails]
  );
  const cartProductData = useMemo(
    () =>
      cartData?.data?.length > 0
        ? cartData?.data?.reduce((cartAllShopData, shopData) => {
            shopData?.items?.forEach((item) => {
              cartAllShopData.push(item);
            });
            return cartAllShopData;
          }, [] as ICartProductDetails[])
        : [],
    [cartData]
  );

  const renderCartSummary = useCallback(() => {
    return (
      <CartSummary
        isActionButtonEnabled={
          (!isLoading &&
            isSaveEligible &&
            isPaymentDetailsEntered &&
            paymentType === "new_card") ||
          (paymentType === "saved_card" && !!selectedPaymentMethodId)
        }
        cartProductData={cartProductData}
        cartData={cartData}
        showCartText={false}
        onTotalChange={(total) => setCartTotal(total)}
        onAction={() => {
          const billingAddressDeduced =
            Object.keys(billingAddress).length > 0
              ? billingAddress
              : shippingAddress;
          createOrder({
            stripe: globalStripe,
            elements: globalElements,
            isAuthenticated,
            checkoutSessionId,
            shippingAddressID: existingShippingAddressID,
            newShippingAddress,
            billingAddress:
              paymentType === "saved_card"
                ? {
                    full_name: billingAddress.full_name,
                    address_ln1: billingAddress.address_ln1,
                    address_ln2: billingAddress.address_ln2,
                    city: billingAddress.city,
                    state: billingAddress.state,
                    zip_code: billingAddress.zip_code,
                    country: billingAddress.country,
                  }
                : undefined,
            guestUserEmail: newShippingAddress ? newShippingAddress.email : "",
            guestUserFullName: newShippingAddress
              ? newShippingAddress.full_name
              : "",
            paymentMethodId: selectedPaymentMethodId,
            paymentMethodParams: !selectedPaymentMethodId
              ? {
                  billing_details: {
                    name: billingAddressDeduced.full_name,
                    address: {
                      line1: billingAddressDeduced.address_ln1,
                      line2: billingAddressDeduced.address_ln2,
                      city: billingAddressDeduced.city,
                      state: billingAddressDeduced.state,
                      postal_code: billingAddressDeduced.zip_code,
                      country: "US",
                    },
                  },
                }
              : null,
          });
          setOrderProcessing(true);
        }}
        mViewShortcut={false}
        showLoading={orderProcessing}
      />
    );
  }, [
    billingAddress,
    cartData,
    cartProductData,
    checkoutSessionId,
    createOrder,
    existingShippingAddressID,
    isAuthenticated,
    isLoading,
    isPaymentDetailsEntered,
    isSaveEligible,
    newShippingAddress,
    orderProcessing,
    paymentType,
    selectedPaymentMethodId,
    shippingAddress,
  ]);

  const SHIPPING_BILLING_PAYMENT_TO_STYLE = "flex pb-3";

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col p-2 sm:mx-4 lg:flew-row">
        <a
          href={`/checkout/${checkoutSessionId}`}
          className="flex text-xl font-bold my-3"
        >
          <img src={arrowLeftIcon} className="mr-2" alt="previous" />
          Edit Shipping Address
        </a>
        <div className="flex flex-col lg:flex-row gap-4 justify-between">
          <div
            className="flex flex-col lg:flex-2 rounded-md
    md:border md:border-solid md:border-primary-color-100 md:border-opacity-10 p-4 pb-10 md:shadow-xl"
          >
            <div className="flex flex-col p-4 flex-1 text-left">
              <h4 className={SHIPPING_BILLING_PAYMENT_TO_STYLE}>
                Shipping To:
              </h4>
              <ExistingAddress
                existingAddress={shippingAddress}
                showActionIcons={false}
              />
            </div>
            {isAuthenticated && (
              <CardSelection
                onPaymentTypeSelect={(selectedPaymentType) =>
                  setPaymentType(selectedPaymentType)
                }
                onSelectedPaymentMethod={(paymentMethod) => {
                  if (!paymentMethod.id) {
                    setSelectedPaymentMethodId("");
                  } else {
                    setSelectedPaymentMethodId(paymentMethod.id);
                    setBillingAddress({
                      full_name: paymentMethod.name,
                      address_ln1: paymentMethod.address.line1,
                      address_ln2: paymentMethod.address.line2,
                      city: paymentMethod.address.city,
                      state: paymentMethod.address.state,
                      zip_code: paymentMethod.address.postal_code,
                      country: paymentMethod.address.country,
                    });
                  }
                }}
              />
            )}
            {((isAuthenticated && paymentType === "new_card") ||
              !isAuthenticated) && (
              <InjectedCheckoutForm
                isLoading={isLoading}
                onReady={onReady}
                onPaymentElementChange={(e) => {
                  if (e.complete) {
                    setIsPaymentDetailsEntered(true);
                  } else {
                    setIsPaymentDetailsEntered(false);
                  }
                  setError("");
                }}
                cardError={error}
                amount={parseFloat(cartTotal) || 0}
                setIsSaveEligible={(val) => setIsSaveEligible(val)}
                onBillingAddressChange={(val) =>
                  setBillingAddress({
                    full_name: val.full_name as string,
                    address_ln1: val.address_ln1 as string,
                    address_ln2: val.address_ln2 as string,
                    city: val.city as string,
                    state: val.state as string,
                    zip_code: val.zip_code as string,
                    country: val.country as string,
                  })
                }
              />
            )}
          </div>
          <div className="hidden my-4 lg:flex lg:flex-row lg:flex-1 sm:m-0">
            {renderCartSummary()}
          </div>
        </div>
        <div className="flex items-center justify-center max-w-3xl">
          <Cart
            showCartText={false}
            hideIsGiftOption
            showCartSummary={false}
            shippingAddress={shippingAddress}
            checkoutSessionId={checkoutSessionId}
            reviewPageStyle="w-full mx-0 my-5 sm:p-1"
            onAction={(data) => {
              setCartDetails(data);
            }}
          />
        </div>
        <div className="flex flex-col w-full my-4 lg:hidden">
          {renderCartSummary()}
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
