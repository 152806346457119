/* eslint-disable no-underscore-dangle */
import React from "react";

// packages
import { useHistory, useParams } from "react-router-dom";

// components
import CheckoutAddressForm from "./CheckoutAddressForm";

// hooks
import { useQueryParams } from "../../hooks";

// ts
import { AddressPayload } from "../../types";

const Checkout = (): JSX.Element => {
  const history = useHistory();
  const searchParams = useQueryParams();
  const urlParams = useParams();
  const { id: checkoutSessionId } = urlParams as { id: string };

  const redirectToPayment = (
    existingAddressID = "",
    newAddress = {} as AddressPayload
  ) => {
    searchParams.set("shipping", "complete");

    history.push({
      pathname: `/checkout/${checkoutSessionId}/payment`,
      search: searchParams.toString(),
      state: {
        shippingAddressID: existingAddressID,
        newShippingAddress: newAddress,
      },
    });
  };

  return (
    <div className="flex mx-4 md:ml-20 md:mr-20">
      <CheckoutAddressForm
        label="Enter your shipping address"
        buttonText="Continue to Payment"
        redirectToPayment={redirectToPayment}
      />
    </div>
  );
};
export default Checkout;
