// actions
import { SET_USER, SET_GLOBAL_MESSAGE, SET_LOADER } from "./actions.types";

// ts
import { UserProfile, IGlobalMessage } from "./types";
import { userProfileDefaultValues } from "./defaultValues";

export interface ReduxStateProps {
  user: { profile: UserProfile };
  global_message: IGlobalMessage;
  loading: boolean;
}

export interface ActionProps {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

export const initialState = {
  user: { profile: userProfileDefaultValues },
  global_message: { message: "", type: "" },
  loading: false,
};

// TODO: need to add immutability
const Reducer = (
  state: ReduxStateProps,
  action: ActionProps
): ReduxStateProps => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_GLOBAL_MESSAGE:
      return {
        ...state,
        global_message: action.payload,
      };
    case SET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
