/* eslint-disable import/prefer-default-export */

// utils
import { TypeOfState } from "../TextInput/TextInput.utils";

// constants
import USStates from "./states.json";

export const US_STATES_OPTIONS = Object.keys(USStates).map((USState) => {
  return { label: USStates[USState as never], value: USState };
});

export const DEFAULT_ELEMENT = {
  value: "",
  elementState: "normal" as TypeOfState,
};

export const REQUIRED_ELEMENT = {
  value: "",
  elementState: "error" as TypeOfState,
};

export const DEFAULT_ADDRESS_OBJECT = {
  _id: "",
  full_name: REQUIRED_ELEMENT,
  address_ln1: REQUIRED_ELEMENT,
  address_ln2: DEFAULT_ELEMENT,
  zip_code: REQUIRED_ELEMENT,
  city: REQUIRED_ELEMENT,
  state: REQUIRED_ELEMENT,
  isDefaultShippingAddress: {
    value: false,
    elementState: "normal" as TypeOfState,
  },
  isPlaceHolderAddress: {
    value: true,
    elementState: "normal" as TypeOfState,
  },
  county: { value: ".", elementState: "normal" as TypeOfState },
  country: { value: "United States", elementState: "normal" as TypeOfState },
  isShow: false,
};

export const DEFAULT_ADDRESS = {
  _id: "",
  full_name: "",
  address_ln1: "",
  address_ln2: "",
  zip_code: "",
  city: "",
  state: "",
  isDefaultShippingAddress: false,
  isPlaceHolderAddress: true,
  county: ".",
  country: "United States",
  isShow: false,
};

export const MANDATORY_ADDRESS_KEYS = [
  "full_name",
  "address_ln1",
  "zip_code",
  "city",
  "state",
];

export const getPreSelectedUSState = (
  stateValue: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { label: string; value: any } => {
  return stateValue
    ? { label: USStates[stateValue as never], value: stateValue }
    : { label: "", value: "" };
};
