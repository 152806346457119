import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../../hooks";
import { getUserPaymentMethods } from "../../Profile/Tabs/AccountSettings/CreditCards/actions";
import {
  amexCard,
  discoverCard,
  masterCard,
  visaCard,
  statusInComplete,
  statusInProgress,
} from "../../../assets";
import { brandImageMap } from "../../../utils/constants";
import { IBrandImage } from "../../Profile/types";

interface IPaymentMethod {
  brand: string;
  expiry: string;
  last4: string;
  name: string;
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  };
  isDefault: boolean;
  id: string;
}

const CardSelection = ({
  onPaymentTypeSelect,
  onSelectedPaymentMethod,
}: {
  onPaymentTypeSelect: (selection: string) => void;
  onSelectedPaymentMethod: (paymentMethod: IPaymentMethod) => void;
}): JSX.Element => {
  const [radioSelection, setRadioSelection] = useState("saved_card");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    {} as IPaymentMethod
  );
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    onPaymentTypeSelect(radioSelection);
    if (radioSelection === "saved_card") {
      onSelectedPaymentMethod(selectedPaymentMethod);
    } else {
      onSelectedPaymentMethod({} as IPaymentMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioSelection, selectedPaymentMethod]);

  const { data: paymentMethods } = useQuery(
    "FETCH_PAYMENT_METHODS",
    getUserPaymentMethods,
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const onSelectedCardChange = (newValue: IPaymentMethod) => {
    setSelectedPaymentMethod(newValue);
  };

  const getPaymentMethodList = () => {
    const paymentMethodList: Array<IPaymentMethod> = [];
    if (paymentMethods?.data?.value?.paymentData) {
      paymentMethods?.data?.value?.paymentData.forEach(
        (paymentMethod: {
          card: {
            brand: string;
            exp_month: number;
            exp_year: number;
            last4: string;
          };
          billing_details: {
            name: string;
            address: {
              city: string;
              country: string;
              line1: string;
              line2: string;
              postal_code: string;
              state: string;
            };
          };
          metadata: {
            is_default?: string;
          };
          id: string;
        }) => {
          paymentMethodList.push({
            brand: paymentMethod.card.brand,
            expiry: `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`,
            last4: paymentMethod.card.last4,
            name: paymentMethod.billing_details.name,
            address: paymentMethod.billing_details.address,
            isDefault: paymentMethod.metadata?.is_default === "true",
            id: paymentMethod.id,
          });
        }
      );
    }
    return paymentMethodList;
  };

  return (
    <div className="flex flex-col justify-start w-full max-w-2xl p-2 sm:p-4 gap-8">
      <div className="flex justify-center w-full pt-5">
        <h4 className="w-full flex">Choose a payment type:</h4>
      </div>
      <div className="flex flex-col w-full">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="flex flex-col gap-3 items-baseline justify-between"
          onClick={() => {
            setRadioSelection("saved_card");
          }}
        >
          <div className="flex items-center cursor-pointer">
            <input
              type="radio"
              value="saved_card"
              name="card_selection"
              checked={radioSelection === "saved_card"}
              onChange={() => {}}
              className="w-0 h-0"
            />
            <img
              src={
                radioSelection === "saved_card"
                  ? statusInProgress
                  : statusInComplete
              }
              alt="menu-icon"
              width={24}
              height={24}
              className=" bg-white"
            />
            <div className="ml-2 flex">Use Saved Card</div>
          </div>
          <div className="w-full flex flex-col justify-center items-start cursor-pointer">
            <div className="flex flex-wrap w-full gap-2">
              {radioSelection === "saved_card" &&
                getPaymentMethodList().map((paymentMethod: IPaymentMethod) => {
                  const { brand, expiry, last4, id } = paymentMethod;
                  const brandImage = brandImageMap[brand as keyof IBrandImage];
                  return (
                    <div
                      key={id}
                      className={`flex gap-1 items-center rounded-md
                    border border-solid border-primary-color-100 border-opacity-10 p-4 hover:bg-neutral-color-1 ${
                      selectedPaymentMethod.id === id
                        ? "border-primary-color-100 border-opacity-100 bg-neutral-color-1"
                        : ""
                    }`}
                      onClick={() => onSelectedCardChange(paymentMethod)}
                      onKeyDown={() => onSelectedCardChange(paymentMethod)}
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        src={brandImage}
                        width={48}
                        height={36}
                        alt={`${brand}-credit-card`}
                        className="mr-1 my-1"
                      />
                      <div className="flex flex-col items-start">
                        <div className="font-bold">xxxx-{last4}</div>
                        <div className="text-sm text-gray-500">{expiry}</div>
                      </div>
                    </div>
                  );
                })}
              {/* {radioSelection === "saved_card" && (
                <Dropdown
                  // preSelectedOptions={[getPaymentMethodList()[0]]}
                  placeholderInput="Choose the Card"
                  options={getPaymentMethodList()}
                  type="primary"
                  size="fullWidth"
                  onChange={(newValue) => {
                    handleCardDropdownChange(newValue?.value as string);
                  }}
                />
              )} */}
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="relative py-2 flex flex-row items-baseline sm:items-center justify-between"
          onClick={() => {
            setRadioSelection("new_card");
            setSelectedPaymentMethod({} as IPaymentMethod);
          }}
        >
          <div className="flex items-center cursor-pointer">
            <input
              type="radio"
              value="new_card"
              name="card_selection"
              checked={radioSelection === "new_card"}
              onChange={() => {}}
              className="w-0 h-0"
            />
            <img
              src={
                radioSelection === "new_card"
                  ? statusInProgress
                  : statusInComplete
              }
              alt="menu-icon"
              width={24}
              height={24}
              className=" bg-white"
            />
          </div>
          <div className="w-full flex flex-col sm:flex-row justify-between items-start sm:items-center gap-1 sm:gap-2 md:gap-4 cursor-pointer">
            <div className="ml-2 flex">Add New Card/Other Payment Options</div>
            <div className="flex flex-wrap">
              <img
                src={visaCard}
                width={48}
                height={36}
                alt="visa-credit-card"
                className="mr-1 my-1"
              />
              <img
                src={amexCard}
                width={48}
                height={36}
                alt="amex-credit-card"
                className="mr-1 my-1"
              />
              <img
                src={masterCard}
                width={48}
                height={36}
                alt="master-credit-card"
                className="mr-1 my-1"
              />
              <img
                src={discoverCard}
                width={48}
                height={36}
                alt="discover-credit-card"
                className="my-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSelection;
