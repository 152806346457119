/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from "react";

// packages
import { useMutation, useQuery } from "react-query";

// components
import { toast } from "react-toastify";
import ProductDetail from "../../../../../../components/CartItem";

// actions
import { getOrderReviews } from "../../../../actions";

// ts
import { Order as OrderType, ParentOrder } from "../../OrdersReviews.types";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import { updateOrderStatus } from "../../../../../Shop/Products/actions";
import "react-toastify/dist/ReactToastify.css";
import { brandImageMap } from "../../../../../../utils/constants";

const Order = ({
  parentOrder,
  onClose,
}: {
  parentOrder: ParentOrder;
  onClose: () => void;
}): JSX.Element => {
  const [openOrderModal, setOpenOrderModal] = useState(true);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [refetchReviews, setRefetchReviews] = useState(false);
  const [ordersWithProducts, setOrdersWithProducts] = useState<
    Array<OrderType>
  >([]);
  const {
    orders,
    id,
    total,
    shipping_address,
    order_tax,
    order_total_after_tax,
    status: parentOrderStatus,
    card_brand,
    card_last_4_digits,
  } = parentOrder;

  const [canceledProducts, setCanceledProducts] = useState<Array<OrderType>>(
    []
  );

  const [fullOrderStatus, setFullOrderStatus] = useState("Processing");

  useEffect(() => {
    const ordersShipped = {};
    let canceledItems = [];
    setOrdersWithProducts(
      orders.reduce((allOrdersWithProducts, order) => {
        const {
          product_details,
          cancelled = [],
          shipments,
          shop_details,
          ...rest
        } = order;
        if (cancelled.length > 0) {
          const newCanceledArray = cancelled.map((cancelItem) => {
            return {
              ...cancelItem,
              shop_details,
            };
          });
          canceledItems = [...canceledItems, ...newCanceledArray];
        }

        product_details.forEach((product_detail) => {
          const _shipment = shipments?.find((shipment) => {
            const shippedVariant = shipment.variants.find(
              (_variant) =>
                _variant.product_id === product_detail.product_id &&
                _variant.variant_id === product_detail.variant_id
            );
            if (shippedVariant) {
              ordersShipped[shippedVariant.variant_id] = ordersShipped[
                shippedVariant.variant_id
              ]
                ? ordersShipped[shippedVariant.variant_id] +
                  shippedVariant.quantity
                : shippedVariant.quantity;
            }
            return shippedVariant;
          });
          ordersShipped[product_detail.variant_id] = ordersShipped[
            product_detail.variant_id
          ]
            ? ordersShipped[product_detail.variant_id] - product_detail.quantity
            : "not-shipped";

          allOrdersWithProducts.push({
            ...rest,
            shop_details,
            product_details: {
              ...product_detail,
              shippingProvider: _shipment?.shipping_provider,
              trackingCode: _shipment?.tracking_number,
            },
          });
        });
        return allOrdersWithProducts;
      }, [] as OrderType[])
    );
    setCanceledProducts(canceledItems);
    if (parentOrderStatus === "CANCELLED") {
      setFullOrderStatus("Canceled");
      return;
    }

    if (
      Object.values(ordersShipped).length > 0 &&
      Object.values(ordersShipped).every((shippedOrder) => shippedOrder === 0)
    ) {
      setFullOrderStatus("Shipped");
    } else if (
      Object.values(ordersShipped).every(
        (shippedOrder) => shippedOrder !== "not-shipped"
      )
    ) {
      setFullOrderStatus("Processing");
    } else if (
      Object.values(ordersShipped).some(
        (shippedOrder) => shippedOrder === "not-shipped"
      ) &&
      Object.values(ordersShipped).some((shippedOrder) => shippedOrder === 0)
    ) {
      setFullOrderStatus("Partially Shipped");
    }
  }, [orders, parentOrderStatus]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, refetch } = useQuery([{ id }], getOrderReviews, {
    // enabled: isOpen,
    onError: () => {
      // eslint-disable-next-line no-console
      console.log("failed to fetch reviews");
    },
    onSuccess: () => {
      setRefetchReviews(false);
    },
  });

  useEffect(() => {
    if (refetchReviews) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchReviews]);

  // const previousReviews = useMemo(
  //   () => data?.data?.value?.products || [],
  //   [data]
  // ) as ReviewType[];

  // const productsPreviousReviews = useMemo(
  //   () =>
  //     previousReviews.reduce((out, previousReview) => {
  //       const { rating, review_text, product_id } = previousReview;
  //       if (product_id) {
  //         out[product_id] = { rating, review_text };
  //       }
  //       return out;
  //     }, {} as { [product_id: string]: ReviewType }),
  //   [previousReviews]
  // );

  const setErrorMessage = () => {
    toast.error("Failed to update order status, Please try again", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const { mutate: updateOrderStatusMutation } = useMutation(updateOrderStatus, {
    onSuccess: () => {
      toast.success(
        "Your Order has been canceled. You will receive confirmation email shortly",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setOpenCancelModal(false);
    },
    onError: () => {
      setErrorMessage();
    },
  });

  const onTrackOrder = (provider, trackingId) => {
    // redirect to mail provider website using provider and tracking id

    if (provider === "FEDEX") {
      window.open(
        `https://www.fedex.com/fedextrack/?trknbr=${trackingId}`,
        "_blank"
      );
    }
    if (provider === "USPS") {
      window.open(
        `https://tools.usps.com/go/TrackConfirmAction_input?origTrackNum=${trackingId}`,
        "_blank"
      );
    }
    if (provider === "UPS") {
      window.open(
        `https://www.ups.com/track?loc=en_US&tracknum=${trackingId}`,
        "_blank"
      );
    }
  };

  const cancelOrder = () => {
    // call cancel order
    updateOrderStatusMutation({
      id: parentOrder.id,
      status: "CANCELLED",
      note: "Customer Canceled",
    });
  };

  const brandImage = card_brand
    ? brandImageMap[card_brand as keyof IBrandImage]
    : "";

  return (
    <Modal
      isForceClose={!openOrderModal}
      overrideModalStyle="h-auto w-full max-w-7xl"
      overrideModalContainerStyle="items-end"
      onClose={() => {
        setOpenOrderModal(false);
        onClose();
      }}
    >
      <h3 className="text-center">Order Details</h3>
      <div className="flex flex-col m-4 md:m-8">
        {openCancelModal && (
          <Modal
            isForceClose={!openCancelModal}
            overrideModalStyle="justify-evenly md:max-w-2xl"
            overrideModalContainerStyle="items-end sm:items-center"
            onClose={() => setOpenCancelModal(false)}
          >
            <div className="flex flex-col justify-center w-full h-48">
              <p className="px-4 text-xl text-center font-poppins">
                Are you sure you want to cancel this order?
              </p>
              <div className="flex pt-6 justify-evenly">
                <Button
                  text="No"
                  size="sm"
                  type="outline"
                  onClick={() => setOpenCancelModal(false)}
                />
                <Button
                  text="Yes"
                  size="sm"
                  type="primary"
                  onClick={cancelOrder}
                />
              </div>
            </div>
          </Modal>
        )}
        <div className="flex flex-col-reverse justify-between md:flex-row">
          <div className="flex flex-col items-start py-4">
            <p>
              <b>Order Date:</b>{" "}
              {new Date(parentOrder.created_at).toDateString()}
            </p>

            <p>
              <b>Order #:</b> {parentOrder.id}
            </p>
          </div>
          <div className="flex flex-col items-end">
            <p className="flex self-start justify-end">
              Status: &nbsp;<b>{fullOrderStatus}</b>
            </p>
            {fullOrderStatus !== "Partially Shipped" &&
              fullOrderStatus !== "Shipped" && (
                <span
                  className="flex self-start rounded-md hover:underline hover:text-neutral-color-4"
                  onClick={() => setOpenCancelModal(true)}
                  role="button"
                  tabIndex={0}
                >
                  <p className="text-neutral-color-4">Cancel Order</p>
                </span>
              )}
          </div>
        </div>
        <div className="flex flex-col gap-4 p-4 border border-solid md:flex-row justify-evenly border-primary-color-100 border-opacity-10 rounded-b-md">
          <div className="flex flex-col items-start md:flex-1">
            <p className="pb-2 font-bold">Shipping Address</p>
            <p className="text-left">{shipping_address.full_name}</p>
            <p className="text-left">
              {shipping_address.address_ln1}, {shipping_address.address_ln2}
            </p>
            <p className="text-left">
              {shipping_address.city}, {shipping_address.state},{" "}
              {shipping_address.zip_code}
            </p>
            <p>{shipping_address.country}</p>
          </div>
          <div className="flex flex-col items-start md:flex-1">
            <p className="pb-2 font-bold">Payment Method</p>
            <span className="flex flex-row items-center gap-2">
              {brandImage && (
                <img src={brandImage} width={40} alt="credit-card" />
              )}
              <p>Card ending in {card_last_4_digits ?? "xxxx"}</p>
            </span>
          </div>
          <div className="flex flex-col items-start md:flex-1">
            <p className="pb-2 font-bold">Order Summary</p>
            <span className="flex justify-between w-full">
              <p>Item(s) Subtotal:</p>
              <p>${total?.toFixed(2) || 0.0}</p>
            </span>
            <span className="flex justify-between w-full">
              <p className="text-left">Estimated tax to be collected:</p>
              <p>${order_tax?.toFixed(2) || 0.0}</p>
            </span>
            <span className="flex justify-between w-full">
              <p className="font-bold">Total:</p>
              <p>${order_total_after_tax?.toFixed(2) || 0.0}</p>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col m-4 md:m-8">
        {ordersWithProducts.length > 0 && (
          <div className="flex flex-col gap-10 mb-10 border border-solid border-primary-color-100 border-opacity-10">
            {ordersWithProducts.map((order, index) => {
              const { _id, product_details, shop_details, shop_id } = order;
              const {
                quantity,
                product_id,
                price,
                details: { name, image_url },
                shippingProvider,
                trackingCode,
                variant,
              } = product_details;
              const { name: shopName } = shop_details;

              const productDetails = {
                product: {
                  name,
                  product_id,
                  price,
                  image_url,
                  shop_id,
                  shopName,
                },
                quantity,
                variant,
                _id,
              };
              return (
                <div
                  className={`flex flex-col md:flex-row md:gap-10 gap-2 flex-3 border-b border-primary-color-100 border-opacity-25 pb-10 ${
                    index === 0 ? "pt-10" : ""
                  }`}
                  key={`${_id}-${product_id}`}
                >
                  <div className="flex flex-col w-full gap-5 flex-2">
                    <ProductDetail
                      key={`${_id}-${product_id}`}
                      removeCartItem={() => {}}
                      productDetails={productDetails}
                      updateQuantity={() => {}}
                      handleIsGift={() => {}}
                      excludeUnderline
                      disableQuantityOption
                      hideIsGiftOption
                      trackAvailable={!!shippingProvider}
                      onTrackClick={() =>
                        onTrackOrder(shippingProvider, trackingCode)
                      }
                    />
                  </div>
                  {/* <div className="flex flex-1 w-full">
                  <Review
                    orderId={_id}
                    productId={product_id}
                    previousReview={productsPreviousReviews[product_id]}
                    setRefetch={setRefetchReviews}
                  />
                </div> */}
                </div>
              );
            })}
          </div>
        )}
        {canceledProducts.length > 0 && (
          <div className="flex flex-col">
            <h3 className="my-4 text-left">Canceled Items</h3>
            <div className="flex flex-col gap-10 mb-10 border border-solid border-primary-color-100 border-opacity-10">
              {canceledProducts.map((canceledItem, index) => {
                const {
                  _id,
                  details,
                  note,
                  shop_details,
                  quantity,
                  price,
                  product_id,
                  variant,
                } = canceledItem;
                const { name, image_url, shop_id } = details;
                const { name: shopName } = shop_details;

                const productDetails = {
                  product: {
                    name,
                    product_id,
                    price,
                    image_url,
                    shop_id,
                    shopName,
                  },
                  quantity,
                  variant,
                  _id,
                };
                const noteMapping = {
                  NOT_AVAILABLE: "Item not available. Canceled by Seller.",
                };
                return (
                  <div
                    className={`flex flex-col md:flex-row md:gap-10 gap-2 flex-3 border-b border-primary-color-100 border-opacity-25 pb-10 ${
                      index === 0 ? "pt-10" : ""
                    }`}
                    key={`${_id}-${product_id}`}
                  >
                    <div className="flex flex-col w-full gap-5 flex-2">
                      <ProductDetail
                        key={`${_id}-${product_id}`}
                        removeCartItem={() => {}}
                        productDetails={productDetails}
                        updateQuantity={() => {}}
                        handleIsGift={() => {}}
                        excludeUnderline
                        disableQuantityOption
                        hideIsGiftOption
                        trackAvailable={false}
                        note={noteMapping[note] ?? ""}
                      />
                    </div>
                    {/* <div className="flex flex-1 w-full">
                  <Review
                    orderId={_id}
                    productId={product_id}
                    previousReview={productsPreviousReviews[product_id]}
                    setRefetch={setRefetchReviews}
                  />
                </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default Order;
