import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ts
import { AxiosError } from "axios";
import { IAddCart, IUpdatedProduct, Result } from "../../types";

// utils
import axios, { processErrorCatch } from "../../axios";

// eslint-disable-next-line import/prefer-default-export
export const fetchCart = async (): Promise<Result> => {
  try {
    const resp = await axios.get(`/cart`);
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const addToCart = async (cartDetails: IAddCart): Promise<Result> => {
  try {
    const resp = await axios.post(`/cart`, {
      product_id: cartDetails.product_id,
      quantity: cartDetails.quantity,
      type: cartDetails.type,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateCart = async (
  cartDetails: IUpdatedProduct
): Promise<Result> => {
  try {
    const resp = await axios.put(`/cart/id/${cartDetails.productId}`, {
      product_id: cartDetails.productId,
      quantity: cartDetails.quantity,
      type: cartDetails.type,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    toast.error("Failed to update quantity, Please try again", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return processErrorCatch(error as AxiosError);
  }
};

export const deleteProductFromCart = async ({
  productId,
  productName,
}: {
  productId: string;
  productName: string;
}): Promise<Result> => {
  try {
    const resp = await axios.delete(`/cart/id/${productId}`);
    toast.info(`${productName || "Product"} is removed from cart`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    toast.error("Failed to remove product, Please try again", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return processErrorCatch(error as AxiosError);
  }
};
