/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import Pagination from "../../components/Pagination";
import ProductFilter from "../../components/ProductFilter";
import ProductWithImage from "../../components/ProductWithImage";
import {
  addProductToDefaultFavorites,
  getAllUserFavorites,
  removeProductFromFavorites,
} from "../../containers/Favorites/actions";
import { useAuth, useQueryParams } from "../../hooks";
import { fetchCart, fetchProducts } from "../../store/actions";
import { FETCH_ALL_FAVORITES, GET_CART } from "../../store/actions.types";
import { setCookie } from "../../utils/cookieStorage";
import { addProductToCart } from "../Product/actions";
import { CategoriesPageTypes, IPrice } from "./types";
import { AddProduct } from "../AddOrEditProduct/AddOrEditProduct.types";
import { Context } from "../../store";

const CategoriesPage = (): JSX.Element => {
  const [productList, setProductList] = useState<AddProduct[]>([]);
  const [categoryList, setCategoryList] = useState([{ name: "", value: "" }]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [productFilter, setProductFilter] = useState([10, 2000]);
  const queryParams = useQueryParams();
  const productSearch = queryParams.get("product_search") || "";
  const categorySearch = queryParams.get("category") || "";

  const productCount =
    parseInt(queryParams.get("product_count") || "0", 10) || 0;

  const { isAuthenticated } = useAuth();

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { email_verified },
      },
    },
  } = useContext(Context);

  // fetching search results
  const { mutate: fetchSearchResultsMutation, isLoading } = useMutation(
    fetchProducts as ({
      text_search,
      filters,
      limit,
      page,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CategoriesPageTypes) => Promise<any>,
    {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSuccess: ({
        data,
      }: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        categories: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        similar_categories: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resultsInfo: any;
      }) => {
        if (Array.isArray(data?.value?.data)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setProductList(data?.value?.data as any);
        }
        if (Array.isArray(data?.value?.categories)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const topCategories: any[] = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data?.value?.categories.forEach((category: any, k: number) => {
            if (k < 3) {
              topCategories.push(category);
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setCategoryList(topCategories as any);
        }
        if (Object.keys(data?.value?.resultsInfo).length !== 0) {
          const { totalCount, pageSize: apiPageSize } =
            data?.value?.resultsInfo;
          setTotalPages(totalCount);
          setPageSize(apiPageSize);
        }
      },
      onError: () => {},
    }
  );

  const { refetch: refetchCart } = useQuery(GET_CART, fetchCart, {
    enabled: false,
  });

  const { refetch: refetchAllFavorites, data: allFavorites } = useQuery(
    FETCH_ALL_FAVORITES,
    getAllUserFavorites,
    {
      enabled: isAuthenticated && !!email_verified,
    }
  );

  const { mutate: addToCartMutation, isLoading: addToCartLoading } =
    useMutation(addProductToCart, {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSuccess: ({ data }) => {
        refetchCart();
        if (!isAuthenticated) {
          setCookie("session-id", data?.headers.sessionid, 30);
        }
      },
      onError: () => {},
    });

  const { mutate: addToDefaultFavorites, isLoading: addToFavoritesLoading } =
    useMutation(addProductToDefaultFavorites, {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSuccess: ({ data }) => {
        // show success message for favorites
        refetchAllFavorites();
      },
      onError: () => {},
    });

  const { mutate: removeFromFavorites, isLoading: removeFromFavoritesLoading } =
    useMutation(removeProductFromFavorites, {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onSuccess: ({ data }) => {
        // show success message for favorites
        refetchAllFavorites();
      },
      onError: () => {},
    });

  useEffect(() => {
    const filters: { price: IPrice; category?: string } = {
      price: {
        low: productFilter[0],
        high: productFilter[1],
      },
    };
    if (categorySearch) {
      filters.category = categorySearch;
    }
    fetchSearchResultsMutation({
      text_search: productSearch,
      filters,
      limit: pageSize,
      page: pageNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch, categorySearch, pageNumber, productFilter]);

  const handleProductFilterChange = useCallback((filter) => {
    setProductFilter(filter);
  }, []);

  const handleAddToCart = ({
    productId,
    price,
  }: {
    productId: string;
    price: string;
  }) => {
    addToCartMutation({
      product_id: productId,
      quantity: 1,
      variant_id: "",
    });
  };

  const handleFavoriteClick = (productId: string, isFavorite: boolean) => {
    if (!isFavorite) {
      addToDefaultFavorites({
        item_id: productId,
        type: "products",
      });
    } else {
      removeFromFavorites({
        item_id: productId,
        type: "products",
      });
    }
  };
  const itemsLayout =
    "grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-1 gap-y-6 sm:gap-x-4 md:gap-x-5 lg:gap-x-10 lg:gap-y-8 xl:gap-x-12 xl:gap-y-10";
  const content = (
    <div className="flex flex-col gap-y-6 w-full">
      {categorySearch && (
        <h3 className="pt-4 self-start text-xl font-bold">
          Category Search:{" "}
          {categoryList.filter((category: { value: string; name: string }) => {
            return category.value === categorySearch;
          }).length > 0
            ? categoryList.filter(
                (category: { value: string; name: string }) => {
                  return category.value === categorySearch;
                }
              )[0].name
            : ""}
        </h3>
      )}
      <div className={itemsLayout}>
        {productList.map((product) => {
          const {
            _id: productId,
            name,
            image_url: imageUrl = "",
            shop_id: shopId,
            max_price: maxPrice,
            min_price: minPrice,
            product_rating: {
              rating,
              no_of_reviews: noOfReviews,
              product_rating: productRating,
            },
          } = product; //eslint-disable-line
          let actualPrice = "";
          if (maxPrice && minPrice) {
            actualPrice =
              maxPrice !== minPrice
                ? `${minPrice} - $${maxPrice}`
                : `${minPrice}`;
          }
          const allFavoritesArray = allFavorites?.data?.value;
          const isFavoriteProduct =
            allFavoritesArray && allFavoritesArray.length > 0
              ? allFavoritesArray[0]?.items?.filter(
                  // eslint-disable-next-line no-underscore-dangle
                  (favorite: string) => favorite === _id
                ).length > 0
              : false;

          return (
            <ProductWithImage
              key={productId}
              productId={productId || ""}
              shopId={shopId}
              productImage={imageUrl}
              imageAlt={name}
              headerText={name}
              productCost={actualPrice}
              enableAddToCart={false}
              productRatings={{
                starRatings: rating || productRating,
                totalReviews: noOfReviews,
              }}
              handleAddToCart={handleAddToCart}
              handleFavoriteClick={handleFavoriteClick}
              isFavorite={isFavoriteProduct}
            />
          );
        })}
      </div>
    </div>
  );
  return (
    <div className="m-auto flex mb-4 px-2 py-4 max-w-xl md:px-4 md:py-6 md:max-w-3xl lg:max-w-6xl xl:max-w-screen-2xl">
      {/* <div className="flex justify-start">Categories breadcrumbs</div> */}
      <div className="flex flex-col mt-3 items-start">
        <div className="flex w-full md:flex-1 md:w-64">
          <ProductFilter onProductFilterChange={handleProductFilterChange} />
        </div>
        <div className="flex flex-wrap m-auto gap-x-6 gap-y-6 overflow-hidden items-center justify-center">
          <Pagination
            renderContent={content}
            isContentLoading={isLoading}
            totalPages={totalPages / pageSize}
            pageNumber={pageNumber - 1}
            handlePageClick={(selectedItem) => {
              setPageNumber(selectedItem?.selected + 1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage;
