/* eslint-disable @typescript-eslint/naming-convention */
// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../../axios";
import { getUseQueryParams } from "../../../utils";

// ts
import { Result } from "../../../types";

export const fetchProductReviews = async (payload: {
  queryKey: { productId: string; pageNumber: number; pageSize: number }[];
}): Promise<Result> => {
  try {
    const { productId, pageNumber, pageSize } = getUseQueryParams(payload);
    const { data } = await axios.get(
      `/product/id/${productId}/reviews/list?pageNo=${pageNumber}&pageSize=${pageSize}`
    );
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export default fetchProductReviews;
