import { AxiosError } from "axios";
import { Result } from "../../types";

// utils
import axios, { processErrorCatch } from "../../axios";

// eslint-disable-next-line import/prefer-default-export
export const addProductToDefaultFavorites = async (payload: {
  item_id: string;
  type: string;
}): Promise<Result> => {
  try {
    const resp = await axios.post(`/watchlist/add`, payload);
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const getAllUserFavorites = async (): Promise<Result> => {
  try {
    const resp = await axios.get(`/watchlist/list`);
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const getAllUserFavoriteProducts = async (): Promise<Result> => {
  try {
    const resp = await axios.get(`/watchlist/type/products`);
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const removeProductFromFavorites = async (payload: {
  item_id: string;
  type: string;
}): Promise<Result> => {
  try {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { item_id, type } = payload;
    const resp = await axios.delete(
      `/watchlist//item/id/${item_id}/type/${type}`
    );
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
