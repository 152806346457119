import React, { useState } from "react";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";

// components
import Order from "./Order";

// actions
import { getOrdersByShop } from "../actions";
import { ChildOrder, ParentOrder } from "../../Shop.types";

const Orders = ({ shopId }: { shopId: string }): JSX.Element => {
  const pageSize = 1;
  const [pageNumber] = useState(1);
  const [showAllOrders, setShowAllOrders] = useState(false);

  const {
    data: parentOrders,
    refetch: reFetchOrdersByShop,
    isLoading,
  } = useQuery([{ id: shopId, pageNumber, pageSize }], getOrdersByShop, {
    enabled: !!shopId,
    refetchOnWindowFocus: false,
  });
  const allChildOrders = (
    (parentOrders as ParentOrder[])?.map?.(
      (parentOrder) => (parentOrder?.orders?.[0] || {}) as ChildOrder
    ) as ChildOrder[]
  )?.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  );

  const orders = showAllOrders ? allChildOrders : allChildOrders?.slice(0, 3);

  if (isLoading) {
    return (
      <div className="flex justify-center w-full">
        <ClipLoader color="#E07A5F" size={30} loading />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-col items-start md:justify-between md:items-start">
        <div className="w-full flex flex-col gap-5">
          {orders?.length > 0
            ? orders?.map((childOrder) => (
                <Order
                  order={childOrder || {}}
                  key={childOrder._id}
                  reFetchOrdersByShop={reFetchOrdersByShop}
                />
              ))
            : "No orders have been placed yet."}
        </div>
        {orders?.length > 0 && !showAllOrders ? (
          <div
            className="mt-5 cursor-pointer flex text-contrast-color-1-100 w-full justify-end"
            role="button"
            tabIndex={0}
            onKeyDown={() => null}
            onClick={() => setShowAllOrders(true)}
          >
            View all orders...
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Orders;
