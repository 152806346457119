import { AxiosError } from "axios";
import { Result } from "../../types";

// utils
import axios, { processErrorCatch } from "../../axios";

// eslint-disable-next-line import/prefer-default-export
export const getCheckoutSession = async (): Promise<Result> => {
  try {
    const resp = await axios.get(`/cart/checkout`);
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
