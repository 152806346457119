import React from "react";
import { InputProps } from "./types";

const ProductLabel = ({ labelColor, labelValue }: InputProps): JSX.Element => {
  return (
    <div
      className={`py-1 px-1 rounded-full text-xs border border-solid 
      border-primary-color-100 border-opacity-10 bg-${labelColor}-400`}
      style={{ fontSize: "8px" }}
    >
      {labelValue}
    </div>
  );
};

export default ProductLabel;
