/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

// packages
import { ClipLoader } from "react-spinners";

export interface Props {
  onSelect: (searchResultString: string) => void;
  showDefaultSearches: boolean;
  IsNoResultsFound: boolean;
  isLoading: boolean;
  searchValue: string;
  searchResultsCount?: number;
}

const SearchResults = ({
  onSelect,
  isLoading,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showDefaultSearches,
  searchValue,
  IsNoResultsFound,
  searchResultsCount = 0,
}: Props): JSX.Element => {
  const isDefaultSearch = searchResultsCount === 0;

  const getHeaderMessage = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col p-3 text-sm not-italic font-light leading-7 text-primary-color-100 font-opensans">
          <div className="flex items-center">Loading.....</div>
          <div className="flex items-center justify-center">
            <ClipLoader color="#000" loading size={40} />
          </div>
        </div>
      );
    }
    if (IsNoResultsFound) {
      return (
        <div className="flex p-3 text-sm not-italic font-light leading-7 text-primary-color-100 font-opensans">
          {`No results found for ${
            searchValue.length > 10
              ? `${searchValue.substr(0, 10)}...`
              : searchValue
          }`}
        </div>
      );
    }
    return isDefaultSearch ? (
      <div className="flex p-3 text-xl not-italic font-light leading-9 font-poppins text-primary-color-100">
        Popular searches in your area
      </div>
    ) : (
      <div />
    );
  };

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        border: "1px solid rgba(63, 65, 91, 0.25)",
        boxSizing: "border-box",
        borderRadius: 6,
        width: "inherit",
        background: "white",
        maxHeight: 300,
        overflow: "auto",
      }}
    >
      {getHeaderMessage()}
      {!IsNoResultsFound && (
        <div
          className={`flex pt-${!isDefaultSearch && 2} hover:bg-primary-color-20
               cursor-pointer p-3`}
          onClick={() => onSelect(searchValue)}
        >
          <div className="flex w-11/12">
            <div className="flex">
              <div className="text-sm not-italic text-black truncate whitespace-nowrap font-opensans">
                Results found: {searchResultsCount}
              </div>
            </div>
            <div className="flex ml-auto">
              {searchValue && (
                <div className="text-sm not-italic truncate whitespace-nowrap text-primary-color-50 font-opensans">
                  (Press Enter to view).
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
