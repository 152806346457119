import React, { useState } from "react";
import Slider from "../Slider";
import { InputProps, FilterParams } from "./types";

const ProductFilter = ({ onProductFilterChange }: InputProps): JSX.Element => {
  const [selectedPriceFilter, setSelectedPriceFilter] = useState<
    FilterParams[]
  >([]);
  const onSliderAfterChange = (data: FilterParams[]) => {
    setSelectedPriceFilter(data);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onProductFilterChange(data);
  };
  return (
    <div className="flex flex-col m-4 w-full">
      <div className="flex flex-col">
        <p className="text-xl mb-2">Price</p>
        <Slider
          min={0}
          max={5000}
          defaultValue={[10, 2000]}
          sliderWrapperClass="w-full"
          unit="$"
          marks={{ 0: 0, 5000: 5000 }}
          onSliderAfterChange={onSliderAfterChange}
        />
        <div className="flex flex-row justify-between mt-5 mb-2">
          <p>Min: ${selectedPriceFilter[0] || 10}</p>
          <p>Max: ${selectedPriceFilter[1] || 2000}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
