import React, { FC } from "react";

// packages
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "react-query";

// components
import Button from "../../components/Button";

// actions
import { fetchUserProfile, fetchShopDetails } from "../../store/actions";

// types
import { GET_USER } from "../../store/actions.types";

const MyShop: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const pathnameTokens = pathname.split("/");
  const shopId = pathnameTokens[pathnameTokens.length - 1];

  const { data: shopDetailsResp, isLoading } = useQuery(
    [{ id: shopId }],
    fetchShopDetails
  );

  useQuery(GET_USER, fetchUserProfile);

  const isValidShop = shopDetailsResp?.data?.value?._id === shopId;

  return (
    <div className="flex flex-col items-center">
      {isValidShop ? (
        <div className="flex flex-col items-center gap-6 m-5">
          <div className="flex font-poppins font-poppins font-semibold text-3xl">
            Congratulations!
          </div>
          <div className="flex font-poppins font-poppins font-light text-xl">
            You becoma a part of Lonima sellers family.
          </div>
          <div className="flex font-poppins font-poppins font-light text-xl">
            Please add items and payment information in the Shop settings
          </div>
          <div className="flex w-3/12">
            <Button
              containerClassName="flex w-full"
              text="View my shop"
              size="lg"
              type="red"
              onClick={() => history.push(`/shop/${shopId}`)}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center m-5">
          <div className="flex font-poppins font-poppins font-semibold text-3xl">
            {!isLoading ? "Shop does not exist!" : ""}
          </div>
        </div>
      )}
    </div>
  );
};
export default MyShop;
