import { Position } from "./PopOver.types";

export const baseArrowStyle = {
  backgroundColor: "#FFF",
  height: 32,
  width: 32,
  position: "absolute",
};

const extraMargin = -7;

export const getArrowPositionStyle = (
  position?: Position
): {
  top: string | number;
  left?: string | number;
  right?: string | number;
  borderLeft: string;
  borderTop: string;
  transform: string;
} => {
  switch (position) {
    case "left":
      return {
        left: extraMargin,
        top: 0,
        borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        transform: "translate(50%, -50%) rotate(45deg)",
      };
    case "center":
      return {
        left: "50%",
        top: 0,
        borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        transform: "translate(-50%, -50%) rotate(45deg)",
      };
    case "right":
      return {
        right: extraMargin,
        top: 0,
        borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        transform: "translate(-50%, -50%) rotate(45deg)",
      };
    default:
      return { top: "", borderLeft: "", borderTop: "", transform: "" };
  }
};
