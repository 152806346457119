/* eslint-disable import/prefer-default-export */
import React, { useContext, useMemo, useState } from "react";

// packages
import { useQuery } from "react-query";

// components
import Tab from "../../../components/Tab";
import ShopProducts from "./ShopProducts";
import ShopAbout from "./ShopAbout";
import Payments from "./Payments";
import Orders from "./Orders";

// actions
import { fetchProductsByShop } from "./actions";
import { fetchPayments } from "../Profile/actions";

// ts
import { ListData } from "../../../types";
import { Context } from "../../../store";
import ShopContext from "../Shop.context";
import { useAuth } from "../../../hooks";

export const Products = (): JSX.Element => {
  const pageSize = 20;
  const [pageNumber, setPageNumber] = useState(1);

  const {
    state: {
      user: {
        profile: { shops },
      },
    },
  } = useContext(Context);

  const { id: shopId } = useContext(ShopContext);
  const { isAuthenticated } = useAuth();

  const isUserBelongToShop = useMemo(
    () => shopId === shops?.[0]?.shop_id,
    [shops, shopId]
  );

  const {
    data: productsInShop,
    isFetching,
    refetch: refetchProductsByShop,
  } = useQuery(
    [{ id: shopId, featured: false, pageNumber, pageSize }],
    fetchProductsByShop,
    {
      enabled: !!shopId,
      refetchOnWindowFocus: false,
    }
  );

  const { data: payments, isFetching: isFetchingPayments } = useQuery(
    "FETCH_PAYMENTS",
    fetchPayments,
    {
      enabled: isUserBelongToShop && isAuthenticated,
      refetchOnWindowFocus: false,
    }
  );

  const tabs = [
    { display: "Products", value: "all-products", hide: false },
    { display: "About", value: "about", hide: false },
    { display: "Orders", value: "orders", hide: !isUserBelongToShop },
    { display: "Payments", value: "payments", hide: !isUserBelongToShop },
  ];

  const [currentTabValue, setCurrentTabValue] = useState(tabs[0].value);

  const onSelect = (tabValue: string) => {
    setCurrentTabValue(tabValue);
  };

  const allProductsInShop = productsInShop?.data?.value;

  const getTabs = () => {
    switch (currentTabValue) {
      case "all-products":
        return (
          <ShopProducts
            shopId={shopId}
            pageNumber={pageNumber}
            isLoading={isFetching}
            products={allProductsInShop as ListData}
            onPageChange={(page) => setPageNumber(page)}
            refetchProductsByShop={refetchProductsByShop}
            isUserBelongToShop={isUserBelongToShop}
          />
        );
      case "orders":
        return <Orders shopId={shopId} />;
      case "about":
        return <ShopAbout />;
      case "payments":
        return (
          <Payments
            payments={payments?.data?.value}
            isLoading={isFetchingPayments}
          />
        );
      default:
        return (
          <ShopProducts
            shopId={shopId}
            pageNumber={pageNumber}
            isLoading={isFetching}
            products={allProductsInShop as ListData}
            onPageChange={(page) => setPageNumber(page)}
            refetchProductsByShop={refetchProductsByShop}
            isUserBelongToShop={isUserBelongToShop}
          />
        );
    }
  };

  return (
    <div className="flex w-full max-w-2xl py-4 mb-4 md:py-6 md:max-w-3xl lg:max-w-5xl xl:max-w-screen-2xl md:w-full">
      <div className="flex flex-col w-full p-1 overflow-x-hidden md:m-6 md:flex-grow">
        <div className="flex mb-5 border-b border-opacity-25 border-primary-color-100">
          {tabs.map((tab) => {
            if (tab.hide) {
              return null;
            }
            return (
              <Tab
                key={tab.value}
                option={tab}
                isSelected={currentTabValue === tab.value}
                onClick={onSelect}
              />
            );
          })}
        </div>
        <div className="flex w-full">{getTabs()}</div>
      </div>
    </div>
  );
};
