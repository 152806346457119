/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import { toast } from "react-toastify";
import { useWindowSize } from "react-use";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";
import { capitalize, cloneDeep } from "lodash";
import { useMutation, RefetchOptions, QueryObserverResult } from "react-query";
import { AxiosError } from "axios";
import Modal from "../../../../../components/Modal";
import { ChildOrder, ParentOrder, Shipment } from "../../../Shop.types";
import Dropdown from "../../../../../components/Dropdown";
import styles from "../Order.module.css";
import { deleteIcon } from "../../../../../assets";
import Button from "../../../../../components/Button";
import TextInput from "../../../../../components/TextInput";
import { isPositiveNumber } from "../../../../AddOrEditProduct/ItemDetails/Variants/Variants";
import { TypeOfState } from "../../../../../components/TextInput/TextInput.utils";
import { createShipment } from "../../actions";

export const ORDER_STATUSES = [
  "PENDING",
  "PLACED",
  "SHIPPING_LABEL_CREATED",
  "COMPLETED",
  "FAILED",
  "CANCELED",
];

export const SHIPPING_CARRIERS = ["FEDEX", "UPS", "USPS"];

interface VariantRecord {
  id: string;
  name: string;
  status: string;
  quantity: number;
  price: number;
  url: string;
  productId: string;
  variant: { [key: string]: string };
}

export const getInputState = (
  value: string,
  valueLimit: number
): { validationType: TypeOfState; message: string } => {
  if (!isPositiveNumber(value)) {
    return { validationType: "error", message: "Please enter a valid number" };
  }
  if (+value > valueLimit) {
    return {
      validationType: "error",
      message: "Quantity cannot be more than available",
    };
  }
  return { validationType: "normal", message: " " };
};

const Shipments = ({
  isOpen,
  onClose,
  order,
  reFetchOrdersByShop,
}: {
  isOpen: boolean;
  onClose: () => void;
  order: ChildOrder;
  reFetchOrdersByShop: (
    options?: RefetchOptions | undefined
  ) => Promise<
    QueryObserverResult<AxiosError<unknown, unknown> | ParentOrder[], unknown>
  >;
}): JSX.Element => {
  const [shipmentLineItems, setShipmentLineItems] = useState<{
    [key: string]: {
      variantId: string;
      value: string;
      quantity: number;
    };
  }>({});
  const [trackingNumber, setTrackingNumber] = useState("");
  const [shippingProvider, setShippingProvider] = useState("");
  const [products, setProducts] = useState<VariantRecord[]>([]);
  const { width } = useWindowSize();
  const isSmallDevices = width < 768;
  const shipments = order?.shipments || [];
  const shippingAddress = order?.shipping_address;

  const shipmentsWithDetails = shipments.reduce(
    (shipmentsUpdated, shipment) => {
      const newShipment = cloneDeep(shipment);
      const { variants = [] } = shipment;
      variants.forEach((variantObj, index) => {
        const orderProduct = order.product_details.find(
          (product) => product.variant_id === variantObj.variant_id
        );
        if (orderProduct) {
          const {
            details: { name, image_url: imageUrl },
            variant,
          } = orderProduct;
          const variantDetails = `${name}${
            variant ? ` - ${Object.values(variant).join(",")}` : ""
          }`;
          newShipment.variants[index] = {
            ...shipment.variants[index],
            name: variantDetails,
            imageUrl,
          };
        }
      });
      shipmentsUpdated.push(newShipment);
      return shipmentsUpdated;
    },
    [] as Shipment[]
  );
  // to keep track of shipped variants and their quantities
  const shippedVariantsWithQuantities = shipmentsWithDetails.reduce(
    (acc, shipment) => {
      const { variants } = shipment;
      variants.forEach((variant) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { variant_id, quantity } = variant;
        if (acc[variant_id]) {
          acc[variant_id] += quantity;
        } else {
          acc[variant_id] = quantity;
        }
      });
      return acc;
    },
    {} as { [key: string]: number }
  );

  const availableVariants = products.map(({ name, id }) => ({
    name,
    id,
  }));

  const variantsKeys = Object.keys(shippedVariantsWithQuantities);

  const { mutate: creatShipmentMutation, isLoading } = useMutation(
    createShipment,
    {
      onSuccess: () => {
        reFetchOrdersByShop();
        toast.success("Shipment created successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShipmentLineItems({});
        setShippingProvider("");
        setTrackingNumber("");
      },
      onError: (err: Error) => {
        toast.error(`${err.message} Please try again`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    }
  );

  useEffect(() => {
    const transformedProducts = order?.product_details.reduce(
      (acc, product) => {
        const { variant = {}, details, quantity, price } = product;
        const variantDesc = Object.values(variant).join(", ");
        // remove the shipped quantity from the total quantity
        const shippedQuantity =
          shippedVariantsWithQuantities[product.variant_id] || 0;
        acc.push({
          variant,
          id: product.variant_id,
          productId: product.product_id,
          name: `${details.name}${variantDesc ? ` - ${variantDesc}` : ""}`,
          status: "",
          quantity: quantity - shippedQuantity,
          price,
          url: "",
        });
        return acc;
      },
      [] as VariantRecord[]
    );
    setProducts(transformedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.product_details, shipments]);

  const shipmentColumns = useMemo(
    () => [
      {
        Header: "Shipments",
        columns: [
          {
            Header: "Shipping Provider",
            accessor: "shipping_provider",
          },
          {
            Header: "Tracking Number",
            accessor: "tracking_number",
          },
          {
            Header: "Products",
            accessor: (shipmentRecord: Shipment) => (
              <div className="flex flex-col gap-4">
                <table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shipmentRecord?.variants?.map((variant) => (
                      <tr key={variant.variant_id}>
                        <td>
                          <div
                            className={`flex ${
                              isSmallDevices ? "flex-col" : ""
                            } gap-4`}
                          >
                            <img
                              src={variant.imageUrl || ""}
                              alt={variant.name}
                              width={36}
                              height={24}
                            />
                            {variant.name}
                          </div>
                        </td>
                        <td>{variant.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
    ],
    [isSmallDevices]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Products",
        columns: [
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Quantity",
            accessor: "quantity",
          },
          {
            Header: "Price",
            accessor: "price",
          },
        ],
      },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data:
        products.length > 0
          ? products.map((variantsRecord) => {
              return {
                ...variantsRecord,
              };
            })
          : [],
    });

  const {
    getTableProps: getShipmentTableProps,
    getTableBodyProps: getShipmentTableBodyProps,
    headerGroups: shipmentHeaderGroups,
    rows: shipmentRows,
    prepareRow: prepareShipmentRow,
  } = useTable({
    columns: shipmentColumns,
    data:
      shipmentsWithDetails.length > 0
        ? shipmentsWithDetails.map((variantsRecord) => {
            return {
              ...variantsRecord,
            };
          })
        : [],
  });

  const initializeShipment = () => {
    setShipmentLineItems({
      [uuid()]: {
        variantId: "",
        value: "",
        quantity: 0,
      },
    });
  };
  const shipmentKeys = Object.keys(shipmentLineItems);
  const alreadyPopulatedIds = Object.values(shipmentLineItems).map(
    (shipmentLineItem) => shipmentLineItem.variantId
  );
  const { notAvailableProducts, availableProducts } = products.reduce(
    (acc, product) => {
      if (product.quantity === 0) {
        acc.notAvailableProducts.push(product.id);
      } else {
        acc.availableProducts.push(product.id);
      }
      return acc;
    },
    { notAvailableProducts: [], availableProducts: [] } as {
      notAvailableProducts: string[];
      availableProducts: string[];
    }
  );
  const variantOptions = (
    availableVariants.map(({ name, id }) => ({
      label: name,
      value: id,
    })) || []
  ).filter(
    (variant) =>
      !alreadyPopulatedIds.includes(variant.value) &&
      !notAvailableProducts.includes(variant.value)
  );
  const disabled = Object.values(shipmentLineItems).some(
    (shipmentLintItem) =>
      !shipmentLintItem.variantId ||
      getInputState(
        shipmentLintItem.quantity.toString(),
        products.find((product) => product.id === shipmentLintItem.variantId)
          ?.quantity || 0
      ).validationType === "error" ||
      !shippingProvider ||
      !trackingNumber
  );

  const disableAddShipmentLineItem =
    shipmentKeys.length === variantsKeys.length;

  return (
    <Modal
      overrideModalStyle="h-5/6 w-full max-w-7xl"
      overrideModalContainerStyle="items-center"
      isForceClose={!isOpen}
      onClose={onClose}
    >
      <div className="px-12">
        <div className="flex flex-col">
          <div className="pb-4">
            <h4 className="flex mt-6 mb-2">Manage Shipments</h4>
            <p className="flex text-sm font-normal text-left font-opensans">
              Manage and View Order Shipments.
            </p>
            <div className="mt-4">
              <div className="flex justify-between gap-5">
                {products.length > 0 && availableProducts.length > 0 && (
                  <div className={styles.table}>
                    <table
                      {...getTableProps()}
                      style={{
                        overflowX: "auto",
                        width: "100%",
                      }}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            // eslint-disable-next-line react/prop-types
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start mt-8">
              <h4 className="pb-2">Shipping Address</h4>
              {shippingAddress?.address_ln1 && (
                <p>{shippingAddress?.address_ln1}</p>
              )}
              {shippingAddress?.address_ln2 && (
                <p>{shippingAddress?.address_ln2}</p>
              )}
              {shippingAddress?.city && (
                <p>
                  {shippingAddress?.city}, {shippingAddress?.state},{" "}
                  {shippingAddress?.zip_code}
                </p>
              )}
            </div>
            {shipments.length > 0 && (
              <div className="mt-12">
                <div className="flex justify-between gap-5">
                  {shipments.length > 0 && (
                    <div className={styles.table}>
                      <table
                        {...getShipmentTableProps()}
                        style={{
                          overflowX: "auto",
                          width: "100%",
                        }}
                      >
                        <thead>
                          {shipmentHeaderGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                  {column.render("Header")}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getShipmentTableBodyProps()}>
                          {shipmentRows.map((row) => {
                            prepareShipmentRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
            {shipmentKeys.length > 0 && (
              <div className="mt-8">
                <div
                  className="flex flex-col gap-4 p-5 pt-8 overflow-x-auto border border-solid border-primary-color-100 border-opacity-10"
                  style={{ width: "fit-content" }}
                >
                  <div className="flex flex-col gap-4">
                    <Dropdown
                      type="primary"
                      title="Select Shipping Provider"
                      isRequired
                      isClearable
                      textSize="sm"
                      size={isSmallDevices ? "sm" : "lg"}
                      preSelectedOptions={[
                        {
                          label: shippingProvider,
                          value: shippingProvider,
                        },
                      ]}
                      options={SHIPPING_CARRIERS.map((carrier) => ({
                        label: capitalize(carrier),
                        value: carrier,
                      }))}
                      onChange={(selValue) => {
                        setShippingProvider(selValue?.value as string);
                      }}
                    />
                    <TextInput
                      isRequired
                      inputId="tracking_number"
                      label="Tracking Number"
                      textInputType="text"
                      containerStyleClass="w-5/12"
                      value={trackingNumber}
                      typeOfState={!trackingNumber ? "error" : "normal"}
                      infoMessage={
                        !trackingNumber
                          ? "Please enter a valid tracking number"
                          : " "
                      }
                      size="sm"
                      placeholder=""
                      overrideInfoMessageStyleClass=""
                      onChange={(val) => setTrackingNumber(val)}
                    />
                    {shipmentKeys.map((shipmentId) => {
                      const { variantId, value, quantity } =
                        shipmentLineItems[shipmentId];
                      const deleteShipment = () => {
                        if (shipmentKeys.length > 1) {
                          setShipmentLineItems((prevShipmentLineItems) => {
                            const tempLineItems = {
                              ...prevShipmentLineItems,
                            };
                            delete tempLineItems[shipmentId];
                            return tempLineItems;
                          });
                        }
                      };
                      const { validationType, message } = getInputState(
                        quantity.toString() || "0",
                        products.find((product) => product.id === variantId)
                          ?.quantity || 0
                      );
                      return (
                        <div className="flex gap-4" key={shipmentId}>
                          <div className="flex items-start w-full gap-4 mb-5 border-b">
                            <Dropdown
                              type="primary"
                              title="Select Product"
                              isRequired
                              isClearable
                              textSize="sm"
                              size={isSmallDevices ? "sm" : "lg"}
                              preSelectedOptions={[
                                {
                                  label: value,
                                  value,
                                },
                              ]}
                              options={variantOptions}
                              onChange={(selValue) => {
                                setShipmentLineItems(
                                  (prevShipmentLineItems) => {
                                    const updatedShipmentLineItems = {
                                      ...prevShipmentLineItems,
                                    };
                                    updatedShipmentLineItems[shipmentId] = {
                                      ...updatedShipmentLineItems[shipmentId],
                                      value: selValue?.label as string,
                                      variantId: selValue?.value as string,
                                    };
                                    return updatedShipmentLineItems;
                                  }
                                );
                              }}
                            />
                            <TextInput
                              isRequired
                              inputId="quantity"
                              label="Quantity"
                              textInputType="text"
                              value={quantity.toString()}
                              typeOfState={validationType}
                              infoMessage={message}
                              size="sm"
                              placeholder="0"
                              overrideInfoMessageStyleClass=""
                              onChange={(val) => {
                                setShipmentLineItems(
                                  (prevShipmentLineItems) => {
                                    const updatedShipmentLineItems = {
                                      ...prevShipmentLineItems,
                                    };
                                    updatedShipmentLineItems[shipmentId] = {
                                      ...updatedShipmentLineItems[shipmentId],
                                      quantity: +val,
                                    };
                                    return updatedShipmentLineItems;
                                  }
                                );
                              }}
                            />
                            <div
                              role="button"
                              tabIndex={0}
                              className={`flex flex-shrink-0 cursor-${
                                shipmentKeys.length > 1
                                  ? "pointer"
                                  : "not-allowed"
                              } mt-5`}
                              onKeyDown={deleteShipment}
                              onClick={deleteShipment}
                            >
                              <img src={deleteIcon} alt="delete" width={24} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {availableProducts.length > 0 && (
              <div className="flex flex-col w-full gap-4 pt-8">
                {shipmentKeys.length === 0 ? (
                  <span
                    className="text-left underline cursor-pointer"
                    onClick={initializeShipment}
                    onKeyDown={initializeShipment}
                    role="button"
                    tabIndex={0}
                  >
                    Create Shipment
                  </span>
                ) : (
                  <div className="flex justify-between">
                    <span
                      className={`cursor-${
                        disableAddShipmentLineItem ? "not-allowed" : "pointer"
                      } underline text-left`}
                      onClick={() => {
                        if (disableAddShipmentLineItem) {
                          return;
                        }
                        setShipmentLineItems((prevShipmentLineItems) => {
                          return {
                            ...prevShipmentLineItems,
                            [uuid()]: {
                              variantId: "",
                              value: "",
                              quantity: 0,
                            },
                          };
                        });
                      }}
                      onKeyDown={() => null}
                      role="button"
                      tabIndex={0}
                    >
                      {isSmallDevices
                        ? "+Add Shipment"
                        : "+Add Shipment Line Item"}
                    </span>
                    <Button
                      text={isSmallDevices ? "Confirm" : "Confirm Shipment"}
                      size="sm"
                      type="primary"
                      disabled={disabled}
                      loading={isLoading}
                      onClick={() => {
                        if (!disabled) {
                          creatShipmentMutation({
                            order_id: order._id,
                            tracking_number: trackingNumber,
                            shipping_provider: shippingProvider,
                            variants: Object.values(shipmentLineItems).map(
                              (shipmentLintItem) => ({
                                variant_id: shipmentLintItem.variantId,
                                quantity: shipmentLintItem.quantity,
                                product_id:
                                  products.find(
                                    (product) =>
                                      product.id === shipmentLintItem.variantId
                                  )?.productId || "",
                              })
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Shipments;
