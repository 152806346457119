// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../../axios";

// ts
import { Result } from "../../../types";
import { ParentOrder } from "../Shop.types";

// eslint-disable-next-line import/prefer-default-export
export const fetchProductsByShop = async (payload: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: any;
}): Promise<Result> => {
  try {
    const { id, featured, pageNumber, pageSize } = payload.queryKey[0];
    let url = featured
      ? `/product/list/shop/id/${id}?featured=true`
      : `/product/list/shop/id/${id}?`;
    if (pageNumber) {
      url += `&pageNo=${pageNumber}`;
    }
    if (pageSize) {
      url += `&pageSize=${pageSize}`;
    }
    const resp = await axios.get(url);
    return await new Promise((resolve) =>
      resolve({ data: { value: resp.data } })
    );
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const getOrdersByShop = async (payload: {
  queryKey: { [key: string]: unknown }[];
}): Promise<ParentOrder[] | AxiosError> => {
  try {
    const { id, pageNumber, pageSize } = payload.queryKey[0];
    let url = `/order/shop/id/${id}/list`;
    if (pageNumber) {
      url += `?pageNo=${pageNumber}`;
    }
    if (pageSize) {
      url += `${pageNumber ? "&" : "?"}pageSize=${pageSize}`;
    }
    const { data = [] } = await axios.get(url);
    return await Promise.resolve(data as ParentOrder[]);
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateOrderStatus = async ({
  id,
  status,
  variantIds = [],
  note = "",
}: {
  id: string;
  status: string;
  variantIds?: string[];
  note?: string;
}): Promise<ParentOrder[]> => {
  try {
    const payload: { [key: string]: string | string[] } = { status, note };
    if (variantIds.length) {
      payload.variant_ids = variantIds;
    }
    const { data = [] } = await axios.put(`/order/id/${id}/status`, payload);
    return await Promise.resolve(data as ParentOrder[]);
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const createShipment = async ({
  order_id,
  tracking_number,
  shipping_provider,
  variants,
}: {
  order_id: string;
  tracking_number: string;
  shipping_provider: string;
  variants: { variant_id: string; quantity: number; product_id: string }[];
}): Promise<ParentOrder[]> => {
  try {
    const { data = [] } = await axios.post(`/order/id/${order_id}/shipment`, {
      tracking_number,
      shipping_provider,
      variants,
    });
    return await Promise.resolve(data as ParentOrder[]);
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateProductQuantity = async ({
  orderId,
  variantId,
  quantity,
  note,
}: {
  orderId: string;
  variantId: string;
  note: string;
  quantity: number;
}): Promise<ParentOrder[]> => {
  try {
    const { data = [] } = await axios.put(
      `/order/id/${orderId}/item/id/${variantId}/quantity`,
      { quantity, note }
    );
    return await Promise.resolve(data as ParentOrder[]);
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
