/* eslint-disable import/prefer-default-export */
export const validateEmail = (email: string): boolean => {
  return /\S+@\S+\.\S+/.test(email);
};

export const validatePhone = (phone: string): boolean => {
  return /^\+1 \(\d{3}\) \d{3}-\d{4}$/.test(phone);
};

export const validateName = (name: string): boolean => {
  return /^[a-z ,.'-]+$/i.test(name);
};

// should contain atleast 1 number, 1 special character and 8 characters in length
export const validatePassword = (
  value: string,
  excludeValues: string[] = []
): boolean => {
  return (
    /^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value) &&
    !excludeValues
      .filter((val) => val !== "")
      .some((excludeValue) =>
        value.toLowerCase().includes(excludeValue.toLowerCase())
      )
  );
};

export const convertBlobToFile = (blobImage: Blob, fileName: string): File => {
  const file = new File([blobImage], fileName, { type: blobImage.type });
  return file;
};

export const convertUrlToBlob = async (url: string): Promise<void | Blob> => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const name = url.split("/").pop() || "";
      const blobImg: Blob & { name: string; originalname: string } =
        blob as Blob & { name: string; originalname: string };
      blobImg.name = name;
      blobImg.originalname = name;
      return blob;
    })
    .catch((err: Error) => {
      // eslint-disable-next-line no-console
      console.error("converting url to blob", err?.toString());
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KV = { [key: string]: any };

export const getKeyValues = (payload: KV, keys: string[]): KV => {
  return keys.reduce((acc, profileKey) => {
    const value = payload[profileKey] as never;
    const isValidValue = !Number.isNaN(value) ? !!value : true;
    if (isValidValue) {
      acc[profileKey] = value;
    }
    return acc;
  }, {} as KV);
};

export const setKeyValues = (payload: KV, keysToUpdate: string[]): KV => {
  const payloadKeys = Object.keys(payload);
  return keysToUpdate.reduce((acc, keyToUpdate) => {
    if (payloadKeys.includes(keyToUpdate)) {
      acc[keyToUpdate] = payload[keyToUpdate];
    }
    return acc;
  }, payload);
};

type KeyValueType = { [key: string]: unknown };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUseQueryParams = (paylod: { queryKey: any }): KeyValueType => {
  return (paylod?.queryKey || [])?.reduce(
    (acc: KeyValueType, curr: KeyValueType) => {
      const queryKeys = Object.keys(curr || {});
      queryKeys.forEach((key) => {
        acc[key] = curr[key];
      });
      return acc;
    },
    {} as KeyValueType
  );
};

export const isNumeric = (n: string | number, hasDecimals = true): boolean => {
  return (
    /^-?\d+$/.test(n.toString()) ||
    (hasDecimals ? /^\d+\.\d+$/.test(n.toString()) : false)
  );
};

export const isJsonString = (str: string): boolean => {
  try {
    const obj = JSON.parse(str);
    if (typeof obj === "object") {
      return true;
    }
  } catch (e) {
    return false;
  }
  return true;
};

export const validateSocialMediaProfileUrl = (value: string): boolean => {
  return /^(https?:\/\/)?(www\.)?(facebook|instagram|twitter)\.com\/[a-zA-Z0-9(\\.\\?)?]/.test(
    value
  );
};
