/* eslint-disable import/prefer-default-export */
import { AxiosError } from "axios";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { Result, AddressPayload } from "../../types";

// utils
import axios, { processErrorCatch } from "../../axios";
import { getUseQueryParams, getItem } from "../../utils";

export const checkoutCart = async (): Promise<Result> => {
  try {
    const resp = await axios.post("/cart/checkout");
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const saveCardForFuture = async ({
  setup_intent_id,
  save_for_future_use,
}: {
  setup_intent_id: string;
  save_for_future_use: boolean;
}): Promise<Result> => {
  try {
    const resp = await axios.post("/payment/saveForFutureUse", {
      setup_intent_id,
      save_for_future_use,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchPaymentMethod = async (payload: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: any;
}): Promise<Result> => {
  try {
    const paymentIntentURL = getItem("paymentIntentURL") || "";
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { setup_intent_id = "" } = getUseQueryParams(payload);
    const resp = await axios.post(`/payment/${paymentIntentURL}`, {
      setup_intent_id,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchSavedPaymentMethod = async (payload: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: any;
}): Promise<Result> => {
  try {
    const { checkoutSessionId } = payload.queryKey[0];
    const resp = await axios.get(
      `/payment/${checkoutSessionId}/paymentDetails`
    );
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const createSetupIntent = async ({
  checkoutSessionId,
}: {
  checkoutSessionId: string;
}): Promise<Result> => {
  try {
    const resp = await axios.post(`/payment/setupIntent`, {
      checkout_session_id: checkoutSessionId,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateCheckoutSession = async ({
  checkoutSessionId,
  paymentMethodId,
}: {
  checkoutSessionId: string;
  paymentMethodId: string;
}): Promise<Result> => {
  try {
    const resp = await axios.put(`/cart/checkout`, {
      checkout_session_id: checkoutSessionId,
      payment_method_id: paymentMethodId,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateDuplicatePaymentMethod = async ({
  paymentMethodId,
}: {
  paymentMethodId: string;
}): Promise<Result> => {
  try {
    const resp = await axios.post(`/payment/updateDuplicatePaymentMethods`, {
      payment_method_id: paymentMethodId,
    });
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const getTax = async ({
  checkoutId,
  shippingAddress,
}: {
  checkoutId: string;
  shippingAddress: AddressPayload;
}): Promise<Result> => {
  try {
    const resp = await axios.post("/cart/checkout/review", {
      checkout_id: checkoutId,
      shipping_address: {
        address_ln1: shippingAddress.address_ln1,
        address_ln2: shippingAddress.address_ln2,
        city: shippingAddress.city,
        zip_code: shippingAddress.zip_code,
        state: shippingAddress.state,
      },
    });
    return resp.data;
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const handle3DSStripePayment = async ({
  stripe,
  clientSecret,
  orderId,
}: {
  stripe: Stripe;
  clientSecret: string;
  orderId: string;
}): Promise<Result> => {
  try {
    const { error, paymentIntent } = await stripe.handleNextAction({
      clientSecret,
    });
    if (error) {
      return await Promise.reject(error);
    }
    return { data: { value: { paymentIntent, orderId } } };
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const placeOrder = async ({
  checkout_session_id,
  payment_method_id,
  email,
  fullName,
  ship_to,
  billing_address,
}: {
  checkout_session_id: string;
  payment_method_id?: string;
  email?: string;
  fullName?: string;
  ship_to: string | AddressPayload;
  billing_address?: AddressPayload;
}): Promise<Result> => {
  const reqBody = email
    ? {
        checkout_session_id,
        email,
        full_name: fullName,
        shipping_address: ship_to,
        payment_method_id,
        billing_address,
      }
    : {
        checkout_session_id,
        shipping_address_id: ship_to,
        payment_method_id,
        billing_address,
      };
  const resp = await axios.post("/order", reqBody);
  return resp.data;
};

export const createPaymentAndOrder = async ({
  stripe,
  elements,
  paymentMethodParams,
  isAuthenticated,
  checkoutSessionId,
  shippingAddressID,
  guestUserEmail,
  guestUserFullName,
  newShippingAddress,
  paymentMethodId,
  billingAddress,
}: {
  stripe: Stripe;
  elements: StripeElements;
  paymentMethodParams: {
    billing_details: {
      name: string;
      address: {
        line1: string;
        line2: string;
        city: string;
        state: string;
        postal_code: string;
        country: string;
      };
    };
  } | null;
  isAuthenticated: boolean;
  checkoutSessionId: string;
  shippingAddressID?: string;
  guestUserEmail?: string;
  guestUserFullName?: string;
  newShippingAddress?: string | AddressPayload;
  paymentMethodId?: string;
  billingAddress?: AddressPayload;
}): Promise<Result> => {
  try {
    let paymentMethodNew = null;
    let orderResp = null;
    // No payment method id means adding new card or guest user
    // so we need to create payment method
    if (!paymentMethodId) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError);
      }
      const resp = await stripe.createPaymentMethod({
        elements,
        params: paymentMethodParams,
      });
      const { error, paymentMethod } = resp;
      if (error) {
        throw new Error(error);
      }
      paymentMethodNew = paymentMethod;
    }

    if (isAuthenticated) {
      orderResp = await placeOrder({
        checkout_session_id: checkoutSessionId,
        ship_to: shippingAddressID ?? newShippingAddress,
        payment_method_id: paymentMethodId || paymentMethodNew?.id,
        billing_address: billingAddress || {
          full_name: paymentMethodParams.billing_details.name,
          address_ln1: paymentMethodParams.billing_details.address.line1,
          address_ln2: paymentMethodParams.billing_details.address.line2,
          city: paymentMethodParams.billing_details.address.city,
          state: paymentMethodParams.billing_details.address.state,
          zip_code: paymentMethodParams.billing_details.address.postal_code,
          country: paymentMethodParams.billing_details.address.country,
        },
      });
    } else {
      // for guest users
      orderResp = await placeOrder({
        checkout_session_id: checkoutSessionId,
        email: guestUserEmail,
        fullName: guestUserFullName,
        ship_to: newShippingAddress,
        payment_method_id: paymentMethodNew?.id,
        billing_address: {
          full_name: paymentMethodParams.billing_details.name,
          address_ln1: paymentMethodParams.billing_details.address.line1,
          address_ln2: paymentMethodParams.billing_details.address.line2,
          city: paymentMethodParams.billing_details.address.city,
          state: paymentMethodParams.billing_details.address.state,
          zip_code: paymentMethodParams.billing_details.address.postal_code,
          country: paymentMethodParams.billing_details.address.country,
        },
      });
    }
    return await Promise.resolve({ data: { value: orderResp } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
