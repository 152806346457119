// constants
import { months } from "./constants";

// ts
import { Month, DOB } from "./types";

const YEAR_RANGE_FROM_CURRENT_YEAR = 150;

// eslint-disable-next-line import/prefer-default-export
export const getDaysInMonthYear = (month: number, year: number): number => {
  return new Date(year, month, 0).getDate();
};

export const getDOBYears = (): { label: string; value: string }[] => {
  const dt = new Date();
  dt.setFullYear(dt.getFullYear() - 13);
  const latestYear = dt.getFullYear().valueOf();
  return Array.from(Array(YEAR_RANGE_FROM_CURRENT_YEAR).keys()).map((year) => {
    const currentValue = (latestYear - year).toString();
    return { label: currentValue, value: currentValue };
  });
};

export const getMonths = (): { label: string; value: string }[] => {
  return Object.keys(months).map((month) => {
    return { label: month, value: months[month as Month] };
  });
};

export const getReverseMonths = (): { [key: string]: string } => {
  return Object.keys(months).reduce((acc, curr) => {
    const value = months[curr as Month]?.toString();
    acc[value] = curr;
    return acc;
  }, {} as { [key: string]: string });
};

export const getDOBTokens = (dobVal: string): string[] => {
  const dobTokens = dobVal?.split("T");
  return dobTokens?.length >= 2 && dobTokens[0] ? dobTokens[0].split("-") : [];
};

export const getBirthDayOptions = (
  numOfDays: number
): { label: string; value: string }[] => {
  return Array.from(Array(numOfDays).keys()).map((day) => {
    const nextDayVal = day + 1;
    const val = (
      day < 10 ? `${nextDayVal < 10 ? "0" : ""}${nextDayVal}` : nextDayVal
    ).toString();
    return { label: val, value: val };
  });
};

export const getDobValues = (dobStr: string): { [key: string]: DOB } => {
  const values = getDOBTokens(dobStr);
  const [yearToken = "", monthToken = ""] = values;
  const birthYearOptions = getDOBYears();
  const birthMonthOptions = getMonths();
  const lastDay = getDaysInMonthYear(
    +(monthToken || birthMonthOptions[0]?.value),
    +(yearToken || birthYearOptions[0]?.value)
  );
  const birthDayOptions = getBirthDayOptions(lastDay);
  const dobMapper = {
    birth_year: {
      position: 0,
      label: "Year",
      placeHolder: "Birth Year",
      value: "",
      options: birthYearOptions,
    },
    birth_month: {
      position: 1,
      label: "Month",
      placeHolder: "Birth Month",
      value: "",
      options: birthMonthOptions,
    },
    birth_day: {
      position: 2,
      label: "Day",
      placeHolder: "Birth Day",
      value: "",
      options: birthDayOptions,
    },
  };
  const dobKeyValues = dobMapper as {
    [key: string]: DOB;
  };
  return Object.keys(dobKeyValues).reduce(
    (acc, curr) => {
      acc[curr].value = values[dobKeyValues[curr].position] || "";
      const val = acc[curr].value;
      const optionValues = acc[curr].options?.map((option) => option?.value);
      const newPreSelectedOption = {
        label: curr === "birth_month" ? getReverseMonths()[val] : val,
        value: val,
      };
      acc[curr].preSelectedOptions = optionValues.includes(val)
        ? [newPreSelectedOption]
        : [];
      return acc;
    },
    dobMapper as {
      [key: string]: DOB;
    }
  );
};
