/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import React, { useContext, useEffect, useState } from "react";

// packages
import { RawDraftContentState } from "draft-js";
import cheerio from "cheerio";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";

// icons
import {
  facebookNoColorIcon,
  instagramNoColorIcon,
  twitterNoColorIcon,
} from "../../../../assets";

// context
import ShopContext from "../../Shop.context";

export const ShopAbout = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    about_us = "{}",
    social_links,
    policies = "{}",
    return_and_exchanges = "{}",
  } = useContext(ShopContext);
  const [aboutUsRichText, setAboutUsRichText] = useState("");
  const [policiesRichText, setPoliciesRichText] = useState("");
  const [returnsAndExchangesRichText, setReturnsAndExchangesRichText] =
    useState("");

  const purifyDraftHTML = (rawDraftString: string) => {
    const rawDraftObj = JSON.parse(rawDraftString) as RawDraftContentState;
    if (rawDraftObj.blocks) {
      const html = draftToHtml(rawDraftObj);
      const $ = cheerio.load(html);
      const text = $.html() || "";
      return DOMPurify.sanitize(text);
    }
    return "";
  };

  useEffect(() => {
    setAboutUsRichText(purifyDraftHTML(about_us));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(about_us)]);

  useEffect(() => {
    setReturnsAndExchangesRichText(purifyDraftHTML(return_and_exchanges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(return_and_exchanges)]);

  useEffect(() => {
    setPoliciesRichText(purifyDraftHTML(policies));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(policies)]);

  const facebookLink = social_links?.facebook || "https://facebook.com";
  const instagramLink = social_links?.instagram || "https://instagram.com";
  const twitterLink = social_links?.twitter || "https://twitter.com";
  const innerHTMLContainerClassName = "text-justify text-sm md:text-base";

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex flex-col gap-4 md:justify-between">
        <div className="flex">
          {facebookLink && (
            <a href={facebookLink} className="hover:opacity-75">
              <img width={40} src={facebookNoColorIcon} alt="facebook-icon" />
            </a>
          )}
          {instagramLink && (
            <a href={instagramLink} className="hover:opacity-75">
              <img width={40} src={instagramNoColorIcon} alt="instagram-icon" />
            </a>
          )}
          {twitterLink && (
            <a href={twitterLink} className="hover:opacity-75">
              <img width={40} src={twitterNoColorIcon} alt="twitter-icon" />
            </a>
          )}
        </div>
      </div>
      <div className="flex flex-col items-start m-2 md:justify-between md:items-start">
        <h3 className="font-bold pb-2">About Us</h3>
        <div className={innerHTMLContainerClassName}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                aboutUsRichText ||
                "We will update our returns & exchange policy shortly ...",
            }}
          />
        </div>
      </div>
      <span className="border-b border-primary-color-100 border-opacity-25" />
      <div className="flex flex-col items-start m-2 md:justify-between md:items-start">
        <h3 className="font-bold pb-2">Returns and Exchanges</h3>
        <div className={innerHTMLContainerClassName}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                returnsAndExchangesRichText ||
                "We will update our returns & exchange policy shortly ...",
            }}
          />
        </div>
      </div>
      <span className="border-b border-primary-color-100 border-opacity-25" />
      <div className="flex flex-col items-start m-2 md:justify-between md:items-start">
        <h3 className="font-bold pb-2">Policies</h3>
        <div className={innerHTMLContainerClassName}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                policiesRichText || "We will update our policies shortly ...",
            }}
          />
        </div>
      </div>
    </div>
  );
};
