/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

// components
import { Bag, Location } from "../../../components/Icons";

const Dot = () => (
  <div
    className="flex bg-primary-color-80 mr-2"
    style={{ borderRadius: "50%", height: 8, width: 8 }}
  />
);
const icons = {
  name: Bag,
  location: Location,
} as {
  [key: string]: unknown;
};

export const Progress = ({
  activeField,
}: {
  activeField: string;
}): JSX.Element => {
  const keys = Object.keys(icons);
  return (
    <div className="flex">
      {keys.map((icon, index) => {
        const Icon = icons[icon];
        const isActive = activeField === icon;
        const bgColor = isActive ? "contrast-color-1-100" : "primary-color-100";
        return (
          <div className="flex items-center" key={icon}>
            <div
              className={`flex justify-center items-center w-12 h-12 border-${bgColor} border bg-${bgColor} mr-2`}
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                cursor: "pointer",
              }}
            >
              <Icon width="30px" height="30px" fill="#FFFF" />
            </div>
            <div className="flex">
              {index !== keys.length - 1 && (
                <div className="flex gap-5">
                  {Array.from({ length: 4 }, (v, i) => {
                    return <Dot key={`dot-${i}`} />;
                  })}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Progress;
