import React, { ReactElement } from "react";

const SvgProfileAvatar = (): ReactElement => {
  return (
    <svg
      width="143"
      height="143"
      viewBox="0 0 143 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="71.5628"
        cy="71.4966"
        r="70.9163"
        fill="#3F415B"
        fillOpacity="0.25"
      />
      <path
        d="M116.716 105.603C116.716 111.848 115.45 115.983 113.24 118.713C111.036 121.437 107.83 122.842 103.767 123.486C99.6948 124.131 94.8255 124.002 89.3607 123.705C88.1893 123.641 86.991 123.57 85.7688 123.497C81.3069 123.231 76.5254 122.947 71.5633 122.947C66.6012 122.947 61.8197 123.231 57.3578 123.497C56.1356 123.57 54.9373 123.641 53.7659 123.705C48.3011 124.002 43.4317 124.131 39.3599 123.486C35.2969 122.842 32.0902 121.437 29.8865 118.713C27.677 115.983 26.4102 111.848 26.4102 105.603C26.4102 80.6655 46.6259 60.4497 71.5633 60.4497C96.5007 60.4497 116.716 80.6655 116.716 105.603Z"
        fill="#3F415B"
        stroke="#FAF8F9"
      />
      <circle
        cx="71.5624"
        cy="47.2093"
        r="28.1659"
        fill="#3F415B"
        stroke="#FAF8F9"
      />
    </svg>
  );
};

export default SvgProfileAvatar;
