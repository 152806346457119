import React, { FC, useContext, useState } from "react";
import { useMutation } from "react-query";

// components
import { useHistory } from "react-router-dom";
import BannerMessage from "../../components/BannerMessage";

// hooks
import { useAuth } from "../../hooks";

// store
import { Context } from "../../store";

// actions
import resendEmail from "./actions";
import { getDataFromToken } from "../../utils/localStorage";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

const getBannerMessageComp = (email = "") => {
  return (
    <div className="flex-col text-white">
      <div className="flex justify-center font-poppins font-normal text-base md:text-2xl pt-7 pb-1">
        Your account isn&#39;t confirmed yet
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col md:flex-row font-poppins font-light text-base md:text-2xl tracking-wide">
          <p>Confirm</p>
          <p className="font-bold ml-2 mr-2">{email}</p>{" "}
          <p>to start using Lonima.</p>
        </div>
      </div>
    </div>
  );
};

const ResendEmailBanner: FC = () => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { email, isLoading },
      },
    },
  } = useContext(Context);

  const logoutUser = () => {
    setTimeout(() => {
      history.push("/logout");
    }, 3000);
  };

  const emailVerified = getDataFromToken()?.email_verified;
  const { mutate } = useMutation(resendEmail, {
    onSuccess: () => {
      setShowSuccessModal(true);
      logoutUser();
    },
    onError: () => {},
  });

  const resend = () => {
    mutate({ email });
  };

  return (
    <div>
      {showSuccessModal && (
        <Modal
          isForceClose={!showSuccessModal}
          overrideModalStyle="justify-evenly md:max-w-2xl w-1/2"
          overrideModalContainerStyle="items-end sm:items-center"
          onClose={() => setShowSuccessModal(false)}
        >
          <div className="flex flex-col w-full h-48 justify-center">
            <p className="font-opensans text-xl text-center">
              Please check your inbox for a confirmation email.
            </p>
            <p className="font-opensans text-xl text-center">
              You will be logged out now.
            </p>
            <div className="flex justify-evenly pt-6">
              <Button
                text="Close"
                size="sm"
                type="outline"
                onClick={() => setShowSuccessModal(false)}
              />
            </div>
          </div>
        </Modal>
      )}
      {!emailVerified && !isLoading && email && isAuthenticated && (
        <BannerMessage
          message={getBannerMessageComp(email)}
          actionTitle="Resend Email"
          onAction={resend}
        />
      )}
    </div>
  );
};

export default ResendEmailBanner;
