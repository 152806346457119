/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from "react";

// components
import Order from "./Order";

import { ParentOrder } from "../OrdersReviews.types";

const Orders = ({
  parentOrders,
}: {
  parentOrders: { [parentOrderId: string]: ParentOrder };
}): JSX.Element => {
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [activeParentOrder, setActiveParentOrder] = useState<ParentOrder>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    null!
  );
  const [activeParentOrderId, setActiveParentOrderId] = useState("");

  return (
    <div className="flex flex-col gap-5 w-full">
      {openOrderModal && (
        <Order
          onClose={() => setOpenOrderModal(false)}
          parentOrder={activeParentOrder}
          key={activeParentOrderId}
        />
      )}
      {Object.keys(parentOrders).map((parentOrderId) => {
        const parentOrder = parentOrders[parentOrderId];
        const { id, total } = parentOrder;

        return (
          <div
            className="flex justify-between items-center
            border border-solid border-primary-color-100 border-opacity-10 md:shadow-xl
            bg-neutral-color-1 py-5 px-3 rounded-sm
            font-opensans font-normal text-base w-full sm:gap-32"
            key={id}
          >
            <div className="flex flex-col justify-center text-left items-start">
              <p className="text-sm sm:text-base font-bold">{`Order #: ${id}`}</p>
              <p className="text-primary-color-60 text-sm sm:text-base">
                {new Date(parentOrder.created_at).toDateString()}
              </p>
              <span
                onClick={() => {
                  setOpenOrderModal(true);
                  setActiveParentOrder(parentOrder);
                  setActiveParentOrderId(activeParentOrderId);
                }}
                onKeyPress={() => {
                  setOpenOrderModal(true);
                  setActiveParentOrder(parentOrder);
                  setActiveParentOrderId(activeParentOrderId);
                }}
                tabIndex={0}
                role="button"
                className="hover:underline hover:text-neutral-color-3 text-sm pt-2"
              >
                View Order Details
              </span>
            </div>
            <div className="flex items-center">
              <div className="hidden md:flex">{`$${total}`}</div>
            </div>
            {/* <div
              className={`flex justify-center items-center rounded-full py-1 px-3 bg-${orderColorMap.pending} h-1/2`}
            >
              <p className="text-sm font-bold">{orderStatusMap.pending}</p>
            </div> */}
          </div>
        );
      })}
    </div>
  );
};
export default Orders;
