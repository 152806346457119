import React, { createContext, Dispatch, useReducer } from "react";
import Reducer, { initialState, ActionProps, ReduxStateProps } from "./reducer";

interface ContextProps {
  state: ReduxStateProps;
  dispatch: Dispatch<ActionProps>;
}

interface IStoreInput {
  children: JSX.Element;
}

export const Context = createContext({} as ContextProps);

const Store = ({ children }: IStoreInput): JSX.Element => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default Store;
