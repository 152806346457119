import React from "react";
import Button from "../Button";

// ts
export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
  actionTitle: string;
  onAction: () => void;
}

const BannerMessage = ({
  message,
  actionTitle,
  onAction,
}: Props): JSX.Element => {
  return (
    <div className="flex-col bg-primary-color-80 w-full">
      {message}
      <div className="flex justify-center pt-4 pb-4">
        <Button
          containerClassName="flex w-max border border-solid border-white rounded-md"
          text={actionTitle}
          size="sm"
          type="primary"
          onClick={onAction}
        />
      </div>
    </div>
  );
};

BannerMessage.defaultProps = {
  message: <div />,
  actionTitle: "",
  onAction: () => {},
};

export default BannerMessage;
