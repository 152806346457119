import React from "react";
import { Redirect } from "react-router-dom";
import { useQueryParams } from "../../hooks";
import { setItem } from "../../utils";
import { getCookie } from "../../utils/cookieStorage";

export default function RedirectUser(): JSX.Element {
  const queryParams = useQueryParams();
  const token = queryParams.get("token");
  if (token) {
    setItem("token", token);
    if (getCookie("redirect_url_last_visited")) {
      const url = getCookie("redirect_url_last_visited");
      return <Redirect to={`${url}`} />;
    }
  }
  return <Redirect to="/" />;
}
