import { createContext } from "react";
import { Shop } from "../../store/types";

const SellOnLonimaContext = createContext({
  name: "",
  setName: (() => {}) as React.Dispatch<React.SetStateAction<string>>,
  isNameAvailable: false,
  setIsNameAvailable: (() => {}) as React.Dispatch<
    React.SetStateAction<boolean>
  >,
  location: "",
  setLocation: (() => {}) as React.Dispatch<React.SetStateAction<string>>,
  shopId: "",
  setShopId: (() => {}) as React.Dispatch<React.SetStateAction<string>>,
  shops: [] as Array<Shop>,
  email_verified: false,
});
export default SellOnLonimaContext;
