export const ADDRESS_PAYLOAD_KEYS = [
  "_id",
  "email",
  "address_ln1",
  "address_ln2",
  "city",
  "country",
  "county",
  "state",
  "zip_code",
  "isShow",
  "full_name",
  "phone",
  "isDefaultShippingAddress",
  "isPlaceHolderAddress",
];

export const defaultAddressValues = {
  full_name: "",
  address_ln1: "",
  address_ln2: "",
  zip_code: "",
  city: "",
  state: "",
  isDefaultShippingAddress: false,
  isPlaceHolderAddress: true,
  county: ".",
  country: "United States",
  isShow: false,
  email: "",
  phone: "",
};

export const ADDRESS_HELPER_KEYS = ["isPlaceHolderAddress", "isShow"];

export default ADDRESS_PAYLOAD_KEYS;
