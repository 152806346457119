/* eslint-disable import/prefer-default-export */
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const GET_USER_PREFS = "GET_USER_PREFS";
export const GET_ADDRESSES = "GET_ADDRESSES";
export const GET_CART = "GET_CART";
export const FETCH_ALL_FAVORITES = "FETCH_ALL_FAVORITES";
export const GET_CATEGORY_HIERARCHY = "GET_CATEGORY_HIERARCHY";

export const SET_GLOBAL_MESSAGE = "SET_GLOBAL_MESSAGE";
export const GET_GLOBAL_MESSAGE = "GET_GLOBAL_MESSAGE";
export const SET_LOADER = "SET_LOADER";
