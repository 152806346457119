/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";

const SvgLocation = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  const { fill = "#3F415B" } = props;
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25049 7C7.28549 7 6.50049 7.785 6.50049 8.751C6.50049 9.716 7.28549 10.5 8.25049 10.5C9.21549 10.5 10.0005 9.716 10.0005 8.751C10.0005 7.785 9.21549 7 8.25049 7ZM8.25049 12C6.45849 12 5.00049 10.543 5.00049 8.751C5.00049 6.958 6.45849 5.5 8.25049 5.5C10.0425 5.5 11.5005 6.958 11.5005 8.751C11.5005 10.543 10.0425 12 8.25049 12Z"
        fill={fill}
      />
      <mask
        id="mask0_127_2264"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.5H16.4995V20H0V0.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_127_2264)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.24951 2C4.52751 2 1.49951 5.057 1.49951 8.813C1.49951 13.592 7.12351 18.248 8.24951 18.496C9.37551 18.247 14.9995 13.591 14.9995 8.813C14.9995 5.057 11.9715 2 8.24951 2ZM8.24951 20C6.45551 20 -0.000488281 14.448 -0.000488281 8.813C-0.000488281 4.229 3.70051 0.5 8.24951 0.5C12.7985 0.5 16.4995 4.229 16.4995 8.813C16.4995 14.448 10.0435 20 8.24951 20Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default SvgLocation;
