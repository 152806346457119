/* eslint-disable import/prefer-default-export */
export const getItem = (key: string): string | unknown => {
  return localStorage.getItem(key);
};

export const setItem = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

export const deleteItem = (key: string): void => {
  localStorage.removeItem(key);
};

export const deleteItemStartWith = (key: string): void => {
  Object.keys(localStorage).forEach((item) => {
    if (item.startsWith(key)) {
      localStorage.removeItem(item);
    }
  });
};

export const getDataFromToken = (): string | null => {
  try {
    const token = localStorage.getItem("token") || "";
    const parts = token.split(".");
    if (parts[1]) {
      const data = atob(parts[1]);
      return JSON.parse(data);
    }
    return null;
  } catch (e) {
    return null;
  }
};
