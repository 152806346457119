import React from "react";

// utils
import { amexCard, discoverCard, masterCard, visaCard } from "../../../assets";

interface Props {
  lastFourDigits: string;
  expiryMonth: string;
  expiryYear: string;
  cardBrand: string;
}

const cardBrandImageMap = {
  amex: amexCard,
  discover: discoverCard,
  master: masterCard,
  visa: visaCard,
} as { [key: string]: string };

const textStyle = "font-opensans font-normal text-xl text-primary-color-100";

const CardInfo = ({
  lastFourDigits,
  expiryMonth,
  expiryYear,
  cardBrand,
}: Props): JSX.Element => {
  return (
    <div className="flex gap-5">
      <div className="flex">
        <img
          src={cardBrandImageMap[cardBrand]}
          width={58}
          height={40}
          alt={cardBrand}
        />
      </div>
      <div className={`flex flex-col ${textStyle}`}>
        <div className="flex">{`.... ..... .... ${lastFourDigits}`}</div>
        <div className="flex">{`Exp. ${expiryMonth}/${expiryYear}`}</div>
      </div>
    </div>
  );
};

export default CardInfo;
