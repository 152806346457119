/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react";

// packages
import { pick, omit } from "lodash";

// store
import { useMutation, useQuery } from "react-query";

// ts
import { Address, AddressResponse } from "../../../store/types";
import { AddressPayload } from "../../../types";

// components
import AddressForm from "../../../components/AddressForm";
import Button from "../../../components/Button";

// constants
import {
  ADDRESS_PAYLOAD_KEYS,
  ADDRESS_HELPER_KEYS,
} from "../../Profile/Tabs/AccountSettings/Addresses/constants";

// hooks
import { useAuth } from "../../../hooks";
import AddressFormModal from "../../Profile/Tabs/AccountSettings/Addresses/AddressFormModal";
import {
  addAddress,
  fetchAddresses,
  setDefaultAddress,
  updateAddress,
} from "../../Profile/Tabs/AccountSettings/Addresses/actions";
import { GET_ADDRESSES, GET_USER } from "../../../store/actions.types";
import { fetchUserProfile } from "../../../store/actions";
import { emailOutlineIcon, phoneOutlineIcon } from "../../../assets";
import { Context } from "../../../store";

interface Props {
  label?: string;
  buttonText?: string;
  headerFlexAlignment?: string;
  formWidth?: string;
  redirectToPayment?: (
    shippingAddressID?: string,
    newShippingAddress?: AddressPayload,
    cardSelection?: boolean
  ) => void;
}

const CheckoutAddressForm = ({
  label,
  buttonText,
  headerFlexAlignment,
  formWidth,
  redirectToPayment = () => {},
}: Props): JSX.Element => {
  const [isSaveEligible, setIsSaveEligible] = useState(false);
  const [values, setValues] = useState<AddressResponse>({} as AddressResponse);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [editAddressId, setEditAddressId] = useState("");
  const [userAddresses, setUserAddresses] = useState<Array<Address>>();
  const { isAuthenticated } = useAuth();
  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { default_address_id },
      },
    },
  } = useContext(Context);
  const { data: newAddressesData, refetch: refetchNewAdresses } = useQuery(
    GET_ADDRESSES,
    fetchAddresses,
    {
      enabled: false,
    }
  );

  const { refetch: refetchUserProfile } = useQuery(GET_USER, fetchUserProfile, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isAuthenticated) {
      refetchNewAdresses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newAddressesData?.data) {
      setUserAddresses(newAddressesData?.data.value.addresses);
    }
  }, [newAddressesData?.data]);

  useEffect(() => {
    if (editAddressId) {
      setShowAddressModal(true);
    }
  }, [editAddressId]);

  const continueToNextStep = () => {
    if (isSaveEligible) {
      const payload = omit(
        pick(omit(values, ["_id"]), ADDRESS_PAYLOAD_KEYS),
        ADDRESS_HELPER_KEYS
      ) as AddressPayload;
      redirectToPayment("", payload, false);
    }
  };

  const { mutate: setDefaultAddressMutation } = useMutation(setDefaultAddress, {
    onSuccess: () => {
      refetchUserProfile();
    },
    onError: () => {},
  });

  const { mutate: addAddressMutation } = useMutation(addAddress, {
    onSuccess: (response) => {
      if (response?.data?.value) {
        const { id, isDefaultShippingAddress } = response.data.value;
        if (isDefaultShippingAddress) {
          setDefaultAddressMutation(id);
        }
        refetchNewAdresses();
      }
    },
    onError: () => {
      refetchNewAdresses();
    },
  });

  const { mutate: updateAddressMutation } = useMutation(updateAddress, {
    onSuccess: (response) => {
      if (response?.data?.value) {
        const { id, isDefaultShippingAddress } = response.data.value;
        if (isDefaultShippingAddress) {
          setDefaultAddressMutation(id);
        }
        refetchNewAdresses();
      }
    },
    onError: () => {
      refetchNewAdresses();
    },
  });

  const upsertAddress = (updatedAddress: AddressResponse) => {
    const payload = omit(
      pick(updatedAddress, ADDRESS_PAYLOAD_KEYS),
      ADDRESS_HELPER_KEYS
    ) as AddressPayload;
    if (updatedAddress.isPlaceHolderAddress) {
      addAddressMutation(payload);
    } else {
      updateAddressMutation(payload);
    }
    setEditAddressId("");
  };

  const onSelectAddress = (id: string) => {
    redirectToPayment(id);
  };

  return (
    <div
      className={`flex flex-col justify-center m-auto ${formWidth} p-2 sm:p-4 gap-8`}
    >
      {showAddressModal && (
        <AddressFormModal
          uniqueAddressId={editAddressId}
          key={editAddressId}
          isDefaultAddressReadOnly={false}
          title={editAddressId ? "Edit an address" : "Add new address"}
          onSave={upsertAddress}
          onClose={() => {
            setShowAddressModal(false);
            setEditAddressId("");
          }}
          previousAddress={
            (editAddressId
              ? {
                  ...userAddresses?.find(
                    (address) => address._id === editAddressId
                  ),
                  isDefaultShippingAddress:
                    editAddressId === default_address_id,
                }
              : {}) as AddressResponse
          }
        />
      )}
      <div className={`flex ${headerFlexAlignment} w-full pt-5`}>
        <p className="w-full text-center font-poppins font-semibold text-3xl text-primary-color-100">
          {label}
        </p>
      </div>
      {isAuthenticated && (
        <div>
          {userAddresses &&
            userAddresses.length > 0 &&
            userAddresses.map((addr) => {
              return (
                <div
                  className="w-full flex flex-col sm:flex-row justify-between items-center rounded-md
            border border-solid border-primary-color-100 border-opacity-10 p-4 mb-4"
                  key={addr._id}
                >
                  <div className="flex flex-col items-start w-full text-left sm:w-1/2 max-w-md gap-1">
                    <span className="flex-wrap font-bold">
                      {addr.full_name}
                    </span>
                    <div className="flex flex-col gap-2">
                      <div className="flex">
                        <img
                          width={25}
                          height={5}
                          src={emailOutlineIcon}
                          alt="email-outline"
                        />
                        <p className="ml-2">{addr.email}</p>
                      </div>
                      <div className="flex">
                        <img
                          width={25}
                          height={10}
                          src={phoneOutlineIcon}
                          alt="phone-outline"
                        />
                        <p className="ml-2">{addr.phone}</p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="flex-wrap">{addr.address_ln1}</span>
                      <span className="flex-wrap">{addr.address_ln2}</span>
                      <span className="flex-wrap">
                        {addr.city}, {addr.state} - {addr.zip_code}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mt-4 sm:mt-0">
                    <Button
                      containerClassName="w-full"
                      text="Use this address"
                      size="sm"
                      type="primary"
                      onClick={() => {
                        onSelectAddress(addr._id);
                      }}
                    />
                    <Button
                      containerClassName="w-full"
                      text="Edit"
                      size="sm"
                      type="outline"
                      onClick={() => {
                        setEditAddressId(addr._id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          <span
            className="cursor-pointer underline"
            onClick={() => setShowAddressModal(true)}
            onKeyDown={() => setShowAddressModal(true)}
            role="button"
            tabIndex={0}
          >
            Add New address
          </span>
        </div>
      )}
      {!isAuthenticated && (
        <AddressForm
          uniqueAddressId={editAddressId}
          showExtraFormElements
          isDefaultAddressReadOnly={false}
          updateValues={(vals) => setValues(vals)}
          setIsSaveEligible={(val) => setIsSaveEligible(val)}
        />
      )}
      {!isAuthenticated && (
        <div className="flex pb-4 justify-center">
          <div
            className={`flex w-full ${
              isSaveEligible
                ? "pointer-events-auto cursor-pointer 2"
                : "pointer-events-none cursor-not-allowed"
            }`}
          >
            <Button
              containerClassName={`w-full ${
                isSaveEligible ? "" : "opacity-20"
              }`}
              textClassName="py-3"
              text={buttonText}
              size="lg"
              type="primary"
              onClick={continueToNextStep}
            />
          </div>
        </div>
      )}
    </div>
  );
};
CheckoutAddressForm.defaultProps = {
  headerFlexAlignment: "justify-center",
  label: "Enter your shipping address",
  buttonText: "Continue to Payment",
  formWidth: "",
  redirectToPayment: () => {},
};

export default CheckoutAddressForm;
