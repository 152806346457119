/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useContext, useState } from "react";

// packages
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";

// components
import { heartPngIcon, heartPngFilledIcon } from "../../assets";
import { useAuth } from "../../hooks";
import Button from "../Button";
import ProductLabel from "../ProductLabel";
import AddToCartModal from "../Product/AddToCartModal";
import StarIcon from "../StarIcon";

// ts
import { ProductType } from "./types";
import { setPlaceHolderImageOnError } from "../Product/Product.utils";
import { Context } from "../../store";

const ProductWithImage = ({
  productId,
  productImage = "",
  imageAlt,
  headerText,
  productCost,
  productRatings = {
    starRatings: 0,
    totalReviews: 0,
  },
  productLabels = [],
  shopId,
  // handleProductClick,
  handleFavoriteClick = () => {},
  handleAddToCart = () => {},
  isFavorite = false,
  enableAddToCart = true,
  overrideShowFavoriteFlag = true,
  isEditableMode = false,
  showAdditionalOptions = false,
  onClickAdditionalOptions = () => {},
}: ProductType): JSX.Element => {
  const [showFavorite, setShowFavorite] = useState(false);
  const [uniqID, setUniqID] = useState(uuid());
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
  const history = useHistory();
  const toggleShowFavoriteIcon = () => {
    setShowFavorite(!showFavorite);
  };
  const addToCart = () => {
    setShowAddedToCartModal(true);
    setUniqID(uuid());
    handleAddToCart({ productId, price: productCost });
  };
  const { isAuthenticated } = useAuth();

  const favoriteIcon = isFavorite ? heartPngFilledIcon : heartPngIcon;
  const favoriteDivClassName = isFavorite
    ? "bg-white"
    : "bg-neutral-color-1 hover:bg-white";

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { email_verified },
      },
    },
  } = useContext(Context);

  return (
    <div
      className={`flex flex-col relative w-full md:w-56 lg:w-60 hover:shadow-lg ${
        isEditableMode
          ? "opacity-20 cursor-default pointer-events-none"
          : "opacity-1"
      }`}
      onMouseEnter={toggleShowFavoriteIcon}
      onMouseLeave={toggleShowFavoriteIcon}
    >
      {showAddedToCartModal && (
        <AddToCartModal
          key={uniqID}
          imageUrl={productImage}
          title={headerText}
          price={+productCost}
          discountedPrice={(Math.round((+productCost || 0) * 0.85) * 100) / 100}
          onClose={() => {
            setShowAddedToCartModal(false);
          }}
        />
      )}
      <a
        className={`${
          isEditableMode ? "pointer-events-none" : "pointer-events-default"
        }`}
        href={
          showAdditionalOptions
            ? `/edit-product?shop_id=${shopId}&product_id=${productId}`
            : `${window.location.origin}/product?id=${productId}`
        }
      >
        <img
          className="rounded-t-md object-cover w-full h-40 md:h-48 lg:h-52 cursor-pointer"
          src={productImage}
          alt={imageAlt}
          onError={setPlaceHolderImageOnError}
        />
      </a>
      {isAuthenticated &&
        email_verified &&
        overrideShowFavoriteFlag &&
        (isFavorite || showFavorite) && (
          <div
            className={`flex items-center justify-center rounded-full absolute ${favoriteDivClassName}`}
            style={{ width: 36, height: 36, top: 6, right: 6 }}
          >
            <img
              src={favoriteIcon}
              width={20}
              height={20}
              alt="favorite-product"
              style={{
                position: "relative",
              }}
              onClick={() => handleFavoriteClick(productId, isFavorite)}
              onKeyPress={() => handleFavoriteClick(productId, isFavorite)}
              role="button"
            />
          </div>
        )}
      <div className="flex flex-col items-start gap-2 p-2 md:p-3 w-full rounded-b-md">
        <p
          className={`truncate w-full text-left ${
            isEditableMode
              ? "cursor-default pointer-events-none"
              : "cursor-pointer pointer-events-auto"
          }`}
          onClick={() => {
            history.push(
              showAdditionalOptions
                ? `/edit-product?shop_id=${shopId}&product_id=${productId}`
                : `/product?id=${productId}`
            );
          }}
        >
          {headerText}
        </p>
        <span>
          <b>${productCost}</b>
        </span>
        {productRatings.starRatings !== 0 ? (
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              {Array.from({ length: productRatings.starRatings }, (v, i) => (
                <StarIcon starId={i + 1} key={`star_${i + 1}`} marked />
              ))}
              <span className="text-xs pl-2">
                ({productRatings.totalReviews})
              </span>
            </div>
            {showAdditionalOptions && (
              <div
                className="flex cursor-pointer"
                onClick={onClickAdditionalOptions}
                role="button"
                tabIndex={0}
                onKeyPress={onClickAdditionalOptions}
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundImage:
                    "radial-gradient(circle, black 2px, transparent 1px)",
                  backgroundSize: "100% 33.33%",
                }}
              >
                {" "}
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center">
              {Array.from({ length: 5 }, (v, i) => (
                <StarIcon starId={i + 1} key={`star_${i + 1}`} marked={false} />
              ))}
              <span className="text-xs pl-2">
                ({productRatings.totalReviews})
              </span>
            </div>
            {showAdditionalOptions && (
              <div
                className="flex cursor-pointer"
                onClick={onClickAdditionalOptions}
                role="button"
                tabIndex={parseInt(productId, 10)}
                onKeyPress={onClickAdditionalOptions}
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundImage:
                    "radial-gradient(circle, black 2px, transparent 1px)",
                  backgroundSize: "100% 33.33%",
                }}
              >
                {" "}
              </div>
            )}
          </div>
        )}
        {productLabels.map((label) => (
          <ProductLabel
            key={label.value}
            labelColor={label.color}
            labelValue={label.value}
          />
        ))}
        {enableAddToCart && (
          <Button
            containerClassName="w-full"
            textClassName="block p-2 m-auto justify-center"
            text="Add To Cart"
            size="sm"
            type="outline"
            onClick={addToCart}
          />
        )}
      </div>
    </div>
  );
};

export default ProductWithImage;
