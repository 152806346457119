// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../../axios";

// ts
import { Result, AddressPayload } from "../../../types";
import { Shop } from "../Shop.types";

// eslint-disable-next-line import/prefer-default-export
export const createPaymentAccount = async (): Promise<Result> => {
  try {
    const { data } = await axios.post("/user/createPaymentAccount");
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateShop = async ({
  id,
  shopDetails,
}: {
  id: string;
  shopDetails: Shop;
}): Promise<Result> => {
  try {
    const { data } = await axios.put(`/shop/id/${id}`, { ...shopDetails });
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const uploadShopAvatarAndCoverImage = async ({
  shopLogo,
  shopCover,
  shopId,
}: {
  shopLogo: File | null;
  shopCover: File | null;
  shopId: string;
}): Promise<Result> => {
  try {
    const formData = new FormData();
    if (shopLogo) {
      formData.append("logo_image", shopLogo);
    }
    if (shopCover) {
      formData.append("cover_image", shopCover);
    }
    const {
      data: { imagePath },
    } = await axios.post(`/shop/id/${shopId}/images/upload`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return await Promise.resolve({ data: { value: imagePath } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const updateShopAddress = async ({
  id,
  address,
}: {
  id: string;
  address: AddressPayload;
}): Promise<Result> => {
  try {
    const { data } = await axios.put(`/shop/id/${id}/address/${address._id}`, {
      ...address,
    });
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const getPickupLocations = async (): Promise<Result> => {
  try {
    const { data } = await axios.get("pickup/location/list");
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const fetchPayments = async (): Promise<Result> => {
  try {
    const { data } = await axios.get("payment/transactionsByUser");
    return await Promise.resolve({
      data: {
        value: data,
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
