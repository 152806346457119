/* eslint-disable import/prefer-default-export */
// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../../../../axios";
import { Result } from "../../../../../types";
import { ChangePWPayload } from "./types";

export const changePassword = async ({
  oldPW,
  newPW,
}: ChangePWPayload): Promise<Result> => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/changepassword`,
      {
        old_password: oldPW,
        _password: newPW,
        confirm_password: newPW,
      }
    );
    const { message } = data;
    return await Promise.resolve({
      data: {
        value: {
          message,
        },
      },
    });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
