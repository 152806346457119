import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import {
  createAccount,
  handleTheTech,
  homeCircle1Icon,
  listYourProducts,
  localCommunity,
  sellYourProducts,
  newsLetterBgHomePage,
  sellEasily,
} from "../../assets";
import Button from "../../components/Button";
import ResendEmailBanner from "../ResendEmailBanner";
import HubSpotForm from "../../components/HubSpotForm";

const Home: FC = () => {
  const history = useHistory();

  // const { data, refetch } = useQuery(
  //   GET_CATEGORY_HIERARCHY,
  //   getCategoryHierarchy,
  //   {
  //     enabled: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  // const categoryData = useMemo(() => {
  //   return data?.data?.value;
  // }, [data]);

  return (
    <div>
      <ResendEmailBanner />
      <div className="flex flex-col justify-between">
        <div className="flex gap-4 flex-col md:flex-row md:px-6">
          <div className="flex flex-col mx-16 sm:mx-24 my-10 flex-1 items-center justify-center md:mx-10 md:mb-52 md:mt-24 text-white sm:flex-2 md:gap-2 md:flex-1 md:items-start">
            <h2 className="text-center text-primary-color-100 break-words font-semibold md:leading-tight md:text-5xl md:text-left">
              Buy and Sell Local Products
            </h2>
            <p className="md:flex text-primary-color-100 font-opensans md:text-left pt-2 pb-6 leading-7">
              Sell your locally sourced products. Make money. It’s easy and free
              to get started.
            </p>
            <Button
              containerClassName="flex w-max rounded-md"
              text="Sell on Lonima"
              size="md"
              type="orange"
              onClick={() => history.push("/sell-on-lonima/name")}
            />
          </div>
          <div className="justify-center items-center flex-1 lg:flex-2 md:flex md:justify-evenly">
            <img
              className="mb-8 w-full px-20 sm:px-28 md:px-0 md:w-full lg:w-3/4 z-30"
              src={homeCircle1Icon}
              alt="home-circle-1"
            />
          </div>
        </div>
        <div className="flex flex-col items-center pt-12 md:mt-0 lg:mt-8 xl:mt-16 bg-neutral-color-1">
          <div className="flex flex-col w-full px-4 items-center sm:w-3/4">
            <h2 className="w-full gap-2 font-semibold text-4xl text-center flex flex-row justify-center items-center md:text-5xl">
              <hr className="hidden border border-disabled-900 w-1/12 md:flex md:justify-center md:items-center" />
              Why Lonima?
              <hr className="hidden border border-disabled-900 w-1/12 md:flex md:justify-center md:items-center" />
            </h2>
            <p className="flex font-opensans text-center pt-6 pb-6 leading-7">
              Be part of your local community growth
            </p>
          </div>
          <div className="flex flex-col gap-8 items-center pt-6 pb-10 sm:gap-0 sm:mt-4 sm:flex-row sm:justify-between sm:w-full sm:items-start">
            <div className="flex flex-col max-w-md items-center justify-between sm:mx-8">
              <img className="w-48" src={sellEasily} alt="home-circle-4" />
              <h4 className="flex font-bold text-center pt-4 pb-2 break-words">
                Sell Easily
              </h4>
              <p className="flex text-center pt-2 pb-2 break-words">
                Whatever you&apos;re selling, we help take care of the business.
                Easily list your products and sell to local buyers.
              </p>
            </div>
            <div className="flex flex-col max-w-md items-center justify-between sm:mx-8">
              <img className="w-48" src={handleTheTech} alt="home-circle-4" />
              <h4 className="flex font-bold text-center pt-4 pb-2 break-words">
                We help with your marketing
              </h4>
              <p className="flex text-center pt-2 pb-2 break-words">
                We use the best technology to help give your products visibility
                to local buyers.
              </p>
            </div>
            <div className="flex flex-col max-w-md items-center justify-between sm:mx-8">
              <img className="w-48" src={localCommunity} alt="home-circle-4" />
              <h4 className="flex font-bold text-center pt-4 pb-2 break-words">
                Make Local Impact
              </h4>
              <p className="flex text-center pt-2 pb-2 break-words">
                By Selling on Lonima you&apos;re helping the local economy,
                environment and community well-being.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center pt-16 pb-8 md:pb-16 bg-contrast-color-2-100">
          <div className="flex flex-col w-full px-4 pb-6 items-center sm:w-3/4">
            <h2 className="w-full gap-2 font-semibold text-center flex flex-row justify-center items-center">
              Ready to Sell?
            </h2>
          </div>
          <div className="flex flex-col items-center pt-6 pb-10 gap-10 sm:mt-4 sm:justify-center sm:w-full">
            <div className="flex flex-col items-center justify-between sm:justify-center w-full sm:px-2 md:flex-row">
              <img className="w-48" src={createAccount} alt="create-acccount" />
              <div className="flex flex-col items-center justify-center md:items-start md:ml-10">
                <h3 className="flex text-center md:text-left pt-4 pb-2 max-w-xs">
                  Getting started is a breeze
                </h3>
                <p className="flex font-opensans text-md text-center md:text-left pt-2 pb-2 max-w-xs break-words">
                  Simply create an account to begin selling. It&apos;s free.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between sm:justify-center w-full sm:px-2 md:flex-row">
              <img
                className="w-48"
                src={listYourProducts}
                alt="list-your-products"
              />
              <div className="flex flex-col items-center justify-center md:items-start md:ml-10">
                <h3 className="flex text-center md:text-left pt-4 pb-2 sm:p-0">
                  List your Products
                </h3>
                <p className="flex font-opensans text-md text-center md:text-left pt-2 pb-2 max-w-xs break-words">
                  Snap photos of your item. Provide a brief description. Set
                  your price.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between sm:justify-center w-full sm:px-2 md:flex-row">
              <img
                className="w-48"
                src={sellYourProducts}
                alt="sell-your-products"
              />
              <div className="flex flex-col items-center justify-center md:items-start md:ml-10">
                <h3 className="flex text-center md:text-left pt-4 pb-2 sm:p-0">
                  Sell your Products
                </h3>
                <p className="flex font-opensans text-md text-center md:text-left pt-2 pb-2 max-w-xs break-words">
                  Enjoy quick payments upon sale. Ship it. Repeat.
                </p>
              </div>
            </div>
            <Button
              containerClassName="flex w-max rounded-md"
              text="Start selling"
              size="md"
              type="primary"
              onClick={() => history.push("/sell-on-lonima/name")}
            />
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${newsLetterBgHomePage})`,
            backgroundSize: "cover",
            height: "100%",
            padding: "4rem 0",
          }}
        >
          <div className="flex flex-col items-center justify-center sm:mx-28 bg-neutral-color-1 py-10 gap-4 opacity-90">
            <h2 className="font-playfair text-3xl md:text-4xl text-center text-primary-color-100">
              Stay updated with your local community
            </h2>
            <div
              className="flex flex-col justify-between"
              style={{ minHeight: "160px" }}
            >
              <HubSpotForm
                region="na1"
                portalId="45653691"
                formId="94e70281-7b05-4526-b290-f070054810e2"
                target="hubspotForm"
                overrideClasses="flex justify-center items-end"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
