import React from "react";
import { InputProps } from "./types";

const StarIcon = ({
  disabled = false,
  marked,
  starId,
  onClick = () => {},
}: InputProps): JSX.Element => {
  return (
    <span
      aria-disabled={disabled}
      data-star-id={starId}
      className={`star cursor-${disabled ? "not-allowed" : "pointer"}`}
      role="button"
      onFocus={() => {}}
      onBlur={() => {}}
      onClick={onClick}
      tabIndex={0}
      onKeyPress={onClick}
    >
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
};

export default StarIcon;
