import React, { useState } from "react";
import dayjs from "dayjs";
import { useMutation } from "react-query";
// import { facebookIcon, twitterIcon } from "../../../../../assets";
import Button from "../../../../../components/Button";
import TextInput from "../../../../../components/TextInput";
import { changePassword } from "./actions";
import { GeneralAccountSettingProps } from "./types";

export const GeneralAccountSettings = ({
  createdAt,
  email,
  loginType,
}: GeneralAccountSettingProps): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const { mutate: changePasswordMutation } = useMutation(changePassword, {
    onSuccess: () => {},
    onError: () => {},
  });

  const passwordsMatch = newPassword === confirmNewPassword;
  const profileCreatedDate = dayjs(createdAt).format("MMM D, YYYY");

  return (
    <div className="flex-col items-start">
      <div className="flex font-poppins font-light text-2xl leading-9 mb-2">
        General Account Settings
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex font-opensans text-lg font-bold">About You</div>
        <div className="flex font-opensans text-sm">
          Join Date: {profileCreatedDate}
        </div>
        <div className="flex font-opensans text-sm font-bold">
          Logged In as: {email} ({loginType})
        </div>
        <div className="border-b border-primary-color-100 border-opacity-25 pt-3" />
      </div>
      {/* <div className="flex flex-col justify-evenly gap-3">
        <div className="flex font-opensans text-lg pt-3">
          Your Connected Accounts
        </div>
        <div className="flex items-center gap-3">
          <img src={facebookIcon} alt="facebook-icon" />
          <a className="underline" href="/facebook-link">
            Connect with Facebook
          </a>
        </div>
        <div className="flex items-center gap-3">
          <img src={twitterIcon} alt="twitter-icon" />
          <a className="underline" href="/twitter-link">
            Connect with Twitter
          </a>
        </div>
        <div className="border-b border-primary-color-100 border-opacity-25 pt-3" />
      </div> */}
      {false && (
        <div className="flex flex-col gap-3 pt-3">
          <div className="flex font-opensans text-lg font-bold">
            Manage Your Password
          </div>
          <TextInput
            label="Current Password"
            placeholder="Enter your current password"
            value={currentPassword}
            inputId="current_password"
            textInputType="password"
            typeOfState="normal"
            size="sm"
            onChange={(value) => setCurrentPassword(value)}
          />
          <TextInput
            label="New Password"
            placeholder="Enter your new password"
            value={newPassword}
            inputId="new_password"
            textInputType="password"
            typeOfState="normal"
            size="sm"
            onChange={(value) => setNewPassword(value)}
          />
          <div className="flex flex-col items-start font-opensans">
            <p className="text-xs">Don&#39;t use a previous password</p>
            <p className="text-xs">
              Don&#39;t use your name or email in your password
            </p>
            <p className="text-xs">Must have 8 characters in length</p>
            <p className="text-xs">
              Must have a mix of lowercase, uppercase, numbers and symbols
              (!@#$%^&amp;*)
            </p>
          </div>
          <TextInput
            label="Confirm New Password"
            placeholder="Confirm your new password"
            value={confirmNewPassword}
            inputId="confirm_new_password"
            textInputType="password"
            typeOfState={passwordsMatch ? "normal" : "error"}
            infoMessage={passwordsMatch ? "" : "Passwords do not match"}
            size="sm"
            onChange={(value) => setConfirmNewPassword(value)}
          />
          <Button
            disabled={!passwordsMatch}
            containerClassName="px-1 py-4"
            text="Change"
            size="sm"
            type="primary"
            onClick={() =>
              changePasswordMutation({
                oldPW: currentPassword,
                newPW: newPassword,
              })
            }
          />
          <div className="border-b border-primary-color-100 border-opacity-25 pt-3" />
        </div>
      )}
    </div>
  );
};

export default GeneralAccountSettings;
