/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation, RefetchOptions, QueryObserverResult } from "react-query";
import { AxiosError } from "axios";
import Modal from "../../../../../components/Modal";
import { CanceledVariants, ChildOrder, ParentOrder } from "../../../Shop.types";
import styles from "../Order.module.css";
import Button from "../../../../../components/Button";
import TextInput from "../../../../../components/TextInput";
import { isPositiveNumber } from "../../../../AddOrEditProduct/ItemDetails/Variants/Variants";
import { TypeOfState } from "../../../../../components/TextInput/TextInput.utils";
import CheckBox from "../../../../../components/CheckBox";
import { updateOrderStatus, updateProductQuantity } from "../../actions";
import edit from "../../../../../assets/edit.svg";

interface VariantRecord {
  id: string;
  name: string;
  status: string;
  quantity: number;
  price: number;
  url: string;
  productId: string;
  variant: { [key: string]: string };
}

export const getInputState = (
  value: string,
  valueLimit: number
): { validationType: TypeOfState; message: string } => {
  if (!isPositiveNumber(value)) {
    return { validationType: "error", message: "Please enter a valid number" };
  }
  if (+value > valueLimit) {
    return {
      validationType: "error",
      message: "Quantity cannot be more than available",
    };
  }
  return { validationType: "normal", message: " " };
};

// show the products/variants in the order and each one will have status
const ManageOrder = ({
  isOpen,
  onClose,
  order,
  reFetchOrdersByShop,
}: {
  isOpen: boolean;
  onClose: () => void;
  order: ChildOrder;
  reFetchOrdersByShop: (
    options?: RefetchOptions | undefined
  ) => Promise<
    QueryObserverResult<AxiosError<unknown, unknown> | ParentOrder[], unknown>
  >;
}): JSX.Element => {
  const [toBeCanceledVariants, setToBeCanceledVariants] = useState<string[]>(
    []
  );
  const [note, setNote] = useState("");
  const [products, setProducts] = useState<VariantRecord[]>([]);
  const [initialProductQuantities, setInitialProductQuantities] = useState<{
    [variantId: string]: number;
  }>({});
  const [editVariant, setEditVariant] = useState("");
  const shipments = order?.shipments || [];
  // to keet track of shipped variants and their quantities
  const shippedVariantsWithQuantities = shipments.reduce((acc, shipment) => {
    const { variants } = shipment;
    variants.forEach((variant) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { variant_id, quantity } = variant;
      if (acc[variant_id]) {
        acc[variant_id] += quantity;
      } else {
        acc[variant_id] = quantity;
      }
    });
    return acc;
  }, {} as { [key: string]: number });
  const variantsInShipments = Object.keys(shippedVariantsWithQuantities);
  const canceledVariants = order?.cancelled || [];
  const canceledVariantIds = canceledVariants.flatMap(
    (cancelledVariant) => cancelledVariant.variant_id
  );

  const { mutate: updateProductQuantityMutation } = useMutation(
    updateProductQuantity,
    {
      onSuccess: () => {
        reFetchOrdersByShop();
      },
      onError: (err: Error) => {
        toast.error(
          err?.message || "Failed to update quantity, Please try again",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      },
    }
  );

  const { mutate: updateOrderStatusMutation } = useMutation(updateOrderStatus, {
    onSuccess: () => {
      reFetchOrdersByShop();
      toast.success("Product canceled successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setToBeCanceledVariants([]);
      setNote("");
    },
    onError: (err: Error) => {
      toast.error(err?.message || "Failed to update, Please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  useEffect(() => {
    const transformedProducts = order?.product_details.reduce(
      (acc, product) => {
        const {
          variant = {},
          details: { name = "", has_variant: hasVariant = false },
          quantity,
          price,
        } = product;
        const variantDesc = Object.values(variant).join(", ");
        // remove the shipped quantity from the total quantity
        const shippedQuantity =
          shippedVariantsWithQuantities[product.variant_id] || 0;
        acc.push({
          variant,
          id: product.variant_id,
          productId: product.product_id,
          name: `${name}${hasVariant ? ` - ${variantDesc}` : ""}`,
          status: "",
          quantity: quantity - shippedQuantity,
          price,
          url: "",
        });
        return acc;
      },
      [] as VariantRecord[]
    );
    setInitialProductQuantities(
      transformedProducts.reduce((acc, product) => {
        acc[product.id] = product.quantity;
        return acc;
      }, {} as { [key: string]: number })
    );
    setProducts(transformedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.product_details]);

  const canceledVariantsColumns = useMemo(
    () => [
      {
        Header: "Canceled Products from the Order",
        columns: [
          {
            Header: "Product ID",
            accessor: "product_id",
          },
          {
            Header: "Product ID",
            accessor: "variant_id",
          },
          {
            Header: "Product Description",
            accessor: (canceledOrder: CanceledVariants) => {
              const keys = Object.keys(canceledOrder.variant || {});
              return (
                <div className="flex flex-col gap-4">
                  {keys.length > 0
                    ? keys.reduce((desc, key) => {
                        const value = canceledOrder.variant[key];
                        // eslint-disable-next-line no-param-reassign
                        desc += `${key}: ${value}\t`;
                        return desc;
                      }, "")
                    : ""}
                </div>
              );
            },
          },
          {
            Header: "Price",
            accessor: "price",
          },
          {
            Header: "Quantity",
            accessor: "quantity",
          },
          {
            Header: "Note",
            accessor: "note",
          },
        ],
      },
    ],
    []
  );

  const toBeCanceledVariantsKeysLength = toBeCanceledVariants.length;

  const columns = useMemo(() => {
    return [
      {
        Header: "Products",
        columns: [
          {
            Header: "Select to cancel",
            accessor: (variant: VariantRecord) => {
              const noProducts = initialProductQuantities[variant.id] === 0;
              if (noProducts) {
                return null;
              }
              return (
                <CheckBox
                  text=""
                  isEnabled={!variantsInShipments.includes(variant.id)}
                  isSelected={toBeCanceledVariants.includes(variant.id)}
                  onChange={({ isSelected }: { isSelected: boolean }) => {
                    setToBeCanceledVariants((prevToBeCanceledVariants) => {
                      if (isSelected) {
                        return [...prevToBeCanceledVariants, variant.id];
                      }
                      return prevToBeCanceledVariants.filter(
                        (key) => key !== variant.id
                      );
                    });
                  }}
                />
              );
            },
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Quantity",
            accessor: (variant: VariantRecord) => {
              const value = variant.quantity || 0;
              const noProducts = initialProductQuantities[variant.id] === 0;
              const getTextInputState = (
                val: number
              ): {
                typeOfState: TypeOfState;
                infoMessage: string;
                isChangedNeeded: boolean;
              } => {
                if (val === 0 && variantsInShipments.includes(variant.id)) {
                  return {
                    typeOfState: "error",
                    infoMessage: "Shipped products quantity cannot be 0",
                    isChangedNeeded: false,
                  };
                }
                if (!val && editVariant.includes(variant.id)) {
                  return {
                    typeOfState: "error",
                    infoMessage: "Please enter a valid number",
                    isChangedNeeded: false,
                  };
                }
                if (val > initialProductQuantities[variant.id]) {
                  return {
                    typeOfState: "error",
                    infoMessage: "Quantity cannot be more than available",
                    isChangedNeeded: false,
                  };
                }
                if (val === initialProductQuantities[variant.id]) {
                  return {
                    typeOfState: "normal",
                    infoMessage: " ",
                    isChangedNeeded: true,
                  };
                }
                return {
                  typeOfState: "normal",
                  infoMessage: " ",
                  isChangedNeeded: true,
                };
              };
              const { typeOfState, infoMessage } = getTextInputState(value);
              return (
                <div
                  className={`flex ${
                    noProducts ? "justify-center" : ""
                  } items-start`}
                >
                  {noProducts ? (
                    "-"
                  ) : (
                    <TextInput
                      inputId="quantity"
                      label=""
                      textInputType="text"
                      readOnly={editVariant !== variant.id}
                      value={value.toString()}
                      typeOfState={typeOfState}
                      infoMessage={infoMessage}
                      size="sm"
                      placeholder=""
                      onBlur={() => {
                        if (typeOfState === "error") {
                          setProducts((prevProducts) => {
                            const updatedProducts = [...prevProducts];
                            const index = updatedProducts.findIndex(
                              (product) => product.id === variant.id
                            );
                            updatedProducts[index].quantity =
                              initialProductQuantities[variant.id] || 0;
                            return updatedProducts;
                          });
                        }
                      }}
                      overrideInfoMessageStyleClass=""
                      onChange={(val) => {
                        setProducts((prevProducts) => {
                          const updatedProducts = [...prevProducts];
                          const index = updatedProducts.findIndex(
                            (product) => product.id === variant.id
                          );
                          updatedProducts[index].quantity = +val || 0;
                          return updatedProducts;
                        });
                        const {
                          typeOfState: localTypeOfState,
                          isChangedNeeded,
                        } = getTextInputState(+val);
                        if (localTypeOfState === "normal" && isChangedNeeded) {
                          updateProductQuantityMutation({
                            orderId: order._id,
                            variantId: variant.id,
                            quantity: +val,
                            note,
                          });
                        }
                      }}
                    />
                  )}
                  {!noProducts && (
                    <div
                      tabIndex={0}
                      role="button"
                      className="w-full mt-2"
                      onKeyDown={() => {}}
                      onClick={() => setEditVariant(variant.id)}
                    >
                      <img src={edit} alt="Edit" className="cursor-pointer" />
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            Header: "Price",
            accessor: "price",
          },
          {
            Header: "Status",
            accessor: (variant: VariantRecord) => {
              if (variantsInShipments.includes(variant.id)) {
                return "Shipment is created";
              }
              if (canceledVariantIds.includes(variant.id)) {
                return "Product is canceled";
              }
              return "Product is available";
            },
          },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toBeCanceledVariants, editVariant]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data:
        products.length > 0
          ? products.map((variantsRecord) => {
              return {
                ...variantsRecord,
              };
            })
          : [],
    });

  const {
    getTableProps: getCanceledOrderTableProps,
    getTableBodyProps: getCanceledOrderBodyProps,
    headerGroups: canceledOrderHeaderGroups,
    rows: canceledOrderRows,
    prepareRow: prepareCanceledOrderRow,
  } = useTable({
    columns: canceledVariantsColumns,
    data:
      canceledVariants.length > 0
        ? canceledVariants.map((variantsRecord) => {
            return {
              ...variantsRecord,
            };
          })
        : [],
  });

  return (
    <Modal
      overrideModalStyle="h-5/6 w-full max-w-7xl"
      overrideModalContainerStyle="items-center"
      isForceClose={!isOpen}
      onClose={onClose}
    >
      <div className="px-12">
        <div className="flex flex-col">
          <div className="pb-4">
            <h4 className="flex mt-6 mb-2">Manage Order</h4>
            <p className="flex font-normal font-opensans text-left text-sm">
              Manage and View Products Orders.
            </p>
            <div className="mt-4">
              <div className="flex justify-between gap-5">
                {products.length > 0 && (
                  <div className={styles.table}>
                    <table
                      {...getTableProps()}
                      style={{
                        overflowX: "auto",
                        width: "100%",
                      }}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            // eslint-disable-next-line react/prop-types
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            {toBeCanceledVariantsKeysLength > 0 && (
              <div className="mt-4">
                <TextInput
                  isRequired
                  inputId="note"
                  label="Note"
                  textInputType="text"
                  containerStyleClass="w-5/12"
                  value={note}
                  typeOfState={note.trim().length === 0 ? "error" : "normal"}
                  infoMessage={
                    note.trim().length === 0
                      ? "Please enter a reason for cancelation"
                      : " "
                  }
                  size="sm"
                  placeholder=""
                  overrideInfoMessageStyleClass=""
                  onChange={setNote}
                />
              </div>
            )}
            <div className="flex flex-col gap-2 mt-4 items-start">
              <Button
                text="Cancel Products"
                size="sm"
                type="red"
                disabled={
                  toBeCanceledVariantsKeysLength === 0 ||
                  note.trim().length === 0
                }
                containerClassName="w-1/4"
                onClick={() =>
                  updateOrderStatusMutation({
                    id: order._id,
                    status: "CANCELLED",
                    note,
                    variantIds: toBeCanceledVariants,
                  })
                }
              />
              {variantsInShipments.length > 0 && (
                <span className="text-xs text-red-500">
                  *Note: Products with shipments cannot be canceled
                </span>
              )}
            </div>
            <div className="pt-4">
              <div className="flex justify-between gap-5">
                {canceledVariants.length > 0 && (
                  <div className={styles.table}>
                    <table
                      {...getCanceledOrderTableProps()}
                      style={{
                        overflowX: "auto",
                        width: "100%",
                      }}
                    >
                      <thead>
                        {canceledOrderHeaderGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getCanceledOrderBodyProps()}>
                        {canceledOrderRows.map((row) => {
                          prepareCanceledOrderRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ManageOrder;
