import React, { useEffect, useState } from "react";

// packages
import { useHistory, Link } from "react-router-dom";

// components
import Modal from "../../components/Modal";

// utils
import { useQueryParams, useAuth } from "../../hooks";
import { setItem } from "../../utils";

export default function ActivateUser(): JSX.Element {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  const history = useHistory();
  const queryParams = useQueryParams();
  const { isAuthenticated } = useAuth();
  const token = queryParams.get("token") || "";

  useEffect(() => {
    // if user is already authenticated, update his token and redirect to home page,
    // else ask the user to re-login,
    // Ref: https://github.com/TeamLonima/lonima-api/issues/94
    if (isAuthenticated && token) {
      setItem("token", token);
      history.push("/");
    } else {
      setShowLoginPrompt((s) => !s);
    }
  }, [history, isAuthenticated, token]);

  return (
    <Modal alwaysOpen overrideModalStyle="md:max-w-2xl">
      <div className="flex flex-col m-10 gap-6 w-full">
        {showLoginPrompt && (
          <div className="flex w-full justify-center">
            <div className="bodySmall text-primary-color-80">
              Your account is activated. Please click&nbsp;
            </div>
            <span className="bodySmall text-indigo">
              <Link to="/login">here</Link>
            </span>
            <div className="bodySmall text-primary-color-80">
              &nbsp;to login
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
