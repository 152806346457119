import React, { useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  ICartResponse,
  ICartProductDetails,
} from "../../components/CartItem/types";
import CartSummary from "../../components/CartSummary";
import {
  deleteProductFromCart,
  fetchCart,
  updateCart,
} from "../../containers/CartItem/actions";
import CartItemContainer from "../../containers/CartItem";
import { GET_CART } from "../../store/actions.types";
import { getCheckoutSession } from "./actions";
import arrowLeftIcon from "../../assets/arrow-left.svg";
import { getTax } from "../Checkout/actions";
import { AddressPayload } from "../../types";
import ResendEmailBanner from "../../containers/ResendEmailBanner";

interface Props {
  showCartText?: boolean;
  showCartSummary?: boolean;
  onAction?: (data?: unknown) => void;
  disableQuantityOption?: boolean;
  hideIsGiftOption?: boolean;
  reviewPageStyle?: string;
  checkoutSessionId?: string;
  shippingAddress?: AddressPayload;
}

const CartPage = ({
  showCartText = true,
  showCartSummary = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAction = (data: unknown) => {},
  disableQuantityOption,
  hideIsGiftOption,
  reviewPageStyle = "",
  checkoutSessionId = "",
  shippingAddress = {},
}: Props): JSX.Element => {
  const history = useHistory();
  const { data: cartDetails, refetch: refetchCart } = useQuery(
    GET_CART,
    fetchCart,
    { refetchOnMount: true, refetchOnWindowFocus: false }
  );

  const { mutate: getTaxMutation } = useMutation(getTax, {
    onSuccess: (data) => {
      onAction(data);
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.error("Failed to calculate tax");
    },
  });
  const { mutate: updateCartMutation } = useMutation(updateCart, {
    onSuccess: () => {
      refetchCart();
      if (!showCartText) {
        getTaxMutation({ checkoutId: checkoutSessionId, shippingAddress });
      }
    },
    onError: () => {},
  });
  const { mutate: deleteProductFromCartMutation } = useMutation(
    deleteProductFromCart,
    {
      onSuccess: () => {
        refetchCart();
        if (!showCartText) {
          getTaxMutation({ checkoutId: checkoutSessionId, shippingAddress });
        }
      },
      onError: () => {},
    }
  );
  const { data: checkoutSessionDetails, refetch: getCheckoutSessionDetails } =
    useQuery("GET_CHECKOUT_SESSION", getCheckoutSession, {
      enabled: false,
    });

  useEffect(() => {
    if (
      showCartText &&
      checkoutSessionDetails &&
      checkoutSessionDetails.data?.value.checkoutSessionId
    ) {
      history.push(
        `/checkout/${checkoutSessionDetails.data?.value.checkoutSessionId}`
      );
    }
  }, [checkoutSessionDetails, history, showCartText]);

  const handleUpdateQuantity = (
    productId: string,
    quantity: number,
    productName?: string
  ) => {
    if (quantity > 0) {
      updateCartMutation({
        productId,
        productName: productName || "",
        quantity,
        type: "cart",
      });
    } else {
      deleteProductFromCartMutation({
        productId,
        productName: productName || "",
      });
    }
  };

  const handleOnActionClick = () => {
    if (showCartText) {
      getCheckoutSessionDetails();
    }
    onAction();
  };

  const cartData = useMemo(
    () => (cartDetails?.data?.value || {}) as ICartResponse,
    [cartDetails]
  );
  const cartProductData = useMemo(
    () =>
      cartData?.data?.length > 0
        ? cartData?.data?.reduce((cartAllShopData, shopData) => {
            shopData?.items?.forEach((item) => {
              cartAllShopData.push(item);
            });
            return cartAllShopData;
          }, [] as ICartProductDetails[])
        : [],
    [cartData]
  );

  return (
    <div className={`flex flex-col ${reviewPageStyle || "mx-4 md:mx-5 my-4"}`}>
      <ResendEmailBanner />
      {!reviewPageStyle && (
        <a href="/" className="w-full flex text-xl font-bold mt-3 mb-6">
          <img src={arrowLeftIcon} className="mr-2" alt="previous" />
          Continue Shopping
        </a>
      )}
      {cartProductData.length > 0 && (
        <div className="flex gap-6 flex-col lg:flex-row">
          {showCartSummary && (
            <CartSummary
              isActionButtonEnabled
              cartProductData={cartProductData}
              cartData={cartData}
              showCartText={showCartText}
              onAction={handleOnActionClick}
              mViewShortcut
            />
          )}
          <CartItemContainer
            cartProductData={cartProductData}
            showCartText={showCartText}
            handleUpdateQuantity={handleUpdateQuantity}
            disableQuantityOption={disableQuantityOption}
            hideIsGiftOption={hideIsGiftOption}
            cartData={cartData}
          />
          {showCartSummary && (
            <div className="flex w-full lg:w-8/12">
              <CartSummary
                isActionButtonEnabled
                cartProductData={cartProductData}
                cartData={cartData}
                showCartText={showCartText}
                onAction={handleOnActionClick}
                mViewShortcut={false}
              />
            </div>
          )}
        </div>
      )}
      {cartProductData.length === 0 && (
        <p className="text-2xl">Your cart is empty</p>
      )}
      {/* {showCartText && <div className="flex">You may also like</div>} */}
    </div>
  );
};

CartPage.defaultProps = {
  showCartText: true,
  showCartSummary: true,
  onAction: () => {},
  hideIsGiftOption: false,
  disableQuantityOption: false,
  reviewPageStyle: "",
  checkoutSessionId: "",
  shippingAddress: {},
};

export default CartPage;
