const tabs = [
  { display: "Account", value: "account" },
  // { display: "Preferences", value: "preferences" },
  // { display: "Privacy", value: "privacy" },
  // { display: "Security", value: "security" },
  { display: "Addresses", value: "addresses" },
  { display: "Credit Cards", value: "payments" },
  // { display: "Email", value: "email" },
];

export default tabs;
