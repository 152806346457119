/* eslint-disable @typescript-eslint/naming-convention */
// utils
import { AxiosError } from "axios";
import axios, { processErrorCatch } from "../../axios";

// ts
import { Result } from "../../types";

// eslint-disable-next-line import/prefer-default-export
export const getAddressFromCoordinates = async (location: {
  lat: number;
  lng: number;
}): Promise<Result> => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_GOOGLE_API_URL}?latlng=${location.lat},${location.lng}&result_type=administrative_area_level_1|locality&key=AIzaSyBnCsQlpWDEwwRlPhGJ03Os7xQnaWYERHo`
    );
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export const getCategoryHierarchy = async (): Promise<Result> => {
  try {
    const resp = await axios.get("/category/hierarchy");
    return await Promise.resolve({ data: { value: resp.data } });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};
