/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useContext,
  useState,
  useEffect,
  MutableRefObject,
} from "react";

// packages
import { useMutation } from "react-query";
import { Editor } from "react-draft-wysiwyg";
import DOMPurify from "dompurify";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// components
import { ImageEditor } from "./ImageEditor";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import TextInput from "../../../../components/TextInput";
import * as Icons from "../../../../components/Icons";

// context
import ShopContext from "../../Shop.context";

// actions
import {
  updateShop,
  updateShopAddress,
  uploadShopAvatarAndCoverImage,
} from "../actions";

// icons
import {
  facebookNoColorIcon,
  instagramNoColorIcon,
  twitterNoColorIcon,
} from "../../../../assets";

// ts
import { Shop, SocialMediaPlatform } from "../../Shop.types";
import { Address as PlaceAddress } from "../../../SellOnLonima/Location";

// utils
import {
  getGoogleMapsPlacePredictions,
  getPlaceDetails,
} from "../../../SellOnLonima/Location/location.utils";
import {
  convertBlobToFile,
  validateSocialMediaProfileUrl,
} from "../../../../utils";
import {
  getPreSelectedUSState,
  US_STATES_OPTIONS,
} from "../../../../components/AddressForm/constants";

// https://github.com/jpuri/react-draft-wysiwyg/issues/795
const options = [
  "inline",
  "blockType",
  "fontSize",
  "fontFamily",
  "list",
  "textAlign",
  "colorPicker",
  // "link",
  // "embedded" ,
  // "emoji",
  // "image",
  "remove",
  "history",
];

const EditProfile = ({
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: () => void;
}): JSX.Element => {
  const [error, setError] = useState("");
  const [userEnteredLocation, setUserEnteredLocation] = useState("");
  const [location, setLocation] = useState({
    addressLn1: "",
    addressLn2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  });
  const [searchRef, setSearchRef] =
    useState<MutableRefObject<HTMLInputElement | null>>();
  const [addresses, setAddresses] = useState<PlaceAddress[]>([]);
  // using to track adding version to image or not
  const [isImageEditorClicked, setIsImageEditorClicked] = useState(false);
  const [profilePicture, setProfilePicture] = useState<{
    url: string;
    blobImg: Blob | MediaSource;
  }>({ url: "", blobImg: null as unknown as Blob | MediaSource });
  const [coverPicture, setCoverPicture] = useState<{
    url: string;
    blobImg: Blob | MediaSource;
  }>({ url: "", blobImg: null as unknown as Blob | MediaSource });
  const [policiesEditorState, setPoliciesEditorState] = useState(
    EditorState.createEmpty()
  );
  const [aboutUsEditorState, setAboutUsEditorState] = useState(
    EditorState.createEmpty()
  );
  const [returnsExchangesEditorState, setReturnsExchangesEditorState] =
    useState(EditorState.createEmpty());

  const { editProfileShopDetails, updateShopDetails, id, imageRerenderId } =
    useContext(ShopContext);
  const {
    description = "",
    address,
    name = "",
    cover_image,
    logo_image,
    about_us = "[]",
    social_links,
    policies = "[]",
    return_and_exchanges = "[]",
  } = editProfileShopDetails;

  const resetError = () => {
    setTimeout(() => {
      setError("");
    }, 5000);
  };

  useEffect(() => {
    if (address[0]) {
      setUserEnteredLocation(
        `${address[0]?.address_ln1}, ${address[0]?.city}, ${address[0]?.state}`
      );
      setLocation({
        addressLn1: address[0].address_ln1,
        addressLn2: address[0].address_ln2,
        city: address[0].city,
        state: address[0].state,
        country: "US",
        zipCode: address[0].zip_code,
      });
    }
  }, [address]);

  const { mutate: updateShopImagesMutation, isLoading: imagesUploadLoading } =
    useMutation(uploadShopAvatarAndCoverImage, {
      onSuccess: () => {
        onSave();
        onClose();
      },
      onError: () => {
        setError("Failed to update shop details, Please try again");
        resetError();
      },
    });
  const profilePictureBlogImage = profilePicture.blobImg;
  const coverPictureBlogImage = coverPicture.blobImg;
  const isImagePresent = profilePictureBlogImage || coverPictureBlogImage;

  const { mutate: updateShopMutation, isLoading } = useMutation(updateShop, {
    onSuccess: () => {
      if (isImagePresent) {
        updateShopImagesMutation({
          shopLogo: profilePictureBlogImage
            ? convertBlobToFile(profilePictureBlogImage as Blob, "shop_avatar")
            : null,
          shopCover: coverPictureBlogImage
            ? convertBlobToFile(coverPictureBlogImage as Blob, "shop_cover")
            : null,
          shopId: id,
        });
      } else {
        onSave();
        onClose();
      }
    },
    onError: () => {
      setError("Failed to update shop details, Please try again");
      resetError();
    },
  });

  const {
    mutate: updateShopAddressMutation,
    isLoading: isShopAddresMutationLoading,
  } = useMutation(updateShopAddress, {
    onSuccess: () => {
      updateShopMutation({
        id,
        shopDetails: {
          ...editProfileShopDetails,
          about_us: DOMPurify.sanitize(
            JSON.stringify(convertToRaw(aboutUsEditorState.getCurrentContent()))
          ),
          policies: DOMPurify.sanitize(
            JSON.stringify(
              convertToRaw(policiesEditorState.getCurrentContent())
            )
          ),
          return_and_exchanges: DOMPurify.sanitize(
            JSON.stringify(
              convertToRaw(returnsExchangesEditorState.getCurrentContent())
            )
          ),
        } as Shop,
      });
    },
    onError: () => {
      setError("Failed to update shop address, Please try again");
      resetError();
    },
  });

  useEffect(() => {
    const aboutUsObj = JSON.parse(about_us) as RawDraftContentState;
    if (aboutUsObj.blocks) {
      const newEditorState = EditorState.createWithContent(
        convertFromRaw(aboutUsObj)
      );
      // Update the state
      setAboutUsEditorState(newEditorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(about_us)]);

  useEffect(() => {
    const policiesObj = JSON.parse(policies) as RawDraftContentState;
    if (policiesObj.blocks) {
      const newEditorState = EditorState.createWithContent(
        convertFromRaw(policiesObj)
      );
      // Update the state
      setPoliciesEditorState(newEditorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(policies)]);

  useEffect(() => {
    const returnsExchangeObj = JSON.parse(
      return_and_exchanges
    ) as RawDraftContentState;
    if (returnsExchangeObj.blocks) {
      const newEditorState = EditorState.createWithContent(
        convertFromRaw(returnsExchangeObj)
      );
      // Update the state
      setReturnsExchangesEditorState(newEditorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(return_and_exchanges)]);

  const updateLocation = async (val: string) => {
    setUserEnteredLocation(val);
    if (val.length > 4) {
      const results = (await getGoogleMapsPlacePredictions(
        userEnteredLocation
      )) as PlaceAddress[];
      setAddresses(
        results?.length
          ? results.map(({ description: addressDescription, place_id }) => ({
              description: addressDescription,
              place_id,
            }))
          : []
      );
    } else {
      setAddresses([]);
    }
  };

  const onSelectAddress = async (placeId: string) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { formatted_address, address_components } = (await getPlaceDetails(
      searchRef?.current as HTMLDivElement,
      placeId
    )) as {
      formatted_address: string;
      address_components: Array<{
        short_name: string;
        long_name: string;
        types: Array<string>;
      }>;
    };

    setUserEnteredLocation(formatted_address);
    const fullAddressObj = {
      street_number: "",
      route: "",
      postal_code: "",
      postal_code_suffix: "",
      locality: "",
      administrative_area_level_1: "",
      country: "",
    };
    address_components.forEach((addressComponent) => {
      const componentType = addressComponent.types[0];

      switch (componentType) {
        case "street_number": {
          fullAddressObj.street_number = addressComponent.short_name;
          break;
        }

        case "route": {
          fullAddressObj.route = addressComponent.short_name;
          break;
        }

        case "postal_code": {
          fullAddressObj.postal_code = addressComponent.long_name;
          break;
        }

        case "postal_code_suffix": {
          fullAddressObj.postal_code_suffix = addressComponent.long_name;
          break;
        }

        case "locality":
          fullAddressObj.locality = addressComponent.long_name;
          break;

        case "administrative_area_level_1": {
          fullAddressObj.administrative_area_level_1 =
            addressComponent.short_name;
          break;
        }

        case "country":
          fullAddressObj.country = addressComponent.long_name;
          break;
        default:
          break;
      }
    });

    setLocation({
      addressLn1: `${fullAddressObj.street_number} ${fullAddressObj.route}`,
      addressLn2: "",
      city: fullAddressObj.locality,
      state: fullAddressObj.administrative_area_level_1,
      country: fullAddressObj.country,
      zipCode: `${fullAddressObj.postal_code} - ${fullAddressObj.postal_code_suffix}`,
    });
    setAddresses([]);
  };

  const socialMediaMap = {
    facebook: {
      icon: facebookNoColorIcon,
      value: social_links?.facebook?.trim(),
      isValidUrl: validateSocialMediaProfileUrl(social_links?.facebook?.trim()),
    },
    instagram: {
      icon: instagramNoColorIcon,
      value: social_links?.instagram?.trim(),
      isValidUrl: validateSocialMediaProfileUrl(
        social_links?.instagram?.trim()
      ),
    },
    twitter: {
      icon: twitterNoColorIcon,
      value: social_links?.twitter?.trim(),
      isValidUrl: validateSocialMediaProfileUrl(social_links?.twitter?.trim()),
    },
  };

  const isSaveDisabled =
    Object.values(socialMediaMap).some(
      (value) => !value.isValidUrl && value.value
    ) ||
    name?.trim()?.length === 0 ||
    userEnteredLocation?.trim()?.length === 0 ||
    isLoading ||
    isShopAddresMutationLoading;

  const profilePictureLocalUrl = profilePicture.url || logo_image;
  const coverPictureLocalUrl = coverPicture.url || cover_image;
  const wrapperStyle = {
    maxHeight: "20em",
  };

  return (
    <Modal
      onClose={onClose}
      overrideModalStyle="h-5/6 sm:w-5/6 justify-between sm:justify-start overflow-none w-full"
      overrideModalContainerStyle="items-end sm:items-center"
    >
      <div
        className="flex flex-col justify-evenly items-center h-full"
        style={{ flex: 3 }}
      >
        <div className="flex justify-center pb-6 w-3/4" style={{ flex: 0.25 }}>
          <div className="flex font-poppins not-italic font-light text-2xl leading-9 text-primary-color-100">
            Edit Shop Details
          </div>
        </div>
        <div
          className="flex flex-col gap-10 px-6 w-full overflow-y-auto mb-5"
          style={{ flex: 2 }}
        >
          <div className="flex flex-col-reverse md:flex-row lg:flex-row">
            <div className="flex flex-col w-full gap-2">
              <div className="flex">
                <label
                  aria-required
                  className="flex font-opensans font-normal text-base"
                  htmlFor="profile_picture"
                >
                  Profile Picture
                </label>
              </div>
              <div className="flex gap-2">
                {profilePictureLocalUrl ? (
                  <img
                    key={`edit-profile-${imageRerenderId}`}
                    src={`${profilePictureLocalUrl}${
                      isImageEditorClicked ? "" : `?${imageRerenderId}`
                    }`}
                    alt="Avatar"
                    width="200px"
                  />
                ) : (
                  <Icons.ProfileAvatar />
                )}
                <ImageEditor
                  isCircularCrop
                  onUpload={({ blobImg }) => {
                    setIsImageEditorClicked(true);
                    setProfilePicture({
                      url: URL.createObjectURL(blobImg),
                      blobImg,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col w-full gap-2">
              <div className="flex">
                <label
                  aria-required
                  className="flex font-opensans font-normal text-base"
                  htmlFor="profile_picture"
                >
                  Cover Picture
                </label>
              </div>
              <div className="flex gap-2 w-full">
                {coverPictureLocalUrl ? (
                  <img
                    key={`edit-cover-${imageRerenderId}`}
                    src={`${coverPictureLocalUrl}${
                      isImageEditorClicked ? "" : `?${imageRerenderId}`
                    }`}
                    alt="Avatar"
                    className="rounded-t-md object-cover h-40 md:h-48 lg:h-52 w-full"
                  />
                ) : (
                  <Icons.ProfileAvatar />
                )}
                <ImageEditor
                  onUpload={({ blobImg }) => {
                    setCoverPicture({
                      url: URL.createObjectURL(blobImg),
                      blobImg,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <TextInput
              isRequired
              inputId="name"
              label="Name (Your shop name that will be shown everywhere)"
              textInputType="text"
              value={name || ""}
              containerStyleClass="w-full"
              typeOfState={name?.trim()?.length > 0 ? "normal" : "error"}
              size="sm"
              placeholder=""
              infoMessage={
                name?.trim()?.length > 0
                  ? ""
                  : "Name field should not be empty."
              }
              overrideInfoMessageStyleClass=""
              onChange={(value: string) =>
                updateShopDetails({ key: "name", value })
              }
            />
          </div>
          <div className="flex w-full">
            <TextInput
              inputId="description"
              label="Title (Give a small title for your shop so customers know what you sell) "
              textInputType="text"
              value={description || ""}
              containerStyleClass="w-full"
              size="sm"
              placeholder=""
              infoMessage=""
              overrideInfoMessageStyleClass=""
              onChange={(value: string) =>
                updateShopDetails({ key: "description", value })
              }
            />
          </div>
          <div className="flex flex-col w-full">
            <TextInput
              isRequired
              setRef={setSearchRef}
              inputId="location"
              label="Shop Address"
              textInputType="text"
              value={userEnteredLocation}
              typeOfState={
                userEnteredLocation?.trim()?.length === 0 ? "error" : "normal"
              }
              containerStyleClass="w-full"
              size="sm"
              placeholder="Add your address..."
              infoMessage={
                userEnteredLocation?.trim()?.length === 0
                  ? "Shop address cannot be empty"
                  : ""
              }
              overrideInfoMessageStyleClass=""
              onChange={updateLocation}
            />
            {addresses.length > 0 && (
              <div className="flex flex-col w-full h-7/12 border border-primary-color-100 border-opacity-25">
                {addresses.map(
                  ({ description: addressDescription, place_id }) => {
                    return (
                      <div
                        className="flex p-2 border-b border-primary-color-100 cursor-pointer"
                        onClick={() => onSelectAddress(place_id)}
                        key={place_id}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                      >
                        {addressDescription}
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-6 mb-10 w-full">
            <div className="flex w-full">
              <TextInput
                disabled
                inputId="address_ln1"
                label="Street Address"
                textInputType="text"
                value={location.addressLn1}
                containerStyleClass="w-full"
                size="sm"
                placeholder=""
                infoMessage=""
                overrideInfoMessageStyleClass=""
                onChange={() => {}}
              />
            </div>
            <div className="flex w-full">
              <TextInput
                inputId="address_ln2"
                label="Apt / Suite / Other"
                textInputType="text"
                value={location.addressLn2}
                containerStyleClass="w-full"
                size="sm"
                placeholder=""
                infoMessage=""
                overrideInfoMessageStyleClass=""
                onChange={(value) => {
                  setLocation({
                    ...location,
                    addressLn2: value,
                  });
                }}
              />
            </div>
            <div className="flex flex-wrap md:flex-nowrap gap-8">
              <TextInput
                disabled
                inputId="zip_code"
                label="Zip / Postal code"
                textInputType="text"
                value={location.zipCode}
                containerStyleClass="w-full"
                size="sm"
                placeholder=""
                infoMessage=""
                overrideInfoMessageStyleClass=""
                onChange={() => {}}
              />
              <TextInput
                disabled
                inputId="city"
                label="City"
                textInputType="text"
                value={location.city}
                containerStyleClass="w-full"
                size="sm"
                placeholder=""
                infoMessage=""
                overrideInfoMessageStyleClass=""
                onChange={() => {}}
              />
            </div>
            {location.state && (
              <div className="flex md:w-1/2 lg:w-1/4">
                <Dropdown
                  disableDropdown
                  placeholderInput="Choose State ..."
                  title="State"
                  type="primary"
                  size="fullWidth"
                  preSelectedOptions={[
                    getPreSelectedUSState(location.state) as {
                      label: string;
                      value: string;
                    },
                  ]}
                  options={US_STATES_OPTIONS}
                  useAddressFormStyle
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-5">
            {Object.keys(socialMediaMap).map((socialMediaKey) => {
              const socialMediaPlatform = socialMediaKey as SocialMediaPlatform;
              const { icon, isValidUrl } = socialMediaMap[socialMediaPlatform];
              return (
                <div className="flex gap-2 w-full" key={socialMediaKey}>
                  <div>
                    <img
                      width={55}
                      height={55}
                      src={icon}
                      alt={`${socialMediaPlatform}-icon`}
                    />
                  </div>
                  <TextInput
                    inputId={`social_link_${socialMediaPlatform}`}
                    textInputType="text"
                    value={social_links?.[socialMediaPlatform] || ""}
                    containerStyleClass="w-full"
                    size="sm"
                    placeholder={`Enter your shop ${socialMediaPlatform} url here`}
                    typeOfState={
                      // eslint-disable-next-line no-nested-ternary
                      isValidUrl
                        ? "normal"
                        : social_links?.[socialMediaPlatform]?.trim()?.length >
                          0
                        ? "error"
                        : "normal"
                    }
                    infoMessage={
                      // eslint-disable-next-line no-nested-ternary
                      isValidUrl
                        ? ""
                        : social_links?.[socialMediaPlatform]?.trim()?.length >
                          0
                        ? `Enter a valid ${socialMediaPlatform} url`
                        : ""
                    }
                    overrideInfoMessageStyleClass=""
                    onChange={(value: string) =>
                      updateShopDetails({
                        key: "social_links",
                        value,
                        valueKey: socialMediaPlatform,
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-5 mb-10">
            <div className="flex">
              <label
                aria-required
                className="flex font-opensans font-bold text-base"
                htmlFor="policies"
              >
                About Us
              </label>
            </div>
            <Editor
              wrapperStyle={wrapperStyle}
              editorStyle={{
                padding: 10,
                border: "1px solid #f1f1f1",
              }}
              editorState={aboutUsEditorState}
              onEditorStateChange={setAboutUsEditorState}
              placeholder="Tell us about the shop..."
              toolbar={{
                options,
              }}
            />
          </div>
          <div className="flex flex-col gap-5 my-10">
            <div className="flex">
              <label
                aria-required
                className="flex font-opensans font-bold text-base"
                htmlFor="policies"
              >
                Policies
              </label>
            </div>

            <Editor
              wrapperStyle={wrapperStyle}
              editorStyle={{
                padding: 10,
                border: "1px solid #f1f1f1",
              }}
              editorState={policiesEditorState}
              onEditorStateChange={setPoliciesEditorState}
              placeholder="Add your policies here."
              toolbar={{
                options,
              }}
            />
          </div>
          <div className="flex flex-col gap-5 my-10">
            <div className="flex">
              <label
                aria-required
                className="flex font-opensans font-bold text-base"
                htmlFor="returns_and_exchanges"
              >
                Returns and Exchanges
              </label>
            </div>
            <Editor
              wrapperStyle={wrapperStyle}
              editorStyle={{
                padding: 10,
                border: "1px solid #f1f1f1",
              }}
              editorState={returnsExchangesEditorState}
              onEditorStateChange={setReturnsExchangesEditorState}
              placeholder="Add your Returns and Exchanges info here."
              toolbar={{
                options,
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-1/2 md:w-2/6" style={{ flex: 0.5 }}>
          <Button
            containerClassName="flex w-full justify-end rounded-md"
            text="Save"
            size="lg"
            type="primary"
            disabled={isSaveDisabled}
            onClick={() => {
              if (!isSaveDisabled) {
                updateShopAddressMutation({
                  id,
                  address: {
                    address_ln1: location.addressLn1,
                    address_ln2: location.addressLn2,
                    city: location.city,
                    state: location.state,
                    zip_code: location.zipCode,
                    _id: address[0]?._id,
                    county: address[0]?.county || ".",
                    full_name: address[0]?.full_name || name,
                  },
                });
              }
            }}
            loading={
              isImagePresent ? imagesUploadLoading || isLoading : isLoading
            }
          />
          <div className="flex flex-end justify-center w-full mt-5">
            {!!error && (
              <div className="flex justify-center font-opensans font-normal text-sm text-neutral-color-4">
                {error}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfile;
