/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import React, { useState, useRef, useMemo, useContext, useEffect } from "react";

// components
import ProductWithImage from "../../../../../components/ProductWithImage";

// ts
import { Product as ProductType } from "../../../../Product/types";

// hooks
import { useAuth } from "../../../../../hooks";

// store
import { Context } from "../../../../../store";

export const Product = ({
  product,
  onRemove,
}: {
  product: ProductType;
  onRemove: () => unknown;
}): JSX.Element => {
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const { isAuthenticated } = useAuth();
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const {
    state: {
      user: {
        profile: { shops },
      },
    },
  } = useContext(Context);
  const isUserBelongToShop = useMemo(
    () => product.shop_id === shops?.[0]?.shop_id,
    [shops, product.shop_id]
  );

  const toggleAdditionalOptions = () => {
    if (isAuthenticated && isUserBelongToShop) {
      setShowAdditionalOptions(!showAdditionalOptions);
    }
  };

  useEffect(() => {
    const handleHotKeys = (e: MouseEvent) => {
      if (!contextMenuRef?.current?.contains(e.target as HTMLElement)) {
        setShowAdditionalOptions(false);
      }
    };
    document.addEventListener("mousedown", handleHotKeys);
    return () => {
      document.removeEventListener("mousedown", handleHotKeys);
    };
  }, [contextMenuRef]);

  const {
    _id,
    image_url,
    shop_id,
    name,
    min_price,
    max_price,
    product_rating: { no_of_reviews, product_rating },
  } = product;
  const actualPrice =
    max_price && min_price && max_price !== min_price
      ? `${min_price} - $${max_price}`
      : min_price;
  return (
    <div className="p-2 relative">
      {showAdditionalOptions && (
        <div
          style={{
            position: "absolute",
            bottom: "0%",
            right: "25%",
          }}
          ref={contextMenuRef}
          className=" flex flex-col gap-4 justify-evenly items-start rounded-md 
          border border-solid border-primary-color-100 border-opacity-10 
          p-4 
          bg-white 
          filter drop-shadow w-max z-50"
        >
          <a href={`${window.location.origin}/product?id=${product._id}`}>
            View Product
          </a>
          <a
            href={`/edit-product?shop_id=${product.shop_id}&product_id=${product._id}`}
          >
            Edit Product
          </a>

          <button
            className="cursor-pointer hover:underline"
            type="button"
            onClick={onRemove}
          >
            {" "}
            Delete Product
          </button>

          {/* <div className="flex gap-10">
            <Icons.Show
              fill="black"
              onClick={() => history.push(`/product?id=${product._id}`)}
            />
            <Icons.EditSquare
              fill="black"
              onClick={() =>
                history.push(
                  `/add-product?shop_id=${product.shop_id}&product_id=${product._id}`
                )
              }
            />
            <Icons.Delete
              fill="red"
              onClick={() => {
                if (isMouseOver) {
                  onRemove();
                }
              }}
            />
          </div> */}
        </div>
      )}
      <ProductWithImage
        key={_id}
        productId={_id}
        shopId={shop_id}
        productImage={image_url}
        imageAlt="product-image"
        headerText={name as string}
        productCost={actualPrice as string}
        enableAddToCart={false}
        productRatings={{
          starRatings: product_rating,
          totalReviews: no_of_reviews,
        }}
        overrideShowFavoriteFlag={false}
        showAdditionalOptions={isAuthenticated && isUserBelongToShop}
        onClickAdditionalOptions={toggleAdditionalOptions}
      />
    </div>
  );
};
