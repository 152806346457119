/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from "react";

// packages
import { useQuery } from "react-query";

// components
import StarIcon from "../../StarIcon";
import Pagination from "../../Pagination";

// actions
import { fetchProductReviews } from "./Reviews.actions";

interface ProductReview {
  rating: number;
  review_text: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  _v: string;
  _id: string;
  product_id: string;
  order_id: string;
  user: {
    first_name: string;
    last_name: string;
    avatar: string;
  };
}

const pageSize = 20;

const Reviews = ({ productId }: { productId: string }): JSX.Element => {
  const [pageNumber, setPageNumber] = useState(1);

  const { data: reviews, isFetching } = useQuery(
    [{ productId, pageNumber, pageSize }],
    fetchProductReviews,
    {
      enabled: !!productId,
      onError: () => {
        // eslint-disable-next-line no-console
        console.log("failed to fetch product reviews");
      },
    }
  );

  const productReviews = (reviews?.data?.value?.productReviews.data ??
    []) as ProductReview[];
  const totalCount =
    reviews?.data?.value?.productReviews?.resultInfos?.totalCount || 0;

  const getTimestamp = (dateStr: string) => {
    const dateStrObj = new Date(dateStr);
    const fullYear = dateStrObj.getFullYear();
    const month = dateStrObj.getMonth();
    const date = dateStrObj.getDate();
    const dateObj = new Date(fullYear, month, date);
    const monthAlphaStr = dateObj.toLocaleString("en-US", {
      month: "short",
    });
    return `${monthAlphaStr} ${dateStrObj.getDate()}, ${dateStrObj.getFullYear()}`;
  };

  const content = (
    <div className="flex flex-col gap-5">
      {productReviews.length ? (
        productReviews.map((productReview) => {
          const {
            _id,
            rating,
            review_text,
            updated_at,
            user: { first_name, last_name, avatar },
          } = productReview;
          return (
            <div key={`review_main_${_id}`}>
              <div className="flex gap-3">
                {avatar && (
                  <div className="flex">
                    <img
                      className="rounded-full cursor-pointer"
                      src={avatar}
                      alt="Avatar"
                      width={25}
                      height={25}
                    />
                  </div>
                )}
                <div className="flex font-normal text-primary-color-100 font-opensans">
                  {`${last_name}, ${first_name}`}
                </div>
              </div>
              <div className="flex items-start gap-2">
                <span>
                  {Array.from({ length: 5 }, (v, i) => {
                    const value = i + 1;
                    return (
                      <StarIcon
                        starId={value}
                        key={`star_${value}`}
                        disabled
                        marked={rating >= value}
                        onClick={() => {}}
                      />
                    );
                  })}
                </span>
                <div className="flex font-normal text-primary-color-80 font-opensans">
                  {getTimestamp(updated_at)}
                </div>
              </div>
              {!!review_text && (
                <div className="flex">
                  <div className="flex font-normal text-left text-primary-color-100 font-opensans">
                    {review_text}
                  </div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="flex">
          <p>Reviews are not available yet...</p>
        </div>
      )}
    </div>
  );
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-center">
        {totalCount > 0 ? (
          <Pagination
            isContentLoading={isFetching}
            renderContent={content}
            totalPages={Math.ceil(totalCount / pageSize)}
            pageNumber={pageNumber - 1}
            handlePageClick={(selectedItem) => {
              setPageNumber(selectedItem?.selected + 1);
            }}
          />
        ) : (
          <div>{content}</div>
        )}
      </div>
    </div>
  );
};
export default Reviews;
