import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import Tab from "../../components/Tab";
import { useQueryParams } from "../../hooks";
import { heartPngIcon } from "../../assets";
import ProductWithImage from "../../components/ProductWithImage";
import { FETCH_ALL_FAVORITES } from "../../store/actions.types";
import { getAllUserFavoriteProducts } from "../../containers/Favorites/actions";

const PublicProfile: FC = () => {
  const favTabs = [
    {
      display: "Your favorite items",
      value: "items",
      count: 0,
      icon: heartPngIcon,
    },
    // {
    //   display: "Your favorite sellers (0)",
    //   value: "sellers",
    //   count: 0,
    //   icon: bagIcon,
    // },
  ];
  const queryParams = useQueryParams();
  const [currentTabValue, setCurrentTabValue] = useState(
    queryParams.get("tab") || favTabs[0].value
  );

  const [favoriteProducts, setFavoriteProducts] = useState<
    Array<{ name: string; _id: string; shop_id: string }>
  >([]);

  const history = useHistory();

  const { refetch: refetchAllFavoriteProducts, data: allFavoriteProducts } =
    useQuery(FETCH_ALL_FAVORITES, getAllUserFavoriteProducts, {
      enabled: false,
    });

  useEffect(() => {
    refetchAllFavoriteProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allFavoriteProducts?.data) {
      setFavoriteProducts(allFavoriteProducts?.data.value.items_details);
    }
  }, [allFavoriteProducts]);

  const onSelect = (tabValue: string) => {
    setCurrentTabValue(tabValue);
    const userId = queryParams.get("id");
    if (userId) {
      const routeToPush = tabValue
        ? `/profile?id=${userId}&tab=${tabValue}`
        : `/profile?id=${userId}`;
      history.push(routeToPush);
    }
  };

  const getTabs = () => {
    switch (currentTabValue) {
      case "items":
        return (
          favoriteProducts.length > 0 &&
          favoriteProducts.map((favProduct) => (
            <div className="p-4 flex gap-6" style={{ minHeight: 200 }}>
              <ProductWithImage
                key={favProduct._id}
                productId={favProduct._id}
                shopId={favProduct.shop_id}
                productImage="https://res.cloudinary.com/lonima/image/upload/v1633922581/sample.jpg"
                imageAlt=""
                headerText={favProduct.name}
                productCost="10.00"
                productRatings={{
                  starRatings: 0,
                  totalReviews: 0,
                }}
                enableAddToCart={false}
                isFavorite
              />
            </div>
          ))
        );
      case "sellers":
        return (
          <div className="p-4" style={{ minHeight: 200 }}>
            You haven’t favorited any items yet. Start exploring...you might
            just find something you love!
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className="flex flex-col m-6 justify-start">
      {/* <div className="flex flex-start p-4 w-full items-center">
        <Icons.ProfileAvatar />
        <div className="flex flex-col p-6">
          <p className="font-poppins font-semibold text-3xl text-primary-color-100">
            Joseph Smith
          </p>
          <div className="flex justify-evenly">
            <p className="pr-4">0 Following</p>
            <p>0 Followers</p>
          </div>
          <p>Tampa, Florida</p>
        </div>
      </div> */}
      <div className="flex flex-col">
        <div className="flex">
          {favTabs.map((favTab) => {
            return (
              <Tab
                key={favTab.value}
                option={favTab}
                isSelected={currentTabValue === favTab.value}
                onClick={onSelect}
              />
            );
          })}
        </div>
        <div className="flex">{getTabs()}</div>
      </div>
    </div>
  );
};

export default PublicProfile;
