import React, { useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

// components
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import PopOver from "../../../PopOver";
import Portal from "../../../Portal";
import bag from "../../../../assets/bag.svg";
import shopOutlinePrimary from "../../../../assets/shop-outline-primary-icon.svg";
import setting from "../../../../assets/setting.svg";
import logOut from "../../../../assets/logout.svg";

// store
import { Context } from "../../../../store";
import { getItem } from "../../../../utils";

interface Props {
  toggleShowUserOptions: () => void;
  title: string;
  avatar: string;
}

const UserActionPopOver = ({
  toggleShowUserOptions,
  title,
  avatar,
}: Props): JSX.Element => {
  const history = useHistory();
  const node = useRef<HTMLDivElement>(null);

  const {
    state: {
      user: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        profile: { shops = [], email_verified },
      },
    },
  } = useContext(Context);

  const shopId = shops.length ? shops[shops.length - 1].shop_id : "";

  useEffect(() => {
    const handleHotKeys = (e: KeyboardEvent | MouseEvent) => {
      if (!node?.current?.contains(e.target as HTMLElement)) {
        toggleShowUserOptions();
      }
    };
    document.addEventListener("mousedown", handleHotKeys);
    return () => {
      document.removeEventListener("mousedown", handleHotKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  const redirectTo = (path: string) => {
    history.push(path);
    toggleShowUserOptions();
  };

  const signOut = () => {
    toggleShowUserOptions();
    window.location.href = `${window.location.origin}/logout`;
  };

  const getOptions = (
    options: {
      icon: string;
      alt: string;
      url: string;
      label: string;
      hide?: boolean;
    }[]
  ) => {
    return options.map(({ icon, alt, url, label, hide }) => {
      if (hide) return null;
      return (
        <div
          className="flex baseline justify-left py-2 md:px-1 text-primary-color-100 hover:font-bold hover:bg-contrast-color-1-10"
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          key={label}
          onClick={() => redirectTo(url)}
        >
          <div className="flex w-7" key={alt}>
            <img src={icon} className="w-full" alt={alt} />
          </div>
          <div className="flex-col w-full ml-1 md:ml-2" key={label}>
            <div className="flex font-opensans font-normal text-base leading-6">
              {label}
            </div>
          </div>
        </div>
      );
    });
  };

  const {
    treatments: {
      customer_account_settings: customerAccountSettings,
      customer_orders_reviews: customerOrdersReviews,
    },
    isReady,
  } = useSplitTreatments({
    names: ["customer_account_settings", "customer_orders_reviews"],
    attributes: { userId: getItem("split_override_user_id") as string },
  });
  if (!isReady) return <div>Loading...</div>;

  const options = [
    // { icon: chat, alt: "Chat", url: "/account/messages", label: "Messages" },
    {
      icon: bag,
      alt: "Bag",
      url: "/account/orders-reviews",
      label: "Orders & Reviews",
      hide: customerOrdersReviews.treatment === "off" || !email_verified,
    },
    {
      icon: shopOutlinePrimary,
      alt: "Shop Outline",
      url: shopId ? `/shop/${shopId}` : "/sell-on-lonima/name",
      label: shopId ? "My Shop" : "Sell on Lonima",
    },
    {
      icon: setting,
      alt: "Setting",
      url: "/account/account-settings",
      label: "Account Settings",
      hide: customerAccountSettings.treatment === "off",
    },
  ];

  return (
    <div>
      <Portal id="user-profile">
        <PopOver
          position="right"
          overrideStyleClassName="w-64 pt-6 sm:pt-3 z-50 md:w-72 py-2"
        >
          <div className="flex-co w-full" ref={node}>
            <div className="items-center flex pl-2 pb-2 gap-3">
              <div className="flex">
                <img
                  className="rounded-full cursor-pointer"
                  src={avatar}
                  alt="Avatar"
                  width={50}
                  height={50}
                />
              </div>
              <div className="flex-col">
                <div className="flex font-opensans font-normal hover:font-bold text-base leading-6 text-primary-color-100">
                  {title}
                </div>
                <div
                  className="flex hover:font-bold hover:underline"
                  onKeyDown={() => {}}
                  onClick={() => redirectTo("/account/profile-settings")}
                  role="button"
                  tabIndex={0}
                >
                  Edit Profile
                </div>
              </div>
            </div>
            {getOptions(options)}
            <div
              className="flex py-2 md:px-1 text-primary-color-100 hover:bg-contrast-color-1-10"
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              onClick={signOut}
            >
              <div className="flex">
                <img src={logOut} alt="Log Out" className="cursor-pointer" />
              </div>
              <div className="flex-col ml-1 md:ml-2">
                <div className="flex font-opensans font-normal hover:font-bold text-base leading-6">
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        </PopOver>
      </Portal>
    </div>
  );
};

export default UserActionPopOver;
