import React, { useState, useEffect } from "react";

// packages
import { useLocation } from "react-router-dom";

// hooks
import { useQueryParams } from "../../../hooks";

// components
import styles from "./CheckoutProgress.module.css";

// icons
import {
  statusComplete,
  statusInComplete,
  statusInProgress,
} from "../../../assets";

type Status = "complete" | "inprogress" | "incomplete";

interface ProgressItem {
  status: Status;
  label: string;
}

type Key = "shipping" | "payment";

interface Props {
  updatedKeyValue?: { key: Key; value: Status };
}

const CheckoutProgress = ({
  updatedKeyValue = { key: "shipping", value: "complete" },
}: Props): JSX.Element => {
  const [checkoutStatus, setCheckoutStatus] = useState<{
    shipping: ProgressItem;
    payment: ProgressItem;
  }>({
    shipping: { status: "inprogress", label: "Shipping" },
    payment: { status: "complete", label: "Payment & Review" },
  });

  const location = useLocation();

  const queryParams = useQueryParams();

  useEffect(() => {
    setCheckoutStatus((prev) => {
      const temp = { ...prev };
      const { key, value } = updatedKeyValue;
      temp[key].status = value;
      return temp;
    });
  }, [updatedKeyValue]);

  const mapping = {
    shipping: "/checkout",
    payment: `/payment`,
  };

  const getStatus = (key: "shipping" | "payment"): Status => {
    if (queryParams.get(key)) {
      return "complete";
    }
    if (location.pathname.includes(mapping[key])) {
      return "inprogress";
    }
    return "incomplete";
  };

  useEffect(() => {
    setCheckoutStatus((prev) => {
      const temp = { ...prev };
      temp.shipping.status = getStatus("shipping");
      temp.payment.status = getStatus("payment");
      if (location.pathname.includes("/order")) {
        temp.shipping.status = "complete";
        temp.payment.status = "complete";
      }
      return temp;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const iconMapping = {
    complete: { icon: statusComplete, text: "Complete" },
    incomplete: { icon: statusInComplete, text: "InComplete" },
    inprogress: { icon: statusInProgress, text: "InProgress" },
  };

  return (
    <div className="flex justify-between w-full lg:w-5/6 my-2">
      {Object.keys(checkoutStatus).map((checkoutStatusItem: string) => {
        const { status = "incomplete", label = "" } = checkoutStatus[
          checkoutStatusItem as Key
        ] as ProgressItem;
        return (
          <div className="flex flex-col w-1/2" key={label}>
            <div
              className={`flex ${
                ["complete", "inprogress"].includes(status)
                  ? styles.progressSelected
                  : styles.progress
              }`}
              style={{ width: "100%" }}
            />
            <div className="flex my-2 items-center">
              <div className="flex mr-2">
                <img
                  src={iconMapping[status]?.icon}
                  alt={iconMapping[status]?.text}
                  className="cursor-pointer"
                />
              </div>
              <div className="flex">{label}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CheckoutProgress.defaultProps = {
  updatedKeyValue: { key: "shipping", value: true },
};

export default CheckoutProgress;
