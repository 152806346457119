import React from "react";
import Modal from "../../Modal";
import { InputProps } from "./types";

const Categories = ({
  categories,
  hiddenFlag,
  onCloseCategories,
}: InputProps): JSX.Element => {
  const sortedCategories = categories?.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return (
    <div>
      {!hiddenFlag && (
        <Modal
          overrideModalStyle="h-auto lg:hidden w-full"
          overrideModalContainerStyle="items-end"
          onClose={onCloseCategories}
        >
          <div className="flex flex-col w-full mb-6">
            {[
              { name: "Products near me", value: "products_near_me" },
              ...sortedCategories,
            ].map((category) => (
              <button
                key={category.value}
                type="button"
                className="flex items-center justify-between px-4 py-2 text-base font-normal capitalize font-opensans text-primary-color-100 focus:outline-none lg:pt-0 hover:bg-contrast-color-1-10"
                onClick={() => {
                  if (category.value === "products_near_me") {
                    window.location.href = `${window.location.origin}/search`;
                  } else {
                    window.location.href = `${window.location.origin}/search?category=${category.value}`;
                  }
                }}
              >
                {category.value === "products_near_me" ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${window.location.origin}/search`}
                  >
                    {category.name}
                  </a>
                ) : (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${window.location.origin}/search?category=${category.value}`}
                  >
                    {category.name}
                  </a>
                )}

                {/* {!hiddenFlag && (
            <img className="lg:hidden" src={arrowRightIcon} alt="arrow-right" />
          )} */}
              </button>
            ))}
          </div>
        </Modal>
      )}
      <div className="z-30 hidden w-full px-4 border rounded-md md:py-0 lg:px-0 lg:pt-2 md:justify-between lg:border-0 lg:flex lg:flex-row">
        {[
          { name: "Products near me", value: "products_near_me" },
          ...sortedCategories,
        ]?.map((category) => (
          <button
            key={category.value}
            type="button"
            className={`py-2 items-center justify-between flex font-opensans font-normal rounded-full text-base text-primary-color-100 capitalize focus:outline-none px-2  ${
              category.value === "products_near_me"
                ? "bg-primary-color-100 hover:bg-primary-color-90"
                : "hover:bg-disabled-300"
            }`}
            onClick={() => {
              window.location.href =
                category.value === "products_near_me"
                  ? `${window.location.origin}/search`
                  : `${window.location.origin}/search?category=${category.value}`;
            }}
          >
            <span
              className={`lg:block ${
                category.value === "products_near_me"
                  ? "text-contrast-color-1-10"
                  : ""
              }`}
            >
              {category.name}
            </span>
            {/* {!hiddenFlag && (
            <img className="lg:hidden" src={arrowRightIcon} alt="arrow-right" />
          )} */}
          </button>
        ))}
      </div>
    </div>
  );
};

Categories.defaultProps = {
  categories: [],
  hiddenFlag: true,
};

export default Categories;
