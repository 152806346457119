/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useContext,
} from "react";

// packages
import { useMutation } from "react-query";
import "react-image-crop/dist/ReactCrop.css";

// components
import { toast } from "react-toastify";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import CheckBox from "../../../../components/CheckBox";
import Button from "../../../../components/Button";

import * as Icons from "../../../../components/Icons";

// constants
import {
  defaultProfileConfig,
  DEFAULT_PRONOUNS,
  ENABLED_PROFILE_KEYS,
} from "../../constants";

// ts
import { UserDetails } from "../../types";
import { ProfileKeys, DOB, Props, DOBKey } from "./types";

// utils
import { getKeyValues, setKeyValues } from "../../../../utils";
import {
  getDaysInMonthYear,
  getDOBTokens,
  getBirthDayOptions,
  getDobValues,
} from "./utils";

// store
import { updateUserDetails } from "../../actions";
import { SET_USER } from "../../../../store/actions.types";
import { Context } from "../../../../store";

import "react-toastify/dist/ReactToastify.css";

export const BaseProfile = ({
  userDetails = defaultProfileConfig,
  onChange = () => {},
  onProfilePictureUpload = () => {},
  avatarUrl,
  uploadAvatarLoading,
}: Props): JSX.Element => {
  const [dobVals, setDobVals] = useState<{ [key: string]: DOB }>({});
  const [upImg, setUpImg] = useState<string>(avatarUrl || "");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [profile, setProfile] = useState<UserDetails>(defaultProfileConfig);
  const fileRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imgRef = useRef<any>(null);

  const { dispatch } = useContext(Context);

  // on mount let's populate user profile
  useEffect(() => {
    if (userDetails && Object.values(userDetails).length > 0) {
      setProfile(userDetails);
      setUpImg(userDetails.avatar);
    }
  }, [userDetails]);

  useEffect(() => {
    setUploadLoading(uploadAvatarLoading);
  }, [uploadAvatarLoading]);

  useEffect(() => {
    setUpImg(avatarUrl);
    imgRef.current = null;
  }, [avatarUrl]);

  const {
    first_name = "",
    last_name = "",
    dob = "",
    pronouns = [],
    about = "",
    email_verified = false,
  } = profile;

  useEffect(() => {
    setDobVals(getDobValues(profile.dob || ""));
    onChange(profile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const updateProfile = (
    key: ProfileKeys,
    value: string | boolean | string[]
  ) => {
    setProfile((prevProfile) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const prevProfileCopy: any = { ...prevProfile };
      prevProfileCopy[key] = value;
      return prevProfileCopy;
    });
  };

  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleUpload = () => {
    onProfilePictureUpload(imgRef.current);
    setUploadLoading(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e.target.files?.length > 0) {
      const reader = new FileReader();
      const image = e.target.files[0];
      imgRef.current = image;
      if (image.size > 10000000) {
        toast.error("Image file size should be less than 10MB");
        imgRef.current = null;
        return;
      }
      reader.addEventListener("load", () => {
        const imageNew = new Image();
        imageNew.src = reader.result as string;
        imageNew.onload = () => {
          const { height, width } = imageNew;
          if (height < 400 || width < 400) {
            toast.error("Image dimensions should be atleast 400px by 400px");
            imgRef.current = null;
            return;
          }
          setUpImg(reader?.result as string);
        };
      });
      reader.readAsDataURL(image);
    }
  };

  const { mutate } = useMutation(updateUserDetails, {
    onSuccess: (response) => {
      const data = response?.data?.value;
      const newData = setKeyValues(data, ENABLED_PROFILE_KEYS) as UserDetails;
      setProfile(newData);
      dispatch({
        type: SET_USER,
        payload: { profile: { ...data } },
      });
    },
    onError: () => {},
  });

  const updatePronouns = (currentPronoun: string, isSelected: boolean) => {
    let payload = pronouns;
    if (isSelected) {
      if (!pronouns.includes(currentPronoun)) {
        payload = [...pronouns, currentPronoun];
      }
    } else {
      payload = pronouns.filter((pronoun) => pronoun !== currentPronoun);
    }
    updateProfile("pronouns", payload);
  };

  const updateDOB = (key: DOBKey, value: string) => {
    const tokens = getDOBTokens(dob);
    const tz = "T00:00:00.000Z";
    const dobMap = {
      Year: `${value}-${tokens[1] || ""}-${tokens[2] || ""}`,
      Month: `${tokens[0] || ""}-${value}-${tokens[2] || ""}`,
      Day: `${tokens[0] || ""}-${tokens[1] || ""}-${value}`,
    };
    // While updating Year/Month, the number of Days should be recalculated
    if (key !== "Day") {
      setDobVals((prevDobValues = {}) => {
        const { birth_day, birth_month, birth_year } = prevDobValues as {
          [key: string]: DOB;
        };
        const monthValue = +(key === "Year" ? birth_month?.value : value);
        const yearValue = +(key === "Month" ? birth_year?.value : value);
        const numOfDays = getDaysInMonthYear(monthValue, yearValue);
        return numOfDays
          ? {
              ...prevDobValues,
              birth_day: {
                ...birth_day,
                options: getBirthDayOptions(numOfDays),
              },
            }
          : prevDobValues;
      });
    }
    const matchingValue = dobMap[key];
    if (matchingValue) {
      updateProfile("dob", `${matchingValue}${tz}`);
    }
  };

  const textStyle =
    "flex font-opensans text-lg leading-7 text-primary-color-100";
  // TODO: the width should be dynamic
  const checkBoxContainerStyle = "flex px-1";
  const wrapperStyle = "flex flex-col gap-3 w-full";
  const headerStyle = "flex flex-col gap-3 w-full md:w-6/12";
  const subTextStyle =
    "font-opensans not-italic font-light text-base leading-6";
  const detailsContainerStyle =
    "flex gap-16 p-4 border-b border-primary-color-100 border-opacity-25";
  return (
    <div className="flex flex-col flex-1">
      <div className={detailsContainerStyle}>
        <div className={wrapperStyle}>
          <div className="hidden md:flex font-poppins not-italic font-light text-2xl leading-9">
            Profile
          </div>
          <div className="flex justify-center md:justify-start">
            {upImg ? (
              <img
                src={upImg}
                className="rounded-full w-36 h-36"
                alt="Avatar"
              />
            ) : (
              <Icons.ProfileAvatar />
            )}
          </div>
          <div className="flex flex-col">
            <input
              type="file"
              ref={fileRef}
              id="upload-button"
              accept=".png, .jpg, .jpeg"
              className="hidden"
              onChange={handleChange}
            />
            <div className="flex flex-col sm:flex-row">
              <Button
                text="Choose"
                size="sm"
                type="outline"
                disabled={!email_verified}
                onClick={handleClick}
              />
              <div className="font-opensans not-italic font-normal text-base leading-6 pl-5 pt-2">
                {imgRef.current ? imgRef.current.name : "No File Chosen"}
              </div>
              {imgRef.current && (
                <Button
                  text="Upload"
                  size="sm"
                  type="primary"
                  containerClassName="pt-4 sm:pl-6 sm:pt-0"
                  onClick={handleUpload}
                  loading={uploadLoading}
                />
              )}
              {imgRef.current && (
                <Button
                  text="Cancel"
                  size="sm"
                  type="primary"
                  containerClassName="pt-4 sm:pl-6 sm:pt-0"
                  onClick={() => {
                    imgRef.current = null;
                    setUpImg(avatarUrl);
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex pt-2">
            <div className={subTextStyle}>
              * Must be a .jpg, .jpeg or .png file smaller than 10MB and at
              least 400px by 400px.
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${detailsContainerStyle} flex-col lg:flex-row flex-wrap`}
      >
        <div className={`${wrapperStyle} md:flex-1`}>
          <div className={`${textStyle} font-bold`}>Your Name</div>
          <div className="flex flex-col w-full md:w-1/2 gap-8">
            <TextInput
              label="First Name"
              placeholder="Joseph"
              value={first_name}
              inputId="first_name"
              typeOfState="normal"
              size="sm"
              onChange={(value) => updateProfile("first_name", value)}
            />
            <TextInput
              label="Last Name"
              placeholder="Smith"
              value={last_name}
              inputId="last_name"
              typeOfState="normal"
              size="sm"
              onChange={(value) => updateProfile("last_name", value)}
            />
          </div>
        </div>
        <div className="flex md:flex-1 flex-col gap-8 w-full">
          <div className="flex flex-col gap-3">
            <div className={`${textStyle} font-bold`}>Birthday</div>
            <div className="flex flex-col w-8/12 sm:w-6/12 md:w-full md:flex-row gap-3">
              {Object.keys(dobVals).map((dobValueKey) => {
                const { label, placeHolder, options, preSelectedOptions } =
                  dobVals[dobValueKey];
                const keyLabel = label as DOBKey;
                const uniqKey = `${JSON.stringify(options)}, ${JSON.stringify(
                  preSelectedOptions
                )}`;
                return (
                  <Dropdown
                    key={uniqKey}
                    preSelectedOptions={preSelectedOptions}
                    placeholderInput={placeHolder}
                    options={options}
                    title={keyLabel}
                    type="primary"
                    size="sm"
                    onChange={(newValue) =>
                      updateDOB(keyLabel, newValue?.value as string)
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className={`${textStyle} font-bold`}>Pronouns</div>
            <div className="flex flex-wrap gap-4">
              {DEFAULT_PRONOUNS.map((currentPronoun) => {
                return (
                  <div className={checkBoxContainerStyle} key={currentPronoun}>
                    <CheckBox
                      text={currentPronoun}
                      isSelected={pronouns.includes(currentPronoun)}
                      isEnabled
                      onChange={({ isSelected }: { isSelected: boolean }) =>
                        updatePronouns(currentPronoun, isSelected)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={detailsContainerStyle}>
        <div className={headerStyle}>
          <div className={`${textStyle} font-bold`}>About</div>
          <div className="flex flex-col gap-8">
            <TextInput
              label="A little about me"
              placeholder="Don't keep us in suspense"
              value={about}
              inputId="about"
              typeOfState="normal"
              size="lg"
              onChange={(value) => updateProfile("about", value)}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full sm:w-auto justify-center md:justify-start gap-16 p-4 py-9">
        <Button
          containerClassName="px-1 py-4"
          text="Update Profile"
          size="md"
          type="primary"
          disabled={!email_verified}
          onClick={() => {
            // TODO: update the keys as the API has new values available
            mutate(getKeyValues(profile, ENABLED_PROFILE_KEYS) as UserDetails);
          }}
        />
      </div>
    </div>
  );
};

BaseProfile.defaultProps = {
  userDetails: {},
  onChange: () => {},
};

export default BaseProfile;
