import React from "react";

// ts
import { Address } from "../../store/types";

const ShopContext = React.createContext({
  id: "" as string,
  title: "" as string,
  address: [] as Address[],
  default_address: "" as string,
  pickup_location: {} as Address,
  pickup_location_id: "" as string,
  description: "" as string,
  name: "" as string,
  tags: [] as string[],
  about_us: "" as string,
  cover_image: "" as string,
  logo_image: "" as string,
  policies:
    "It might sound silly, but we are pretty sure that we are known more for our customer service than for our dice! In short, we care. This philosophy permeates every aspect of the business, from the quality of products we sell, to the fact that we inspect your dice, what quality shipping materials we use, to whom we hire, and how we treat you when you have questions or concerns. We love dice, but we love making our customers happy, just a little bit more." as string,
  rating: 0,
  return_and_exchanges:
    "I gladly accept returns and exchanges Contact me within: 14 days of delivery Ship items back within: 30 days of delivery I don't accept cancellations But please contact me if you have any problems with your order. The following items can't be returned or exchanged Because of the nature of these items, unless they arrive damaged or defective, I can't accept returns for: Custom or personalized orders Perishable products (like food or flowers) Digital downloads Intimate items (for health/hygiene reasons) Returns and exchange details We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.",
  social_links: { facebook: "", instagram: "", twitter: "" },
  total_review_count: 0,
  editProfileShopDetails: {
    title: "" as string,
    address: [] as Address[],
    default_address: "" as string,
    description: "" as string,
    name: "" as string,
    tags: [] as string[],
    about_us: "" as string,
    cover_image: "" as string,
    logo_image: "" as string,
    policies:
      "It might sound silly, but we are pretty sure that we are known more for our customer service than for our dice! In short, we care. This philosophy permeates every aspect of the business, from the quality of products we sell, to the fact that we inspect your dice, what quality shipping materials we use, to whom we hire, and how we treat you when you have questions or concerns. We love dice, but we love making our customers happy, just a little bit more." as string,
    rating: 0,
    return_and_exchanges:
      "I gladly accept returns and exchanges Contact me within: 14 days of delivery Ship items back within: 30 days of delivery I don't accept cancellations But please contact me if you have any problems with your order. The following items can't be returned or exchanged Because of the nature of these items, unless they arrive damaged or defective, I can't accept returns for: Custom or personalized orders Perishable products (like food or flowers) Digital downloads Intimate items (for health/hygiene reasons) Returns and exchange details We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.",
    social_links: { facebook: "", instagram: "", twitter: "" },
    total_review_count: 0,
  },
  updateShopDetails: (() => {}) as ({
    key,
    value,
    valueKey,
  }: {
    key: string;
    value: unknown;
    valueKey?: string;
  }) => void,
  imageRerenderId: "" as string,
});
export default ShopContext;
