import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { useQueryParams } from "../../../hooks";

export const ShopSuccess = (): JSX.Element => {
  const queryParams = useQueryParams();
  const shopId = queryParams.get("id");
  const history = useHistory();
  return (
    <div className="flex flex-col px-4 mt-20 justify-center items-center gap-3">
      <h2>Congratulations!</h2>
      <p>You are now a part of Lonima sellers family.</p>
      <p>Please add items and payment information in the Shop settings.</p>
      <Button
        containerClassName="flex rounded-md"
        text="Go to Shop"
        size="md"
        type="red"
        onClick={() => {
          history.push(`/shop/${shopId}`);
        }}
      />
    </div>
  );
};

export default ShopSuccess;
