import React, { ReactElement } from "react";

const SvgDelete = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  const { fill = "#3F415B", onClick } = props;
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24684 20.4999C7.89184 20.4999 6.57084 20.4849 5.26384 20.4579C3.59184 20.4249 2.43484 19.3409 2.24584 17.6289C1.93084 14.7889 1.39184 8.09491 1.38684 8.02791C1.35284 7.61491 1.66084 7.25291 2.07384 7.21991C2.48084 7.20891 2.84884 7.49491 2.88184 7.90691C2.88684 7.97491 3.42484 14.6459 3.73684 17.4639C3.84384 18.4369 4.36884 18.9389 5.29484 18.9579C7.79484 19.0109 10.3458 19.0139 13.0958 18.9639C14.0798 18.9449 14.6118 18.4529 14.7218 17.4569C15.0318 14.6629 15.5718 7.97491 15.5778 7.90691C15.6108 7.49491 15.9758 7.20691 16.3848 7.21991C16.7978 7.25391 17.1058 7.61491 17.0728 8.02791C17.0668 8.09591 16.5248 14.8069 16.2128 17.6219C16.0188 19.3689 14.8648 20.4319 13.1228 20.4639C11.7898 20.4869 10.5038 20.4999 9.24684 20.4999Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.708 5.48926H0.75C0.336 5.48926 0 5.15326 0 4.73926C0 4.32526 0.336 3.98926 0.75 3.98926H17.708C18.122 3.98926 18.458 4.32526 18.458 4.73926C18.458 5.15326 18.122 5.48926 17.708 5.48926Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4406 5.48924C13.3026 5.48924 12.3146 4.67824 12.0906 3.56224L11.8476 2.34624C11.7966 2.16124 11.5856 2.00024 11.3456 2.00024H7.11258C6.87258 2.00024 6.66158 2.16124 6.60058 2.39224L6.36758 3.56224C6.14458 4.67824 5.15558 5.48924 4.01758 5.48924C3.60358 5.48924 3.26758 5.15324 3.26758 4.73924C3.26758 4.32524 3.60358 3.98924 4.01758 3.98924C4.44358 3.98924 4.81358 3.68524 4.89758 3.26724L5.14058 2.05124C5.38758 1.11924 6.19458 0.500244 7.11258 0.500244H11.3456C12.2636 0.500244 13.0706 1.11924 13.3076 2.00624L13.5616 3.26724C13.6446 3.68524 14.0146 3.98924 14.4406 3.98924C14.8546 3.98924 15.1906 4.32524 15.1906 4.73924C15.1906 5.15324 14.8546 5.48924 14.4406 5.48924Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgDelete;
