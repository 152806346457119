/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import React, { FC, useState, useEffect } from "react";

// packages
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { intersection } from "lodash";
import { v4 as uuid } from "uuid";

// components
import Profile from "./Profile";
import Products from "./Products";

// store
import { fetchShopDetails } from "../../store/actions";

// context
import ShopContext from "./Shop.context";

// ts
import { Shop as ShopType } from "./Shop.types";

// utils
import { isJsonString } from "../../utils";
import { fetchProductsByShop } from "./Products/actions";

const DRAFT_JSON_KEYS = ["blocks", "entityMap"];

const Shop: FC = () => {
  const { id } = useParams() as { id: string };
  const [imageRerenderId, setImageRerenderId] = useState("");

  const {
    data: shopDetailsResp,
    isFetched,
    refetch: refetchShopDetails,
  } = useQuery([{ id }], fetchShopDetails, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const { data: productsInShop, refetch: refetchProductsByShop } = useQuery(
    [{ id, featured: false, pageNumber: 1, pageSize: 1 }],
    fetchProductsByShop,
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );

  const refetch = () => {
    setImageRerenderId(uuid());
    refetchShopDetails();
    refetchProductsByShop();
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    address,
    description,
    name,
    tags,
    default_address,
    cover_image,
    logo_image,
    rating,
    social_links,
    total_review_count,
    title,
    pickup_location_id,
    pickup_location,
  } = shopDetailsResp?.data?.value || {};

  const [shopDetails, setShopDetails] = useState<ShopType>(
    JSON.parse(JSON.stringify(shopDetailsResp?.data?.value || {}))
  );

  useEffect(() => {
    setShopDetails(shopDetailsResp?.data?.value);
  }, [isFetched, shopDetailsResp?.data?.value]);

  const updateShopDetails = ({
    key,
    value,
    valueKey = "",
  }: {
    key: string;
    value: string;
    valueKey?: string;
  }) =>
    setShopDetails((prevShopDetails) => {
      const tempPrevProductDetails = { ...prevShopDetails };
      if (key === "social_links" && valueKey) {
        if (
          Object.keys(tempPrevProductDetails[key as "social_links"] || {})
            .length === 0
        ) {
          tempPrevProductDetails.social_links = {
            facebook: "",
            instagram: "",
            twitter: "",
          };
        }
        tempPrevProductDetails.social_links[
          valueKey as "facebook" | "instagram" | "twitter"
        ] = value;
      } else {
        tempPrevProductDetails[key as "name"] = value;
      }
      return tempPrevProductDetails;
    });

  const draftAboutUsObj = {
    about_us:
      isJsonString(shopDetails?.about_us) &&
      intersection(
        Object.keys(JSON.parse(shopDetails?.about_us)),
        DRAFT_JSON_KEYS
      ).length === 2
        ? shopDetails.about_us
        : "{}",
    policies:
      isJsonString(shopDetails?.policies) &&
      intersection(
        Object.keys(JSON.parse(shopDetails?.policies)),
        DRAFT_JSON_KEYS
      ).length === 2
        ? shopDetails.policies
        : "{}",
    return_and_exchanges:
      isJsonString(shopDetails?.return_and_exchanges) &&
      intersection(
        Object.keys(JSON.parse(shopDetails?.return_and_exchanges)),
        DRAFT_JSON_KEYS
      ).length === 2
        ? shopDetails.return_and_exchanges
        : "{}",
  };

  return (
    <div className="flex flex-col h-full w-full">
      <ShopContext.Provider
        value={{
          id,
          address,
          description,
          name,
          tags,
          default_address,
          about_us: draftAboutUsObj.about_us,
          cover_image,
          logo_image,
          policies: draftAboutUsObj.policies,
          rating,
          return_and_exchanges: draftAboutUsObj.return_and_exchanges,
          social_links,
          total_review_count,
          title,
          pickup_location,
          pickup_location_id,
          editProfileShopDetails: {
            ...shopDetails,
            about_us: draftAboutUsObj.about_us,
            policies: draftAboutUsObj.policies,
            return_and_exchanges: draftAboutUsObj.return_and_exchanges,
          },
          updateShopDetails,
          imageRerenderId,
        }}
      >
        <Profile
          productsSize={productsInShop?.data?.value.data?.length || 0}
          onSave={refetch}
        />
        <Products />
      </ShopContext.Provider>
    </div>
  );
};

export default Shop;
