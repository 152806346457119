/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";

// components
import { useHistory } from "react-router-dom";
import * as Icons from "../../Icons";
import UserActionPopOver from "./UserActionPopOver";

// icons
import { avatarIcon } from "../../../assets";

import shopOutlinePrimary from "../../../assets/shop-outline-primary-icon.svg";

export interface Props {
  isAuthenticated?: boolean;
  title?: string;
  avatar?: string;
  shopId?: string;
  handleSignIn: () => void;
  handleFavoritesClick: () => void;
}

const User = ({
  isAuthenticated = false,
  handleSignIn,
  title = "",
  avatar = avatarIcon,
  shopId,
}: Props): JSX.Element => {
  const [showUserOptions, setShowUserOptions] = useState(false);
  const history = useHistory();

  const toggleShowUserOptions = () => {
    setShowUserOptions((s) => !s);
  };

  return (
    <div className="flex">
      {isAuthenticated ? (
        <div className="flex gap-3 md:gap-4">
          {shopId && (
            <div
              className="flex cursor-pointer hover:bg-disabled-300 px-3 py-2 rounded-full"
              onClick={() => history.push(`/shop/${shopId}`)}
              onKeyPress={() => history.push(`/shop/${shopId}`)}
            >
              <img src={shopOutlinePrimary} width={24} alt="Shop" />
            </div>
          )}
          {/* <div className="flex cursor-pointer">
            <img src={notificationIcon} alt="Favorite" />
            <div className="hidden md:flex mt-2">
              <Icons.ArrowDown />
            </div>
          </div> */}
          <div
            className="flex cursor-pointer p-2 relative h-full"
            onClick={toggleShowUserOptions}
          >
            <img
              className="rounded-full cursor-pointer"
              src={avatar}
              alt="Avatar"
              width={30}
              height={30}
            />
            <div className="cursor-pointer md:flex mt-2">
              <Icons.ArrowDown fill="#3F415B" />
            </div>
            <div
              id="user-profile"
              className="absolute cursor-default top-8 sm:top-5 md:top-8 right-2 sm:right-4 md:-right-3 z-50"
            />
          </div>
        </div>
      ) : (
        <div
          className="flex font-opensans text-base md:text-lg text-primary-color-100 cursor-pointer hover:underline"
          onClick={() => handleSignIn()}
        >
          <p className="display-block md:hidden">Sign In</p>
          <p className="hidden md:block">Sign In</p>
        </div>
      )}
      {showUserOptions && (
        <UserActionPopOver
          toggleShowUserOptions={toggleShowUserOptions}
          title={title}
          avatar={avatar}
        />
      )}
    </div>
  );
};

export default User;
