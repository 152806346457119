import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  MutableRefObject,
} from "react";
import DOMPurify from "dompurify";
import {
  getTextInputStyle,
  TextInputSizes,
  TypeOfState,
  TextInputTypes,
} from "./TextInput.utils";

export interface Props {
  onChange?: (val: string) => void;
  onFocus?: () => void;
  onClick?: () => void;
  onBlur?: (val: string) => void;
  setRef?: (ref?: MutableRefObject<HTMLInputElement | null>) => void;
  inputId: string;
  // to programatically trigger focus for text input
  focusUniqID?: string;
  size?: TextInputSizes;
  textInputType?: TextInputTypes;
  typeOfState?: TypeOfState;
  label?: string;
  placeholder: string;
  infoMessage?: string;
  overrideInfoMessageStyleClass?: string;
  containerStyleClass?: string;
  value: string;
  isRequired?: boolean;
  isSetFocus?: boolean;
  renderExtraComp?: () => JSX.Element;
  extraCompPosition?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoCompleteSuggestions?: "none" | "inline" | "list" | "both" | undefined;
  overrideInputContainerClass?: string;
  hideInput?: boolean;
  decimalPrecision?: number;
}

const TextInput = ({
  onChange = () => {},
  onClick = () => {},
  onFocus = () => {},
  onBlur = () => {},
  setRef = () => {},
  inputId = "text-input",
  size = "sm",
  textInputType = "text",
  typeOfState = "normal",
  isRequired = false,
  isSetFocus = false,
  focusUniqID = "",
  label,
  placeholder,
  infoMessage,
  containerStyleClass,
  overrideInfoMessageStyleClass,
  overrideInputContainerClass,
  value,
  renderExtraComp,
  extraCompPosition = "right",
  disabled = false,
  readOnly = false,
  autoCompleteSuggestions = "none",
  hideInput = false,
  decimalPrecision,
}: Props): JSX.Element => {
  const node = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState(value);
  const [textType, setTextType] = useState(textInputType);

  useEffect(() => {
    setInputValue(value);
    setTextType(textInputType);
  }, [value, textInputType]);

  useEffect(() => {
    setRef(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  useEffect(() => {
    if (isSetFocus && node?.current?.focus) {
      node.current.focus();
    }
  }, [isSetFocus, focusUniqID]);

  const { inputClass, inputClassContainer, labelClass, infoMessageStyleClass } =
    getTextInputStyle({
      size,
      typeOfState,
    });

  const infoMessageStyle =
    overrideInfoMessageStyleClass || infoMessageStyleClass;

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: val } = e.target;
    const sanitizedVal = DOMPurify.sanitize(val);

    setInputValue(sanitizedVal);
    onChange(sanitizedVal);
  };

  const onBlurChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: val } = e.target;
    const sanitizedVal = DOMPurify.sanitize(val);

    if (decimalPrecision) {
      let parsedVal = parseFloat(sanitizedVal);
      if (Number.isNaN(parsedVal)) {
        parsedVal = 0;
      }
      setInputValue(parsedVal.toFixed(decimalPrecision));
      onBlur(parsedVal.toFixed(decimalPrecision));
    } else {
      onBlur(sanitizedVal);
    }
  };

  return (
    <div className={`flex flex-col ${containerStyleClass}`} onFocus={onFocus}>
      <label
        aria-required
        className={`flex ${labelClass} w-inherit`}
        htmlFor={inputId}
      >
        {label}
        {isRequired && <span className="text-red-500"> *</span>}
      </label>
      {!hideInput && (
        <div
          className={`${inputClassContainer}${
            overrideInputContainerClass ? ` ${overrideInputContainerClass}` : ""
          }`}
        >
          {extraCompPosition === "left" && renderExtraComp && renderExtraComp()}
          <input
            disabled={disabled}
            readOnly={readOnly}
            aria-autocomplete={autoCompleteSuggestions}
            onBlur={onBlurChange}
            ref={node}
            className={inputClass}
            required
            autoComplete="off"
            onFocus={(e) => {
              if (e.target.autocomplete) {
                e.target.autocomplete = "whatever" as AutoFill;
              }
            }}
            type={textType}
            id={inputId}
            onClick={onClick}
            value={inputValue}
            placeholder={placeholder}
            onChange={onHandleChange}
            style={
              readOnly ? { backgroundColor: "#f4f4f4", color: "#888" } : {}
            }
          />
          {extraCompPosition === "right" &&
            renderExtraComp &&
            renderExtraComp()}
        </div>
      )}
      {!!infoMessage && <span className={infoMessageStyle}>{infoMessage}</span>}
    </div>
  );
};

TextInput.defaultProps = {
  label: "",
  infoMessage: "",
  overrideInfoMessageStyleClass: "",
  isRequired: false,
  containerStyleClass: "",
  textInputType: "text",
  renderExtraComp: () => <div />,
};

export default TextInput;
