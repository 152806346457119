// ts
import { AxiosError } from "axios";
import { ResendEmail } from "./types";
import { Result } from "../../types";

// utils
import axios, { processErrorCatch } from "../../axios";

const resendEmail = async (formValues: ResendEmail): Promise<Result> => {
  try {
    const { data } = await axios.post("/user/activation/resend", formValues);
    const { message } = data;
    return await Promise.resolve({ message });
  } catch (error) {
    return processErrorCatch(error as AxiosError);
  }
};

export default resendEmail;
