/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useMemo, FC, useContext } from "react";

// packages
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { v4 as uuid, validate as uuidValidate } from "uuid";
import { toast } from "react-toastify";
import { useWindowSize } from "react-use";
import "react-toastify/dist/ReactToastify.css";
import { cloneDeep } from "lodash";

// components
import Images from "./Images";
import ImageView from "./Images/Image";
import ItemDetails from "./ItemDetails";
import Button from "../../components/Button";
import Product from "../../components/Product";

// ts
import {
  AddProductDetails,
  AddImage,
  AddProduct,
} from "./AddOrEditProduct.types";
import { ProductPayload, Variant } from "../Product/types";

// context
import AddOrEditProductContext from "./AddOrEditProduct.context";
import { Context } from "../../store";

// hooks
import { useQueryParams } from "../../hooks";

// actions
import {
  createProduct,
  updateProduct,
  fetchProductDetails,
} from "../Product/actions";

// constants
import {
  DEFAULT_ADD_PRODUCT_DETAILS,
  MAIN_IMAGE_KEY,
  MAIN_IMAGE_PLACEHOLDER_TEXT,
  MAIN_PLACEHOLDER_IMAGE_KEY,
  OTHER_PLACEHOLDER_IMAGE_KEY,
  OTHER_IMAGE_PLACEHOLDER_TEXT,
} from "./AddOrEditProduct.constants";

// utils
import { setItem, convertUrlToBlob, deleteItemStartWith } from "../../utils";
import { arrowLeftIcon } from "../../assets";
import Modal from "../../components/Modal";
import { ImageEditor } from "../Shop/Profile/EditProfile/ImageEditor";
import CheckBox from "../../components/CheckBox";

// when importing a constant JSON.parse(JSON.stringify(obj)) is the only way to make it immmutable, {...obj} does not work
const getDefaultProductData = () => {
  return JSON.parse(JSON.stringify(DEFAULT_ADD_PRODUCT_DETAILS));
};

export const AddOrEditProduct: FC = () => {
  const [productDetails, setProductDetails] = useState<AddProductDetails>(
    getDefaultProductData()
  );
  const [images, setImages] = useState<AddImage[]>([]);
  const [toDeleteImages, setToDeleteImages] = useState<string[]>([]);
  // this will act as as a placeholder for product id (temporary - not persisted in db)
  const [previewId, setPreviewId] = useState(uuid());
  const [shopId, setShopId] = useState("");
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [deleteImageKey, setDeleteImageKey] = useState("");
  const [deleteImageUrl, setDeleteImageUr] = useState("");
  const [fieldsEdited, setFieldsEdited] = useState(false);
  const [mainImageId, setMainImageId] = useState("");
  const [variantSelections, setVariantSelections] = useState<{
    [key: string]: string[];
  }>({});

  const queryParams = useQueryParams();
  const history = useHistory();
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPreviewId(uuid());
  }, [shopId]);

  const {
    state: {
      user: {
        profile: { shops },
      },
    },
  } = useContext(Context);

  const isPreviewMode = queryParams.get("preview")?.trim() === "true";
  const urlShopId = queryParams.get("shop_id")?.trim() || "";
  const urlProductId = queryParams.get("product_id")?.trim() || "";
  const isMobile = useMemo(() => width < 768, [width]);

  const { data: productDetailsResponse, refetch: getProductDetails } = useQuery(
    [{ id: urlProductId }],
    fetchProductDetails,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (urlProductId) {
      getProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlProductId]);

  const editableProductData: AddProduct = productDetailsResponse?.data?.value;

  const getOtherPlaceHolderImage = () => {
    return {
      imageKey: OTHER_PLACEHOLDER_IMAGE_KEY,
      renderElement: () => (
        <ImageEditor
          text={OTHER_IMAGE_PLACEHOLDER_TEXT}
          type={OTHER_PLACEHOLDER_IMAGE_KEY}
          onUpload={({ blobImg, type }) =>
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onUpload({ imageType: type, blobImg })
          }
        />
      ),
    };
  };

  const onUpload = ({
    imageType,
    blobImg,
  }: {
    imageType: string;
    blobImg: Blob | MediaSource;
  }) => {
    setImages(((prevImages: AddImage[]) => {
      const tempImages = [...prevImages];
      const isMainImagePlaceHolder = imageType === MAIN_PLACEHOLDER_IMAGE_KEY;
      const imageKey = isMainImagePlaceHolder
        ? mainImageId || MAIN_IMAGE_KEY
        : uuid();
      const newImage = {
        imageKey,
        blobImg,
        renderElement: () => (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <ImageView
            blobImg={blobImg}
            imageKey={imageKey}
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onRemove={onRemove}
          />
        ),
      };
      if (isMainImagePlaceHolder) {
        const placeHolderOtherImage = tempImages[1];
        tempImages[0] = placeHolderOtherImage;
        tempImages[1] = newImage;
      } else {
        const matchIndex = tempImages.findIndex(
          (tempImage) => tempImage.imageKey === OTHER_PLACEHOLDER_IMAGE_KEY
        );
        tempImages.push(newImage);
        if (matchIndex === -1) {
          tempImages.push(getOtherPlaceHolderImage());
        } else {
          const updatedImages = tempImages.filter(
            (_tempImage, idx) => idx !== matchIndex
          );
          updatedImages.push(getOtherPlaceHolderImage());
          return updatedImages;
        }
      }
      return tempImages;
    }) as unknown as AddImage[]);
  };

  const onRemove = ({ imgKey, imgUrl }: { imgKey: string; imgUrl: string }) => {
    setShowDeleteImageModal(true);
    setDeleteImageKey(imgKey);
    setDeleteImageUr(imgUrl);
  };

  const onDeleteImage = () => {
    setShowDeleteImageModal(false);
    const deleteImageFileName = deleteImageUrl.split("/").pop() || "";
    setToDeleteImages((prevDelImages) => [
      ...prevDelImages,
      deleteImageFileName,
    ]);
    setImages(((prevImages: AddImage[]) => {
      let temp = [...prevImages];
      temp = temp.filter((image) => image.imageKey !== deleteImageKey);
      if (deleteImageKey === MAIN_IMAGE_KEY) {
        temp.unshift({
          imageKey: MAIN_IMAGE_PLACEHOLDER_TEXT,
          renderElement: () => (
            <ImageEditor
              text={MAIN_IMAGE_PLACEHOLDER_TEXT}
              type={MAIN_PLACEHOLDER_IMAGE_KEY}
              onUpload={({ blobImg, type }) =>
                onUpload({ imageType: type, blobImg })
              }
            />
          ),
        });
      }
      return temp;
    }) as unknown as AddImage[]);
  };

  useEffect(() => {
    if (editableProductData) {
      const simpleDataKeys = {
        name: { isRequired: true, value: "" },
        category: { isRequired: true, value: "" },
        description: { isRequired: true, value: "" },
        originZipCode: { isRequired: true, value: "" },
        shop_id: { isRequired: true, value: "" },
        sku: { isRequired: false, value: "" },
        price: { isRequired: true, value: 0 },
        quantity: { isRequired: true, value: 0 },
        tags: { isRequired: true, value: [] },
        estimated_processing_time: { isRequired: true, value: 0 },
        published: { isRequired: false, value: false },
        has_variant: { isRequired: false, value: false },
      };

      const productKeys = Object.keys(editableProductData);
      setProductDetails((prevProductDetails) => {
        const tempProductDetails = cloneDeep(prevProductDetails);
        Object.keys(simpleDataKeys).forEach((simpleDataKey) => {
          tempProductDetails[simpleDataKey as "name"] = {
            isRequired: simpleDataKeys[simpleDataKey as "name"].isRequired,
            value: productKeys.includes(simpleDataKey)
              ? editableProductData[simpleDataKey as "name"]
              : simpleDataKeys[simpleDataKey as "name"].value,
            elementState: "normal",
          };
        });
        tempProductDetails.weight_value = {
          isRequired: true,
          value: productKeys.includes("weight")
            ? editableProductData.weight.value
            : 0,
          elementState: "normal",
        };

        tempProductDetails.weight_units = {
          isRequired: true,
          value: productKeys.includes("weight")
            ? editableProductData.weight.units
            : "",
          elementState: "normal",
        };

        tempProductDetails.dimensions_height = {
          isRequired: true,
          value: productKeys.includes("dimensions")
            ? editableProductData.dimensions.height
            : 0,
          elementState: "normal",
        };

        tempProductDetails.dimensions_width = {
          isRequired: true,
          value: productKeys.includes("dimensions")
            ? editableProductData.dimensions.width
            : 0,
          elementState: "normal",
        };

        tempProductDetails.dimensions_length = {
          isRequired: true,
          value: productKeys.includes("dimensions")
            ? editableProductData.dimensions.length
            : 0,
          elementState: "normal",
        };

        tempProductDetails.dimensions_units = {
          isRequired: true,
          value: productKeys.includes("dimensions")
            ? editableProductData.dimensions.units
            : "",
          elementState: "normal",
        };

        const updatedVariants = (
          editableProductData.variants as Variant[]
        )?.reduce((productVariants, variant) => {
          // eslint-disable-next-line no-param-reassign
          productVariants[uuid()] = Object.keys(variant).reduce(
            (updatedVariant, variantKey) => {
              const key = variantKey as "_id";
              // eslint-disable-next-line no-param-reassign
              updatedVariant[key] = variant?.[key];
              return updatedVariant;
            },
            {} as Variant
          );
          return productVariants;
        }, {} as { [key: string]: Variant });
        tempProductDetails.variants = {
          value: productKeys.includes("variants") ? updatedVariants : {},
          isRequired: false,
          elementState: "normal",
        };
        return tempProductDetails;
      });
    }

    const setEditableImages = async () => {
      const mainImageUrlIdx = (
        editableProductData?.product_images as string[]
      ).findIndex((img) => img.endsWith(editableProductData?.main_image));
      const mainImageUrl = editableProductData?.product_images[mainImageUrlIdx];
      const _mainImageId = editableProductData?.main_image;
      if (editableProductData?.main_image) {
        setMainImageId(editableProductData?.main_image);
      }
      const otherImages = editableProductData?.product_images?.filter(
        (img) => !img.endsWith(editableProductData?.main_image)
      );
      const otherImageBlobs = await Promise.all(
        otherImages?.map((product_image: string) =>
          convertUrlToBlob(product_image)
        )
      );
      const mainImageBlob = await convertUrlToBlob(mainImageUrl);
      const editableUploadedImages = [
        {
          imageKey: mainImageId || _mainImageId || MAIN_IMAGE_KEY,
          type: MAIN_IMAGE_KEY,
          blobImg: mainImageBlob,
          renderElement: () => (
            <ImageView
              imageUrl={mainImageUrl}
              imageKey={mainImageId || _mainImageId || MAIN_IMAGE_KEY}
              onRemove={onRemove}
            />
          ),
        },
        ...otherImages.map((productImage: string, index: number) => ({
          imageKey: productImage,
          blobImg: otherImageBlobs[index],
          renderElement: () => (
            <ImageView
              imageUrl={productImage}
              imageKey={productImage}
              onRemove={onRemove}
            />
          ),
        })),
      ];
      const allImages: AddImage[] = [...editableUploadedImages];
      if (
        allImages.findIndex(
          (image) => image?.imageKey === OTHER_PLACEHOLDER_IMAGE_KEY
        ) === -1
      ) {
        allImages.push(getOtherPlaceHolderImage());
      }
      setImages(allImages);
    };
    if (editableProductData?.image_url) {
      setEditableImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableProductData]);

  useEffect(() => {
    const shopIdActual = shops?.length > 0 ? shops[0].shop_id : urlShopId;
    setShopId(shopIdActual);
  }, [shops, urlShopId]);

  const isSaveDisabled =
    Object.keys(productDetails).some(
      (key) =>
        productDetails[key as "name"].elementState === "error" ||
        (productDetails.variants &&
          Object.keys(productDetails.variants?.value).length === 0)
    ) ||
    // main image is mandatory if there is at least one image
    (images.length > 0
      ? !images.find(
          (image) => image.imageKey === (mainImageId || MAIN_IMAGE_KEY)
        )
      : false);

  const getVariants = (variantsToCreate: string[]) => {
    const variantsPayload = Object.values(productDetails.variants.value).map(
      (variantPaylod) => {
        const variantDataValues = {
          quantity: +variantPaylod.quantity,
          original_price: variantPaylod.original_price || 0,
          discount_percentage: variantPaylod.discount_percentage || 0,
        } as Record<string, unknown>;
        variantsToCreate.forEach((variantKey) => {
          const variantTypedKey = variantKey;
          variantDataValues[variantKey] =
            variantPaylod[variantTypedKey as "variant_key"];
        });
        variantDataValues.price = variantPaylod.price || 0;

        return variantDataValues;
      }
    );
    return variantsPayload;
  };

  const getVariantSelections = () => {
    const variantValues = Object.values(productDetails.variants.value);
    const variantsToCreate = Object.keys(variantSelections).reduce(
      (validVariants: string[], variant) => {
        if (
          variantValues.every(
            (variantValue) => variantValue[variant as "variant_key"]
          )
        ) {
          validVariants.push(variant);
        }
        return validVariants;
      },
      []
    );
    const createVariantPayloads =
      variantsToCreate.length > 0
        ? variantsToCreate.map((variantKey) => {
            return {
              property: variantKey,
              values: variantSelections[variantKey as "variant_key"],
            };
          })
        : [];
    return { variant_selections: createVariantPayloads, variantsToCreate };
  };

  const getImages = () => {
    const imagesToBeUploaded = [
      ...images
        .filter(({ imageKey, blobImg }) => {
          return (
            imageKey &&
            (uuidValidate(imageKey) || imageKey === MAIN_IMAGE_KEY) &&
            blobImg
          );
        })
        ?.map((image) => image),
    ];
    return imagesToBeUploaded;
  };

  const { mutate: createProductMutation, isLoading: createProductLoading } =
    useMutation(createProduct, {
      onSuccess: (data) => {
        toast.success("Uploaded product details successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (data?.data?.value?._id) {
          setTimeout(() => history.push(`/shop/${shopId}`), 1500);
        }
      },
      onError: (err: Error) => {
        toast.error(
          err?.message || "Failed to update product details, Please try again",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      },
    });

  const { mutate: updateProductMutation, isLoading: updateProductLoading } =
    useMutation(updateProduct, {
      onSuccess: () => {
        toast.success("Updated product details successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => history.push(`/shop/${shopId}`), 1500);
      },
      onError: (err: Error) => {
        toast.error(
          err?.message || "Failed to update product details, Please try again",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      },
    });

  const uploadProductData = () => {
    const payload = {
      category: productDetails.category.value,
      name: productDetails.name.value,
      description: productDetails.description.value,
      shop_id: shopId,
      originZipCode: +productDetails.originZipCode.value,
      estimated_processing_time:
        +productDetails.estimated_processing_time.value,
      dimensions: {
        height: +productDetails.dimensions_height.value,
        width: +productDetails.dimensions_width.value,
        length: +productDetails.dimensions_length.value,
        units: productDetails.dimensions_units.value,
      },
      weight: {
        value: +productDetails.weight_value.value,
        units: productDetails.weight_units.value,
      },
      published: productDetails.published.value,
      has_variant: productDetails?.has_variant?.value || false,
    };
    const { variant_selections, variantsToCreate } = getVariantSelections();
    const productPayload = {
      ...payload,
      variant_selections,
      variants: getVariants(variantsToCreate) as unknown as Variant[],
      published: productDetails.published.value,
    } as ProductPayload;
    const imagesToUpload = getImages();
    if (!urlProductId) {
      createProductMutation({
        productData: productPayload,
        images: imagesToUpload,
      });
    } else {
      updateProductMutation({
        id: urlProductId,
        payload: productPayload,
        images: imagesToUpload,
        deletedImages: toDeleteImages,
      });
    }
  };

  const updateProductDetails = ({
    key,
    value,
    valueKey = "value",
    isEdited = false,
  }: {
    key: string;
    value?: string | number | Record<string, unknown> | boolean;
    valueKey?: string;
    isEdited?: boolean;
  }) => {
    if (isEdited) {
      setFieldsEdited(true);
    }
    setProductDetails((prevProductDetails) => {
      const tempPrevProductDetails = { ...prevProductDetails };
      // added a sample key to typecast
      tempPrevProductDetails[key as "name"][
        valueKey as "value" | "elementState"
      ] = value as "error" | "normal";
      return tempPrevProductDetails;
    });
  };

  useEffect(() => {
    if (shopId) {
      updateProductDetails({
        key: "shop_id",
        value: shopId,
      });
    }
  }, [shopId]);

  const isProductUploading = useMemo(
    () => createProductLoading || updateProductLoading,
    [createProductLoading, updateProductLoading]
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (fieldsEdited) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [fieldsEdited]);

  const variantsData = useMemo(
    () => Object.values(productDetails?.variants?.value || []),
    [productDetails?.variants]
  );

  return (
    <div
      className={`flex flex-col m-3 mt-8 mb-5 md:mt-12 text-primary-color-100 justify-center ${
        isMobile ? "overflow-x-hidden" : ""
      }`}
    >
      {showDeleteImageModal && (
        <Modal
          isForceClose={!showDeleteImageModal}
          overrideModalStyle="justify-evenly md:max-w-2xl w-full"
          overrideModalContainerStyle="items-end sm:items-center"
          onClose={() => setShowDeleteImageModal(false)}
        >
          <div className="flex flex-col justify-center w-full h-48">
            <h3 className="px-4 text-center">
              Are you sure you want to delete this image?
            </h3>
            <p>
              If deleting the image make sure to hit save on this page to update
              the images on the product
            </p>
            <div className="flex pt-6 justify-evenly">
              <Button
                text="Cancel"
                size="sm"
                type="outline"
                onClick={() => setShowDeleteImageModal(false)}
              />
              <Button
                text="Delete"
                size="sm"
                type="primary"
                onClick={onDeleteImage}
              />
            </div>
          </div>
        </Modal>
      )}
      {showCancelConfirmModal && (
        <Modal
          isForceClose={!showCancelConfirmModal}
          overrideModalStyle="justify-evenly md:max-w-2xl w-1/2"
          overrideModalContainerStyle="items-end sm:items-center"
          onClose={() => setShowCancelConfirmModal(false)}
        >
          <div className="flex flex-col justify-center w-full h-48">
            <p className="text-xl text-center font-poppins">
              Changes you made may not be saved. Leave?
            </p>
            <div className="flex pt-6 justify-evenly">
              <Button
                text="Cancel"
                size="sm"
                type="outline"
                onClick={() => setShowCancelConfirmModal(false)}
              />
              <Button
                text="Leave"
                size="sm"
                type="primary"
                onClick={() => history.push(`/shop/${shopId}`)}
              />
            </div>
          </div>
        </Modal>
      )}
      <div className="flex flex-col gap-4 p-4 md:gap-12">
        {!isPreviewMode && (
          <a
            href={`${window.location.origin}/shop/${shopId}`}
            className="flex w-full mt-3 mb-6 text-xl font-bold"
          >
            <img src={arrowLeftIcon} className="mr-2" alt="previous" />
            Go to my shop
          </a>
        )}
        {!isPreviewMode && (
          <div className="flex gap-10 mb-5 text-xl font-semibold font-poppins md:text-4xl">
            <div className="flex text-left">
              {urlProductId ? `${productDetails.name.value}` : "Add New Item"}
            </div>
          </div>
        )}
        <AddOrEditProductContext.Provider
          value={{
            productDetails,
            updateProductDetails,
            images,
            setImages,
            variantSelections,
            setVariantSelections,
          }}
        >
          {!isPreviewMode ? (
            <>
              <div className="p-4 border border-solid rounded-md shadow border-primary-color-100 border-opacity-10">
                <Images onUpload={onUpload} />
              </div>
              <ItemDetails />
            </>
          ) : (
            <Product
              main_product_image_url=""
              other_product_image_urls={[]}
              readOnly
              title={productDetails.name.value}
              price={+(variantsData[0]?.price || 0)}
              rating={0}
              reviewsCount={0}
            />
          )}
        </AddOrEditProductContext.Provider>
        <div className="flex flex-col">
          {!isPreviewMode && (
            <div
              className={`flex flex-col bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative mb-10 max-w-full md:max-w-md lg:max-w-lg ${
                isMobile ? "text-sm" : "text-base"
              }`}
              role="alert"
            >
              <span className="text-left">
                {" "}
                Checking this option will allow this product to be searchable
                for public. Please make sure the product information is correct
                before enabling this option. You can come back anytime to
                enable/disable this option.
              </span>
              <div className="mt-4">
                <CheckBox
                  text="Publish"
                  isEnabled
                  isSelected={
                    productDetails && productDetails.published
                      ? productDetails.published.value
                      : false
                  }
                  onChange={(values) => {
                    updateProductDetails({
                      key: "published",
                      value: values.isSelected,
                      isEdited: true,
                    });
                  }}
                />
              </div>
            </div>
          )}
          {!isPreviewMode && (
            <div
              className={`${
                isMobile ? "flex-col justify-center w-full" : "flex w-9/12"
              }`}
            >
              <div
                className={`flex ${
                  isMobile ? "w-full justify-center mb-5" : ""
                }`}
              >
                <Button
                  containerClassName="w-6/12 sm:w-5/12 md:w-full"
                  text="Cancel"
                  size="md"
                  type="outline"
                  loading={false}
                  disabled={isProductUploading}
                  onClick={() => {
                    if (fieldsEdited) {
                      setShowCancelConfirmModal(true);
                    } else {
                      history.push(`/shop/${shopId}`);
                    }
                  }}
                />
              </div>
              <div
                className={`flex ${
                  isMobile ? "justify-center" : "justify-end"
                } w-full gap-5 md:gap-10`}
              >
                {!isMobile ? (
                  <Button
                    text="Preview"
                    size="md"
                    type="outline"
                    disabled={isSaveDisabled}
                    onClick={() => {
                      // Get canvas contents as a data URL
                      const previewImages = images?.reduce(
                        (
                          imageUrls: { imageKey: string; url: string }[],
                          image
                        ) => {
                          if (image?.blobImg) {
                            imageUrls.push({
                              imageKey: image.imageKey,
                              url: URL.createObjectURL(image.blobImg),
                            });
                          }
                          return imageUrls;
                        },
                        []
                      );
                      deleteItemStartWith(`preview-${shopId}`);
                      setItem(
                        `preview-${shopId}-${previewId}`,
                        JSON.stringify({ ...productDetails, previewImages })
                      );
                      window.open(
                        `/add-product?shop_id=${shopId}&preview_id=${previewId}&preview=true`,
                        "_blank"
                      );
                    }}
                  />
                ) : null}
                <Button
                  text="Save"
                  size="md"
                  type="primary"
                  containerClassName="w-6/12 sm:w-5/12"
                  disabled={isSaveDisabled || isProductUploading}
                  loading={isProductUploading}
                  onClick={uploadProductData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOrEditProduct;
