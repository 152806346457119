import React from "react";
import * as ReactSlider from "rc-slider";
import "rc-slider/assets/index.css";

const { createSliderWithTooltip } = ReactSlider;
const Range = createSliderWithTooltip(ReactSlider.Range);

const Slider = ({
  min,
  max,
  allowCross = false,
  sliderWrapperClass,
  unit,
  marks,
  defaultValue,
  onSliderAfterChange,
}: {
  min: number;
  max: number;
  allowCross?: boolean;
  sliderWrapperClass: string;
  unit: string;
  marks: { [key: number]: number };
  defaultValue: Array<number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSliderAfterChange: (data: Array<any>) => void;
}): JSX.Element => {
  return (
    <div className={sliderWrapperClass}>
      <Range
        min={min}
        max={max}
        allowCross={allowCross}
        marks={marks}
        defaultValue={defaultValue}
        handleStyle={[
          {
            backgroundColor: "#3F415B",
            borderColor: "#3F415B",
          },
        ]}
        trackStyle={[
          {
            backgroundColor: "#3F415B",
            borderColor: "#3F415B",
          },
        ]}
        onAfterChange={onSliderAfterChange}
        tipFormatter={(value: number) => `${value}${unit}`}
      />
    </div>
  );
};

Slider.defaultProps = {
  allowCross: false,
};

export default Slider;
