/* eslint-disable import/prefer-default-export */
import { SyntheticEvent } from "react";

// icons
import { imageUnavailablePlaceHolder } from "../../assets";

export const setPlaceHolderImageOnError = (
  e: SyntheticEvent<HTMLImageElement, Event>
): SyntheticEvent<HTMLImageElement, Event> => {
  e.currentTarget.src = imageUnavailablePlaceHolder;
  e.currentTarget.className = `${e.currentTarget.className} error`;
  e.currentTarget.style.backgroundColor = "lightgray";
  return e;
};

export const nonVariantTypeKeys = [
  "_id",
  "price",
  "original_price",
  "quantity",
  "discount_percentage",
];
